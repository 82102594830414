import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Element from "../Element";
import ButtonNext from "./ButtonNext"
import AppStateContext from "appState/AppStateContext";
import ControlTrad from "assets/trad/ControlTrad";
import useOrientation from "orientation/Orientation"

const Wrapper = styled.div`
    display:flex;
    padding: 2vh
`

const Panel = (props) => {
    const [step, setStep] = useState(0)
    const appState = React.useContext(AppStateContext);
    let state = appState.state.get("state");
    let numQuestions = appState.state.get("quizNumQuestions");
    let quizIndexQuestion = appState.state.get("quizIndexQuestion");

    let controlSteps = () => {
        switch (state) {
            case "QUIZ_QUESTION":
                setStep(0);
                break;
            case "QUIZ_CORRECTION":
                setStep(1);
                break;
            case "QUIZ_GRAPH":
                setStep(2);
                break;
            case "QUIZ_SCORE":
                setStep(3);
                break;
            default:
                break;
        }
    }

    useEffect(() => {
        controlSteps();
    })


    let display1 = (
        <Wrapper>
            <ButtonNext
                active={step <= 0}
                text={ControlTrad.getTrad("btnCorregir")}
                onClick={(e) => { controlSteps() }}
            />
            <ButtonNext
                active={step === 1}
                text={ControlTrad.getTrad("btnGraphic")}
                onClick={(e) => {
                    controlSteps()
                }}
            />
            <ButtonNext
                active={step === 2}
                text={numQuestions - 1 === quizIndexQuestion ? ControlTrad.getTrad("btnFinal") : ControlTrad.getTrad("btnScore")}
                onClick={(e) => {
                    controlSteps()
                }}
            />
            <ButtonNext
                active={step === 3}
                text={numQuestions - 1 === quizIndexQuestion ? ControlTrad.getTrad("btnExitQuiz") : ControlTrad.getTrad("next")}
                onClick={(e) => {
                    numQuestions - 1 === quizIndexQuestion ? controlSteps() : setStep(0)
                    controlSteps()
                }}
            />
        </Wrapper>
    )

    let display2 = (
        <Wrapper>
            {step <= 0 ?
                <ButtonNext
                    text={ControlTrad.getTrad("btnCorregir")}
                    onClick={(e) => { controlSteps() }}
                />
                : <></>}
            {step == 1 ?
                <ButtonNext
                    text={ControlTrad.getTrad("btnGraphic")}
                    onClick={(e) => {
                        controlSteps()
                    }}
                />
                : <></>}
            {step == 2 ?
                <ButtonNext
                    text={numQuestions - 1 === quizIndexQuestion ? ControlTrad.getTrad("btnFinal") : ControlTrad.getTrad("btnScore")}
                    onClick={(e) => {
                        controlSteps()
                    }}
                />
                : <></>}
            {step == 3 ?
                <ButtonNext
                active={step === 3}
                text={numQuestions - 1 === quizIndexQuestion ? ControlTrad.getTrad("btnExitQuiz") : ControlTrad.getTrad("next")}
                onClick={(e) => {
                    numQuestions - 1 === quizIndexQuestion ? controlSteps() : setStep(0)
                    controlSteps()
                }}
            />
                : <></>}

        </Wrapper>
    );

    let display = useOrientation() ? display1 : display2;

    return (
        <Element params={props.params}>
            {display}
        </Element>
    );
};

export default Panel;