import React, { useState, useEffect } from "react"
import styled from "styled-components";

import Element from "../Element";
import Websockets from "Websockets/Websockets";
import AppStateContext from "appState/AppStateContext";
import ControlTrad from "assets/trad/ControlTrad";
import Tasting from "assets/tasting/Tasting";
import FetchUtils from "Fetch/FetchUtils";
import SoundUtils from "assets/utils/SoundUtils";

const Wrapper = styled.div`
  font-size: 2.5vh;
  height:100%;
  width:100%;
  display:flex;
  flex-direction: column;
  border-radius: 1vh;
`;

const MenuWrapper = styled.div`
  display:flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 0.5vh;
`;

const TextBox = styled.span`
  ${(props) => `
    color: white;
    cursor: ${props.buttonLike ? "pointer" : "auto"};
    margin-right: 0.5vh;
    font-size: 2.5vh;
  `}
`;

const AssistantNotes = styled.textarea`
  ${(props) => `
    border-radius: 1vh;
    padding: 1vh 2vh;
    border: 0px;
    flex: 1 1 0%;
    font-size: 2vh;
    outline: none;
    resize: none;
    color: ${props.editable ? "white" : "black"};
    background-color: ${props.editable ? "#4d4d4d" : "white"};
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transition : 0.2s
  `}
`;

const styles = {
  whiteColor: {
    backgroundColor: "white",
    color: "black"
  },
  blackColor: {
    backgroundColor: "#4d4d4d",
    color: "white"
  },
  textBoxCursor: {
    cursor: "auto"
  }
}

const Notes = (props) => {
  const appState = React.useContext(AppStateContext);
  let currentProductRef, wineAnnotation;

  if(Tasting.getType() === "live"){
    currentProductRef = appState.state.get(`currentWine`)?.reference;
    wineAnnotation = appState.state.get('currentUserAssessmentsParameters')?.annotation;
  } else {
    currentProductRef = props.params?.currentProductRef;
    wineAnnotation = props.params?.currentProductData?.[currentProductRef]?.annotation;
  }

  const [editable, setEditable] = useState(true);
  const [assistantAnn, setAssistantAnn] = useState(wineAnnotation || "");
  const [oldWine, setOldWine] = useState(currentProductRef)

  useEffect(() => {
    if (assistantAnn === "") {
      let newProductRef = props.params?.currentProductRef;
      let newAnnotation = props.params?.currentProductData?.[newProductRef]?.annotation;
      if (newAnnotation) {
        setAssistantAnn(newAnnotation);
      }
    }
  }, [props.params]);

  const updateEditable = (e) => {
    let newEditable = !editable;

    if (newEditable) {
      sendAnswer(assistantAnn, currentProductRef);
    }

    setEditable(newEditable);
  }

  const sendAnswer = (value, wine) => {
    if(Tasting.getType() === "live"){
      Websockets.send("SLAVE_PRODUCT_PARAMETER", [
        wine,
        "annotation",
        value,
      ], false);
    } else {
      props.params.setDataWine((prev) => ({
          ...prev,
        [props.params.currentProductRef]: {
          ...prev[props.params.currentProductRef],
            annotation: value
          }
      }));
      FetchUtils.sendProductParameter(props.params.currentProductRef, "annotation", value);
    }
  };

  useEffect(() => {
    if(!editable) setEditable(true);
    if (Tasting.getType() === "live") {
      setOldWine(currentProductRef);
      sendAnswer(assistantAnn, oldWine);
      setAssistantAnn(wineAnnotation || "");
    } else {
      setAssistantAnn(props.params?.currentProductData?.[currentProductRef]?.annotation || "");
    }
  }, [currentProductRef]);

  return (
    <Element params={props.params} >
      <Wrapper>
        <MenuWrapper>
          <TextBox
            style={styles.textBoxCursor}>
            {ControlTrad.getTrad("myAnotations")}
          </TextBox>
        </MenuWrapper>
        <AssistantNotes
          value={assistantAnn}
          placeholder={ControlTrad.getTrad("insertNotes")}
          editable={editable}
          readOnly={editable}
          onClick={editable ? updateEditable : null}
          onBlur={(e) => {
            updateEditable(e);
            setAssistantAnn(e.currentTarget.value);
          }}
          onChange={(e) => {
            setAssistantAnn(e.currentTarget.value)
          }}
        >
        </AssistantNotes>
      </Wrapper>
    </Element>
  );
};
export default Notes;