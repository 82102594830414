import Element from "../Element";
import React, { useState, useEffect } from "react";
import AppStateContext from "appState/AppStateContext";

import JitsiConfig from "./JitsiConfig";
import Websockets from "Websockets/Websockets";
import Fetch from "Fetch/Fetch";
import styled from "styled-components";

import Tasting from "assets/tasting/Tasting";
import User from "assets/user/User";
import Icon from "./Icon";

import MicOnIcon from "assets/images/icons/ic_mic_on_white.svg";
import MicOffIcon from "assets/images/icons/ic_mic_off_white.svg";

import CameraOnIcon from "assets/images/icons/ic_camera_on_white.svg";
import CameraOffIcon from "assets/images/icons/ic_camera_off_white.svg";

import TileViewOnIcon from "assets/images/icons/ic_tileview_on_white.svg";
import TileViewOffIcon from "assets/images/icons/ic_tileview_off_white.svg";

import FullscreenOnIcon from "assets/images/icons/ic_fullscreen_on_white.svg";
import FullscreenOffIcon from "assets/images/icons/ic_fullscreen_off_white.svg";

import ScreenShareOnIcon from "assets/images/icons/ic_screenshare_on_white.svg";
import ScreenShareOffIcon from "assets/images/icons/ic_screenshare_off_white.svg";
import Button from "components/Shared/Button"


import HandIcon from "assets/images/icons/ic_hand_white.svg";
import ModalMessage from "components/Sandbox/ModalMessage";
import Colours from "assets/custom/Custom";
import ControlTrad from "assets/trad/ControlTrad";


const ButtonWrapper = styled.div`
  ${(props) => `
  position: absolute;
  bottom: 0;
  left:0;
  display: flex;
  padding: 1vh;
  z-index:7 !important;
  &>div{
    margin: 0 1vh 0 0;
  }
  &>div:nth-child(0){
    visibility: ${props.permissionGiven ? "visible" : "hidden"}
  }
  `}
`;

const Wrapper = styled.div`
  ${(props) => `
    width: ${props.fullscreen ? "95vw" : /*"calc(100% - 2vh)"*/ /*"100%"*/ "inherit"};
    height: ${props.fullscreen ? "95vh" : /*"calc(100% - 2vh)"*/  /*"100%"*/ "inherit"};
    z-index: 5;
    overflow-y: hidden;
    position: ${props.fullscreen ? "absolute" : "relative"};

    ${props.fullscreen ? `
      left: 50%;
      top: 50%;
      transform: translate(-50%,-50%);
    ` : ``}

    border: 1vh solid ${props.permissionGiven ? Colours.getColour("mainColor") : "transparent"};
  `}
`;

const ButtonDoubts = styled.div`
  width:100%;
  @media (max-width: 426px) {
    width:95%;
  }
`

const WrapperPop = styled.div`
  display:flex;
  flex-direction : column;
  margin : 4.5vh;
  margin-top:3vh;
  margin-bottom:2vh;
  @media (max-width: 426px) {
    margin : 1rem;
  }
`

const DoubtText = styled.textarea`
  flex-grow: 1;
  resize: none;
  border: 0px;
  height: 10rem;
  border-radius: 3px;
  padding: 1rem;
  font-family: "Open Sans",sans-serif!important;
`

const WrapperButtons = styled.div`
  display:flex;
  flex-direction : row;
  justify-content: space-between;
  margin-top: 3vh;
`

const ComponentWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;  
  height: 100%;
  width: 100%;
`

const JitsiScreenBlock = styled.div`
  position:absolute;
  height:100%;
  width:100%;
  z-index:6;
  bottom:0;
  left:0;
`

const JitsiIframe = (props) => {
  const jitsiContainerId = "jitsi-container-id";
  const [permissions, setPermissions] = useState({
    microphone: false,
    camera: false,
  });
  const [askInstructorShown, setAskInstructorShown] = useState(false)

  const [isSharingScreen, setIsSharingScreen] = useState(false)
  const [premiumDomain, setPremiumDomain] = useState(undefined);
  const [jitsi, setJitsi] = useState(null);
  const [camAvailable, setCamAvailable] = useState(false);
  const [micAvailable, setMicAvailable] = useState(false);

  const appState = React.useContext(AppStateContext);

  const DOMAIN = "meets.linked.wine"; // "meet.jit.si"; //premiumDomain ? "jitsi.uptasting.online" : "meet.jit.si";
  const jitsiConfig = appState.state.get("jitsiConfig");
  const assistantJitsiConfig = appState.state.get("assistantJitsiConfig");
  const jitsiActive = appState.state.get("jitsiActive") && Tasting.isStreamingAllowed();
  const [input, setInput] = useState("");

  const audios = [
    { sound: "https://upwine.es/audios/asistente-micro-activo.mp3", label: 'audioAssistantMic' },
    { sound: "https://upwine.es/audios/instructor-pregunta.mp3", label: 'audioInstructorDoubt'},
    { sound: "https://upwine.es/audios/instructor-micro-pedido.mp3", label: 'audioInstructorMic'},
  ]

  const playSound = (src) => {
    const sound = new Audio(src);
    sound.load();
    sound.play();
  }

  const loadJitsiScript = () => {
    let resolveLoadJitsiScriptPromise = null;

    const loadJitsiScriptPromise = new Promise((resolve) => {
      resolveLoadJitsiScriptPromise = resolve;
    });

    const script = document.createElement("script");
    script.src = `https://${DOMAIN}/external_api.js`;
    script.async = true;
    script.onload = () => resolveLoadJitsiScriptPromise(true);
    document.body.appendChild(script);

    return loadJitsiScriptPromise;
  };

  const initialiseJitsi = async () => {
    if (!window.JitsiMeetExternalAPI) {
      await loadJitsiScript();
    }
    let config = {
      parentNode: document.getElementById(jitsiContainerId),
      roomName: "UPTASTING" + Tasting.getPublicCode(),
      userInfo: {
        displayName: User.getName(),
      },
    };
    config = {
      ...config,
      ...JitsiConfig.getConfig(props.params)
    };

    const _jitsi = new window.JitsiMeetExternalAPI(DOMAIN, config);

    _jitsi.addListener("screenSharingStatusChanged", (e) => {
      setIsSharingScreen(e.on);
    });

    _jitsi.addListener("videoConferenceJoined", onVideoConferenceJoined);

    setJitsi(_jitsi);
  };

  const onVideoConferenceJoined = (data) => {
    console.log("Jitsi video conference joined", data);
    setTimeout(() => {
      Websockets.send("SLAVE_JITSI_READY", [], false);
    }, 5000);
  };

  const askSpeakRequest = () => {
    Websockets.send("SLAVE_JITSI_SPEAK_REQUEST", [],false);
  };
  const toggleTileView = () => {
    Websockets.send("JITSI_GRID", [],true);
  };
  const toggleFullscreen = () => {
    Websockets.send("JITSI_MAXIMIZE", [],true);
  };
  const toggleShareScreen = () => {
    jitsi.executeCommand("toggleShareScreen");
  }
  const updatePermissions = (key, val) => {
    setPermissions((prev) => ({
      ...prev,
      [key]: val,
    }));
  };
  const setPermission = (key, val) => {
    if (key === "microphone") {
      jitsi.isAudioMuted().then((muted) => {
        if (!muted !== val) {
          jitsi.executeCommand("toggleAudio");
          updatePermissions(key, val);
        }
      });
    }
    if (key === "camera") {
      jitsi.isVideoMuted().then((muted) => {
        if (!muted !== val) {
          jitsi.executeCommand("toggleVideo");
          updatePermissions(key, val);
        }
      });
    }
  };

  const sendDoubt = () => {
    if (input !== "") {
      Websockets.send("SLAVE_DOUBT", input,false);
    }
    setAskInstructorShown(false);
  }

  useEffect(() => {
    Fetch.getPlan(appState.state.get("tastingId")).then(plan => {
      setPremiumDomain(plan.dedicatedJitsi);
    })
  }, [appState.state.get("state")])

  useEffect(() => {
    if (jitsi !== null) {
      jitsi.addListener("tileViewChanged", (tile) => {
        if(tile !== jitsiConfig?.mosaico) jitsi.executeCommand("setTileView", jitsiConfig?.mosaico); 
      })
      jitsi.executeCommand("setTileView", jitsiConfig?.mosaico);
    }
  }, [jitsi, jitsiConfig?.mosaico]);

  useEffect(() => {
    if (jitsi !== null && !props.params.isInstructor) {
      if (assistantJitsiConfig?.permissionGiven) {
        playSound(audios[0].sound);
        jitsi.isVideoAvailable().then(available => {
          setPermission("camera", true);
          setCamAvailable(available);
        })

        jitsi.isAudioAvailable().then(available => {
          setPermission("microphone", true);
          setMicAvailable(available);
        })
      } else {
        setPermission("microphone", false);
        setPermission("camera", false);
      }
    }
  }, [assistantJitsiConfig?.permissionGiven]);

  useEffect(() => {
    if (jitsiActive && premiumDomain !== undefined) initialiseJitsi();
    return () => jitsi?.dispose?.();
  }, [jitsiActive, premiumDomain]);


  useEffect(() => {
    if (askInstructorShown) { setInput("") }
  }, [askInstructorShown]);

  useEffect(() => {
    if (jitsiConfig?.fullscreen && User.getNavegation() === "safari") {
      if (props?.params?.showToast) {
        props.params.showToast("El instructor ha maximizar la pantalla completa, pero esta función no disponible para Safari.");
      }
    }
  }, [jitsiConfig?.fullscreen]);


  return (
    <>
      <Element params={props.params} id="youtubeIframe">
        <ComponentWrapper>
          {jitsiActive ?
            <Wrapper
              fullscreen={User.getNavegation() !== "safari" && jitsiConfig?.fullscreen}
              permissionGiven={
                assistantJitsiConfig?.permissionGiven && !props.params.isInstructor
              }
            >
              <div id={jitsiContainerId} style={{ height: "100%" }} />
              <JitsiScreenBlock />
              <ButtonWrapper>
                {!props.params.isInstructor ? (
                  assistantJitsiConfig?.permissionGiven ? (
                    <>
                      <Icon
                        hint={permissions.microphone ? "" : ControlTrad.getTrad("waitInstructorMic")}
                        src={permissions.microphone ? MicOnIcon : MicOffIcon}
                        active={micAvailable}
                        onClick={() =>setPermission("microphone", !permissions.microphone)}
                      />
                      <Icon
                        hint={permissions.camera ? ControlTrad.getTrad("cameraOFF") : ControlTrad.getTrad("cameraON")}
                        src={permissions.camera ? CameraOnIcon : CameraOffIcon}
                        active={camAvailable}
                        onClick={() => setPermission("camera", !permissions.camera)}
                      />
                    </>
                  ) : (
                    <>
                      <Icon src={MicOffIcon} active={false} hint={ControlTrad.getTrad("handsUpStream")} />
                      <Icon
                        hint={ControlTrad.getTrad("sendMic")}
                        src={HandIcon}
                        active={!assistantJitsiConfig?.speakRequest}
                        onClick={
                          assistantJitsiConfig?.speakRequest
                            ? () => { }
                            : () => askSpeakRequest()
                        }
                      />
                    </>
                  )
                ) : (
                  <>
                    <Icon
                      hint={jitsiConfig?.mosaico ? ControlTrad.getTrad("individualMode") : ControlTrad.getTrad("groupMode")}
                      src={
                        jitsiConfig?.mosaico ? TileViewOffIcon : TileViewOnIcon
                      }
                      onClick={() => toggleTileView()}
                    />
                    <Icon
                      hint={jitsiConfig?.fullscreen ? ControlTrad.getTrad("minScreen") : ControlTrad.getTrad("maxScreen")}
                      src={
                        jitsiConfig?.fullscreen
                          ? FullscreenOffIcon
                          : FullscreenOnIcon
                      }
                      onClick={() => toggleFullscreen()}
                    />
                    <Icon
                      hint={isSharingScreen ? ControlTrad.getTrad("notSharedScreen") : ControlTrad.getTrad("sharedScreen")}
                      src={
                        isSharingScreen
                          ? ScreenShareOnIcon
                          : ScreenShareOffIcon
                      }
                      onClick={() => toggleShareScreen()}
                    />
                  </>
                )}
              </ButtonWrapper>
              
            </Wrapper>
            : <></>}
          {!props.params.isInstructor && jitsiActive ?
            <ButtonDoubts params={props.params}>
              <Button styles={"width: 85%;"} value={ControlTrad.getTrad("askInstructor")} onClick={() => { setAskInstructorShown(true) }} />
            </ButtonDoubts>
            :
            <></>}
        </ComponentWrapper>

      </Element>
      <ModalMessage 
        isShown={askInstructorShown}
        setIsShown={setAskInstructorShown} 
        title={ControlTrad.getTrad("askInstructor")}
        styleHeader={"font-size: 1.25rem;padding-left: 5vh;border-top-left-radius:1vh;border-top-right-radius:1vh;"}
        styleMain={"border-bottom-right-radius:1vh;border-bottom-left-radius:1vh;"}
        styleClose={"display:none;"}
        boxSize={"border-radius:1vh;"}>

        <WrapperPop>
          <DoubtText onChange={(e) => setInput(e.currentTarget.value)} placeholder = {ControlTrad.getTrad("yourCommentsPlace")} />
          <WrapperButtons>
            <Button type={"cancel"} value={ControlTrad.getTrad("cancel")} onClick={() => { sendDoubt(); }} styles={"background-color:black; color: white; border :0.3vh solid black; font-size:2vh; width:15vh;"} />
            <Button value={ControlTrad.getTrad("sendQuestion")} onClick={() => { sendDoubt(); }} styles={"font-size:2vh; width:15vh;"} />
          </WrapperButtons>
        </WrapperPop>
      </ModalMessage>
    </>
  );
};

JitsiIframe.defaultProps = {
  isInstructor: false,
};
export default JitsiIframe;
