import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import NoSleep from 'nosleep.js';
import styled from "styled-components";

import "App.css";

import Home from "components/Home/Home";
import InstructorEngine from "components/Instructor/InstructorEngine"
import AssistantEngine from "components/Assistant/AssistantEngine"
import InstructorLogin from "components/InstructorLogin/InstructorLogin"
import PublicTastingEngine from "components/PublicTasting/PublicTastingEngine"
import InstructorTastingSelection from "components/InstructorTastingSelection/InstructorTastingSelection"

import ModeButton from "components/Shared/ModeButton"
import SandboxEngine from "components/Sandbox/SandboxEngine"
import Firebase from "./Firebase";

import BackgroundCover from "assets/images/background_assistant.png";
import ModalMessage from "components/Sandbox/ModalMessage";
import User from "assets/user/User";
import ControlTrad from "assets/trad/ControlTrad";

const AppWrapper = styled.div`
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.8)), url("${BackgroundCover}") no-repeat center;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width:100vw;
  height: 100vh;
  z-index: -1;
  position: absolute;
  -webkit-transition: background-image 5s linear;
  transition: background-image 5s linear;
`

function App() {
  let f = new Firebase().firebase.default;
  const [user, setUser] = useState();
  const [block, setBlock] = useState(false);

  console.log('REACT_APP_ENVIRONMENT', process.env.REACT_APP_ENVIRONMENT);

  function PrivateRoute({path, view}) {
    const getUser = () => {
      f.auth().onAuthStateChanged(
        function (user) {
          if(user === null && path !== "/instructorLogin"){ window.location.replace('/instructorLogin')}
          else{setUser(user)}
        }
      )
    }

    useEffect(()=>{getUser()},[path])

    return(
      <>
        <div>{view}</div>
      </>
    );

  }

  useEffect(() => {
    var noSleep = new NoSleep();

    function enableNoSleep() {
      document.removeEventListener('touchstart', enableNoSleep, false);
      document.removeEventListener('click', enableNoSleep, false);
      noSleep.enable();
      console.log("No sleep enabled");
    }

    document.addEventListener('click', enableNoSleep, false);
    document.addEventListener('touchstart', enableNoSleep, false);

    setTimeout(() => {
      {/*User.getNavegation() === "safari" ? setBlock(true) :*/ User.getNavegation() === "IE" ? setBlock(true) : setBlock(false) }
    }, 2000)
  }, []);


  return (
    <AppWrapper id={"id_wrapper"}>
      <Router>
        <Switch>
          <PrivateRoute path="/instructorLogin" view={<InstructorLogin/>}/>
          <PrivateRoute path="/instructorHome"  view={<InstructorTastingSelection user={user} />}/>
          <PrivateRoute path="/instructor/:id" view={<InstructorEngine/>} />
          <Route path="/sandbox"><SandboxEngine /></Route>
          <Route path="/publicInstructor/:id"><InstructorEngine /></Route>
          <Route path="/publicTasting/:uid/:tid"><PublicTastingEngine /></Route>
          <Route path="/:id"><AssistantEngine/></Route>
          <Route path="/"><Home /></Route>
        </Switch>
      </Router>
      <ModeButton/>
      <ModalMessage 
        isShown={block}
        disableCloseButton={true}
      >
        <p style={{fontSize  :"2rem"}}>{User.getNavegation() === "safari" ? ControlTrad.getTrad("safariBlock") : User.getNavegation() === "IE"  ? ControlTrad.getTrad("IEBlock") : ""}</p>
      </ModalMessage>
    </AppWrapper>

  );
}

export default App;
