import React, { useEffect, useState } from "react";

import styled from "styled-components";
import AppStateContext from "appState/AppStateContext";
import Button from "components/Shared/Button"
import User from "assets/user/User";
import Fetch from "Fetch/Fetch";
import ControlTrad from "assets/trad/ControlTrad";
import Tasting from "assets/tasting/Tasting";
import Custom from "assets/custom/Custom";
import Element from "../Element";
import FetchUtils from "Fetch/FetchUtils";

const Wrapper = styled.div`
    background:#fff;
    border-radius:1rem;
    max-height: 100%;
    overflow: auto;
`

const Header = styled.div`
  ${(props) => `
    display: "flex";
    padding: 1.5vh 2vh;
    background: ${Custom.getColour("mainColor")};
    color:white;
    border-radius: 1rem;
    border-bottom-left-radius: 0vh;
    border-bottom-right-radius: 0vh;
    @media (max-width: 426px) {
        font-size:1.1rem;
    }
    @media (min-width: 426px) and (pointer: coarse) {
        font-size:1.3rem;
    }
    > div:first-child {
        flex: 1;
        text-align: left;
    }
  `}
`;
const TitleWrapper = styled.div`
  ${props => `
    ${props.styles}
  `}
`

const BodyWrapper = styled.div`
    display:flex;
    padding : 0 3vh;
    flex-direction:column;
`

const LabelBox = styled.label`
    text-align: left;
    padding: 0 0 0.5vh;
    margin-top:1vh;
    @media (max-width: 426px) {
        padding: 0 0 0.25rem
    }
`

const InputBox = styled.input`
    font-size: 2vh;
    background-color: rgba(216,216,216,0.8);
    border : 0;
    margin-top:0.2vh;
    border-radius:1vh;
    padding:0.5vh;
    height:3.5vh;
    ::-webkit-input-placeholder: {
        color: rgba(255, 255, 255, 0.7);
        font-size: 3vh;
    }
    @media (max-width: 426px) {
        font-size: 2.5vh;
        height: 4vh;
    }
`


const ButtonsWrapper = styled.div`
    display: block;
    padding : 2vh 3vh;
    justify-content: space-between;
    @media (max-width: 426px) {
        padding : 1rem;
        flex-direction: column;
    }
`

const Check = styled.input`
    color: white;
    width: 2vh;
    height: 2vh;
    @media (max-width: 426px){
        width: 1rem;
        height: 1rem;
        marginTop: 0;
    }
`

const DivCheck = styled.div`
    padding:2vh 3vh ;
    text-align: initial;
`

const Label = styled.label`
    text-align:left;
    font-size: 2vh;
    color: #000;
    @media (max-width: 426px){
        font-size: 1rem;
    }
`
const LabelLinks = styled.a`
    font-size: 2vh;
    color: #000;
    @media (max-width: 426px){
        font-size: 1rem;
    }
`

const ErrorSpan = styled.span`
    display:none;
    color:red;
    font-weight: bold;
`

const QuizWinnerModal = (props) => {
    const [name, setName] = useState(User.getNotDefaultName());
    const [email, setEmail] = useState("");
    const [check, setCheck] = useState(false);

    const appState = React.useContext(AppStateContext);
    let assistants, prize;

    if (Tasting.getType() === 'live') {
        assistants = appState.state.get("assistants");
        prize = appState.state?.get("quizPrize");
    }

    function validateEmail(email) {
        email = email.replace(/^ /, '').replace(/ $/, '');
        setEmail(email);
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    const checkWinner = () => {
        let correct = true;
        if (name !== null && name !== undefined && name?.length === 0) {
            document.getElementById("quizWinnerNameError").style.display = "block"
            correct = false;
        } else {
            document.getElementById("quizWinnerNameError").style.display = "none";
        }

        if (!validateEmail(email)) {
            document.getElementById("quizWinnerEmailError").style.display = "block"
            correct = false;
        } else {
            document.getElementById("quizWinnerEmailError").style.display = "none";
        }

        if (!check) {
            correct = false;
            document.getElementById("quizWinnerCheckError").style.display = "block"
        } else {
            document.getElementById("quizWinnerCheckError").style.display = "none";
        }

        if (correct) {
            let assistantId = User.getId();
            if (assistantId) {
                User.setName(name);
                User.setEmail(email);
                FetchUtils.createOrUpdateUser();

                if (Tasting.getType() === "podcast") {
                    FetchUtils.saveUserAsQuizWinner(props?.params?.productRef, props?.params?.prize);
                }
            } else {
                console.error("Can not save quiz winner, assistant id not found");
            }
            props.params.toast(ControlTrad.getTrad("quizConfirmationEmailToast").replace("{email}", email));
            props.params.onClick();
        } 
    }

    const skip = () => {
        props.params.toast(ControlTrad.getTrad("quizSkipEmailToast"));
        props.params.onClick();
    }

    useEffect(() => {
        setName(User.getNotDefaultName())
    }, [User.getName()]);

    return (
        <Element params={props.params}>
            <Wrapper>
                <Header header={props.header} styleHeader={props?.styleHeader}>
                    <TitleWrapper>{ControlTrad.getTrad("winQuizFillData")}</TitleWrapper>
                </Header>
                <BodyWrapper>
                    <LabelBox>{ControlTrad.getTrad("nameForm")}</LabelBox>

                    <InputBox
                        value={name}
                        placeholder={ControlTrad.getTrad("insertName")}
                        onChange={(e) => { setName(e.currentTarget.value) }}
                        onKeyDown={(e) => {
                            if (e.which == 13) e.target.blur();
                        }} />
                    <ErrorSpan id={"quizWinnerNameError"}>{ControlTrad.getTrad("inputLobbyError")}</ErrorSpan>
                    <LabelBox>{ControlTrad.getTrad("emailForm")}</LabelBox>
                    <InputBox
                        value={email}
                        placeholder={ControlTrad.getTrad("emailPlace")}
                        onChange={(e) => { setEmail(e.currentTarget.value) }}
                        onKeyDown={(e) => {
                            if (e.which == 13) e.target.blur();
                        }} />
                    <ErrorSpan id={"quizWinnerEmailError"}>{ControlTrad.getTrad("errorEmail")}</ErrorSpan>
                </BodyWrapper>
                <DivCheck>
                    <Check
                        type={"checkbox"}
                        id={"checkTerm"}
                        checked={check}
                        onChange={(e) => { setCheck(!check); }}
                    ></Check>
                    <Label for={"checkTerm"}>
                        {ControlTrad.getTrad("terms") + " "}
                        <b>
                            <LabelLinks href={Custom.Links.termsOfUse}
                                target={"_blank"}
                                rel="noopener noreferrer">
                                {ControlTrad.getTrad("conditions1")}
                            </LabelLinks>
                        </b>
                        {" " + ControlTrad.getTrad("conditions2") + " "}
                        <b>
                            <LabelLinks href={Custom.Links.privacyPolicies}
                                target={"_blank"}
                                rel="noopener noreferrer">
                                {ControlTrad.getTrad("conditions3")}
                            </LabelLinks>
                        </b>
                    </Label>
                </DivCheck>
                <ErrorSpan id={"quizWinnerCheckError"}>{ControlTrad.getTrad("termerror")}</ErrorSpan>

                <ButtonsWrapper>
                    <Button
                        styles={"flex:1; margin:1vh auto; display:block;"}
                        value={ControlTrad.getTrad("win")}
                        /*active={name !== "" && email !== "" && check}*/
                        onClick={checkWinner}
                    />
                    <Button
                        type={"cancel"}
                        styles={"flex:1; margin:1vh auto; display:block;"}
                        value={ControlTrad.getTrad("noWin")}
                        onClick={skip}
                    />
                </ButtonsWrapper>
            </Wrapper>
        </Element>
    );
};

export default QuizWinnerModal;