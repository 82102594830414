import React, { useState, useEffect } from "react";
import Element from "../Element";
import Button from "components/Shared/Button"
import ModalMessage from "components/Sandbox/ModalMessage";
import AppStateContext from "appState/AppStateContext";
import Chat from "./Chat";
import ControlTrad from "assets/trad/ControlTrad";
import LocalStorage from "LocalStorage/LocalStorage";
import Tasting from "assets/tasting/Tasting";

const DisplayChatButton = (props) => {
    const appState = React.useContext(AppStateContext);
    const INSTANCE_ID = Tasting.getPublicCode();

    let allChat = appState.state.get("chat");

    const [popUp, setPopUp] = useState(false);
    const [readedMsgs, setReadedMsgs] = useState(null);
    const [undreadedMsgCount, setUndreadedMsgCount] = useState(0);

    useEffect(() => {
        if (popUp) {
            setUndreadedMsgCount(0);
        } else {
            if (readedMsgs == null) {
                let _readedMsgs = LocalStorage.get(INSTANCE_ID + "/readedMsgs") || [];
                setReadedMsgs(_readedMsgs);
            }
            let unreadedMsg = (allChat || []).filter(msg => !(readedMsgs || []).includes(msg?.id)).length;
            setUndreadedMsgCount(unreadedMsg);
        }
    }, [allChat, readedMsgs, popUp]);

    const readAll = () => {
        let readed = [];
        (allChat || []).forEach(msg => {
            readed.push(msg?.id);
        });
        setReadedMsgs(readed);
        LocalStorage.set(INSTANCE_ID + "/readedMsgs", readed);
    };

    const setShownPopup = (state) => {
        if (!state) readAll();
        setPopUp(state)
    }

    return (
        <>
            <Element params={props.params}>
                <Button value={props.params.text} badge={undreadedMsgCount} onClick={() => {
                    setPopUp(true);
                    readAll();
                }} />
            </Element>
            <ModalMessage
                position={"width:50%;"}
                boxSize={"height: 100%; width:100%; border-bottom-left-radius:0.1vh;"}
                title={ControlTrad.getTrad("chat")}
                isShown={popUp}
                setIsShown={setShownPopup}
                background={false}
                isChat={true}>
                <Chat isInstructor={true}/>
            </ModalMessage>
        </>
    );
};

export default DisplayChatButton;
