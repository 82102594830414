import React from "react";
import Element from "../Element";
import Button from "components/Shared/Button"
import Websockets from "Websockets/Websockets";

const StartQuizButton = (props) => {

    const loadQuiz = () =>{
        Websockets.send("NEXT_STEP", [],true);
    }


    return (
        <Element params={props.params}>
            <Button 
                value={props.params.text}
                onClick={(e) => { loadQuiz() }}
            />
        </Element>
    );
};

export default StartQuizButton;
