import React, { useEffect, useState } from "react";

import styled from "styled-components";
import Element from "../Element";
import AppStateContext from "appState/AppStateContext";
import Tasting from "assets/tasting/Tasting";

const Wrapper = styled.div`
    ${props => `
        color: white;
        font-size: 3vh;
        border: 0.3vh solid;
        border-radius:1vh;
        height: 100%;
        width: 100%;
    `}
`;

const Timer = (props) => {
    const appState = React.useContext(AppStateContext);
    let timer, state;

    if (Tasting.getType() === 'live') {
        timer = appState?.state.get("quizTimer");
    } else {
        timer = props.params.time;
        state = props.params.state;
        setTimeout(() => {
            if(state === "QUIZ_QUESTION"){
                if(timer !==0){
                    let time = timer;
                    props.params.setTime(time-1);
                }else{
                    props.params.onTimed();
                }

            }
        }, 1000);
    }

    return (
        <Element params={props.params}>
            <Wrapper params={props.params}>
                {"00:"+ (timer < 10 ? "0"+timer : timer)}
            </Wrapper>
        </Element>
    );

}
export default Timer;