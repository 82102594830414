
import React, { useState } from "react";
import styled from "styled-components";

import ModalMessage from "./ModalMessage";

const Wrapper = styled.div`
  background: green;
  height: 100vh;
  width: 100vw;
`;

const SandboxComponent = () => {
  const [modalIsShown1, setModalIsShown1] = useState(false);
  const [modalIsShown2, setModalIsShown2] = useState(false);

  return (
    <Wrapper>
      <div
        onClick={() => {
          setModalIsShown1(true);
        }}
      >
        Open 1
      </div>
      <div
        onClick={() => {
          setModalIsShown2(true);
        }}
      >
        Open 2
      </div>
      <ModalMessage isShown={modalIsShown1} setIsShown={setModalIsShown1} title={'Título chulo'}>
        <div>eeee</div>
        <div>eeee</div>
        <div>eeee</div>
        <div>eeee</div>
        <div>eeee</div>
        <div>eeee</div>
        <div>eeee</div>
        <div>eeee</div>
        <div>eeee</div>
        <div>eeee</div>
        <div>eeee</div>
        <div>eeee</div>
        <div>eeee</div>
        <div>eeee</div>
        <div>eeee</div>
        <div>eeee</div>
        <div>eeee</div>
        <div>eeee</div>
        <div>eeee</div>
        <div>eeee</div>
        <div>eeee</div> 
        <div>eeee</div>
        <div>eeee</div>
        <div>eeee</div>
        <div>eeee</div>
        <div>eeee</div>
      </ModalMessage>
      <ModalMessage isShown={modalIsShown2} setIsShown={setModalIsShown2} header={false} background={false}>
        <div>eeee</div>
        <div>eeee</div>
        <div>eeee</div>
        <div onClick={() =>setModalIsShown2(false)}>eeee</div>
      </ModalMessage>
    </Wrapper>
  );
};

export default SandboxComponent;
