import React, { useEffect, useState } from "react";

import Websockets from "Websockets/Websockets";
import AppStateContext from "appState/AppStateContext";
import MutableComponent from "../Composer/MutableComponent";
import ControlTrad from "assets/trad/ControlTrad";
import Colours from "assets/custom/Custom";
import useOrientation from "orientation/Orientation"

const InstructorComponent = () => {
  const [currentDisplay, setCurrentDisplay] = useState(0);
  const [currentState, setCurrentState] = useState("INITIAL");
  const [jitsiPosition, setJitsiPosition] = useState("18/51/50/99")
  const [popUpError, setPopUpError] = useState(false);
  const [textError, setTextError] = useState(ControlTrad.getTrad("errorPopUpText"));
  const [titleError, setTitleError] = useState(ControlTrad.getTrad("errorPopUpTitle"));

  const appState = React.useContext(AppStateContext);
  const state = appState.state.get("state");

  const layoutType = appState.state.get("tastType") ? appState.state.get("tastType") : "CONVENTIONAL_TAST";
  console.log(layoutType === "BLIND_TEMPLATE");

  Websockets.setError = setPopUpError;
  Websockets.setMessageError = setTextError;
  Websockets.setTitleError = setTitleError;

  //0: LOBBY:
  //1: WINE VALORATE
  //2: QUIZ HOME
  //3: QUIZ QUESTION
  const newStep = (params) => {
    Websockets.send(params, [], true);
  }

  const changeState = () => {
    if (state) {
      setCurrentDisplay(0);
      setCurrentState(state);
      //setPopUpError(false)
    }
    else {
      /*setPopUpError(true);
      //here goes the differents texts for the popUp error (WIP).
      setTextError("Conectando al servidor...")
      setTitleError("Conectando al servidor")*/
    }
  }

  const display = {
    "INITIAL": [
      [
        ["loadingComponent", {
          position: "1/1/100/100",
          active: true,
          ready: () => changeState()
        }],
      ]

    ],
    "WAITING": [
      [
        [
          "rectangle",
          { position: "1/1/100/50", styles: "background: rgb(243, 241, 241);" },
        ],
        [
          "rectangle",
          { position: "1/1/10/50", styles: "background: black;" },
        ],
        ["assistantsCount", { position: "1/3/9/50" }],
        ["assistantsListLobby", { position: "9/1/100/50", }],

        [
          "rectangle",
          { position: "1/50/100/100", styles: "background: white" },
        ],
        [
          "rectangle",
          { position: "1/50/9/100", styles: "background: black;" },
        ],
        ["text", {
          position: "1/51/9/100",
          text: ControlTrad.getTrad("titleStreaming"),
        }],

        ["iButtonStartJitsi", { position: "10/51/16/99", active: ControlTrad.getTrad("cancelStreaming"), text: ControlTrad.getTrad("streaming") }],
        //["jitsiIframe", { position: "18/51/50/99", isInstructor: true }],

        [
          "rectangle",
          { position: "61/50/67/100", styles: "background: black;" },
        ],
        ["text", {
          position: "62/51/66/100",
          text: ControlTrad.getTrad("dataSession"),
        }],
        ["text", {
          position: "68/51/72/74",
          text: ControlTrad.getTrad("tastName"),
          styles: "color:black; text-align:center; font-weight:600; font-size:2.8vh;"
        }],
        ["tastingType", { position: "72/51/76/74", styles: "color:black;font-weight:normal;font-size:2.8vh;" }],
        ["text", {
          position: "68/79/72/96",
          text: ControlTrad.getTrad("tastCode"),
          styles: "color:black; text-align:center;font-size:2.8vh;font-weight:600;"
        }],

        ["tastingPublicCode", {
          position: "73/80/78/95",
          shared: true
        }],
        ["text", {
          position: "79/76/82/99",
          text: ControlTrad.getTrad("shareCode"),
          styles: "font-size:1.7vh;text-align:center;color:black;font-style: italic;"
        }],

        ["displayChatButton", { position: "85/51/91/74", text: ControlTrad.getTrad("showChat") }],
        ["showMessagesButton", { position: "85/76/91/99", text: ControlTrad.getTrad("doubts") }],
        ["startTastingButton", {
          position: "93/51/97/99",
          noJitsi: false,
          next: () => {
            newStep("NEXT_STEP")
            setCurrentDisplay(1);
          },
        }],
      ],

    ],
    "IN_PROGRESS": [
      [
        //["jitsiIframe", { position: "75/1/100/25", isInstructor: true }],
        [
          "rectangle",//banner
          { position: "1/1/100/25", styles: "background: white;" },
        ],
        [
          "rectangle",//tag wine
          { position: "1/1/9/32", styles: "background: black;" },
        ],
        [
          "rectangle",//tag assistants
          { position: layoutType === "SIMPLE_TEMPLATE" ? "1/25/9/100" : "1/25/9/66", styles: "background: black;" },
        ],
        ["previousStepButton", { position: "2/2/8/8", text: ControlTrad.getTrad("previous") }],
        ["nextStepButton", {
          position: "2/17/8/24",
          text: ControlTrad.getTrad("next"),
          textFinally: ControlTrad.getTrad("next"),
          onClick: () => {
            newStep("NEXT_STEP")
          },
        }],
        ["wineIndex", { position: "9/1/13/25", styles: 'color: black; font-size: 3vh !important;', wineCount: true }],
        ["wineImage", { position: "15/1/35/25", instructor: true }],
        ["wineName", {
          position: "36/1/40/25",
          styles: 'color: black; font-weight:bold; font-size: 2.5vh',
          moreInfoStyles: "font-size: 2vh; padding: 0.5vh 1vh; margin: 0vh;",
          wrapperStyles: "flex-direction: row;",
          infoButton: true, instructor: true, iconType: false
        }],
        ["startQuizButton", {
          position: layoutType === "BLIND_TEMPLATE" ? "54/3/59/22" : "54/3/59/22",
          text: ControlTrad.getTrad("activateQuiz"),
          onClick: () => {
            newStep("LOAD_QUIZ")
          }
        }],
        ["showProductInfoButton", { 
          disable: layoutType === "BLIND_TEMPLATE" ? false : true, 
          position: "47/3/53/22" }],
        ["showMessagesButton", { position: "61/3/66/22", text: ControlTrad.getTrad("doubts") }],
        ["displayChatButton", { position: "68/3/71/22", text: ControlTrad.getTrad("showChat") }],
        ["tastingPublicCode", {
          position: "1/47/9/66",
          styles: "color:white; font-size:3vh; background-color: transparent;",
          shared: true,
        }],
        ["assistantsCount", { position: "1/27/9/48" }],
        ["averageRate", { position: layoutType === "BLIND_TEMPLATE" ? "39/3/46/17" : "39/1/46/25" }],
        ["averageProductPrice", { disable: layoutType === "BLIND_TEMPLATE" ? false : true, position: "40/17/46/23" }],
        ["assistantsList", { position: layoutType === "SIMPLE_TEMPLATE" ? "9/25/100/100" : "9/25/100/66" }],
        ["assessmentsList", { position: "1/66/100/100", styles: layoutType === "SIMPLE_TEMPLATE" ? "display: none;" : null }]
      ],
    ],
    "QUIZ_PRESENTATION": [
      [
        ["jitsiIframe", { position: "75/1/100/25", isInstructor: true }],

        [
          "button",
          {
            position: "13/36/20/45",
            text: '<- ' + ControlTrad.getTrad("back"),
            onClick: () => {
              newStep("PREVIOUS_STEP")
            },
          },
        ],
        [
          "buttonStart",
          {
            position: "13/60/20/74",
            text: ControlTrad.getTrad("startQuiz"),
          },
        ],
        [
          "userListInstructor",
          {
            position: "25/35/90/75",
          },
        ],
      ]
    ],
    "QUIZ_QUESTION": [
      [
        //["jitsiIframe", { position: "75/1/100/25", isInstructor: true }],

        [
          "controlPanel",
          {
            position: "1/1/10/100",
          },
        ],
        [
          "questionsPanel",
          {
            position: "15/3/51/40",
          },
        ],
        [
          "insQuizRanking",
          {
            position: "17/41/90/95",
          },
        ],
      ]
    ],
    "QUIZ_CORRECTION": [
      [
        //["jitsiIframe", { position: "75/1/100/25", isInstructor: true }],

        [
          "controlPanel",
          {
            position: "1/1/10/100",
          },
        ],
        [
          "questionsPanel",
          {
            position: "15/3/51/40",
          },
        ],
        [
          "insQuizRanking",
          {
            position: "17/41/90/95",
          },
        ],
      ]
    ],
    "QUIZ_GRAPH": [
      [
        //["jitsiIframe", { position: "75/1/100/25", isInstructor: true }],

        [
          "controlPanel",
          {
            position: "1/1/10/100",
          },
        ],
        [
          "questionsPanel",
          {
            position: "15/3/51/40",
          },
        ],
        [
          "chartsPanel",
          {
            position: "17/41/90/95",
          },
        ]

      ]
    ],
    "QUIZ_SCORE": [
      [
        //["jitsiIframe", { position: "75/1/100/25", isInstructor: true }],
        [
          "controlPanel",
          {
            position: "1/1/10/100",
          },
        ],
        [
          "questionsPanel",
          {
            position: "15/3/51/40",
          },
        ],
        [
          "insQuizRanking",
          {
            position: "17/41/90/95",
          },
        ],
      ]
    ],
    "FINISHED": [
      [
        [
          "rectangle",
          { position: "1/1/100/50", styles: "background: rgb(243, 241, 241);" },
        ],
        [
          "rectangle",
          { position: "1/1/9/50", styles: "background: black;" },
        ],
        ["text", { position: "1/3/9/50", text: ControlTrad.getTrad("status") }],
        ["text", { position: "13/7/20/50", text: ControlTrad.getTrad("titleEnd"), styles: "color:black;font-weight: bold; font-size: 4.5vh;" }],
        ["text", {
          position: "22/3/30/48",
          text: ControlTrad.getTrad("info_5"),
          styles: " color:black;font-size: 2vh; text-align:center"
        }],
        ["displayChatButton", { position: "35/2/42/24", text: ControlTrad.getTrad("showChat") }],
        ["showMessagesButton", { position: "35/25/42/48", text: ControlTrad.getTrad("doubts") }],
        ["iFinishButton", { 
          position: "40/2/50/48",
          text: ControlTrad.getTrad("endTast"),
          onClick: () => { window.location.replace('../');},
          noJitsi : false,
        }],

        [
          "rectangle",
          { position: "1/50/100/100", styles: "background: white" },
        ],
        [
          "rectangle",
          { position: "1/50/9/100", styles: "background: black;" },
        ],
        ["assistantsCount", {
          position: "1/51/9/100",
        }],
        ["assistantListFinished", { position: "9/51/100/100", styles: "background: white" }],
        //["jitsiIframe", { position: "75/1/100/25", isInstructor: true }],

      ]
    ],
  }

  const displayPortrait = {
    "INITIAL": [
      [
        ["loadingComponent", {
          position: "1/1/100/100",
          active: true,
          ready: () => changeState()
        }],
      ]
    ],
    "WAITING": [
      [
        ["rectangle", { // Full screen backgroubnd
          position: "1/1/100/100",
          styles: "background: white;",
        }],

        ["rectangle", { // Session data header backgroubnd
          position: "1/1/7/50",
          styles: "background: black;"
        }],
        ["text", {
          position: "1/3/7/47",
          text: ControlTrad.getTrad("dataSession"),
          styles: "font-size: 2.7vh"
        }],
        ["text", {
          position: "8/3/13/24",
          text: ControlTrad.getTrad("tastName"),
          styles: "color:black; text-align:center; font-weight:600; font-size:2.5vh;"
        }],
        ["tastingType", {
          position: "13/3/25/24",
          styles: "color:black; font-weight:normal; font-size:2.8vh; align-items: start"
        }],
        ["text", {
          position: "8/26/13/48",
          text: ControlTrad.getTrad("codeEvent"),
          styles: "color:black; text-align:center; font-weight:600; font-size:2.5vh;"
        }],
        ["tastingPublicCode", {
          position: "13/26/25/48",
          shared: false,
          responsiveShared: true
        }],
        ["text", {
          position: "25/3/31/48",
          text: ControlTrad.getTrad("shareCode"),
          styles: "color:black; text-align:center; font-weight:600; font-size:2.5vh;"
        }],
        ["startTastingButton", {
          position: "30/3/41/47",
          noJitsi: true,
          next: () => {
            newStep("NEXT_STEP")
            setCurrentDisplay(1);
          },
        }],
        ["rectangle", { // Assistant count backgroubnd
          position: "41/1/47/50",
          styles: "background: black;"
        }],
        ["assistantsCount", {
          position: "41/3/47/47",
          styles: "font-size: 2.7vh"
        }],
        ["assistantsListLobby", {
          position: "47/1/100/50",
        }],
      ]
    ],
    "IN_PROGRESS": [
      [
        ["rectangle", { // All the screen
          position: "1/1/100/50", styles: "background: white;"
        }],
        ["rectangle", { // Navegation Bar
          position: "1/1/9/50", styles: "background: black;"
        }],
        ["previousStepButton", {
          position: "2/2/8/16",
          text: ControlTrad.getTrad("previous")
        }],
        ["nextStepButton", {
          position: "2/35/8/49",
          text: ControlTrad.getTrad("next"),
          textFinally: ControlTrad.getTrad("next"),
          onClick: () => {
            newStep("NEXT_STEP")
          },
        }],
        ["WineIndexMobile", {
          position: "2/16/8/35",
          styles: 'color: white; font-size: 1rem !important;',
          wineCount: true
        }],
        ["wineName", {
          position: "12/23/25/49",
          styles: 'color: black; font-weight:bold; font-size: 2.7vh',
          infoButton: true, instructor: true, iconType: false
        }],
        ["wineImage", {
          position: "12/1/40/22",
          instructor: true
        }],
        ["averageRate", {
          position: "26/23/35/49"
        }],
        ["averageProductPrice", {
          position: "35/23/40/49",
          disable: layoutType === "BLIND_TEMPLATE" ? false : true
        }],
        ["tastingPublicCode", {
          position: "40/1/45/50",
          styles: "color:black; font-size:1rem; background-color:transparent; flex-direction: row; width: fit-content; margin: auto;",
          shared: false,
          responsiveShared: true
        }],
        ["showProductInfoButton", {
          disable: layoutType === "BLIND_TEMPLATE" ? false : true, position: "46/3/52/24",
          btnStyles: "white-space: nowrap;overflow: hidden;"
        }],
        ["startQuizButton", {
          position: layoutType === "BLIND_TEMPLATE" ? "46/26/52/48" : "46/3/52/48",
          text: ControlTrad.getTrad("activateQuiz"),
          btnStyles: "white-space: nowrap;overflow: hidden;",
          onClick: () => {
            newStep("LOAD_QUIZ")
          }
        }],
        ["rectangle",// AssistantCountBackground
          { position: "54/1/60/50", styles: " background: black;" },
        ],
        ["assistantsCount", { position: "54/3/60/47", styles: "font-size:2.7vh;" }],
        ["assistantsList", { position: "60/1/100/50" }],
        /*["button", {
          position: "75/3/83/48",
          text: ControlTrad.getTrad("viewAssistantsAssessments"),
          onClick: () => {
            setCurrentDisplay(2);
          }
        }],*/

      ],
       /*[
        [
          "rectangle",//banner
          { position: "1/1/100/50", styles: "background: white;" },
        ],
        [
          "rectangle",//tag wine
          { position: "1/1/9/50", styles: "background: black;" },
        ],
        ["button", { position: "2/2/8/16", text: ControlTrad.getTrad("back"), onClick: () => { setCurrentDisplay(0); } }],
        ["assistantsCount", { position: "1/18/13/50", styles: "font-size:1.4rem" }],
        ["assistantsList", { position: "10/1/100/50" }],
      ], [
        [
          "rectangle",//banner
          { position: "1/1/100/50", styles: "background: white;" },
        ],
        [
          "rectangle",//tag wine
          { position: "1/1/9/50", styles: "background: black;" },
        ],
        ["button", { position: "2/2/8/16", text: ControlTrad.getTrad("back"), onClick: () => { setCurrentDisplay(0); } }],
        ["assessmentsList", { position: "9/1/100/50" }]

      ]*/
    ],
    "QUIZ_PRESENTATION": [
      [
        [
          "button",
          {
            position: "5/3/14/24",
            text: ControlTrad.getTrad("back"),
            onClick: () => {
              newStep("PREVIOUS_STEP")
            },
          },
        ],
        [
          "buttonStart",
          {
            position: "5/27/14/48",
            text: ControlTrad.getTrad("startQuiz"),
          },
        ],
        [
          "userListInstructor",
          {
            position: "20/2/100/49",
          },
        ],
      ]
    ],
    "QUIZ_QUESTION": [
      [
        [
          "controlPanel",
          {
            position: "1/1/10/50",
          },
        ],
        [
          "questionsPanel",
          {
            position: "15/3/50/48",
          },
        ],
        [
          "insQuizRanking",
          {
            position: "55/3/96/48",
          },
        ],
      ]
    ],
    "QUIZ_CORRECTION": [
      [
        [
          "controlPanel",
          {
            position: "1/1/10/50",
          },
        ],
        [
          "questionsPanel",
          {
            position: "15/3/50/48",
          },
        ],
        [
          "insQuizRanking",
          {
            position: "55/3/96/48",
          },
        ],
      ]
    ],
    "QUIZ_GRAPH": [
      [
        [
          "controlPanel",
          {
            position: "1/1/10/50",
          },
        ],
        [
          "chartsResponsive",
          {
            position: "17/5/90/43",
          }
        ]
      ]
    ],
    "QUIZ_SCORE": [
      [
        [
          "controlPanel",
          {
            position: "1/1/10/50",
          },
        ],
        [
          "insQuizRanking",
          {
            position: "15/3/96/48",
          },
        ]
      ]
    ],
    "FINISHED": [
      [
        ["rectangle", {
          position: "1/1/100/100",
          styles: "background: white;",
        }],
        ["rectangle",
          { position: "1/1/11/50", styles: "background: black;" },
        ],
        ["assistantsCount", { position: "1/3/11/50", styles: "font-size:1.5rem" }],
        ["assistantListFinished", { position: "11/1/61/50", }],
        ["text", { position: "62/1/69/50", text: ControlTrad.getTrad("titleEnd"), styles: "color:black; text-align:center; font-weight: bold; font-size: 4.5vh;" }],
        ["text", {
          position: "70/3/81/48",
          text: ControlTrad.getTrad("info_5"),
          styles: " color:black;font-size: 2vh; text-align:center"
        }],
        ["iFinishButton", { 
          position: "82/2/88/48",
          text: ControlTrad.getTrad("endTast"),
          onClick: () => { window.location.replace('../'); },
          noJitsi : true,
           }],

      ]
    ]
  }

  useEffect(() => {
    if (currentState === "INITIAL") changeState();
    if (currentState !== "INITIAL") changeState();
  }, [state])

  useEffect(() => {
    switch (currentState) {
      case "INITIAL":
      case "WAITING":
        setJitsiPosition("18/51/59/99")
        break;
      case "IN_PROGRESS":
        setJitsiPosition("75/1/100/25")
        break;
      case "QUIZ_PRESENTATION":
      case "QUIZ_QUESTION":
      case "QUIZ_CORRECTION":
      case "QUIZ_GRAPH":
      case "QUIZ_SCORE":
        setJitsiPosition("75/1/100/25");
        break;
      case "FINISHED":
        setJitsiPosition("75/1/100/25");
        break;
      default:
        break;
    }
  }, [currentState, currentDisplay])

  let displayLoad = useOrientation() ? display[currentState][currentDisplay] : displayPortrait[currentState][currentDisplay];
  if (currentState !== null) {
    //displayLoad = ;
  }

  const loadCustom = () => {
    if (appState.state.get("theme") !== undefined && appState.state.get("theme") !== null) {
      loadCustomColors();
      //loadCustomBackground();
    }
  }
  const loadCustomColors = () => {
    if (appState.state.get("theme")?.mainColor !== undefined) Colours.setColour("mainColor", appState.state.get("theme")?.mainColor)
    if (appState.state.get("theme")?.secondaryColor !== undefined) Colours.setColour("secondaryColor", appState.state.get("theme")?.secondaryColor)
  }

  useEffect(() => {
    loadCustom();
  }, [appState.state.get("theme")])

  return <MutableComponent
    popUpError={popUpError}
    setPopUpError={setPopUpError}
    textError={textError}
    titleError={titleError}
    showVideo={true}
    videoPosition={jitsiPosition}
    isInstructor={true}
    disableCloseButton={false}
    display={displayLoad === undefined ? [] : displayLoad} />;
};

export default InstructorComponent;
