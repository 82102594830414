import React from "react";
import styled from "styled-components";

const ChartResponsive = (props) => {

    const NumAnswers = styled.div`
        flex:1;
        @media (max-width: 426px) {
            flex:none;
        }
    `;

    const Bar = styled.div`
        width : 100%;
        color:white;
    
        display:none;
    `;

    const Wrapper = styled.div`
        display:flex;
        flex-direction:column;
        color: black;
        font-size: 2.5vh;
        text-align : center;
        height:100%;
        justify-content: space-evenly;
        border-bottom : black solid;
        flex:1;
    `;

    const Num = styled.div`
        color: black;
        font-size: 3vh;
    `;

    return (
        <Wrapper>
            <NumAnswers>
                <Num>
                    {props.answers}
                    {props.answers === 1 ? " respuesta " : " respuestas "}
                    {props.answers === 1 ? props.correct ? "correcta" : "incorrecta" : props.correct ? " correctas" : "incorrectas"}
                    {" a "}
                </Num>
            </NumAnswers>
            <Bar params={props.params}></Bar>
            <div>{props.answer}</div>
        </Wrapper>
    );
}
export default ChartResponsive;