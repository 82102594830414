import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import AppStateProvider from "appState/AppStateProvider";
import Tasting from "assets/tasting/Tasting"
import Websockets from "Websockets/Websockets";
import Fetch from "Fetch/Fetch";
import LoadingComponent from "components/Composer/Elements/LoadingComponent/LoadingComponent";
import User from "assets/user/User";
import Custom from "assets/custom/Custom";
import Firebase from "../../Firebase";
import ModalMessage from "components/Sandbox/ModalMessage";
import ControlTrad from "assets/trad/ControlTrad";
import Button from "components/Shared/Button";
import Text from "components/Composer/Elements/Text/Text";
import AssistantComponent from "./AssistantComponent";
import FetchUtils from "Fetch/FetchUtils";
import ReactHtmlParser from 'react-html-parser'; 

function Assistant(params) {
  let { id } = useParams();
  let history = useHistory();
  Tasting.setPublicCode(id);

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);

  const [loaded, setLoaded] = useState(false);
  const [assistantLoaded, setAssistantLoaded] = useState(false);
  const [lang, setLang] = useState(null);
  const [data, setData] = useState(null);
  const [assessments, setAssessments] = useState(null);
  const [popupAlertData, setPopUpAlertData] = useState({
    visible: false,
    header: null,
    text: null,
    onClose: () => { },
  });


  const showPopupAlert = (text, onClose) => {
    showTitledPopupAlert(null, text, onClose)
  }

  const showTitledPopupAlert = (title, text, onClose) => {
    setPopUpAlertData((prev) => {
      return {
        ...prev,
        header: title,
        visible: true,
        text: text,
        onClose: () => {
          closePopupAlert();
          if (typeof onClose === 'function') onClose();
        },
      }
    });
  }

  const closePopupAlert = () => {
    setPopUpAlertData((prev) => {
      return {
        ...prev,
        visible: false,
        text: null,
        onClose: () => { }
      }
    });
  }

  const loadTast = () => {
    Fetch.checkCode(id).then((res) => {
      if (res.error || !res.exists) {
        showPopupAlert(ControlTrad.getTrad("codeNotFound"), backHome);
      } else {
        if (res?.type === "PODCAST_TAST") {
          loadTastPodcast();
        } else {
          loadTastLive();
        }
      }
    }).catch((error) => {
      console.error("Error code:", error);
      showPopupAlert(ControlTrad.getTrad("codeNotFound"), backHome);
    });
  }

  const loadTastLive = () => {
    Websockets.connect(false);
    Tasting.setType("live"); 
    setLoaded(true);
  }

  const loadTastPodcast = () => {
    loadDataPodcast();
    Tasting.setType("podcast");
    if (!Tasting.getLite()) {
      setLoaded(true);
    }   
  }

  const loadDataPodcast = () => {
    Fetch.getCodeInfoPod(id).then((res) => {
      setData(res);
    }).catch((error) => {
      console.error("Error loadDataPodcast:", error);
      showTitledPopupAlert(ControlTrad.getTrad("error"), ControlTrad.getTrad("errorLoadEvent"), backHome);
    });
  }


  const loadCustomColors = () => {
    Custom.setColour("mainColor", data.theme.mainColor);
    Custom.setColour("secondaryColor", data.theme.secondaryColor);
  }

  const loadCustomLinks = () => {
    if (data.theme.termsOfUse !== undefined) {
      Custom.setLinks("termsOfUse", data.theme?.termsOfUse);
    }
    if (data.theme.privacyPolicies !== undefined) {
      Custom.setLinks("privacyPolicies", data.theme?.privacyPolicies)
    }
  }

  const loadLobbyTheme = () => {
    if (data.theme.lobbyText !== undefined) {
      Custom.Text.lobbyText = ControlTrad.getUserTrad(data.theme?.lobbyText);
    }
    if (data.theme.lobbySkipActive !== null && data.theme.lobbySkipActive !== undefined) {
      Custom.Lobby.lobbySkipActive = data.theme.lobbySkipActive
    }
    if (data.theme.lobbySkipTimeout) {
      Custom.Lobby.lobbySkipTimeout = data.theme.lobbySkipTimeout
    }
  }

  const loadCustomAudios = () => {
    if (data.theme.audios !== undefined && data.theme.audios !== null) {
      Custom.setAudios(data.theme.audios);
    }
  }

  const preloadImage = (url, callback) => {
    var img = new Image();
    img.src = url;
    img.onload = callback;
  }

  const loadCustomBackground = () => {
    if (data.theme?.logo !== undefined) {
      Custom.setLogo(data.theme?.logo);
    }
    if (data.theme?.lobbyImage !== undefined) {
      Custom.setBackground("lobbyImage", data.theme?.lobbyImage);      
    }
    if (data.theme?.mainBackground !== undefined) {
      let backgroundUrl = data.theme?.mainBackground;
      preloadImage(backgroundUrl, () => {
        document.getElementById("id_wrapper").style.background = `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url("${backgroundUrl}") no-repeat center`;
        document.getElementById("id_wrapper").style.backgroundSize = `cover`;
        Custom.setBackground("mainBackground", backgroundUrl);
      });
    }
  }


  const loadQueryParams = () => {
    let selectedLang = urlParams.get("lang")?.toUpperCase();
    let lite = urlParams.get("lite");
    let streaming = urlParams.get("streaming");

    if (selectedLang !== undefined) {
      User.setLang(selectedLang);
      setLang(selectedLang);
    }
    if (lite !== null) {
      Tasting.setLite(lite === 'true' ? true : false);
    }
    if (streaming !== null) {
      Tasting.setStreamingAllowed(streaming === 'false' ? false : true);
    }

    console.log("Lang:", selectedLang);
    console.log("Lite:", lite);


    console.log("URL Params:", {
      lite: lite,
      lang: lang,
      streaming: streaming
    });
  }

  const backHome = () => {
    history.replace(`/`);
  }


  const setTheme = (theme) => {
    setData({
      ...data,
      theme: theme
    })
  }

  useEffect(() => {
    if (queryString) { loadQueryParams() }
    Tasting.setPublicCode(id);
    switch (Tasting.getType()) {
      case "live":
        loadTastLive();
        break;
      case "podcast":
        loadTastPodcast();
        break;
      default:
        loadTast();
        break;
    }
  }, []);

  useEffect(() => {
    if (data !== null) {
      if (data?.theme) {
        loadCustomColors();
        loadLobbyTheme();
        loadCustomBackground();
        loadCustomLinks();
        loadCustomAudios();
      }
      if (Tasting.getLite() && !loaded && data?.tasting) {
        setLoaded(true);
      }
    }
  }, [data]);

  useEffect(() => {
    if (User.getId() === null) {
      if (Tasting.isPodcast()) { // Podcast users

        FetchUtils.restoreOrCreateNewAssistant(
          // On restore
          (oldUser, lastRestoreDate, oldAssessments) => {
            console.log("Assistant restored: ", oldUser, oldAssessments);
            setAssessments(oldAssessments);
            let formatedDate = lastRestoreDate?.toLocaleString();
            if (formatedDate) {
              showTitledPopupAlert(ControlTrad.getTrad("podcastSession"), ControlTrad.getTrad("restoreUserMsg").replace("{date}", formatedDate));
            }
          },
          // On new created
          (newUser) => {
            console.log("Podcast user created:", newUser);
            setAssistantLoaded(true);
          },
          // On fail
          (error) => {
            console.error("Error restoreOrCreateNewAssistant: ", error);
            setAssistantLoaded(true);
          }
        );
      } else {  // Live users
        // Handled by Websocket server
        setAssistantLoaded(true);
      }
    }
  }, [Tasting.lite, Tasting.type, User.id]);

  useEffect(() => {
    if (assessments !== null) {
      setAssistantLoaded(true);
    }
  }, [assessments]);

  let Wrapper = {
    height: '100vh',
    width: '100vw',
    display: 'grid',
    gridTemplateColumns: "100",
    gridTemplateRows: "100",
  }

  return (
    <AppStateProvider>

      {loaded && assistantLoaded ? (
        <AssistantComponent data={data} assessments={assessments} setTheme={setTheme} />
      ) : (
          <div style={Wrapper} id="mutable">
            {User.getLang()}
            <Text params={{
              position: "1/1/40/100",
              text: ControlTrad.getTrad("welcomeTasting", lang),
              styles: 'text-align:center; font-size:6vh; font-weight:bold; padding: 1vh 2vw;'
            }} />
            <LoadingComponent params={{
              position: "1/1/100/100",
              active: true,
              ready: () => { }
            }} />
            <Text params={{
              position: "60/1/100/100",
              text: ControlTrad.getTrad("welcomeHint", lang),
              styles: 'text-align:center; font-size:3vh; padding: 1vh 2vw;'
            }} />
          </div>
      )}

      <ModalMessage
        isShown={popupAlertData?.visible}
        disableCloseButton={true}

        title={popupAlertData?.header}
        titleStyle={"text-align: center; font-size:2.5vh; font-weight:500;"}
        boxSize={"max-width: 40%;"}
        media={"max-width: 90%;"}
        styleMain={`padding: 0 2vh; font-size: 2vh;`}>
        <p>{ReactHtmlParser(popupAlertData?.text)}</p>
        <Button styles={"margin:0 0 1rem 0"} value={"Aceptar"} onClick={popupAlertData?.onClose} />
      </ModalMessage>

    </AppStateProvider>
  );


}

export default Assistant;
