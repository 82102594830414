import React from "react"
import styled from "styled-components";

import Element from "../Element";

import AppStateContext from "appState/AppStateContext";

const AverageProductPrice = (props) => {

    const Wrapper = styled.div`
        font-size: 2.5vh;
        height:100%;
        width:100%;
        display:flex;
        flex-direction: row;
        text-align:center;
        align-items:center;
        justify-content:center;
    `;

    const Number = styled.div`
        margin: auto 0;
        font-size: 3vh;
        font-weight: bold;
    `;

    const appState = React.useContext(AppStateContext);
    const averageProductPrice = appState.state.get('aggregatedParameters')?.blindPrice?.avg || 0;
    const currency = appState.state.get("currency") || "€";

    return (
        <Element params={props.params} >
            <Wrapper>
                <Number>{averageProductPrice + " " + currency}</Number>
            </Wrapper>
        </Element>
    );
};

export default AverageProductPrice;