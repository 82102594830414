import React from "react";

import styled from "styled-components";
import Element from "../Element";
import Question from "./Question/Question";
import NumQuestions from "./NumQuestions/NumQuestions";
import Options from "./Options/QuizOptions";
import Timer from "./Timer/Timer";

const Wrapper = styled.div`
${props => `
    color: white;
    font-size: 3vh;
    border-radius:1vh;
    height: 100%;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.8);
    display:flex;
    flex-direction: column;
    @media (max-width: 426px) {
        overflow: auto;
    }
`}`;

const Head = styled.div`
    display:flex;
    padding : 1vh;
`
const HeadItem = styled.div`
    flex : 1;
    padding : 0vh 2vh 0;
`

const Panel = styled.div`
    display:flex;
    flex-direction:column;
    padding : 1vh;
`
const PanelItem = styled.div`
    flex:1;
    padding: 0.5vh 2vh;

`


const QuestionPanel = (props) => {

    return (
        <Element params={props.params}>
            <Wrapper params={props.params}>
                <Head>
                    <HeadItem>
                        <NumQuestions />
                    </HeadItem>
                    <HeadItem>
                        <Timer />
                    </HeadItem>
                </Head>
                <Panel>
                    <PanelItem>
                        <Question />
                    </PanelItem>
                    <PanelItem>
                        <Options />
                    </PanelItem>
                </Panel>
            </Wrapper>
        </Element>
    );

}
export default QuestionPanel;