import React, { useState } from "react";
import Element from "../Element";
import Button from "components/Shared/Button"
import styled from "styled-components";
import ModalMessage from "components/Sandbox/ModalMessage";
import AppStateContext from "appState/AppStateContext";
import ControlTrad from "assets/trad/ControlTrad";
import useOrientation from "orientation/Orientation"

const StartTastingButton = (props) => {
    const [popUp1, setPopUp1] = useState(false)

    const appState = React.useContext(AppStateContext);
    const jitsiActive = appState.state.get("jitsiActive");

    const WrapperModal = styled.div`
        width :100%;
        height:20vh;
        overflow: auto;
        flex-direction: column;
        display: flex;
    `

    const ButtonsWrapper = styled.div`
        display:flex;
        align-items:center;
        justify-content:center;
        flex-directon:row;
    `

    const SpanTxt = styled.span`
        padding-left: 2vh;
        padding-right: 2vh;
        font-size:2.5vh;
        margin: 4vh 0;
    `

    const StartTasting = () => {
        console.log(props.params.noJitsi);
        if(props.params.noJitsi){
            props.params.next();
        }else{
            if (jitsiActive) {
                props.params.next();
            } else {
                setPopUp1(true);
            }
        }


    }

    return (
        <>
            <Element params={props.params}>
                <Button value={ControlTrad.getTrad("startTast")} onClick={StartTasting} />
            </Element>
            <ModalMessage
                boxSize={"min-width: 70vh !important; border-radius:1.5vh;"}
                styleHeader={"border-top-left-radius:1.5vh; border-top-right-radius:1.5vh; font-size:2.2vh;"}
                styleMain={"border-bottom-left-radius:1.5vh;border-bottom-right-radius:1.5vh;"}
                isShown={popUp1}
                disableCloseButton={true}
                setIsShown={setPopUp1}
                title={ControlTrad.getTrad("startTastePopUp")} >

                <WrapperModal>
                    <SpanTxt>{ControlTrad.getTrad("start_without_jitsi")}</SpanTxt>
                    <ButtonsWrapper>
                        <Button styles={"width:13vw;height:3vh;display:flex;justify-content:center;background-color:black; border-color: black;"} onClick={() => setPopUp1(false)} value={ControlTrad.getTrad("no")} />
                        <Button styles={"width:13vw;height:3vh;display:flex;justify-content:center; margin-left:2vh;"} onClick={() => { setPopUp1(false); props.params.next(); }} value={ControlTrad.getTrad("yes")} />
                    </ButtonsWrapper>
                </WrapperModal>
            </ModalMessage>
        </>

    );
};

export default StartTastingButton;
