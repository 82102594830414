let layout = {
    live: {
        INITIAL: {
            portrait: {
                0: {
                    upwineLogo: { position: "3/14/11/39" },
                    form: { position: "5/1/95/50" },
                    formButton: { position: "84/4/93/46" }
                },
                1: {
                    initialSurvey: { position: "1/1/100/50", },
                },
                2:{
                    jitsiConfiguration : {position: "15/20/85/50"}
                }
            },
            landscape: {
                0: {
                    upwineLogo: { position: "7/44/15/57" },
                    form: { position: "5/1/95/100", },
                    formButton: { position: "83/68/89/34", }
                },
                1: {
                    initialSurvey: { position: "1/1/100/100", },
                },
                2:{
                    jitsiConfiguration : {position: "15/20/85/80"}
                }
            }
        },
        WAITING: {
            portrait: {
                0: {
                    upwineLogo: { position: "3/11/21/39" },
                    textTitle: { position: "18/1/24/50" },
                    textDescriptionTitle: { position: "24/26/29/50" },
                    textDescription: { position: "29/26/47/49" },
                    textTimeTitle: { position: "24/1/29/26" },
                    textTime: { position: "29/1/34/26" },
                    textWinesToTasteTitle: { position: "34/1/39/26" },
                    textWinesToTaste: { position: "39/1/44/26" },
                    rectangle: { position: "1/1/100/50", styles: "background:rgba(0, 0, 0, 0.5)" },
                    chat: { position: "52/7/59/44" }
                }
            },
            landscape: {
                0: {
                    upwineLogo: { position: "5/10/13/22" },
                    textTitle: { position: "18/3/24/30" },
                    textDescriptionTitle: { position: "43/3/46/30" },
                    textDescription: { position: "47/3/57/30" },
                    textTimeTitle: { position: "37/3/41/30" },
                    textTime: { position: "41/3/41/30" },
                    textWinesToTasteTitle: { position: "33/3/29/30" },
                    textWinesToTaste: { position: "36/3/33/30" },
                    rectangle: { position: "1/1/100/35", styles: "background:rgba(255, 255, 255, 0.1)" },
                    waitingLobby: { position: "7/36/72/87" },
                    observationsText4: { position: "73/36/78/87" },
                    observationsText5: { position: "78/36/83/87" },
                    observationsText6: { position: "83/36/88/87" },
                    chat : {position: "72/62/95/87", chatMaxSize: 12},
                    observations: { position: "73/36/95/61" }
                }
            }
        },
        IN_PROGRESS: {
            portrait: {
                0:{
                    upwineLogo: { position: "3/11/21/39" },
                    wineIndex: { position: "1/20/11/30" },
                    wineName: { position: "12/15/21/37" },
                    wineImage: { position: "12/5/21/14" },

                    BLIND_TEMPLATE: {
                        winePrice: {
                            hide: false,
                            position: "16/38/21/46"
                        },
                        moreInfoButton: {
                            position: "12/38/17/46"
                        },
                        navegationWine: {
                            hide: false,
                            position: "1/1/11/51"
                        },
                        categoriesWrapper: {
                            hide: false,
                            position: "23/5/36/46",
                        },
                        showProductInfo: {
                            hide: false,
                            position: "12/28/22/47"
                        },
                        responsiveRate: {
                            hide: false,
                            position: "38/5/45/46"
                        },
                        responsiveAnnotations: {
                            hide: false,
                            position: "46/5/54/24"
                        },
                        responsiveChat: {
                            hide: false,
                            position: "46/26/54/46"
                        },
                        assessmentDisplay: {
                            hide: true,
                            position: "25/1/70/50",
                            positionFull: "25/1/100/50"
                        },
                    },
                    CONVENTIONAL_TAST: {
                        winePrice: {
                            hide: false,
                            position: "16/38/21/46"
                        },
                        moreInfoButton: {
                            position: "12/38/17/47"
                        },
                        navegationWine: {
                            hide: false,
                            position: "1/1/11/51"
                        },
                        categoriesWrapper: {
                            hide: false,
                            position: "23/5/36/46",
                        },
                        showProductInfo: {
                            hide: false,
                            position: "12/28/22/47"
                        },
                        responsiveRate: {
                            hide: false,
                            position: "38/5/45/46"
                        },
                        responsiveAnnotations: {
                            hide: false,
                            position: "46/5/54/24"
                        },
                        responsiveChat: {
                            hide: false,
                            position: "46/26/54/46"
                        },
                        assessmentDisplay: {
                            hide: true,
                            position: "25/1/70/50",
                            positionFull: "25/1/100/50"
                        },
                    },
                    SIMPLE_TEMPLATE:{
                        winePrice: {
                            hide: false,
                            position: "16/38/21/46"
                        },
                        moreInfoButton: {
                            position: "12/38/17/47"
                        },
                        navegationWine: {
                            hide: false,
                            position: "1/1/11/51"
                        },
                        categoriesWrapper: {
                            hide: true,
                            position: "23/5/36/46",
                        },
                        showProductInfo: {
                            hide: true,
                            position: "12/28/22/47"
                        },
                        responsiveRate: {
                            hide: true,
                            position: "38/5/45/46"
                        },
                        responsiveAnnotations: {
                            hide: true,
                            position: "46/5/54/24"
                        },
                        responsiveChat: {
                            hide: true,
                            position: "46/26/54/46"
                        },
                        assessmentDisplay: {
                            hide: false,
                            position: "25/1/70/50",
                            positionFull: "25/1/100/50"
                        },
                    }

                },
                2:{}
            },
            landscape:{
                0:{
                    upwineLogo: { position: "2/14/9/22" },
                    wineIndex: { position: "52/1/56/35" },
                    wineName: { position: "8/1/13/35" },
                    wineImage: {
                        hide: false,
                        position: "19/11/52/25"
                    },
                    BLIND_TEMPLATE: {
                        winePrice: {
                            hide: false,
                            position: "13/1/19/35"
                        },
                        rectangle: {
                            hide: false,
                            position: "1/1/100/35",
                            styles: "background: rgba(255, 255, 255, 0.1); z-index:-1;"
                        },
                        moreInfoButton: {
                            position: "14/23/18/28"
                        },
                        navegationWine: {
                            hide: false,
                            position: "19/2/52/34"
                        },
                        categoriesWrapper: {
                            hide: false,
                            position: "3/36/64/97",
                        },
                        rateBox: {
                            hide: false,
                            position: "67/36/79/66"
                        },
                        priceRateBox: {
                            hide: true,
                            position: "67/67/79/97"
                        },
                        blindPriceRateBox: {
                            hide: false,
                            position: "67/67/79/97"
                        },
                        chat: {
                            hide: false,
                            position: "80/67/98/97"
                        },
                        notes: {
                            hide: false,
                            position: "80/36/98/66"
                        }
                    },
                    CONVENTIONAL_TAST: {
                        winePrice: {
                            hide: false,
                            position: "13/1/19/35"
                        },
                        rectangle: {
                            hide: false,
                            position: "1/1/100/35",
                            styles: "background: rgba(255, 255, 255, 0.1); z-index:-1;"
                        },
                        moreInfoButton: {
                            position: "14/23/18/28"
                        },
                        categoriesWrapper: {
                            hide: false,
                            position: "3/36/64/97",
                        },
                        rateBox: {
                            hide: false,
                            position: "67/36/79/66"
                        },
                        priceRateBox: {
                            hide: false,
                            position: "67/67/79/97"
                        },
                        blindPriceRateBox: {
                            hide: true,
                            position: "67/67/79/97"
                        },
                        chat: {
                            hide: false,
                            position: "80/67/98/97"
                        },
                        notes: {
                            hide: false,
                            position: "80/36/98/66"
                        }
                    },
                    SIMPLE_TEMPLATE:{
                        winePrice: {
                            hide: false,
                            position: "13/1/19/35"
                        },
                        rectangle: {
                            hide: false,
                            position: "1/1/100/35",
                            styles: "background: rgba(255, 255, 255, 0.1); z-index:-1;"
                        },
                        moreInfoButton: {
                            position: "14/23/18/28"
                        },
                        categoriesWrapper: {
                            hide: true,
                            position: "3/36/64/97",
                        },
                        rateBox: {
                            hide: true,
                            position: "67/36/79/66"
                        },
                        priceRateBox: {
                            hide: true,
                            position: "67/67/79/97"
                        },
                        blindPriceRateBox: {
                            hide: true,
                            position: "67/67/79/97"
                        },
                        chat: {
                            hide: true,
                            position: "80/67/98/97"
                        },
                        notes: {
                            hide: true,
                            position: "80/36/98/66"
                        },
                        assessmentDisplay: {
                            hide: false,
                            position: "1/35/100/100",
                        },
                    }
                },
                2:{}
            }
        },
        QUIZ_PRESENTATION:{
            portrait: {
                0: {
                    upwineLogo: { position: "15/6/25/39" },
                    welcomeQuizText: { position: "15/5/20/44", styles: "font-size:4vh", },
                    wineQuizText: { position: "20/5/28/47", styles: "font-size:6vh; color:white;" },
                    separator: { position: "28/5/30/44", lineStyle: "background-color: white" },
                    quizPrize: { position: "30/5/45/45", styles: "font-size : 1.2rem;" },
                    quizDescText: { position: "46/5/65/44", styles: "font-size : 2.5vh; text-align: justify;" },
                    buttonBackQuiz: { position: "2/3/13/25" },
                    buttonStart: { position: "66/4/85/46" }
                }
            },
            landscape: {
                0: {
                    upwineLogo: { position: "10/50/21/68" },
                    welcomeQuizText: { position: "30/50/36/75", styles: "text-align : 6vh;", },
                    wineQuizText: { position: "35/50/49/95", styles: "font-size:6vh; color:white;" },
                    separator: { position: "48/50/50/83", lineStyle: "background-color: white" },
                    quizPrize: { position: "50/50/60/95", styles: "font-size : 4vh;" },
                    quizDescText: { position: "59/50/74/90", styles: "font-size:3vh;" },
                    buttonBackQuiz: { position: "12/33/19/45" },
                    buttonStart: { position: "78/50/89/79" }
                }
            },
        },
        QUIZ_QUESTION: {
            portrait: {
                0: {
                    rectangle: { position: "1/1/1/0", styles: "" },
                    upwineLogo: { position: "12/15/16/35" },
                    timer: { position: "12/15/16/35" },
                    quizNumQuestions: { position: "18/1/25/50", styles: "text-align:center;" },
                    quizQuestion: { position: "22/4/41/45", styles: "font-size: 3vh;" },
                    quizOptions: { position: "40/4/98/45" },
                    quizPoints:{position: "18/5/22/22",final: false,styles: "text-align : left;",}

                }
            },
            landscape: {
                0: {
                    rectangle: { position: "1/1/100/32", styles: "background:rgba(255, 255, 255, 0.1);" },
                    upwineLogo: { position: "5/10/14/23" },
                    timer: { position: "22/11/27/21" },
                    quizNumQuestions: { position: "30/1/35/32", styles: "text-align:center;" },
                    quizQuestion: { position: "10/40/40/90", styles: "" },
                    quizOptions: { position: "30/40/95/90" },
                    quizPoints:{position: "35/8/39/23",final: false,styles: "",}
                    
                }
            }
        },
        QUIZ_CORRECTION: {
            portrait: {
                0: {
                    rectangle: { position: "1/1/1/0", styles: "" },
                    upwineLogo: { position: "12/15/16/35" },
                    timer: { position: "12/15/16/35" },
                    quizNumQuestions: { position: "18/1/25/50", styles: "text-align:center;" },
                    quizQuestion: { position: "22/4/41/45", styles: "font-size: 3vh;" },
                    quizOptions: { position: "40/4/98/45" },
                    quizPoints:{position: "18/5/22/22",final: false,styles: "text-align : left;",}
                }
            },
            landscape: {
                0: {
                    rectangle: { position: "1/1/100/32", styles: "background:rgba(255, 255, 255, 0.1);" },
                    upwineLogo: { position: "5/10/14/23" },
                    timer: { position: "22/11/27/21" },
                    quizNumQuestions: { position: "30/1/35/32", styles: "text-align:center;" },
                    quizQuestion: { position: "5/40/30/90", styles: "" },
                    quizOptions: { position: "30/40/95/90" },
                    quizPoints:{position: "35/8/39/23",final: false,styles: "",}

                }
            }
        },
        QUIZ_GRAPH: {
            portrait: {
                0: {
                    rectangle: { position: "1/1/1/0", styles: "" },
                    upwineLogo: { position: "12/15/16/35" },
                    quizNumQuestions: { position: "18/1/25/50", styles: "text-align:center;" },
                    quizQuestion: { position: "22/4/41/45", styles: "font-size: 3vh;" },
                    quizPoints:{position: "18/5/22/22",final: false,styles: "text-align : left;",},
                    charts : {position: "38/3/88/45"}

                }
            },
            landscape: {
                0: {
                    rectangle: { position: "1/1/100/32", styles: "background:rgba(255, 255, 255, 0.1);" },
                    upwineLogo: { position: "5/9/14/22" },
                    quizNumQuestions: { position: "30/1/35/32", styles: "text-align:center;" },
                    quizQuestion: { position: "5/40/30/90", styles: "" },
                    quizPoints:{position: "35/8/39/23",final: false,styles: "",},
                    charts: { position: "50/40/90/90" }
                }
            }
        },
        QUIZ_SCORE: {
            portrait: {
                0: {
                    rectangle: { position: "1/1/1/0", styles: "" },
                    upwineLogo: { position: "12/15/16/35" },
                    quizNumQuestions: { position: "18/1/25/50", styles: "text-align:center;" },
                    quizQuestion: { position: "22/4/41/45", styles: "font-size: 3vh;" },
                    quizPoints:{position: "18/5/22/22",final: false,styles: "text-align : left;",},
                    quizRanking : {position: "25/3/95/48",},
                    quizPodium:{position: "15/4/35/48"},
                    winnerQuiz:{position: "60/1/70/50",styles:"z-index:-1"}

                }
            },
            landscape: {
                0: {
                    rectangle: { position: "1/1/100/32", styles: "background:rgba(255, 255, 255, 0.1);" },
                    upwineLogo: { position: "5/9/14/22" },
                    quizNumQuestions: { position: "30/1/35/32", styles: "text-align:center;" },
                    quizQuestion: { position: "5/40/30/90", styles: "" },
                    quizPoints:{position: "53/5/58/30",final: false,styles: "",},
                    quizRanking : {position: "38/45/100/85",},
                    quizPodium:{position: "5/45/35/85",},
                    imageGif:{position: "24/14/43/25", styles: "height : 15vh"},
                    winnerQuiz:{position: "47/5/54/30",styles:""},
                    textInfo1:{position: "41/5/48/30", styles: "text-align:center; font-size:5vh;",},
                    textInfo2:{position: "18/5/24/30", styles: "text-align:center; font-size:5vh; background-color:#af1d39;",}
                }
            }
        },
        FINISHED : {
            portrait: {
                0:{
                    recommendationGif:{position: "1/1/100/50"}
                },
                1:{
                    wineRecommendation: {
                        position: "20/4/65/47",
                    },
                    nextButton: {
                        position: "2/4/9/47",
                    },
                    textInfo : {
                        position: "11/5/15/46",
                        styles: 'text-align:center; font-size:4vh; font-weight:bold'
                    }
                },
                2:{
                    tastingSummary: { position: "13/1/66/50" },
                    sendReportButton:{position: "2/25/9/48"},
                    skipReportButton:{
                        position: "2/3/9/24",
                        styles: "color:white !important; white-space: nowrap; text-overflow: ellipsis;",
                    }
                },
                3:{
                    sendFeedback: { position: "2/3/9/48" },
                    finalSurvey:{position: "13/1/66/50"},
                },
                4:{
                    externalBuyFinally:{position: "2/25/9/48"},
                    productsStore: { position: "13/1/66/50" },
                },
                5:{
                    upwineLogo: { position:  "3/14/11/39" },
                    textinfo1:{position: "15/5/30/45",styles: "text-align:center; font-size: 1.5rem;"},
                    textinfo2:{position: "30/5/35/45",styles: "text-align:center; font-size: 1rem;",},
                    textInfo3:{position: "37/5/42/45", styles: "text-align:center; font-size: 0.75rem;"},
                    socialNetworks:{position: "45/1/55/50"},
                    closeButton: {position: "56/4/65/48"},

                }
            },
            landscape:{
                0:{
                    recommendationGif:{position: "1/1/100/100"}
                },
                1:{
                    rectangle: {
                        position: "1/1/100/35",
                        styles: "background:rgba(255, 255, 255, 0.1);"
                    },
                    upwineLogo: {
                        position: "6/10/14/24"
                    },
                    wineRecommendation: {
                        position: "15/40/96/93",
                    },
                    nextButton: {
                        position: "5/40/12/93",
                    },
                    textInfo : {
                        position: "18/5/24/30",
                        styles: 'text-align:center; font-size:4vh; font-weight:bold'
                    }
                },
                2:{
                    rectangle: { position: "1/1/100/35", styles: "background:rgba(255, 255, 255, 0.1);" },
                    upwineLogo: { position: "6/10/14/24" },
                    tastingSummary: { position: "14/40/98/93" },
                    sendReportButton: { position: "5/68/12/93" },
                    skipReportButton: { position: "5/40/12/65", styles: "color:white !important;" },
                    textinfo1:{position: "24/3/24/35",styles: "text-align:center; font-size:5vh; font-weight:400"},
                    textinfo2:{position: "25/1/36/35",styles: "text-align:center; font-size:2.5vh;",},
                },
                3:{
                    rectangle: { position: "1/1/100/35", styles: "background:rgba(255, 255, 255, 0.1);" },
                    upwineLogo: { position: "6/10/14/24" },
                    sendFeedback: { position: "5/40/12/93" },
                    finalSurvey: { position: "14/40/98/93", },

                    textinfo1:{position: "18/5/24/30",styles: "text-align:center; font-size:4vh; font-weight:bold"},
                    textinfo2:{position: "28/5/36/30",styles: "text-align:center; font-size:2vh;",},
                

                },
                4:{
                    rectangle: { position: "1/1/100/35", styles: "background:rgba(255, 255, 255, 0.1);" },
                    upwineLogo: { position: "6/10/14/24" },
                    externalBuyFinally: { position: "5/40/12/93" },
                    productsStore: { position: "14/40/98/93" },

                    textinfo1:{position: "18/5/24/30",styles: "text-align:center; font-size:4vh; font-weight:bold"},
                    textinfo2:{position: "28/5/36/30",styles: "text-align:center; font-size:2vh;",},
                    
                },
                5:{
                    upwineLogo: { position: "14/41/28/59" },
                    closeButton: {position: "76/38/65/62"},
                    textinfo1:{position: "34/1/45/100",styles: "text-align:center; font-size: 5vh;"},
                    textinfo2:{position: "54/1/51/100",styles: "text-align:center; font-size: 3vh;",},
                    textInfo3:{position: "46/1/42/100", styles: "text-align:center; font-size: 3vh;"},
                    socialNetworks:{position: "56/1/60/100"}
                }

            }
        },
    },
    podcast: {
        INITIAL: {
            portrait: {
                0: {
                    upwineLogo: { position: "3/14/11/39" },
                    form: { position: "5/1/95/50", },
                    formButton: { position: "84/4/93/46" }
                },
                1: {
                    initialSurvey: { position: "1/1/100/50", },
                }
            },
            landscape: {
                0: {
                    upwineLogo: { position: "7/44/15/57" },
                    form: { position: "5/1/95/100" },
                    formButton: { position: "83/68/89/34", }
                },
                1: {
                    initialSurvey: { position: "1/1/100/100", },
                }
            }
        },
        WAITING: {
            portrait: {
                0: {
                    upwineLogo: { position: "3/11/21/39" },
                    textTitle: { position: "18/1/24/50" },
                    textDescriptionTitle: { position: "24/26/29/50" },
                    textDescription: { position: "29/26/47/49" },
                    textTimeTitle: { position: "34/1/39/26" },
                    textTime: { position: "39/1/44/26" },
                    textWinesToTasteTitle: { position: "24/1/29/26" },
                    textWinesToTaste: { position: "29/1/34/26" }, 
                    rectangle: { position: "1/1/100/50", styles: "background:rgb(0, 0, 0, 0.5)" },
                    buttonStart: { position: "55/4/66/46" }
                }
            },
            landscape: {
                0: {
                    upwineLogo: { position: "5/10/13/22" },
                    textTitle: { position: "18/3/24/30" },
                    textDescriptionTitle: { position: "43/3/46/30" },
                    textDescription: { position: "47/3/57/30" },
                    textTimeTitle: { position: "33/3/29/30" }, 
                    textTime: { position: "36/3/33/30" }, 
                    textWinesToTasteTitle: { position: "37/3/41/30" },
                    textWinesToTaste: { position: "41/3/41/30" },
                    rectangle: { position: "1/1/100/35", styles: "background:rgba(255, 255, 255, 0.1)" },
                    buttonStart: { position: "88/43/97/80" },
                    waitingLobby: { position: "7/36/72/87" },
                    observationsText4: { position: "73/36/78/87" },
                    observationsText5: { position: "78/36/83/87" },
                    observationsText6: { position: "83/36/88/87" }
                }
            }
        },
        IN_PROGRESS: {
            portrait: {
                0: {
                    upwineLogo: { position: "3/11/21/39" },
                    wineIndex: { position: "1/20/11/30" },
                    wineName: { position: "12/15/21/37" },
                    wineImage: { position: "12/5/21/14" },
                    BLIND_TEMPLATE: {
                        winePrice: {
                            hide: false,
                            position: "16/38/21/46"
                        },
                        moreInfoButton: {
                            position: "12/38/17/46"
                        },
                        navegationWine: {
                            hide: false,
                            position: "1/1/11/51"
                        },
                        categoriesWrapper: {
                            hide: false,
                            position: "23/5/36/46",
                        },
                        showProductInfo: {
                            hide: false,
                            position: "12/16/22/46"
                        },
                        assessmentDisplay: {
                            hide: true,
                            position: "25/1/70/50",
                            positionFull: "25/1/100/50"
                        },
                        responsiveRate: {
                            hide: false,
                            position: "38/5/55/46"
                        },
                    },
                    CONVENTIONAL_TAST: {
                        winePrice: {
                            hide: false,
                            position: "16/38/21/46"
                        },
                        moreInfoButton: {
                            position: "12/38/17/47"
                        },
                        navegationWine: {
                            hide: false,
                            position: "1/1/11/51"
                        },
                        categoriesWrapper: {
                            hide: false,
                            position: "23/5/36/46",
                        },
                        assessmentDisplay: {
                            hide: true,
                            position: "25/1/70/50",
                            positionFull: "25/1/100/50"
                        },
                        responsiveRate: {
                            hide: false,
                            position: "38/5/55/46"
                        },
                    },
                    SIMPLE_TEMPLATE: {
                        winePrice: {
                            hide: false,
                            position: "16/38/21/46"
                        },
                        moreInfoButton: {
                            position: "12/38/17/47"
                        },
                        navegationWine: {
                            hide: false,
                            position: "1/1/11/51"
                        },
                        categoriesWrapper: {
                            hide: true,
                            position: "23/5/36/46",
                        },
                        assessmentDisplay: {
                            hide: false,
                            position: "25/1/70/50",
                            positionFull: "25/1/100/50"
                        },
                        responsiveRate: {
                            hide: true,
                            position: "38/5/55/46"
                        },
                        showProductInfo: {
                            hide: true,
                            position: "29/8/40/28"
                        },
                    }
                },
                1: {

                },
                2:{

                }
            },
            landscape: {
                0: {
                    upwineLogo: { position: "2/14/9/22" },
                    wineIndex: { position: "52/1/56/35" },
                    wineName: { position: "8/1/13/35" },
                    wineImage: {
                        hide: false,
                        position: "20/11/52/25"
                    },
                    BLIND_TEMPLATE: {
                        winePrice: {
                            hide: false,
                            position: "13/1/19/35"
                        },
                        rectangle: {
                            hide: false,
                            position: "1/1/100/35",
                            styles: "background: rgba(255, 255, 255, 0.1); z-index:-1;"
                        },
                        moreInfoButton: {
                            position: "14/23/18/28"
                        },
                        navegationWine: {
                            hide: false,
                            position: "20/2/52/34"
                        },
                        categoriesWrapper: {
                            hide: false,
                            position: "3/36/64/97",
                        },
                        rateBox: {
                            hide: false,
                            position: "65/36/76/66"
                        },
                        priceRateBox: {
                            hide: true,
                            position: "77/36/89/66"
                        },
                        blindPriceRateBox: {
                            hide: false,
                            position: "77/36/89/66"
                        },
                        startQuiz: {
                            hide: false,
                            position: "89/67/97/97"
                        },
                        instructorValoration: {
                            hide: false,
                            position: "89/36/97/66"
                        },
                        notes: {
                            hide: false,
                            position: "65/67/89/97"
                        },
                        showProductInfo: {
                            hide: false,
                            position: "45/11/52/25"
                        },
                        assessmentDisplay: {
                            hide: true,
                            position: "1/35/100/100"
                        },
                    },
                    CONVENTIONAL_TAST: {
                        winePrice: {
                            hide: false,
                            position: "13/1/19/35"
                        },
                        rectangle: {
                            hide: false,
                            position: "1/1/100/35",
                            styles: "background: rgba(255, 255, 255, 0.1); z-index:-1;"
                        },
                        moreInfoButton: {
                            position: "14/23/18/28"
                        },
                        navegationWine: {
                            hide: false,
                            position: "20/2/52/34"
                        },
                        categoriesWrapper: {
                            hide: false,
                            position: "3/36/64/97",
                        },
                        rateBox: {
                            hide: false,
                            position: "65/36/76/66"
                        },
                        priceRateBox: {
                            hide: false,
                            position: "77/36/89/66"
                        },
                        blindPriceRateBox: {
                            hide: true,
                            position: "77/36/89/66"
                        },
                        startQuiz: {
                            hide: false,
                            position: "89/67/97/97"
                        },
                        instructorValoration: {
                            hide: false,
                            position: "89/36/97/66"
                        },
                        notes: {
                            hide: false,
                            position: "65/67/89/97"
                        },
                        assessmentDisplay: {
                            hide: true,
                            position: "1/35/100/100"
                        },
                    },
                    SIMPLE_TEMPLATE: {
                        winePrice: {
                            hide: false,
                            position: "13/1/19/35"
                        },
                        rectangle: {
                            hide: false,
                            position: "1/1/100/35",
                            styles: "background: rgba(255, 255, 255, 0.1); z-index:-1;"
                        },
                        moreInfoButton: {
                            position: "14/23/18/28"
                        },
                        navegationWine: {
                            hide: false,
                            position: "20/2/52/34"
                        },
                        categoriesWrapper: {
                            hide: true,
                            position: "3/36/64/97",
                        },
                        rateBox: {
                            hide: false,
                            position: "65/36/76/66"
                        },
                        priceRateBox: {
                            hide: false,
                            position: "77/36/89/66"
                        },
                        blindPriceRateBox: {
                            hide: true,
                            position: "77/36/89/66"
                        },
                        startQuiz: {
                            hide: false,
                            position: "89/67/97/97"
                        },
                        instructorValoration: {
                            hide: false,
                            position: "89/36/97/66"
                        },
                        notes: {
                            hide: false,
                            position: "65/67/89/97"
                        },
                        assessmentDisplay: {
                            hide: false,
                            position: "1/35/100/100"
                        },
                    }

                },
                1: {

                }
            }
        },
        QUIZ_PRESENTATION: {
            portrait: {
                0: {
                    upwineLogo: { position: "15/6/25/39" },
                    welcomeQuizText: { position: "15/5/20/44", styles: "font-size:4vh", },
                    wineQuizText: { position: "20/5/28/47", styles: "font-size:6vh; color:white;" },
                    separator: { position: "28/5/30/44", lineStyle: "background-color: white" },
                    quizPrize: { position: "30/5/45/45", styles: "font-size : 1.2rem;" },
                    quizDescText: { position: "46/5/65/44", styles: "font-size : 2.5vh; text-align: justify;" },
                    buttonBackQuiz: { position: "2/3/13/25" },
                    buttonStart: { position: "66/4/85/46" }
                }
            },
            landscape: {
                0: {
                    upwineLogo: { position: "10/50/21/68" },
                    welcomeQuizText: { position: "30/50/36/75", styles: "text-align : 6vh;", },
                    wineQuizText: { position: "35/50/49/95", styles: "font-size : 10vh; color:white;" },
                    separator: { position: "48/50/50/83", lineStyle: "background-color: white" },
                    quizPrize: { position: "50/50/60/95", styles: "font-size : 4vh;" },
                    quizDescText: { position: "59/50/74/90", styles: "font-size:3vh;" },
                    buttonBackQuiz: { position: "12/33/19/45" },
                    buttonStart: { position: "78/50/89/79" }
                }
            },
        },
        QUIZ_QUESTION: {
            portrait: {
                0: {
                    rectangle: { position: "1/1/1/0", styles: "" },
                    upwineLogo: { position: "12/15/16/35" },
                    timer: { position: "12/15/16/35" },
                    quizNumQuestions: { position: "18/1/25/50", styles: "text-align:center;" },
                    quizQuestion: { position: "22/4/41/45", styles: "font-size: 3vh;" },
                    quizOptions: { position: "40/4/98/45" }
                }
            },
            landscape: {
                0: {
                    rectangle: { position: "1/1/100/32", styles: "background:rgba(255, 255, 255, 0.1);" },
                    upwineLogo: { position: "5/10/14/23" },
                    timer: { position: "22/11/27/21" },
                    quizNumQuestions: { position: "30/1/35/32", styles: "text-align:center;" },
                    quizQuestion: { position: "5/40/30/90", styles: "" },
                    quizOptions: { position: "30/40/95/90" }
                }
            }
        },
        QUIZ_CORRECTION: {
            portrait: {
                0: {
                    rectangle: { position: "1/1/1/0", styles: "" },
                    upwineLogo: { position: "12/15/16/35" },
                    timer: { position: "12/15/16/35" },
                    quizNumQuestions: { position: "18/1/25/50", styles: "text-align:center;" },
                    quizQuestion: { position: "22/4/41/45", styles: "font-size: 3vh;" },
                    quizOptions: { position: "40/4/98/45" }
                }
            },
            landscape: {
                0: {
                    rectangle: { position: "1/1/100/32", styles: "background:rgba(255, 255, 255, 0.1);" },
                    upwineLogo: { position: "5/10/14/23" },
                    timer: { position: "22/11/27/21" },
                    quizNumQuestions: { position: "30/1/35/32", styles: "text-align:center;" },
                    quizQuestion: { position: "5/40/30/90", styles: "" },
                    quizOptions: { position: "30/40/95/90" }
                }
            }
        },
        QUIZ_SCORE_YES: {
            portrait: {
                0: {
                    rectangle: { position: "1/1/1/0", styles: "" },
                    upwineLogo: { position: "12/15/16/35" },
                    header: { position: "3/5/10/46", noPrizePosition: "15/5/25/46", styles: "font-size:5vh; font-weight: bold; text-align:center;" },
                    text: { position: "11/5/30/46", noPrizePosition: "26/5/65/46", styles: "text-align:center;" },
                    winnerForm: { position: "31/5/95/46", styles: "" },
                    buttonStart: { position: "66/5/80/46", styles: "display: none !important;" },
                }
            },
            landscape: {
                0: {
                    rectangle: { position: "1/1/100/32", styles: "background:rgba(255, 255, 255, 0.1);" },
                    upwineLogo: { position: "5/9/14/22" },
                    header: { position: "5/40/15/90", noPrizePosition: "30/40/40/90", styles: "font-size:5vh; font-weight: bold; text-align:center;" },
                    text: { position: "15/40/35/90", noPrizePosition: "41/40/65/90", styles: "font-size:3vh;text-align:center;" },
                    winnerForm: { position: "35/40/90/90", styles: "" },
                    buttonStart: { position: "66/50/80/80", styles: "display: none !important;" }
                }
            }
        },
        QUIZ_SCORE_NO: {
            portrait: {
                0: {
                    rectangle: { position: "1/1/1/0", styles: "" },
                    upwineLogo: { position: "12/15/16/35" },
                    header: { position: "5/5/30/45", styles: "font-size:5vh; font-weight: bold; text-align:center;" },
                    text: { position: "30/5/75/45", styles: "font-size:3vh;text-align:center;" },
                    buttonStart: { position: "75/5/95/45" },
                }
            },
            landscape: {
                0: {
                    rectangle: { position: "1/1/100/32", styles: "background:rgba(255, 255, 255, 0.1);" },
                    upwineLogo: { position: "5/9/14/22" },
                    header: { position: "15/40/35/90", styles: "font-size:5vh; font-weight: bold; text-align:center;" },
                    text: { position: "35/40/75/90", styles: "font-size:3vh;text-align:center;" },
                    buttonStart: { position: "75/40/85/90" },
                }
            }
        },
        FINISHED : {
            portrait: {
                0: {
                    recommendationGif:{position: "1/1/100/50"}
                },
                1:{
                    wineRecommendation: {
                        position: "20/4/65/47",
                    },
                    nextButton: {
                        position: "2/4/9/47",
                    },
                    textInfo : {
                        position: "11/5/15/46",
                        styles: 'text-align:center; font-size:4vh; font-weight:bold'
                    }
                },
                2:{
                    tastingSummary: { position: "13/1/66/50" },
                    sendReportButton:{position: "2/25/9/48"},
                    skipReportButton:{
                        position: "2/3/9/24",
                        styles: "color:white !important; white-space: nowrap; text-overflow: ellipsis;"
                    }
                },
                3:{
                    sendFeedback: { position: "2/3/9/48" },
                    finalSurvey:{position: "13/1/66/50"},
                },
                4:{
                    externalBuyFinally:{position: "2/25/9/48"},
                    productsStore: { position: "13/1/66/50" },
                },
                5:{
                    upwineLogo: { position:  "3/14/11/39" },
                    textinfo1:{position: "15/5/30/45",styles: "text-align:center; font-size: 1.5rem;"},
                    textinfo2:{position: "30/5/35/45",styles: "text-align:center; font-size: 1rem;",},
                    textInfo3:{position: "37/5/42/45", styles: "text-align:center; font-size: 0.75rem;"},
                    socialNetworks:{position: "45/1/55/50"},
                    closeButton: {position: "56/4/65/48"},

                }
            },
            landscape:{
                0: {
                    recommendationGif:{position: "1/1/100/100"}
                },
                1:{
                    rectangle: {
                        position: "1/1/100/35",
                        styles: "background:rgba(255, 255, 255, 0.1);"
                    },
                    upwineLogo: {
                        position: "6/10/14/24"
                    },
                    wineRecommendation: {
                        position: "15/40/96/93",
                    },
                    nextButton: {
                        position: "5/40/12/93",
                    },
                    textInfo : {
                        position: "18/5/24/30",
                        styles: 'text-align:center; font-size:4vh; font-weight:bold'
                    }
                },
                2:{
                    rectangle: { position: "1/1/100/35", styles: "background:rgba(255, 255, 255, 0.1);" },
                    upwineLogo: { position: "6/10/14/24" },
                    tastingSummary: { position: "14/40/98/93" },
                    sendReportButton: { position: "5/68/12/93" },
                    skipReportButton: { position: "5/40/12/65", styles: "color:white !important;" },
                    textinfo1:{position: "24/3/24/35",styles: "text-align:center; font-size:5vh; font-weight:400"},
                    textinfo2:{position: "25/1/36/35",styles: "text-align:center; font-size:2.5vh;",},
                },
                3:{
                    rectangle: { position: "1/1/100/35", styles: "background:rgba(255, 255, 255, 0.1);" },
                    upwineLogo: { position: "6/10/14/24" },
                    sendFeedback: { position: "5/40/12/93" },
                    finalSurvey: { position: "14/40/98/93" },

                    textinfo1:{position: "18/5/24/30",styles: "text-align:center; font-size:4vh; font-weight:bold"},
                    textinfo2:{position: "28/5/36/30",styles: "text-align:center; font-size:2vh;",},
                
                },
                4:{
                    rectangle: { position: "1/1/100/35", styles: "background:rgba(255, 255, 255, 0.1);" },
                    upwineLogo: { position: "6/10/14/24" },
                    externalBuyFinally: { position: "5/40/12/93" },
                    productsStore: { position: "14/40/98/93" },

                    textinfo1:{position: "18/5/24/30",styles: "text-align:center; font-size:4vh; font-weight:bold"},
                    textinfo2:{position: "28/5/36/30",styles: "text-align:center; font-size:2vh;",},
                    
                },
                5:{
                    upwineLogo: { position: "14/41/28/59" },
                    closeButton: {position: "76/38/65/62"},
                    textinfo1:{position: "34/1/45/100",styles: "text-align:center; font-size: 5vh;"},
                    textinfo2:{position: "54/1/51/100",styles: "text-align:center; font-size: 3vh;",},
                    textInfo3:{position: "46/1/42/100", styles: "text-align:center; font-size: 3vh;"},
                    socialNetworks:{position: "56/1/60/100"}
                }
            },

        },
    }

}

export default layout;

