import React from "react"
import styled from "styled-components";

import Element from "../Element";

const Wrapper = styled.div`
${props=>`
  font-size: 2vh;
  color:white;
  height:100%;
  ${props.params?.styles}
`}`

const TextBox = styled.span`
    ${props => `
        font-size:1.9vh;
        color:white;
        font-weight:bold;
        ${props?.styles}
`}`

const InstructorPlan = (props) => {

    return (
        <Element params={props?.params}>
            <Wrapper params={props?.params}>
                <TextBox styles={"font-weight:normal;"}>Licencia: </TextBox>
                <TextBox>{"GRAN BODEGA"}</TextBox>
            </Wrapper>
        </Element>
    );
};

export default InstructorPlan;
