import React, { useState } from "react";
import styled from "styled-components";

import Element from "../Element"
import ModalMessage from "components/Sandbox/ModalMessage";
import AppStateContext from "appState/AppStateContext";

import CloseImage from "assets/images/icons/ic_close.svg";
import IconTooltip from "assets/images/icons/ic_tooltip.svg";
import Colours from "assets/custom/Custom";
import Tasting from "assets/tasting/Tasting";
import User from "assets/user/User";
import ControlTrad from "assets/trad/ControlTrad";

const Button = styled.div`
  ${(props) => `
    background-color: transparent;
    color: white;
    border: 0.1vh solid white;
    border-radius: 1vh;
    font-size: 3vh;
    cursor: pointer;
    white-space: nowrap;
    
    @media (max-width: 426px) {
      font-size: 2.5vh;
    }
    @media (min-width: 426px) and (pointer: coarse) {
      font-size: 3vh;
      padding: 0.25vh 1vh;
    }
    ${props?.styles}
  `}
`;

const WineWrapper = styled.div`
  display:flex;
  flex-direction : column;
  justify-content:left;
  text-align:left;
  width:100%;
  min-width:100%;
  background-color:white;
  @media (max-width: 426px) {
    flex-direction : column;
  }
`;

const Wine = styled.div`
  display: grid;
  grid-auto-columns: auto;
  grid-template-columns: 30% 30% 30%;
  grid-gap: 3%;
  @media (max-width: 426px) {  
    grid-template-columns: 47.5% 47.5%;
    grid-gap: 5%;
  }
`;

const WineInfoFlexTitle = styled.div`
  font-size: 2vh;
  font-weight: bold;
  margin-top: 0.5vh;
  margin-bottom: 0.5vh;
  @media (max-width: 426px) {
  }
`;

const WineInfoFlexVal = styled.div`
  flex:1;
`;

const WineImg = styled.img`
  height: 45vh;
  margin: auto;
  padding: 0 3vw;
  @media (max-width: 426px) {
      display:none;
  }
`;

const WineImgMobile = styled.img`
  height: 25vh;
  margin: auto;
  padding: 2vh 0vw;
  @media (min-width: 426px) {
      display:none;
  }
`;

const WineHeaderWrapper = styled.div`
  ${(props) => `
    background-color: ${Colours.getColour("mainColor")};
    color: white;
    display:flex;
    align-items:center;
    justify-content:space-between;
    width: 94%;
    padding: 1.5vh 3%;
  `}
`;

const CloseHeader = styled.img`
  filter: invert(100%);
  padding: 0 1vh;
  cursor: pointer;
  height:2.5vh;
  width: auto;
`;

const WineBodyWrapper = styled.div`
  display:flex;
  background: white;
  flex-direction:row;
  padding: 4vh;
  overflow-x: auto;
  max-height: 70vh;
  @media (max-width: 426px) {
    padding: 1vh 3vh;
  }
`;

const WineDetailsWrapper = styled.div`
    width: 100%;
`;

const IconButton = styled.img`
  width:2vh;
  height:2vh;
  cursor:pointer;
  margin-left:1vh;
`;

const WineName = styled.div`
  ${(props) => `
    font-size: 4vh;
    text-align: center;
    font-weight:500;
    color: ${Colours.getColour("mainColor")};
    margin-bottom: 3vh;
    @media (max-width: 426px) {
      margin-bottom: 0vh;
    }
  `}
`;

const WineHeader = styled.div`
  font-size: 3vh;
`;

const CloseBtn = styled.div`
  ${(props) => `
    background-color: ${Colours.getColour("mainColor")};
    color: white;
    border: 0.1vh solid white;
    border-radius: 1vh;
    font-size: 2.5vh;
    cursor: pointer;
    padding: 1vh 10vw;
    margin: 1vh auto;
  `}
`;

const MoreInfoButton = (props) => {
  const appState = React.useContext(AppStateContext);
  const wine = Tasting.getType() === "live" ? appState?.state?.get(`currentWine`)?.product : props.params.wine;
  const visibility = props?.params?.visibility ? props.params.visibility.visible : appState.state?.get(`currentWine`)?.visible;

  const [isInfoPopupVisible, setInfoPopupVisible] = useState(false);

  Button.defaultProps = {
    active: true,
    onClick: () => setInfoPopupVisible(true),
  };

  const loadProperties = () => {
    let row = [];
    if (wine !== undefined) {
      Object.values(wine?.properties).forEach(property => {
        row.push(
          <WineInfoFlexVal>
            <WineInfoFlexTitle>{ControlTrad.getUserTrad(property?.name)}</WineInfoFlexTitle>
            <div>{ControlTrad.getUserTrad(property?.value)}</div>
          </WineInfoFlexVal>

        );
      });
    }
    return row;
  }

  return (
    <Element params={props.params}>
      <>
        {visibility || props.params.instructor || visibility === undefined ? (
          /*props.params.iconType ? (
            <IconButton src={IconTooltip} alt={"info_button"} onClick={() => setInfoPopupVisible(true)} />
          ) : (*/
            <Button styles={"z-index:5;" + props.params.styles} params={props.params}>
              + info
            </Button>
          /*)*/
        ) : (
          <></>
        )}
      </>

      <ModalMessage
        boxSize={"max-width:95vw; width:120vh;"}
        styleMain={"max-height:90vh;font-weight:normal;"}
        isShown={isInfoPopupVisible}
        header={false}
        setIsShown={setInfoPopupVisible}
        overFlow={true}>
        <WineWrapper>
          <WineHeaderWrapper>
            <WineHeader>{ControlTrad.getTrad("moreInformationTxt")}</WineHeader>
            <CloseHeader src={CloseImage} alt={"close popUp"} onClick={() => { setInfoPopupVisible(false) }} />
          </WineHeaderWrapper>
          <WineBodyWrapper>
            <div>
              <WineImg src={wine?.image} />
            </div>
            <WineDetailsWrapper>
              <WineName>{wine?.name}</WineName>
              <div style={{ display: "flex" }}><WineImgMobile src={wine?.image} /></div>   
              <Wine>
                {loadProperties()}
              </Wine>
            </WineDetailsWrapper>
          </WineBodyWrapper>
          <CloseBtn onClick={() => { setInfoPopupVisible(false) }} >{ControlTrad.getTrad("close")}</CloseBtn>
        </WineWrapper>

      </ModalMessage>
    </Element>);
}
export default MoreInfoButton;
