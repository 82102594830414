import app from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";

var configDev = {
  apiKey: "AIzaSyCNG0HV0sEZRh47-aTUon2LcXkl1K4nI8M",
  authDomain: "upwine-dev.firebaseapp.com",
  projectId: "upwine-dev",
  storageBucket: "upwine-dev.appspot.com",
  messagingSenderId: "716041717575",
  appId: "1:716041717575:web:bf50ac9d2961c9d187ee62",
  measurementId: "G-8K7TFQ7C4K"
};

const configProd = {
  apiKey: "AIzaSyAa0AVlQEmHiCb1WnylmRJUcab5GLbj5Vk",
  authDomain: "uptasting-prod.firebaseapp.com",
  projectId: "uptasting-prod",
  storageBucket: "uptasting-prod.appspot.com",
  messagingSenderId: "977171864415",
  appId: "1:977171864415:web:0ce0cb55ffa3561964f4ea",
  measurementId: "G-TKH0VLHZPS"
};

class Firebase {
  constructor() {
    try {
      let env = process.env.REACT_APP_ENVIRONMENT?.toLowerCase();
      let config = (env === "dev" || env === "local") ? configDev : configProd;
      
      app.initializeApp(config);
      console.log("Current Firebase user:", app.auth().currentUser);

    } catch (r) {}
    this.firebase = app;
  }
}

export default Firebase;
