import React from "react";
import Element from "../Element";
import Button from "components/Shared/Button"

import AppStateContext from "appState/AppStateContext";
import Websockets from "Websockets/Websockets";


const PreviousStepButton = (props) => {
   
    const appState = React.useContext(AppStateContext);
    let wineIndex = appState.state.get("wineIndex");
    const previousStep = ()=>{
        Websockets.send("PREVIOUS_STEP",[],true);
    }
  return (
    <Element params={props.params}>
        <Button onClick={previousStep} value={props.params.text} active={wineIndex !== 0}/>
    </Element>
  );
};

export default PreviousStepButton;
