import ModalMessage from "components/Sandbox/ModalMessage";
import React, { useEffect, useState } from "react";

import styled from "styled-components";
import Element from "../Element";
import Websockets from "Websockets/Websockets";
import AppStateContext from "appState/AppStateContext";
import Colours from "assets/custom/Custom";
import Valorate from "assets/images/valorar.svg";
import Notes from "assets/images/notes.svg";
import ControlTrad from "assets/trad/ControlTrad";
import Tasting from "assets/tasting/Tasting";
import FetchUtils from "Fetch/FetchUtils";

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    border-radius: 1vh;
    width: 100%;
    height: 100%;
    text-align: center;
    cursor: pointer;
`

const Title = styled.div`
    padding-left:1rem
`

const WrapperNotes = styled.div`
    font-size: 2.5vh;
    height:35vh;
    width:100%;
    display:flex;
    flex-direction: column;
    border-radius: 1vh;
    text-align:left;
`;

const AssistantNotes = styled.textarea`
    ${(props) => `
        border-radius: 1vh;
        padding: 1vh 2vh;
        border: 0px;
        flex: 1 1 0%;
        font-size: 2vh;
        outline: none;
        resize: none;
        color: ${props.editable ? "white" : "black"};
        background-color: ${props.editable ? "#4d4d4d" : "white"};
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        @media (max-width: 426px) {
            padding: 0vh 1rem;
            font-size: 1rem;
            padding: 0.5rem 1rem ;
        }
    `}
  `;

const ResponsiveAnnotations = (props) => {
    const [popUp1, setPopUp1] = useState(false)
    let currentWineRef, wineAnnotation;

    const appState = React.useContext(AppStateContext);
    if (Tasting.getType() === "live") {
        currentWineRef = appState.state.get(`currentWine`)?.reference;
        wineAnnotation = appState.state.get('currentUserAssessmentsParameters')?.annotation;
    } else {
        currentWineRef = props.params.currentWineRef;
        wineAnnotation = props.params.annotation[currentWineRef]?.annotation;
    }

    const [assistantAnn, setAssistantAnn] = useState(wineAnnotation || "");
    
    const ButtonRate = styled.div`
        background-color: ${wineAnnotation === "" || wineAnnotation === undefined ? Colours.getColour("mainColor") : "#b0b0b0"};
        color: white;
        display: flex;
        justify-content: center;
        padding: 0.6rem 1rem;
        font-size: 2vh ;
        padding: 1vh 1rem ;
        width: -webkit-fill-available;
        @media (max-width: 426px) {
            font-size: 1rem;
            padding: 0.6rem 1rem ;
            height: 100%;
            
        }
        ${Tasting.getType() === "live" ?
            "border-radius: 0 0 1vh 1vh;" :
            "border-radius: 1vh;margin: 0.2rem;"}
    `;

    const TitleButton = styled.div`
        background: white;
        border-radius: 1vh 1vh 0 0;
        font-weight: bold;
        padding: 1vh 0;
        font-size: 3vh;
        flex-direction:column;
        display:flex;
        align-items: center;
        @media (max-width: 426px) {
        font-size: 1.1rem;
        padding: 0.5rem 0;
        border-radius: 0.5rem 0.5rem 0 0;
        }
        ${Tasting.getType() === "live" ? "" : "display:none;"}
    `;

    const CloseModal = styled.div`
        text-align: center;
        border-radius: 0px 0px 1vh 1vh;
        color: white;
        font-size: 2.5vh;
        padding: 1vh;
        border: 1px solid transparent;
        transition: all 0.1s ease 0s;
        background-color: ${Colours.getColour("mainColor")};
        cursor: pointer;
        opacity: 1;
        @media (max-width: 426px) {
            padding: 0vh 1rem;
            border-radius: 0px 0px 0.5rem 0.5rem;
            font-size: 1rem;
            padding: 0.5rem 1rem ;
        }
    `;

    const sendAnswer = (value) => {
        if (Tasting.getType() === "live") {
            Websockets.send("SLAVE_PRODUCT_PARAMETER", [
                currentWineRef,
                "annotation",
                value,
            ], false);
        } else {
            props.params.setDataWine((prev) => ({
                ...prev,
                [props.params.currentWineRef]: {
                    ...prev[props.params.currentWineRef],
                    annotation: value
                }
            }));
            FetchUtils.sendProductParameter(props.params.currentProductRef, "annotation", value);
        }
    };

    useEffect(() => {
        setAssistantAnn(wineAnnotation || "")
    }, [wineAnnotation])


    return (
        <>
            <Element params={props.params}>
                <Wrapper onClick={() => { setPopUp1(true) }}>
                    <TitleButton>
                        {ControlTrad.getTrad("myAnotationsM")}
                        {/*<img src={Notes} alt={"Notes"} style={{ width: "1.3rem" }} />*/}
                    </TitleButton>
                    <ButtonRate>
                        {Tasting.getType() !== "live" ? ControlTrad.getTrad("myAnotationsM")+ " " : ""}
                        <img src={Valorate} alt={"Notes"} style={{ width: '1.3rem', padding:"0 0.5rem", filter: `hue-rotate(${Colours.toDeg("mainColor")}deg)` }} />
                    </ButtonRate>
                </Wrapper>
            </Element>
            <ModalMessage
                isShown={popUp1}
                setIsShown={setPopUp1}
                title={ControlTrad.getTrad("myAnotations")}
                header={true}
                styleMain={"background-color:white"}
                styleHeader={"background-color:white"}
                styleClose={"filter:invert(0%)"}
                boxSize={"width:80%"}
            >
                <WrapperNotes>
                    <Title>{ControlTrad.getTrad("myAnotations")}</Title>
                    <AssistantNotes
                        onChange={(e) => { setAssistantAnn(e.currentTarget.value) }}
                        value={assistantAnn}
                        placeholder={ControlTrad.getTrad("insertNotes")}
                    >
                    </AssistantNotes>
                    <CloseModal onClick={(e) => {
                        sendAnswer(assistantAnn);
                        setPopUp1(false);
                    }}>{ControlTrad.getTrad("accept")}</CloseModal>
                </WrapperNotes>
            </ModalMessage>
        </>
    );
}
export default ResponsiveAnnotations;