import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";

import Button from "components/Shared/Button";
import Logo from "assets/images/logo_white.png";
import assistantBackground from "../../assets/images/background_assistant.png";

import User from "assets/user/User";
import Colours from "assets/custom/Custom";
import ModalMessage from "components/Sandbox/ModalMessage";
import Fetch from "Fetch/Fetch";
import LocalStorage from "LocalStorage/LocalStorage";
import ControlTrad from "assets/trad/ControlTrad";
import Tasting from "assets/tasting/Tasting";

const Wrapper = styled.div`
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.8)), url("${assistantBackground}") no-repeat center;
  background-size: cover;
  text-align:center;
  display: flex;
  height:100vh;
  overflow: auto;
  &>*{
      margin: 1vh 2vh;
  }
  @media (max-width: 426px) {
    flex-direction : column;
  }
  @media (min-width: 426px) and (max-width: 900px) and (orientation: portrait) and (pointer: coarse) {
    height:auto;
    flex-direction : column;

  }
`;

const UpwineLogo = styled.img`
  width: 50%;
  height: auto;
  margin: 0 auto 2vh auto;

  @media (min-width: 700px) and (max-width:1000px) {
    width: 60%;
    margin-bottom: 2.5vh;
  }
  @media (min-width: 426px) and (max-width:700px) {
    width: 70%;
    margin-bottom: 2vh;
  }
  @media (max-width: 426px) {
    width: 80%;
    margin-bottom: 1vh;
  }
`

const FlexCode = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: auto;
  width: 30%;

  @media (min-width: 700px) and (max-width:1000px) {
    width: 50%;
  }
  @media (min-width: 426px) and (max-width:700px) {
    width: 70%;
  }
  @media (max-width: 426px) {
    width: 80%;
  }
`;

const SelectorLang = styled.div`
  display:flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-top: 1vh;
`;

const Lang = styled.div`
  ${(props) => `
    display: flex;
    color: white;
    background-color: ${props.active ? Colours.getColour("mainColor") : "rgba(216, 216, 216, 0.5)"};
    cursor: pointer;
    top: 1rem;
    right: 1rem;
    margin: 5px;
    width: 4.3vh;
    height: 4.3vh;
    min-width: 4.3vh;
    min-height: 4.3vh;
    text-align: center;
    border-radius: 8px;
    font-size: 2.5vh;
    @media (max-width: 426px) {
      font-size: 1.5rem;
      width: 3rem;
      height: 3rem;
    }
    @media (min-width: 426px) and (pointer: coarse) {
      font-size: 1.5rem;
      width: 3rem;
      height: 3rem;
    }
    @media (min-width: 426px) and (max-height:350px) and (pointer: coarse) {
      font-size: 1.3rem;
      width: 2.5rem;
      height: 2.5rem;
    }
  `};
`
const LangContent = styled.div`
  margin: auto;
`
const CodeAssistantDiv = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem 1.5rem;
  border: 1px solid white;
  border-radius: 9px;
  margin: 0.5rem 0px;
  color:white;
  @media (max-width: 426px) {
    font-size: 1rem;
  }
`
const CodeAssistantInput = styled.input`
  text-align: rigth;
  border-radius: 1.4vh;
  background-color: rgba(216, 216, 216, 0.5);
  color: white;
  font-size: 1.5rem;
  padding: 2vh;
  transition: all 0.1s ease 0s;
  border : 0vh;
  height: 1vh;
  width: calc(100% - 4vh);
  margin: 0vh auto 2vh auto;
  ::placeholder  {
      color: lightgray;
  }
`

const CodeInstructorDiv = styled.div`
  ${props => `
    display: flex;
    flex-direction: column;
    padding: 1rem 1.5rem;
    border: 1px solid white;
    border-radius: 9px;
    margin: 0.5rem 0px;
    color:white;
    @media (max-width: 426px) {
      font-size: 1rem;
    }
    ${props.styles}
  `};
`

const CardInfoText = styled.h1`
  ${props => `
    font-weight:normal;
    margin-bottom: 2vh;
    font-size:2.3vh;
    ${props.styles}
  `}
`;

const UpwineLink = styled.a`
  text-decoration:none;
  text-align:center;
  color:white;
  font-size:1.42rem;
`;

const Home = () => {
  const [lang, setLang] = useState(User.getLang());
  const [expandedLangs, setExpandedLangs] = useState(false);
  const [isCodeNotExistPopupVisible, setCodeNotExistPopupVisible] = useState(false);
  const [code, setCode] = useState("");
  const [loading, setLoading] = useState(false);
  let history = useHistory();

  const goAssistant = () => {
    if (!loading) {
      let controlCode = code.toUpperCase();
      if (controlCode !== "") {
        setLoading(true);
        Fetch.checkCode(controlCode).then((res) => {
          if (res.error || !res.exists) {
            setCodeNotExistPopupVisible(true);
            setLoading(false);
          } else {
            if (res?.type === "PODCAST_TAST") {
              loadTasting(controlCode, "podcast");
            } else {
              loadTasting(controlCode, "live", res?.session);
            }
          }
        }).catch((e) => {
          console.error("Error cheking code: ", e);
          setCodeNotExistPopupVisible(true);
          setLoading(false);
        });
      } else {
        setCodeNotExistPopupVisible(true);
      }
    }
  }

  const loadTasting = (code, type, session) => {
    if (type === "live" && session?.jitsi?.active) {
      requestPermissions();
    } else {
      requestFullScreen();
    }
    LocalStorage.set("LANG", User.getLang());
    LocalStorage.get("LANG");
    Tasting.setPublicCode(code);
    Tasting.setType(type);

    history.replace("/" + code);
  }

  const requestPermissions = () => {
    try {
      console.log("Requesting Permissions");
      let permisions = ({
        video: true,
        audio: true
      });

      navigator.getUserMedia(permisions,
        (localMediaStream) => {
          console.log("Permissions Request Accepted", localMediaStream);
          requestFullScreen();
        },
        (err) => {
          console.error("Error requesting permissions", err);
          requestFullScreen();
        }
      );
    } catch (e) {
      console.error("Error requesting permisions", e);
      requestFullScreen();
    }
  }

  function requestFullScreen() {
    try {
      console.log("Requesting Fullscreen");
      let elem = document.documentElement;
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.mozRequestFullScreen) {
        elem.mozRequestFullScreen();
      } else if (elem.webkitRequestFullscreen) {
        elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) {
        elem.msRequestFullscreen();
      }
    } catch (e) {
      console.error("Error requesting fullscreen", e);
    }
}

  const goInstructor = () => {
    window.location.replace('instructorLogin')
  }

  const loadLangs = () => {
    let visibleItems = 3;
    let langs = ["ES", "EN", "CA", "ZH", "FR", "DE", "PT", "IT", "GL", "NL", "PL", "RU", "UK"]
    let row = [];

    let i = 0;
    langs.forEach(lang => {
      if (i++ < visibleItems || expandedLangs) {
        row.push(
          <Lang
            active={User.getLang() === lang}
            onClick={(e) => {
              setLang(lang)
              User.setLang(lang)
              LocalStorage.set("LANG", User.getLang())
            }}>
            <LangContent>{lang}</LangContent>
          </Lang>
        )
      }
    });
    row.push(
      <Lang onClick={(e) => { setExpandedLangs(!expandedLangs) }}>
        <LangContent>{expandedLangs ? "◄" : "►"}</LangContent>
      </Lang>
    );
    return row;
  }

  return (
    <>
      <Wrapper>
        <FlexCode>
          <a href={"https://www.smarttating.net"}><UpwineLogo src={Logo} /></a>
          <CodeAssistantDiv>
            <CardInfoText styles={"font-size:1.5rem; margin-top:0.6vh;"}>{ControlTrad.getTrad("insertCode")}</CardInfoText>
            <CodeAssistantInput
              value={code}
              placeholder={ControlTrad.getTrad("insertCode")}
              onKeyDown={(e) => {
                if (e.code === "Enter" || e.keyCode === 13) {
                  e.target.blur();
                  goAssistant();
                }
              }}
              onChange={(e) => {
                setCode(e.currentTarget.value.toUpperCase());
              }} />
            <Button 
            active = {!loading}
            styles={"font-size:3.2vh; margin-bottom:0.8vh;"} 
            onClick={goAssistant} 
            value={loading ? ControlTrad.getTrad("loading"):ControlTrad.getTrad("startTaste")} />
          </CodeAssistantDiv>
          <CodeInstructorDiv styles={"margin-bottom:0;"}>
            <CardInfoText styles={"font-size:1.5rem;margin-top:0vh;"}>{ControlTrad.getTrad("areYouAInstructor")}</CardInfoText>
            <Button styles={"font-size:3.2vh;"} onClick={goInstructor} value={ControlTrad.getTrad("access")} />
          </CodeInstructorDiv>
          <UpwineLink href={"https://www.smarttating.net"}>www.smarttating.net</UpwineLink>
          <SelectorLang>
            {loadLangs()}
          </SelectorLang>
        </FlexCode>
      </Wrapper>
      <ModalMessage
        isShown={isCodeNotExistPopupVisible}
        setIsShown={setCodeNotExistPopupVisible}
        titleStyle={`font-size:2.1vh; margin-left:3vh;`}
        boxSize={`
          width: 40rem;
          border-radius:1vh;
          background-color: rgba(216,216,216,1);
        `}
        styleMain={`
          border-bottom-left-radius:1vh;
          border-bottom-right-radius:1vh;
          height: 20vh !important;
          display: flex;
          align-items: center;
          justify-content: space-around;
          flex-direction:column;
          font-size: 1.5rem;
          padding: 1vw;
        `}
        styleHeader={`
          border-top-left-radius:1vh;
          border-top-right-radius:1vh;
        `}
      >
        <div style={{ margin: "auto" }}>{ControlTrad.getTrad("codeDoesntExist")}</div>
        <Button
          styles={"background-color:black; border:none; color:white; width:70%; margin: auto;"}
          onClick={() => { setCodeNotExistPopupVisible(false) }}
          type={"cancel"}
          value={ControlTrad.getTrad("close")} />
      </ModalMessage>
    </>
  );
};

export default Home;
