import React, { useEffect, useState } from "react";
import styled from "styled-components";

import Element from "../Element";
import Question from "../FinalSurvey/Question";
import Fetch from "Fetch/Fetch";

const Questions = styled.div`
    display:flex;
    flex-direction:column;
    height:100%;
    overflow:auto;
    background-color:rgba(216,216,216,0.8);
`;
const QuestionItem = styled.div`
padding:1vh;
display:flex;
align-items:center;
justify-content:space-around;
`;

const SimpleSurvey = (props) => {
    const components = props.params.components;
    const wineData = props.params.wineData;
    const setCurrentProductData = props.params.setCurrentProductData;
    const [answers, setAnswers] = useState({});

    const loadQuestions = () => {
        let row = [];

        components.forEach(component => {
            row.push(
                <QuestionItem>
                    <Question
                        concept={component.name}
                        size={10}
                        separation={props.separation}
                        titleStyles={"padding-bottom: 1vh;"}
                        active={answers?.[component.id]}
                        reference={component.id}
                        onClickEvent={onClickEvent}
                    />
                </QuestionItem>
            )
        })
        return row;
    }

    const onClickEvent = (answer, reference) => {
        let satisfaction = document.getElementById(reference);
        satisfaction.setAttribute("satisfactionVal", answer);

        setAnswers((prev) => ({
            ...prev,
            [reference]: answer
        }))

        setCurrentProductData((prev) => ({
            ...prev,
            [props.params.currentWineRef] : {
                ...prev[props.params.currentWineRef],
                [reference]: answer
            }
        }))

    }

    useEffect(() => {
        components.forEach(component => {
            if(wineData) {
                if(wineData[component.id]){
                    setAnswers((prev) => ({
                        ...prev, [component.id] : wineData[component.id]
                    }))

                }
            }
        })
    }, [wineData])

    return (
        <Element params={props.params}>
            <Questions>
                {loadQuestions()}
            </Questions>

        </Element>
    );

}
export default SimpleSurvey;