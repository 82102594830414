import React from "react";
import styled from "styled-components";

import angry from "assets/images/faces/angry.svg";
import halfAngry from "assets/images/faces/half_angry.svg";
import halfHappy from "assets/images/faces/half_happy.png";
import happy from "assets/images/faces/happy.png";
import User from "assets/user/User";
import ControlTrad from "assets/trad/ControlTrad";

const QuestionItem = styled.div`
    display:flex;
    flex-direction:column;
    align-items:center;
    width:100%;
`

const Title = styled.div`
    ${props =>`
        margin-bottom:1vh;
        font-size: 2.5vh;
        ${props.styles}
    `}
`;

const Options = styled.div`
    ${props =>`
        flex:1;
        padding: 1vh 1vh;
        display:flex;
        flex-direction:row;
        flex-wrap: wrap;
        width: ${props.separation ? props.separation + "%" : "100%"};
        @media (max-width: 426px) {
            width:90%;
        }
    `}
`;

const OptionActive = styled.div`
    transition : 0.2s;
    cursor: pointer;
    display:flex;
    font-size: 2.5vh;
    flex-direction:column;
    flex : 1;
    &:hover {
        opacity:0.9;
    }
    @media (max-width: 426px) {
        font-size: 2vh;
    }  
`;

const OptionInactive = styled.div`
    transition : 0.2s;
    cursor: pointer;
    display:flex;
    font-size: 2.5vh;
    flex-direction:column;
    flex : 1;
    justify-content: center;
    align-items: center;
    opacity: 0.75;
    filter: grayscale(1);
    &:hover {
        opacity: 0.6;
    }
    @media (max-width: 426px) {
        font-size: 2vh;
    }
`;

const OptionText = styled.div`
    font-size: 2vh;
    margin: auto;
    padding: 0.5vh;
`;

const ImgFace = styled.img`
    ${props =>`
        width: ${props.size ? props.size : "12"}vh;
        margin: 0 auto;
        @media (max-width: 426px) {
            width : 6vh;
        }

        @keyframes selectAnim {
            0% { width: ${props.size ? props.size : "12"}vh; }
            50% {  width: ${props.size ? props.size + 1 : "13"}vh; }
            100% { width: ${props.size ? props.size : "12"}vh;  }
        }
          
        animation-fill-mode: forwards;
        animation-duration: 1s;

        ${props?.showAnim ? `
          animation-name: selectAnim;
        ` : `
          animation-name: none;
        `}
    `}
`

const Question = (props) => {

    const loadOptions = () => {
        let row = [];

        let options = {
            veryBad: {
                value: 4,
                text: ControlTrad.getTrad("veryBad"),
                icon: angry
            },
            regular: {
                value: 3,
                text: ControlTrad.getTrad("regular"),
                icon: halfAngry
            },
            good: {
                value: 2,
                text: ControlTrad.getTrad("good"),
                icon: halfHappy
            },
            veryGood: {
                value: 1,
                text: ControlTrad.getTrad("veryGood"),
                icon: happy
            }
        }

        Object.entries(options).forEach(([optionKey, option]) => {
            if (props.active == undefined || option.value === props.active) {
                row.push(
                    <OptionActive onClick={(e) => { props.onClickEvent(option.value, props.reference) }}>
                        <ImgFace size={props.size} src={option.icon} showAnim={option.value === props.active} />
                        <OptionText>{option.text}</OptionText>
                    </OptionActive>
                );
            } else {
                row.push(
                    <OptionInactive onClick={(e) => { props.onClickEvent(option.value, props.reference) }}>
                        <ImgFace size={props.size} src={option.icon} />
                        <OptionText>{option.text}</OptionText>
                    </OptionInactive>
                );
            }
        });
        return row;
    }


    return (
        <QuestionItem className={"sat-value"} id={props.reference}>
            <Title styles={props.titleStyles}>{ControlTrad.getUserTrad(props.concept)}</Title>
            <Options separation={props.separation}>{loadOptions()}</Options>
        </QuestionItem>
    );

}
export default Question;