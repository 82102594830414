import React from "react";
import Firebase from "../../../../Firebase";

import Element from "../Element";
import Button from "components/Shared/Button"


const SkipReportButton = (props) => {
    const f = new Firebase().firebase.default;

    const signOut = () => {
        f.auth().signOut();
        window.location.replace("/");
    }

    return (
        <Element params={props.params}>
            <Button value={props.params.text} type = {props.params.type} onClick={signOut}/>
        </Element>
    );
};

export default SkipReportButton;
