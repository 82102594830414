import React from "react";
import correct from "assets/images/icons/success-green.svg";
import styled from "styled-components";
import Colours from "assets/custom/Custom";


const QuizOption = (props) => {

    const Option = styled.div`
        ${(props) => `
            text-align : left;
            color: white;
            width:100%;
            display:flex;
            flex-direction:row;
            background-color : white;
            height: auto;
            border-radius:0.5vh;
            border-bottom-left-radius: 1vh;
            border-top-left-radius: 1vh;
        `}
    `;

    const OptionHead = styled.div`
        text-align : center;
        color: white;
        background-color: black;
        font-size: 2vh;
        width : 7vh;
        padding 1vh;
        border-bottom-left-radius: 0.5vh;
        border-top-left-radius: 0.5vh;
    `;

    const ImgCorrect = styled.img`
        height: 3vh;
        padding: 0 2vh 0 0;
    `;

    const OptionTittle = styled.div`
        text-align : center;
        color: black;
        font-size: 2vh;
        flex:2;
        padding: 1vh 1vh;
        display:flex;
        justify-content: center;
    `

    const OptionAnswers = styled.div`
        text-align : center;
        color: white;
        font-size: 3vh;
        flex:1;
        padding: 1vh 1vh;
        background-color:${Colours.getColour("mainColor")};
        border-bottom-right-radius: 0.5vh;
        border-top-right-radius: 0.5vh;

        @media (max-width: 426px) {
            font-size: 2vh;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    `;

    return (
        <Option params={props.params}>
            <OptionHead>{props.optionHead}</OptionHead>
            <OptionTittle>
                {props.correct ? <ImgCorrect src={correct} /> :<></>}
                {props.option}
            </OptionTittle>
            <OptionAnswers>
                {props.answers}
            </OptionAnswers>
        </Option>
    );

}
export default QuizOption;