import React, { useState, useEffect } from "react"
import styled from "styled-components";

import Button from "components/Shared/Button"
import Fetch from "Fetch/Fetch";
import Firebase from "../../../../../Firebase";

import EditTasting from "assets/images/icons/ic_editar.svg";
import ControlTrad from "assets/trad/ControlTrad";

const Wrapper = styled.div`
${props => `
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:flex-start;
    font-size: 2vh;
    color:white;
    height:100%;
    ${props.params?.styles}
`}`

const TastingWrapper = styled.div`
    display:flex;
    flex-direction:column;
    justify-content:flex-start;
    align-items:center;
    max-height:75vh;
    width: 50vw;
    overflow-y: auto;
    margin-bottom: 1vh;
    @media (max-width: 426px) {
        width: 80vw;
    }
`

const TextBox = styled.span`
    ${props => `
        font-size:4vh;
        color:white;
        font-weight:bold;
        ${props?.styles}
`}`

const TastingRow = styled.div`
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content:center;
    width:100%;
    margin-bottom:2vh;
`

const TastingBox = styled.div`
    ${props => `
        display:flex;
        align-items:center;
        justify-content:center;
        background-color: rgba(0, 0, 0, 0.4);
        border-radius: 0.5vh;
        padding: 0.5rem 10vh;
        font-size: 3vh;
        color: white;
        cursor: pointer;
        flex: 1 1 0%;
        transition: all 0.1s ease 0s;
        margin-left: 1vh;
        height:2.8vw;
        @media (max-width: 426px) {
            width: 15vw;
            height:2rem;
            max-width: 8vw;
        }
        ${props?.styles}
`}`
const TastingBoxTitle = styled.div`
    ${props => `
        display:flex;
        align-items:center;
        justify-content:center;
        background-color: rgba(0, 0, 0, 0.4);
        border-radius: 0.5vh;
        padding: 0.5rem 10vh;
        font-size: 3vh;
        color: white;
        cursor: pointer;
        flex: 1 1 0%;
        transition: all 0.1s ease 0s;
        margin-left: 1vh;
        height:2.8vw;
        min-width: 40vw;
        padding:0;
        @media (max-width: 426px) {
            width: 75vw;
            height:2.5rem;
        }
        ${props?.styles}
`}`

const TastingBoxWrapper = styled.div`
    width:fit-content;
    &:hover ${TastingBox} {
        background-color: rgba(0, 0, 0, 0.6);
    }
    @media (max-width: 426px) {
        width: 100%;
    }
`

const EditImage = styled.img`
    width:2vh;
    height:2vh;
    font-size:1vh;
`
const PanelNoResponsive = styled.div`
@media (max-width: 426px) {
    display:none;
}
`


const TastingId = (props) => {
    const [tastingList, setTastingList] = useState([]);
    const f = new Firebase().firebase.default;

    const createTasting = () => {
        if (process.env.REACT_APP_ENVIRONMENT === "dev") {
            window.open("https://editor-dev.uptasting.online/dashboard/tastings/live/list", "_blank");
        } else {
            window.open("https://editor.uptasting.online/dashboard/tastings/live/list", "_blank");
        }
    }

    const modifyTasting = (reference) => {
        if (process.env.REACT_APP_ENVIRONMENT === "dev") {
            window.open("https://editor-dev.uptasting.online/dashboard/tastings/live/edit/" + reference, "_blank");
        } else {
            window.open("https://editor.uptasting.online/dashboard/tastings/live/edit/" + reference, "_blank");
        }
    }

    useEffect(() => {
        let uID = "";
        if (f.auth().currentUser) uID = f.auth().currentUser.uid;
        Fetch.getTastings(uID, "CONVENTIONAL_TAST").then((tastings) => {
            if (!tastings.error) setTastingList(tastings);
        });
    }, [props.user])

    return (
        <Wrapper >
            <TastingWrapper>
                <TextBox styles={"margin-bottom:2vh; color:black;"}>{ControlTrad.getTrad("selectATast")}</TextBox>
                {tastingList && tastingList.map(tasting => {

                    let reference = tasting?.reference.split("/")[tasting?.reference.split("/").length - 1] || "";
                    return (
                        tasting.type === "CONVENTIONAL_TAST" ?
                            <TastingRow>
                                <TastingBoxWrapper value={reference} onClick={(e) => props.setTastingId(e.currentTarget.getAttribute("value"))}>
                                    <TastingBoxTitle styles={"min-width: 40vw; padding:0;"}>
                                        <TextBox styles={"font-size:3vh; font-weight:normal;"}>{tasting.name}</TextBox>
                                    </TastingBoxTitle>
                                </TastingBoxWrapper>
                                <PanelNoResponsive>
                                    <TastingBoxWrapper>
                                        <TastingBox styles={"max-width:2.8vw; width: 2.8vw; padding:0;"}>
                                            <TextBox onClick={() => modifyTasting(reference)} styles={"font-size:3vh; font-weight:normal;"}>
                                                <EditImage src={EditTasting} alt={"modify tasting"} />
                                            </TextBox>
                                        </TastingBox>
                                    </TastingBoxWrapper>
                                </PanelNoResponsive>

                            </TastingRow>
                            :
                            <></>
                    )
                })}
            </TastingWrapper>
            <PanelNoResponsive>
                <Button value={ControlTrad.getTrad("createNewTast")}
                    onClick={createTasting} />
            </PanelNoResponsive>

        </Wrapper>
    );
};

export default TastingId;
