import React, { useEffect, useState } from "react";
import styled from "styled-components";

import AppStateContext from "appState/AppStateContext";
import Element from "../Element";
import RateStars from 'components/Shared/RateStarsOld';
import Button from "components/Shared/Button";
import User from "assets/user/User";
import Colours from "assets/custom/Custom";
import ControlTrad from "assets/trad/ControlTrad";
import Tasting from "assets/tasting/Tasting";
import SoundUtils from "assets/utils/SoundUtils";
import FetchUtils from "Fetch/FetchUtils";


const Wrapper = styled.div`
        background: #fffffff0;
        border-radius: 1vh;
        font-size: 2.5vh;
        background: white;
        height: 100%;
        overflow-y: scroll;
        @media (max-width: 426px) {
            border-radius:0vh;
        }
    `;

const WineWrapper = styled.div`
        ${props => `
            display: flex;
            flex-wrap: wrap;
            justify-content: space-around;
            align-items: center;
            flex-direction: row;
            padding: 5vh 2vh 2vh;
            background-color: ${props.wineRecommendation ? "lightgrey" : "transparent"};
    `}`;

const WineInfoWrapper = styled.div`
        width: 45%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        @media (max-width: 426px) {
            width: 70%;
        }
    `;

const WineImageWrapper = styled.div`
        width: 25%;
        height: 100%;
        max-height: 25vh;
        @media (max-width: 426px) {
            width: 30%;
        }
    `;

const WineImage = styled.img`
        width: auto;
        height: 90%;
        max-height: 25vh;
        margin: auto;
        @media (max-width: 426px) {
            width: 10vw;
            height: auto;
            margin:auto;
            padding-left: 0vh;

        }
    `;

const WineName = styled.span`
    font-size:2.3vh;
    font-weight:bold;
    text-align : left;
    `;


const WineRatePriceTitle = styled.span`
        font-size: 2vh;
        text-align : left;
    `;

const WineUserRateDiv = styled.div`
    ${props => `
        display:flex;
        flex-direction:row;
        ${props?.styles}
    `}
    `

const WineUserTitleRate = styled.div`
    font-size:2.2vh;
    margin-left:2vh;
    text-align:center;
    `

const WineUserRate = styled.span`
    ${props => `
        font-size:2vh;
        font-weight:600;
        margin-left:2vh;
        margin-top:0.5vh;
        text-align:center;
        ${props.styles}
    `}
`

const Prices = styled.span`
    display: flex;
    width: 100%;
    justify-content: center;
`;


const NoDisccountPrice = styled.span`
    font-size:2.5vh;
    text-align : left;
    text-decoration: line-through;
    padding:2vh 0;
`;

const WineShopWrapper = styled.div`
    width: 30%;
    height: 100%
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    @media (max-width: 426px) {
        width: 100%;
    }
`;

const WineRatePrice = styled.span`
    ${props => `
        font-size: 3vh;
        text-align : left;
        color:${Colours.getColour("mainColor")};
        font-weight:bold;
    `}
`;

const PriceInfo = styled.div`
    color: ${Colours.getColour("mainColor")};
    font-size: 2vh;
    font-weight: bold;
    font-style: italic;
    margin-top: 3vh;
    text-align : left;

`;

const Price = styled.span`
    ${props => `
        font-size: 5vh;
        text-align: left;
        color: ${Colours.getColour("mainColor")};
        font-weight: bold;
    `}
`;

const DicconuntPrice = styled.span`
    ${props => `
        font-size: 5vh;
        text-align: left;
        color: ${Colours.getColour("mainColor")};
        font-weight: bold;
        padding-left: 2vh;
    `}
`;

const ProductsStore = (props) => {

    const appState = React.useContext(AppStateContext);
    let wines, assistants, storeActive, currentData, productType, currency;

    if (Tasting.getType() === "live") {
        wines = appState.state.get(`wines`);
        assistants = appState.state.get("assistants");
        storeActive = appState.state.get("storeActive");
        productType = ControlTrad.getUserTrad(appState.state.get("currentWine")?.product?.type?.name);
        currency = appState.state.get("currency");
    } else {
        wines = props.params.wines;
        storeActive = true;
        currentData = props.params.currentData;
        productType = props.params.productType;
        currency = props.params.currency;
    }

    const loadProducts = () => {
        if (storeActive) {
            let views = [];
            (wines || [])?.forEach(productReference => {
                let product = productReference.product;

                views.push(
                    <WineWrapper wineRecommendation={false}>
                        {loadProductImage(product)}
                        <WineInfoWrapper>
                            {loadProductData(product, productReference.reference)}
                        </WineInfoWrapper>
                        <WineShopWrapper>
                            {loadShop(product, productReference.reference)}
                        </WineShopWrapper>
                    </WineWrapper>
                )
            })

            return views;
        } else {
            props.params.onNoStore();
        }
    }

    const loadProductImage = (product) => {
        return (
            <WineImageWrapper>
                <WineImage src={product.image} alt="productImage" />
            </WineImageWrapper>
        );
    }

    const isValidPrice = (price) => {
        if (price === undefined || price === null || typeof price === "object") {
            return false;
        } else if (typeof price === "string") {
            return price.length > 0;
        }
        return true;
    }

    const loadProductData = (product, reference) => {
        let priceBoxValue, rateBox, userPrice;

        if (Tasting.getType() === 'live') {
            priceBoxValue = assistants?.[User.getId()]?.assessments?.[reference]?.parameters?.ratePrice || ControlTrad.getTrad("notRated");
            rateBox = assistants?.[User.getId()]?.assessments?.[reference]?.parameters?.rateBox || 0;
            userPrice = assistants?.[User.getId()]?.assessments?.[reference]?.parameters?.blindPrice || 0;
        } else {
            rateBox = currentData[reference]?.rateBox || 0;
            priceBoxValue = currentData[reference]?.ratePrice || ControlTrad.getTrad("notRated");
            userPrice = currentData[reference]?.blindPrice || 0;
        };

        const containProductPrice = isValidPrice(product.price);
        const containsDiscountedProdcutPrice = isValidPrice(product.discountedPrice);

        return (
            <>
                <WineName>{product.name}</WineName>
                {reference !== "" ? (
                    <RateStars value={rateBox} edit={false} color2={Colours.getColour("mainColor")} />
                ) : (
                    <PriceInfo>{ControlTrad.getTrad("winerecommended")}</PriceInfo>
                )}
                {reference !== "" ?
                    props.params.layout !== "BLIND_TEMPLATE" ? (
                        <>
                            <WineRatePriceTitle>{ControlTrad.getTrad("textValorate")}</WineRatePriceTitle>
                            <WineRatePrice >{priceBoxValue}</WineRatePrice>
                        </>
                    ) : (
                        <WineUserRateDiv>
                            <WineUserRateDiv styles={"flex-direction:column;"}>
                                <WineUserTitleRate>Precio estimado</WineUserTitleRate>

                                <WineUserRate>{userPrice} {currency}</WineUserRate>
                            </WineUserRateDiv>

                                {containProductPrice ? (
                                    <WineUserRateDiv styles={"flex-direction:column;"}>
                                        <WineUserTitleRate>Precio real</WineUserTitleRate>
                                        <WineUserRate styles={"color:" + Colours.getColour("mainColor")}>{product.price} {currency}</WineUserRate>
                                    </WineUserRateDiv>
                                ) : (
                                    <></>
                                )}
                        </WineUserRateDiv>
                    ) : (
                        <></>
                    )
                }
                <PriceInfo>{ControlTrad.getTrad("getWine") + " " + productType + "!"}</PriceInfo>

                <Prices>
                    {containsDiscountedProdcutPrice ? (
                        containProductPrice ? (
                            <>
                                <NoDisccountPrice>{product.price} {currency}</NoDisccountPrice>
                                <DicconuntPrice>{product.discountedPrice} {currency}</DicconuntPrice>
                            </>
                        ) : (

                                <Price>{product.discountedPrice} {currency}</Price>
                        )
                    ) : (
                            containProductPrice ? (
                                <Price>{product.price} {currency} </Price>
                        ) : (
                                    <></>
                        )
                    )}
                </Prices>
            </>
        );
    }

    const goToLink = (WineStoreURL, productReference) => {
        if (WineStoreURL) {
            if (WineStoreURL.includes("http://") || WineStoreURL.includes("https://")) {
                window.open(WineStoreURL, "_blank");
            } else {
                window.open("https://" + WineStoreURL, "_blank")
            }

            if (productReference && !User.isLinkClicked(productReference)) {
                FetchUtils.notifyStoreLinkClicked(productReference, WineStoreURL, () => {
                    User.setLinkClicked(productReference);
                });
            }
        }
    }

    const loadShop = (product, reference) => {
        return (
            <Button
                styles={"padding : 3vh 2vh; width: 80%; margin: auto;"}
                value={new RegExp("([a-zA-Z0-9]+://)?([a-zA-Z0-9_]+:[a-zA-Z0-9_]+@)?([a-zA-Z0-9.-]+\\.[A-Za-z]{2,4})(:[0-9]+)?(/.*)?").test(product?.externalStoreURL) ?
                    ControlTrad.getTrad("buy") : ControlTrad.getTrad("noStock")}
                active={new RegExp("([a-zA-Z0-9]+://)?([a-zA-Z0-9_]+:[a-zA-Z0-9_]+@)?([a-zA-Z0-9.-]+\\.[A-Za-z]{2,4})(:[0-9]+)?(/.*)?").test(product?.externalStoreURL)}
                onClick={() => goToLink(product?.externalStoreURL, reference)}
                highligthed={true}
            />
        )
    }

    useEffect(() => {
        SoundUtils.play("storeSound");
    }, []);

    return (
        <Element params={props.params} >
            <Wrapper>
                {props.params?.wineRec ?
                    <WineWrapper wineRecommendation={props.params?.wineRec !== null}>
                        {loadProductImage(props.params?.wineRec)}
                        <WineInfoWrapper>
                            {loadProductData(props.params?.wineRec, "")}
                        </WineInfoWrapper>
                        <WineShopWrapper>
                            {loadShop(props.params?.wineRec)}
                        </WineShopWrapper>
                    </WineWrapper>
                    : <></>}
                {loadProducts()}
            </Wrapper>
        </Element>
    );
};

export default ProductsStore;
