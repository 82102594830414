import React, {useState} from "react"

import Element from "../Element";

import TastingEvent from "./TastingEvent/TastingEvent";
import TastingId from "./TastingId/TastingId";

const TastingSelection = (props) => {
    const[tastingId, setTastingId] = useState(null);

    return (
        <Element params={props.params}>
            { tastingId ? 
                <TastingEvent /*params={props.params}*/ tastingId={tastingId} setTastingId={setTastingId} /> 
                : 
                <TastingId /*params={props.params}*/ setTastingId={setTastingId}/>
            }
        </Element>
    );
};

export default TastingSelection;
