import React, { useEffect, useState } from "react";
import styled from "styled-components";
import ControlTrad from "assets/trad/ControlTrad";
import Custom from "assets/custom/Custom";


const DivCheck = styled.div`
    display: flex;
    justify-content: center;
    @media (pointer: coarse) and (min-width:426px) and (orientation: landscape): {
        padding: 0 2rem;
    }
    @media only screen and (max-width:426px): {
        padding: 0 2rem;
    }
`

const CheckBox = styled.input`
    color: white;
    width: 3.5vh;
    height: 3.5vh;
    margin: auto 0;
    @media (min-width: 426px) and (pointer: coarse) and (orientation: landscape) {
        width: 5vh;
        height: 5vh;
    }
    @media (max-width: 426px) {
        width: 7vh;
        height: 7vh;
    }
`

const LabelCheck = styled.label`
    font-size: 2vh;
    margin: auto 1vh;
    @media (max-width:426px) {
        font-size: 1.1rem;
    }
    @media (min-width: 426px) and (pointer: coarse) and (orientation: landscape) {
        font-size: 1.2rem;
    }
    color: white;
        & > b > a {
            color: white;
    }
`

const Error = styled.div`
    visibility:hidden;
`

const H2 = styled.div`
font-size: 2vh;
color: #fff;
margin: 0 auto 1vh auto;
@media (max-width: 426px) {
    font-size: 0.8rem;
  }
  @media (min-width: 426px) and (pointer: coarse) and (orientation: landscape) {
    font-size: 1rem;
  }

`


const TermsAndConditions = (props) => {

    useEffect((() => { }), [Custom.Links]);

    return (
        <>
            <DivCheck>
                <CheckBox type={"checkbox"} id={props.id} onClick={props?.onClick} />
                <LabelCheck for={props.id}>
                    {ControlTrad.getTrad("terms") + " "}
                    <b>
                        <a href={Custom.Links.termsOfUse} target={"_blank"} rel="noopener noreferrer"  >
                            {ControlTrad.getTrad("conditions1")}
                        </a>
                    </b>
                    {" " + ControlTrad.getTrad("conditions2") + " "}
                    <b>
                        <a href={Custom.Links.privacyPolicies} target={"_blank"} rel="noopener noreferrer"  >
                            {ControlTrad.getTrad("conditions3")}
                        </a>
                    </b>
                </LabelCheck>
            </DivCheck>
            <Error id={props.id + "error"}>
                <H2>{ControlTrad.getTrad("termerror")}</H2>
            </Error>
        </>
    );

}
export default TermsAndConditions;
