import React from "react";
import Element from "../Element";
import styled from "styled-components";
import flecha from 'assets/images/icons/Mflecha.svg';
import ControlTrad from "assets/trad/ControlTrad";
import Colours from "assets/custom/Custom";

const NavegationWine = (props) => {

    const Wrapper = styled.div`
        ${(props) => `
            height: 100%;
            display:flex;
            justify-content: space-between;
            padding: 0 7vh;
            align-items: center;
            ${props.styles}
            @media (max-width: 426px) {
                padding: 0 0;
                background-color:rgba(49, 32, 40, 0.8);
            }
        `}
    `

    const HeaderTag = styled.div`
        ${(props) => `
            opacity: 0;  
            display: flex;
            color: white;
            font-size: 3.3vh;
            margin-left: 2vh;

            @media (max-width: 426px) {
                opacity: 1;  
            }
        `}
    `

    const ButtonNavBack = styled.div`
        ${(props) => `
            display:flex;
            color:white;
            height: 7vh;
            width:7vh;
            font-size: 1.7vh;
            border-radius: 5vh;
            flex-direction:column;
            align-items: center;
            padding: 1.5vh;
            opacity: ${props.enabled ? 1 : 0};
            background-color: rgb(176 176 176);
            cursor: ${props.enabled ? "poitner" : "auto"};
            box-shadow: 0px 0px 30px 3px gray;

            &:hover{
                background-color: #848484;
            }

            @media(max-width: 426px) {
                width: auto;
                margin: auto 0 auto 1vh;
                height: fit-content;
                border-radius: 1vh;
                flex-direction: row;
                font-size:1.3rem;

                &:hover{
                    background-color: rgba(255, 255, 255, 0.0);
                }
            }
        `}
    `

    const ButtonNavNext = styled.div`
        ${(props) => `
            display:flex;
            color:white;
            height: 7vh;
            width:7vh;
            border-radius: 5vh;
            flex-direction:column;
            font-size: 1.7vh;
            align-items: center;
            padding: 1.5vh;
            cursor: pointer;
            background-color:${Colours.getColour("mainColor")};

            ${props?.highligthed ? `
                animation-name: highligthedAnim;
                animation-duration: 2s;
                animation-iteration-count: infinite;
                animation-direction: alternate;
                border: 0; 
            `: null}

            &:hover{
                background-color:${Colours.getColour("secondaryColor")};
            }

            @media (max-width: 426px) {
                width: auto;
                margin: auto 1vh auto 0;
                height: fit-content;
                border-radius: 1vh;
                flex-direction: row-reverse;
                font-size:1.3rem;

                &:hover{
                    background-color:rgba(255,255,255,0.0);
                }
            }S
        `}
    `

    const ImgBack = styled.img`
        width: 2.5vh;
        margin: auto;
        transform: rotate(180deg);
        @media (max-width: 426px) {
            padding: 0 0 0 1.5vh;
            height: 1.3rem;
        }
    `

    const ImgNext = styled.img`
        width: 2.5vh;
        margin: auto;
        @media (max-width: 426px) {
            padding: 0 0 0 1.5vh;
            height: 1.3rem;
        }
    `
    const goBack = () => {
        if (props.params.current !== 0) {
            props.params.backWine();
        }
    }

    const goForward = () => {
        if (props.params.current !== props.params.numWines - 1) {
            props.params.nextWine();
        }
    }

    const finishTasting = () => {
        props.params.finally();
    }

    return (
        <Element params={props.params}>
            <Wrapper styles={props.params.styles}>

                {props.params.numWines === 1 ? (
                    <HeaderTag >
                        {ControlTrad.getTrad("interactiveTasting")}
                    </HeaderTag>
                ) : (
                        <ButtonNavBack onClick={goBack} enabled={props.params.current > 0}>
                            <ImgBack src={flecha} />
                            {ControlTrad.getTrad("previous")}
                        </ButtonNavBack>
                )}

                {props.params.current !== props.params.numWines - 1 ? (
                    <ButtonNavNext onClick={goForward} highligthed={props.params.highligthed}>
                        <ImgNext src={flecha} />
                        {ControlTrad.getTrad("next")}
                    </ButtonNavNext>
                ) : (
                        <ButtonNavNext onClick={finishTasting} highligthed={props.params.highligthed}>
                        <ImgNext src={flecha} />
                        {ControlTrad.getTrad("endTastingM")}
                    </ButtonNavNext>
                )}

            </Wrapper>
        </Element>
    )

}
export default NavegationWine;