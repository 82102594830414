import React from "react";
import { nanoid } from "nanoid";
import styled from "styled-components";

import Category from "./Category";
import AppStateContext from "appState/AppStateContext";
import Element from "../Element";
import Tasting from "assets/tasting/Tasting";

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  & > *:first-child {
    margin: 0 1vh 0 0 !important;
  }
  & > *:last-child {
    margin: 0 0 0 1vh !important;
  }
`;

const CategoriesWrapper = (props) => {
  const appState = React.useContext(AppStateContext);
  let categories, currentData, videos, highligthedCatId;


  if (Tasting.getType() === "live") {
    categories = appState.state.get("currentAssessmentCategories");
    currentData = appState.state.get("currentUserAssessments");
  } else {
    categories = props.params.categories;
    currentData = props.params.currentData;
    videos = props.params.wineVideos;
  }

  // Find next highligthed step
  if (props?.params?.highligthedComp === "CategoriesWrapper") {
    categories?.forEach((cat) => {
      let catAssessment = currentData?.[cat?.id];

      if (!highligthedCatId) {
        let catAssessmentEntries = Object.entries(catAssessment || {});
        if (catAssessmentEntries?.videoPlayed) {
          delete catAssessmentEntries.videoPlayed;
        }

        // Category  hasn't been rated
        if (catAssessmentEntries.length === 0) {
          highligthedCatId = cat?.id;

          // Video hasn't been played
        } else if (Tasting.getType() === "podcast") {
          let videoPlayed = currentData?.[cat?.id]?.videoPlayed;
          let isVideoAvaliable = videos?.[cat?.id]?.id !== null && videos?.[cat?.id]?.id !== undefined;

          if (!videoPlayed && isVideoAvaliable) highligthedCatId = cat?.id;
        }
      }
    });

    if ((highligthedCatId === null || highligthedCatId === undefined) && props?.params?.onFinishAllAssesments) {
      props.params.onFinishAllAssesments();
    }
  }

  const categoryClick = (category) => {
    if (Tasting.getType() === "live") {
      appState.assessments.set(category.id);
    } else {
      props.params.setCategory(category)
    }
    props.params.onClick();
  };

  const loadData = (responsive) => {
    let views = [];
    if (categories?.length > 0) {
      categories?.forEach((category) => {
        let videoId = "";
        if (Tasting.getType() !== 'live') {
          videoId = props.params?.wineVideos?.[category.id]?.id;
        }

        views.push(
          Tasting.getType() === "live" ?
            <Category
              data={category}
              key={nanoid()}
              responsive={responsive}
              highligthed={category?.id === highligthedCatId}
              onClick={() => categoryClick(category)}
            />
            :
            <Category
              data={category}
              key={nanoid()}
              responsive={!responsive}
              onClick={() => categoryClick(category)}
              video={videoId}
              setVideo={props.params.setVideo}
              setDataWine={props.params.setDataWine}
              currentData={currentData}
              highligthed={category?.id === highligthedCatId}
              currentProductRef={props.params.currentProductRef}
            />
        );
      });
    } else {
      views.push(
        <div style={{ color: "white" }} key={nanoid()}>
          No categories found
        </div>
      );
    }
    return views;
  };

  return (
    <Element params={props.params}>
      <Wrapper>{loadData(props.params.responsive)}</Wrapper>
    </Element>
  );
};

export default CategoriesWrapper;
