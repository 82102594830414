import React, { useEffect, useState } from "react";
import styled from "styled-components";

import Element from "../Element";
import Question from "./Question"
import Fetch from "Fetch/Fetch";
import AppStateContext from "appState/AppStateContext";
import Tasting from "assets/tasting/Tasting";
import LoadingComponent from "../LoadingComponent/LoadingComponent";

const Wrapper = styled.div`
    background: #fffffff0;
    border-radius: 1vh;
    display:flex;
    flex-direction:column;
    height: 100%;
    overflow:auto;
    @media (max-width: 426px) {
        border-radius:0vh;
    }
`;

const Questions = styled.div`
    margin: auto;
    width: 100%;
`;

const QuestionItem = styled.div`
    padding: 0.5vh;
    display:flex;
    align-items:center;
    justify-content:space-around;
    margin: 0.5vh 0;
`;

const FinalSurvey = (props) => {
    const [question, setQuestion] = useState({});
    const [answers, setAnswers] = useState({});

    const appState = React.useContext(AppStateContext);
    let surveyFinalActive;
    if(Tasting.getType() === 'live'){
        surveyFinalActive = appState.state.get("surveyFinalActive");
    }else{
        surveyFinalActive = props.params.questions;
    }

    const loadQuesions = () => {
        let row = [];
        if (Object.keys(question).length !== 0) {
            question.forEach((question) => {
                row.push(
                    <QuestionItem>
                        <Question
                            concept={question.concept}
                            active={answers?.[question.reference]}
                            reference={question.reference}
                            titleStyles={"font-weight:600;"}
                            onClickEvent={onClickEvent}
                            size={7}
                            separation={60}
                        />
                    </QuestionItem>
                )
            })
        }
        return row;
    }

    const onClickEvent = (answer, reference) => {
        let satisfaction = document.getElementById(reference);
        satisfaction.setAttribute("satisfactionVal", answer);

        setAnswers((prev) => ({
            ...prev,
            [reference]: answer
        }))

    }

    useEffect(() => {
        if (surveyFinalActive) {
            if (Object.keys(question).length === 0) {
                Fetch.get("satisfactionConcepts").then(
                    (result) => {
                        if(Object.keys(result).length === 0){
                            props.params.onNoQuestions();
                        }else{
                            setQuestion(result);

                        }
                        //console.log(result, "<-- aqui");
                    }
                );
                loadQuesions();
            }
        } else {
            props.params.onNoQuestions();
        }
    }, [surveyFinalActive])

    return (
        <Element params={props.params}>
            <Wrapper>
                {Object.keys(question).length === 0 ? (
                    <LoadingComponent params={{ position: "1/1/100/100", active: true, ready: () => { } }} />
                ) : (
                        <Questions>
                            {loadQuesions()}
                        </Questions>
                )}
            </Wrapper>
        </Element>
    );

}
export default FinalSurvey;