import { useReducer } from "react";
import AppStateReducer from "./AppStateReducer";
import StateFinder from "./Finders/StateFinder";
import AssessmentsFinder from "./Finders/AssessmentsFinder";

const useAppState = () => {
  const [state, dispatch] = useReducer(AppStateReducer, {});

  return {
    state: {
      get: (query) => {
        try {
          return StateFinder(state, query);
        } catch (e) {
          //console.log("ERROR", e);
          return null;
        }
      },
      set: (param) => {
        return dispatch(['state', param]);
      },
    },
    assessments:{
      get: (query) => {
        try {
          return AssessmentsFinder(state, query);
        } catch (e) {
          //console.log("ERROR", e);
          return {};
        }
      },
      set: (param) => {
        return dispatch(['assessments', param]);
      },
    },  
    
  };
};

export default useAppState;

//let a = [()=>{console.log('yay!')}, 2, 3]
//const ThemeContext = React.createContext(a);
//export default ThemeContext;

