import React from "react";
import styled from "styled-components";

import User from "assets/user/User";
import AppStateContext from "appState/AppStateContext";
import Colours from "assets/custom/Custom";
import Valorate from "assets/images/valorar.svg";
import Play from "assets/images/icons/ic-play-circle-filled.svg";
import PlayWhite from "assets/images/play-white.svg";
import ControlTrad from "assets/trad/ControlTrad";
import Tasting from "assets/tasting/Tasting";
import SoundUtils from "assets/utils/SoundUtils";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 1vh;
  width: 100%;
  height: 100%;
  text-align: center;
  margin: 0 1vh;
  cursor: pointer;
`;

const Title = styled.div`
  ${props => `  
    background: white;
    border-radius: 1vh 1vh 0 0;
    font-weight: bold;
    padding: 1vh 0;
    font-size: 3vh;

    @media (max-width: 426px) {
      font-size: 1.5rem;
      padding: 0.5rem 0;
      border-radius: 0.5rem 0.5rem 0 0;

      ${props.edited ? "border-bottom: 1px solid white;" : ""}
      ${props.edited ? "background: #b0b0b0;" : ""}
      ${props.edited ? "color: white;" : ""}
    }
  `}
`;

const Body = styled.div`  
  ${props => `  
    display:flex;
    flex-direction:column;
    align-items:center;
    flex: 1;
    background-image: linear-gradient(rgba(176, 176, 176, ${props.dark ? 0.6 : 0}), rgba(176, 176, 176, ${props.dark ? 0.6 : 0})), url("${props.src}");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    overflow-y: auto;
    max-height: 51vh;

    &:hover {
      box-shadow: 0 0px 20px 5px rgb(255 255 255 / 50%);
      filter: brightness(130%);
    }
    @media (max-width: 426px) {
      flex: 0;
      display:none;
    }
    @media (min-width: 426px)  and (pointer: coarse) and (orientation: portrait) {
      flex: 0;
      display:none;
    }
  `}
`;

const Button = styled.div`
  ${props => `
    background-color: ${props.edit ? "#b0b0b0" : Colours.getColour("mainColor")};
    border-radius: 0 0 1vh 1vh;
    padding: 1vh 0;
    font-size: 2.5vh;
    color: white;
    transition : 0.2s;

    ${props.highligthed ? `
      animation-name: example;
      animation-duration: 2s;
      animation-iteration-count: infinite;
      animation-direction: alternate;
      
      @keyframes example {
        0%  {
          box-shadow: 0px 0px 30px 15px white;
          filter: brightness(110%);
        }
        100% {
          box-shadow: 0px 0px 20px 5px gray;
          filter: brightness(95%);
        }
      }
    `: ``}

    &:hover{
      background-color: ${props.edit ? "#848484" : Colours.getColour("secondaryColor")};
    }
    
    @media (max-width: 426px) {
      font-size: 1rem;
      padding: 0.5rem 0;
    }
  `}
`;

const AttributeWrapper = styled.div`
  background-color: rgba(0,0,0,0.8);
  width: 85%;
  padding: 1vh;
  border-radius: 1vh;
  color: white;
  font-size: 2vh;
  border: 0.2vh solid white;
  margin-top: 1.2vh;
`

const AttributeTitle = styled.span`
  font-weight:bold;
`

const AttrbitueText = styled.span`

`

const WrapperButtons = styled.div`
  display:flex;
`

const Icon = styled.img`
  height: 3vh;
  width: 3vh;
  margin: auto 0;
  padding: 0 0.5vw;
`

const ResponsiveIcon = styled.img`
  ${props => `
    width: 1.3rem;
    filter: hue-rotate(${props.edit ? Colours.toDeg("mainColor") : Colours.toDeg("editButton")}deg);

    ${props.highligthed ? `
      animation-name: example;
      animation-duration: 2s;
      animation-iteration-count: infinite;
      animation-direction: alternate;

      @keyframes example {
        0%   {
          background-color:${Colours.getColour("mainColor")};
          box-shadow: 0px 0px 30px 15px white;
        }
        100% {
          background-color:${Colours.getColour("secondaryColor")}; 
          box-shadow: 0px 0px 20px 5px gray;
        }
      }
    `: ``}
  `}
`

const IconWrapper = styled.div`
  display:flex;
  justify-content: center;
`

const Category = (props) => {
  const appState = React.useContext(AppStateContext);
  let currentUserAssessments, currentAssessmentSheet, category;

  if (Tasting.getType() === "live") {
    currentUserAssessments = appState.state.get("currentUserAssessments");
    currentAssessmentSheet = appState.state.get("currentAssessmentCategories");
    category = currentUserAssessments?.[props.data.id];
  } else {
    currentUserAssessments = props?.currentData;
    category = currentUserAssessments?.[props.data.id];
    currentAssessmentSheet = props.data.components;
  }


  let options = {
    "OPTION_LOW": { name: { CA: "Baixa", ES: "Baja", EN: "Low" } },
    "OPTION_MEDIUM": {
      name: { CA: "Mitja", ES: "Media", EN: "Medium" },
    },
    "OPTION_HIGH": { name: { CA: "Alta", ES: "Alta", EN: "High" } },
  };

  const getViews = (category, oldViews, component, tree) => {
    let views = oldViews;

    Object.keys(category).forEach(categoryKey => {
      if (category[categoryKey] === null || category[categoryKey] === undefined) {
        return;
      } else if (category[categoryKey] instanceof Object) {
        views = getViews(category[categoryKey], views, component, tree);
      } else if (tree) {
        if("options" in component) {
          component.options.forEach(option => {
            if (option.id === categoryKey && options[category[categoryKey]]) {
              views[ControlTrad.getUserTrad(option?.name)] = getAtributes(ControlTrad.getUserTrad(option?.name), ControlTrad.getUserTrad(options[category[categoryKey]]?.name));
            } else if ("options" in option) {
              views = getViews(category, views, option, tree);
            }
          });
        }
      } else {
        if (categoryKey === component?.id) {
          if("options" in component) {
            let userOptionValue = ControlTrad.getUserTrad(component?.options?.find(x => x.id === category?.[categoryKey])?.name);
            views[ControlTrad.getUserTrad(component?.name)] = getAtributes(ControlTrad.getUserTrad(component?.name), userOptionValue);
          } else {
            views[ControlTrad.getUserTrad(component?.name)] = getAtributes(ControlTrad.getUserTrad(component?.name), category?.[categoryKey]);
          }

        }
      }

    })

    return views;
  }

  const getAtributes = (title, option) => {
    return (
      <AttributeWrapper>
        <AttributeTitle>{title}: </AttributeTitle>
        <AttrbitueText>{option}</AttrbitueText>
      </AttributeWrapper>
    );
  }

  const isCategoryEmpty = (category) => {
    if (!category) return true;
    else if (Object.entries(category).length === 0) return true;
    return false;
  }

  const loadAttributes = () => {
    let views = {};
    let categoryObj = {};
    if (Tasting.getType() === 'live') {
      //Se filtra en un objeto en vez de una array para evitar bucles
      currentAssessmentSheet.forEach(assessment => {
        if (assessment.id === props.data.id)
          categoryObj = assessment;
      })

      if (category) {
        categoryObj.components?.forEach(component => {
          console.log(component);
          views = getViews(category, views, component, component?.type === "TREE")
        })
      }

      let newViews = [];
      Object.keys(views).forEach(key => {
        newViews.push(views[key])
      })

      return newViews;
    } else {
      let newViews = [];
      if (category) {
        props.data.components?.forEach(component => {
          views = getViews(category, views, component, component?.type === "TREE")
        })
      }
      Object.keys(views).forEach(key => {
        newViews.push(views[key])
      });
      return newViews;
    }
  }

  const loadVideo = () => {
    SoundUtils.play("validate");
    props.setVideo(props.video);

    props.setDataWine((prev) => ({
      ...prev,
      [props.currentProductRef]: {
        ...prev?.[props.currentProductRef],
        [props?.data?.id]: {
          ...prev?.[props.currentProductRef]?.[props?.data?.id],
          videoPlayed: true
        }
      }
    }));
  }

  const videoBeenPlayed = () => {
    return props?.currentData?.[props?.data?.id]?.videoPlayed;
  }

  let isEmpty = isCategoryEmpty(category);

  return (
    <Wrapper >
      <Title edited={!isEmpty} onClick={() => { props.onClick(props.data.id) }}>{props.responsive ? ControlTrad.getUserTrad(props.data.name).split(" ")[0] : ControlTrad.getUserTrad(props.data.name)}</Title>
      <Body src={props.data.image} dark={!isEmpty} onClick={() => { props.onClick(props.data.id) }}>
        {loadAttributes()}
      </Body>

      {Tasting.getType() === "live" ? (

        <Button
          onClick={() => { props.onClick(props.data.id) }}
          edit={!isEmpty}
          highligthed={props.highligthed && isEmpty}>

          {!isEmpty ? (
            props.responsive ? (
              <ResponsiveIcon src={Valorate} alt={"Valorate"} edit={true} />
            ) : (
              <IconWrapper>
                <Icon src={Valorate} />
                {ControlTrad.getTrad("modify")}
              </IconWrapper>
            )
          ) : (
            props.responsive ? (
                <ResponsiveIcon src={Valorate} alt={"Valorate"} edit={false} />
            ) : (
              <IconWrapper>
                <Icon src={Valorate} />
                {ControlTrad.getTrad("select")}
              </IconWrapper>
            )
          )}
        </Button>

      ) : (

        <WrapperButtons>
          <Button
              onClick={() => { props.onClick(props.data.id) }}
              edit={!isEmpty}
              highligthed={props.highligthed && isEmpty}
              style={!isEmpty && props.video ? (
                { flex: '1', borderBottomRightRadius: '0vh' }
              ) : (
                { flex: '1' }
              )}>

              {!isEmpty ? (
                props.responsive ? (
                  <ResponsiveIcon src={Valorate} alt={"Valorate"} edit={true} />
                ) : (
                  <IconWrapper>
                    <Icon src={Valorate} />
                    {ControlTrad.getTrad("modify")}
                  </IconWrapper>
                )
              ) : (
                props.responsive ? (
                    <ResponsiveIcon src={Valorate} alt={"Valorate"} edit={false} />
                ) : (
                  <IconWrapper>
                    <Icon src={Valorate} />
                    {ControlTrad.getTrad("select")}
                  </IconWrapper>
                )
              )
            }
            </Button>

            {!isEmpty && props.video ? (
              <Button
                onClick={() => { loadVideo() }}
                style={{ flex: '1', borderBottomLeftRadius: '0vh', borderLeft: videoBeenPlayed() ? "1px solid white" : "none" }}
                edit={videoBeenPlayed()}
                highligthed={props.highligthed}>

                {props.responsive ? (
                  <img src={Play} alt={"Valorate"} style={{ width: '1.3rem', filter: `invert(1)` }} />
                ) : (
                  <IconWrapper>
                    <Icon src={PlayWhite} />
                    {ControlTrad.getTrad("validate")}
                  </IconWrapper>
                )}

              </Button>
            ) : (
              <></>
            )}
          </WrapperButtons>

        )
      }
    </Wrapper>
  );
};

export default Category;
