import React from "react";
import styled from "styled-components";

import Element from "../Element";
import QuizRank from "./QuizRank"
import AppStateContext from "appState/AppStateContext";

const Podium = styled.div`
    display:flex;
    flex-direction: row;
    height: 100%;
    padding : 2vh;
    border-radius : 3vh;
    background-color : #af1d39;
    border: 0.5vh solid white;
    
`;
const Rank = styled.div`
    flex:1;
    grid-gap: 0em 1vh;
`;




const QuizPodium = (props) => {
    const appState = React.useContext(AppStateContext);
    let assistants = appState.state?.get("assistants");


    const loadPodium = () => {
        let rank = orderPlayers(assistants)
        return (
            <>
                {rank[1] !== undefined ?
                    <Rank>
                        <QuizRank
                            position={2}
                            user={rank[1].name}
                        />
                    </Rank>
                    : <></>}
                {rank[0] !== undefined ?
                    <Rank>
                        <QuizRank
                            position={1}
                            user={rank[0].name}
                        />
                    </Rank>
                    : <></>}
                {rank[2] !== undefined ?
                    <Rank>
                        <QuizRank
                            position={3}
                            user={rank[2].name}
                        />
                    </Rank>
                    : <></>}

            </>
        );

    }

    const orderPlayers = (obj) => {
        let adaptArray = [];
        for (let key in obj) {
            adaptArray.push(obj[key])
        }
        adaptArray.sort(function (a, b) {

            if (a.quizPoints < b.quizPoints) {
                return 1;
            }
            if (a.quizPoints > b.quizPoints) {
                return -1;
            }
            return 0;
        });
        return adaptArray;
    }

    return (
        <Element params={props.params}>
            <Podium>
                {loadPodium()}
            </Podium>
        </Element>
    );

}
export default QuizPodium;