import Element from "./Element";
import Button from "components/Shared/Button"


const ChangeViewButton1 = (props) => {
  return (
    <Element params={props.params}>
        <Button onClick={props.params.onClick} value="Anterior pantalla"/>
    </Element>
  );
};

export default ChangeViewButton1;
