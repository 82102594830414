import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Element from "../Element";
import Websockets from "Websockets/Websockets";
import AppStateContext from "appState/AppStateContext";
import ControlTrad from "assets/trad/ControlTrad";
import Tasting from "assets/tasting/Tasting";
import FetchUtils from "Fetch/FetchUtils";
import Colours from "assets/custom/Custom";

const Wrapper = styled.div`
  font-size: 2.5vh;
  background: white;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  border-radius: 1vh;
  justify-content: flex-start;
`;

const Title = styled.div`
  font-weight: bold;
  padding-top: 1vh;
`;

const OptionsWrapper = styled.div`
  display: flex;
  flex-direction: row; 
  width: 95%;
  margin: auto;
`;

const Option = styled.div`
  ${(props) => `
    flex: 1;
    font-size: 1.8vh;
    margin: auto 0.5vh;
    padding: 1vh 0.25vh;
    background: ${props?.params?.selected || !props?.enabled ? Colours.getColour("mainColor") : "transparent"};
    color: ${props?.params?.selected || !props?.enabled ? "white" : "black" };
    border-radius: 0.7vh;
    white-space: nowrap;
    overflow: hidden;
    display: block;
    text-overflow: ellipsis;
    cursor: pointer;

    &:hover {
      color: white;
      background: ${Colours.getColour("secondaryColor")};
    }

    animation-fill-mode: forwards;
    animation-duration: 0.5s;

    @keyframes selectAnim {
      to {
        background: ${Colours.getColour("mainColor")};
        color: white;
      }
    }

    @keyframes unselectAnim {
      to {
        flex: 0.75;
        opacity: 0.75;
      }
    }

    ${props?.selected ? `
      animation-name: selectAnim;
    ` : props?.enabled ? `
      animation-name: unselectAnim;
    ` : `
      animation-name: none;
    `}
  `}
`;

const PriceRateBox = (props) => {
  const appState = React.useContext(AppStateContext);
  const [currentProductRef, setCurrentProductRef] = useState(null);
  const [priceBoxValue, setPriceBoxValue] = useState(null);

  const options = [
    //{ id: "Muy mala", text: ControlTrad.getTrad("rateForPriceVeryBad") },
    { id: "Mala", text: ControlTrad.getTrad("rateForPriceBad") },
    { id: "Regular", text: ControlTrad.getTrad("rateForPriceRegular") },
    { id: "Buena", text: ControlTrad.getTrad("rateForPriceGood") },
    //{ id: "Muy buena", text: ControlTrad.getTrad("rateForPriceVeryGood") },
  ];

  useEffect(() => {
    let newCurrentProductRef, newPriceBoxValue;

    if (Tasting.getType() === 'live') {
      newCurrentProductRef = appState?.state.get(`currentWine`)?.reference;
      newPriceBoxValue = appState?.state.get('currentUserAssessmentsParameters')?.ratePrice;
    } else {
      newCurrentProductRef = props.params.currentProductRef;
      newPriceBoxValue = props.params.ratePrice;
    }

    if (newCurrentProductRef !== currentProductRef) setCurrentProductRef(newCurrentProductRef);
    if (newPriceBoxValue !== priceBoxValue) setPriceBoxValue(newPriceBoxValue);
  }, [appState, props]);

  const sendAnswer = (value) => {
    if(Tasting.getType() === 'live'){
      Websockets.send("SLAVE_PRODUCT_PARAMETER", [
        currentProductRef,
        "ratePrice",
        value,
      ], false);
    } else {
      props.params.setDataWine((prev) => ({
        ...prev,
        [props.params.currentProductRef]: {
          ...prev[props.params.currentProductRef],
          ratePrice: value
          }
      }));
      FetchUtils.sendProductParameter(props.params.currentProductRef, "ratePrice", value);
    }
  };

  const onChange = (val) => {
    if (val === priceBoxValue) {
      val = null;
    }
    setPriceBoxValue(val);
    sendAnswer(val);
  };

  const loadOptions = () => {
    let views = [];
    options.forEach((option) => {
      views.push(
        <Option
          onClick={() => { onChange(option.id) }}
          selected={option.id === priceBoxValue}
          enabled={priceBoxValue !== null && priceBoxValue !== undefined}>
          {option.text}
        </Option>
      );
    });
    return views
  }

  return (
    <Element params={props.params}>
      <Wrapper>
        <Title>{ControlTrad.getTrad("tittlerateWine")}</Title>
        <OptionsWrapper>
          {loadOptions()}
        </OptionsWrapper>

        {/*<Select value={priceBoxValue} onChange={onChange} options={options} />*/}
      </Wrapper>
    </Element>
  );
};

export default PriceRateBox;
