import React from "react";

import styled from "styled-components";
import Element from "../Element";

const Wrapper = styled.img`
${props => `
    ${props.params.styles}
`}`;

const Image = (props) => {

    return (
        <Element params={props.params}>
            <Wrapper params={props.params} src={props.params.image} />
        </Element>
    );

}
export default Image;