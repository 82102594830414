
export const getDevicePlatform = () => {
    const ua = navigator.userAgent;
    if (/(iPad|Tablet|(android(?!.*Mobile))|(windows(?!.*phone)(.*touch))|Nexus|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(ua)) {
        return "TABLET_WEB";
    }
    if (
        /IEMobile|Windows Phone|Lumia/i.test(ua) || (/iPhone|iP[oa]d/.test(ua) && !window.MSStream) || /Android/.test(ua) || /BlackBerry|PlayBook|BB10/.test(ua) || /Mobile Safari/.test(ua) || /webOS|Mobile|Tablet|Opera Mini|\bCrMo\/|Opera Mobi/i.test(ua)
    ) {
        return "MOBILE_WEB";
    }

    return "PC_WEB";
};

export const completeUrl = (url) => {
    if (url.startsWith("http://") || url.startsWith("https://")) {
        return url;
    } else {
        return "http://" + url;
    }
};