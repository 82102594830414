import User from "assets/user/User";
import Fetch from "Fetch/Fetch";
import LocalStorage from "LocalStorage/LocalStorage";
import Tasting from "assets/tasting/Tasting";

class FetchUtils {

    static createOrUpdateUser = () => {
        let INSTANCE_ID = Tasting.getPublicCode();
        let assistantId = User.getId();
        let assistantData = User.getUser();

        if (assistantId === null || assistantId === undefined) {
            Fetch.addAssitant(assistantData).then((res) => {
                console.log("Assistant created", res);
                User.setId(res.assistantId);
                LocalStorage.set(INSTANCE_ID, {
                    ...User.getUser(),
                    lastRestore: new Date(),
                });
            }).catch((error) => {
                console.error("Error creating assistant", error);
            });
        } else {
            Fetch.updateAssitant(assistantId, assistantData).then((res) => {
                console.log("Assistant updated", res);
                LocalStorage.set(INSTANCE_ID, {
                    ...User.getUser(),
                    lastRestore: new Date(),
                });
            });
        }
    }

    static restoreAssistant = (onRestore, onFail) => {
        let assistantId = User.getId();

        if (assistantId !== null && assistantId !== undefined) {
            Fetch.getAssitant(assistantId).then((res) => {
                console.log("Assistant received:", res);
                User.setUser(res);
                onRestore(res);
            }).catch((error) => {
                console.error("Error restoring assistant:", error);
                onFail(error);
            });
        }
    }

    static restoreOrCreateNewAssistant = (onRestore, onNewCreated, onFail) => {
        // Try to restore old user
        let INSTANCE_ID = Tasting.getPublicCode();
        let oldUser = LocalStorage.get(INSTANCE_ID);

        if (oldUser !== null && oldUser !== undefined) {
            User.setUser(oldUser);
            let lastRestoreDate = oldUser?.lastRestore;

            this.restoreAssistant((user) => {
                let oldAssessments = user?.assessments;
                let parsedAssessments = {};

                Object.entries(oldAssessments || {}).forEach(([prodRef, prodAssessment]) => {
                    let parsedProdAssessments = {};

                    // Parameters
                    Object.entries(prodAssessment?.parameters || {}).forEach(([paramId, paramValue]) => {
                        parsedProdAssessments[paramId] = paramValue;
                    });

                    // Categories
                    Object.entries(prodAssessment?.categories || {}).forEach(([categoryId, categoryData]) => {
                        let parsedProdCategory = {};
                        Object.entries(categoryData || {}).forEach(([catParamId, catParamValue]) => {
                            parsedProdCategory[catParamId] = catParamValue;
                        });
                        parsedProdAssessments[categoryId] = parsedProdCategory;
                    });

                    parsedAssessments[prodRef] = parsedProdAssessments;
                });

                if (typeof lastRestoreDate === "string") {
                    lastRestoreDate = new Date(lastRestoreDate);
                }

                onRestore(oldUser, lastRestoreDate, parsedAssessments);
                LocalStorage.set(INSTANCE_ID, {
                    ...User.getUser(),
                    lastRestore: new Date(),
                });
            }, onFail);
        } else {
            // Create new user if not exists old user
            if (Tasting.isPodLite() && User.getName() === null) {
                User.setName("Lite assistant");
                console.log("Lite user created");
            } else {
                console.log("Podcast user created");
            }
            FetchUtils.createOrUpdateUser();
            onNewCreated(User.getUser());
        }
    }

    static sendProductAssessmentParameter = (productRef, categoryId, values) => {
        let assistantId = User.getId();
        let data = {
            "productRef": productRef,
            "assessment": {
                "categories": {
                    [categoryId]: values,
                }
            }
        }
        Fetch.addAssitantAssessment(assistantId, data).then((res) => {
            console.log("Assessment added succefully:", res);
        }).catch((error) => {
            console.error("Error adding assessment", error);
        });
    }

    static sendProductParameter = (productRef, parameterId, value) => {
        let assistantId = User.getId();
        let data = {
            productRef: productRef,
            assessment: {
                parameters: {
                    [parameterId]: value,
                }
            }
        }
        Fetch.addAssitantAssessment(assistantId, data).then((res) => {
            console.log("Assessment added succefully:", res);
        }).catch((error) => {
            console.error("Error adding assessment", error);
        });
    }

    static saveUserAsQuizWinner = (productRef, prize) => {
        let assistantId = User.getId();

        let data = {
            productRef: productRef,
            prize: prize,
        }

        Fetch.addQuizWinner(assistantId, data).then((res) => {
            console.log("Quiz winner saved succefully:", res);
        }).catch((error) => {
            console.error("Error adding quiz winner", error);
        });
    }

    static notifyStoreLinkClicked = (productRef, url, onSuccess) => {
        let assistantId = User.getId();
        let data = {
            type: "STORE_LINK_CLICKED",
            metadata: {
                productRefPath: productRef,
                url: url,
            }
        }
        Fetch.addAssistantAction(assistantId, data).then((res) => {
            console.log("Store link clicked action added succefully:", res);
            if (onSuccess) onSuccess(res);
        }).catch((error) => {
            console.error("Error adding store link clicked action:", error);
        });
    }
}
export default FetchUtils;