import React, { useState } from "react";

import Element from "../Element";

import styled from "styled-components";
import Websockets from "Websockets/Websockets";
import AppStateContext from "appState/AppStateContext";
import sendImg from "assets/images/chat_send_arrow.svg";
import Colours from "assets/custom/Custom";
import ControlTrad from "assets/trad/ControlTrad";
import ModalMessage from "components/Sandbox/ModalMessage";

const Content = styled.div`
  height: 100%;
  width: 100%;
`;

const Header = styled.div`
  display: flex;
  color: white;
  justify-content: space-between;
  font-size: 2.5vh;
  height:22%;
`;


const Wrapper = styled.div`
  font-size: 2.5vh;
  background: white;
  height: 78%;
  width: 100%;
  display: flex;
  flex-direction:column;
  border-radius: 1vh;
  overflow: hidden;
  background-color: rgb(230, 230, 230);
`;


const AllMessagesDiv = styled.div`
${props =>`
  flex-grow: 1;
  overflow-y: auto;
  border-radius: 0.7vh;
  max-height:${props.maxSize ? props.maxSize : "12"}vh;
`}
`

const MessageDiv = styled.div`
display: flex;

border-radius: 0px 5px 5px;
margin: 0px;
flex-flow:row;
padding:0;
`
const Message = styled.p`
color: black;
font-size: 2vh;
margin: 0;
text-align: left;
padding: 0.5vh 0px 0.5vh 2vh;

`

const InputDiv = styled.div`
display:flex;
padding: 0.5vh 2vh;

`
const SendInput = styled.input`
flex:2
`
const SendImg = styled.img`
flex:1
color: white;
border-radius: 50%;
margin: auto 0px;
font-size: 2rem;
padding: 0px 0.5vh;
opacity: 0.6;
`

const UsersConnected = styled.div`
  cursor:pointer;
`

const AssistantRow = styled.span`
  margin-bottom:0.5vh;
`

const BodyWrapper = styled.div`
  display:flex;
  flex-direction:column;
  justify-content:flex-start;
  align-items:center;
  height:25vh;
  padding: 1vh;
`

const AssistantsList = styled.div`
  display:flex;
  flex-direction:column;
  max-height:20vh;
  scroll-behavior: auto;
`

const AssistantTitle = styled.span`
  font-size:2.5vh;
  font-weight:600;
  margin-bottom:1vh;
`

const Chat = (props) => {
  const MessageUser = styled.span`color: ${Colours.getColour("mainColor")};`

  const appState = React.useContext(AppStateContext);
  let allChat = appState.state.get("chat");
  let sizeAssistants = appState.state.get("sizeAssistants");
  const assistants = appState.state.get("assistants");
  const [input, setInput] = useState("");
  const [popUp1, setPopUp1] = useState(false);


  const sendChat = () => {
    if (input.length > 0 && input.trim().length > 0) {
      Websockets.send("CHAT_MESSAGE", [
        input.trim()
      ],false);
      setInput("")
    }
  }

  const viewAllMessages = () => {
    let rows = []
    if (allChat) {
      Object.keys(allChat).forEach(element => {
        rows.push(
          <MessageDiv>
            <Message>
              <MessageUser>
                [{new Date(allChat[element].date).getHours() < 10 ? "0" + new Date(allChat[element].date).getHours() : new Date(allChat[element].date).getHours()}:{new Date(allChat[element].date).getMinutes() < 10 ? "0" + new Date(allChat[element].date).getMinutes() : new Date(allChat[element].date).getMinutes()}] 
               {" "}{allChat[element].instructor ? <b>{ControlTrad.getTrad("instructor")}</b> : allChat[element].user}
              </MessageUser>: {allChat[element].message}
            </Message>
          </MessageDiv>
        )
      });
    }
    return rows
  }

  const loadAssistantsList = () => {
    let views = [];
    for (let key in assistants) {
      if(assistants[key]){
        views.push(
          <AssistantRow>
            {assistants[key].name}
          </AssistantRow>
        );
      }
    }
    return views;
  }


  setTimeout(() => {
    viewAllMessages()
    var messageBody = document.getElementById("msg-wrapper");
    if (document.getElementById("msg-wrapper") !== null) {
      messageBody.scrollTop = messageBody.scrollHeight - messageBody.clientHeight;
    }
  }, 500)


  return (
    <Element params={props.params}>
      <Content>
        <Header>
          <div>{ControlTrad.getTrad("chat")}</div>
          <UsersConnected onClick={() => setPopUp1(true)}>
              {sizeAssistants} {sizeAssistants === 1 ? ControlTrad.getTrad("userConnected") : ControlTrad.getTrad("usersConnected")}
          </UsersConnected>
        </Header>
        <Wrapper>
          <AllMessagesDiv maxSize={props.params.chatMaxSize} id={"msg-wrapper"}>{viewAllMessages()}</AllMessagesDiv>
          <InputDiv>
            <SendInput value={input}
              autocomplete="off"
              autocorrect="off"
              autocapitalize="off"
              spellcheck="false"
              onKeyDown={(event) => {
                var keycode = event.keyCode ? event.keyCode : event.which;
                if (keycode === 13) {
                  sendChat()
                  event.target.blur();
                }
              }}
              onChange={(e) => setInput(e.currentTarget.value)} />
            <SendImg onClick={(e) => { sendChat() }} src={sendImg} />
          </InputDiv>
        </Wrapper>
      </Content>
      <ModalMessage
        isShown={popUp1}
        setIsShown={setPopUp1}
        title={""}
        styleHeader={"text-transform: uppercase; font-size:2.5vh"} >
          <BodyWrapper>
            <AssistantsList>
              <AssistantTitle> {ControlTrad.getTrad("usersConnected")} </AssistantTitle>
              {loadAssistantsList()}
            </AssistantsList>
          </BodyWrapper>
      </ModalMessage>
    </Element>
  );
};

export default Chat;
