import React from "react"
import styled from "styled-components";

import Element from "../Element";
import Custom from "assets/custom/Custom";

const Wrapper = styled.div`
    ${props=>`
        text-align:left;
        font-size: 2vh;
        background-color:white;
        width: 27vw;
        max-width: 27vw;
        height: 65vh;
        border-radius:1vh;
        display:flex;
        flex-direction:column;
        ${props?.styles}
    `}`

const WaitingLobbyWrapper = styled.div`
    display:flex;
    flex-direction:row;
    max-height:65vh;
    border-radius:2vh;
`

const ImgLogo = styled.img`
    width: 10vw;
    margin-bottom: 2vh;
`

const WaitingLobby = (props) => {

  return (
    <Element params={props.params}>
        <WaitingLobbyWrapper>
            <Wrapper styles={"border-top-right-radius:0px;border-bottom-right-radius:0px;background:url(" + Custom.Background.lobbyImage +"); background-position:center; background-size: auto 100%;"}>
            </Wrapper>
            <Wrapper styles={"border-bottom-right-radius:1vh; border-top-left-radius:0px;border-bottom-left-radius:0px;overflow-y:auto;height:auto;padding:2vw;width:21vw;"}>
                <ImgLogo src={Custom.logo}/>
                {Custom.Text.lobbyText}
            </Wrapper>
        </WaitingLobbyWrapper>
    </Element>
  );
};

export default WaitingLobby;