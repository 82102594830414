import React from "react";

import styled from "styled-components";
import Element from "../Element";
import Chart from "./Chart";
import AppStateContext from "appState/AppStateContext";
import User from "assets/user/User";
import ControlTrad from "assets/trad/ControlTrad";

const Wrapper = styled.div`
    display: flex;
    height:100%;
    width:100%;
    background-color: white;
    padding:4vh 2vh;
    border-radius:3vh;
    
`;

const Answers = styled.div`
    display: flex;
    text-align : left;
    font-size: 3vh;
    height:100%;
    width: 100%;
    flex-direction: row;
`;
const AnswersVertical = styled.div`
    display: flex;
    text-align : left;
    font-size: 3vh;
    height:100%;
    width: 100%;
    flex-direction: column;
    color: white;
    justify-content: space-between;
    
`;
const Answer = styled.div`
    flex : 1;
`;


const Charts = (props) => {

    const appState = React.useContext(AppStateContext);
    let reference = appState.state?.get("quizActualQuestionReference");
    let assistants = appState.state?.get("assistants");
    let question = appState.state?.get("quizActualQuestion");
    let correct = question?.correct[0];

    const loadCharts = () => {
        let row = [];
        if (question?.options !== null && question?.options !== undefined) {
            let allUsers = Object.keys(assistants).length;
            Object.keys(question?.options).forEach(element => {
                let count = countAnswers(element);
                let percent = (count / allUsers) * 100;
                row.push(
                    <Answer>
                        <Chart
                            correct={correct === element}
                            answer={ControlTrad.getUserTrad(question?.options[element])}
                            percent={percent+"%"}
                            answers={count}
                        />
                    </Answer>
                );
            });
        }
        return row;
    }

    const countAnswers = (opt)=> {
        let sum = 0;
        Object.keys(assistants).forEach(element => {
            if(assistants?.[element]?.quizAnswers?.[reference] === opt){
                sum++;
            }
        });
        return sum;
    }


    let component = (props, orientation) => (
        <Element params={props.params}>
            <Wrapper>
                {orientation ?
                    <Answers>
                        {loadCharts(orientation)}
                    </Answers>
                    :
                    <AnswersVertical>
                        {loadCharts(orientation)}
                    </AnswersVertical>
                }

            </Wrapper>
        </Element>
    );
    let display = component(props, true)
    return (display);

}
export default Charts;