import React from "react";

import styled from "styled-components";
import Element from "../Element";
import Chart from "./Chart";
import AppStateContext from "appState/AppStateContext";

const Wrapper = styled.div`
    display: flex;
    height:100%;
    width:100%;
    padding : 2vh;
    border-radius : 1vh;
    background-color : rgba(255, 255, 255, 0.8);
    
`;

const Answers = styled.div`
    display: flex;
    text-align : left;
    font-size: 3vh;
    height:100%;
    width: 100%;
    
`;
const Answer = styled.div`
    flex : 1;
`;
const ChartsPanel = (props) => {
    const appState = React.useContext(AppStateContext);
    let reference = appState.state?.get("quizActualQuestionReference");
    let assistants = appState.state?.get("assistants");
    let question = appState.state?.get("quizActualQuestion");
    let correct = question?.correct[0];

    const loadCharts = () => {
        let row = [];
        if (question?.options !== null && question?.options !== undefined) {
            let allUsers = Object.keys(assistants).length;
            Object.keys(question?.options).forEach(element => {
                let count = countAnswers(element);
                console.log(allUsers);
                let percent = (count / allUsers)*100;
                row.push(
                    <Answer>
                        <Chart
                            correct={correct === element}
                            answer={question?.options[element]["ES"]}
                            percent={percent+"%"}
                            answers={count}
                        />
                    </Answer>
                );
            });
        }
        return row;
    }

    const countAnswers = (opt)=> {
        let sum = 0;
        Object.keys(assistants).forEach(element => {
            if(assistants?.[element]?.quizAnswers?.[reference] === opt){
                sum++;
            }
        });
        return sum;
    }
    return (
        <Element params={props.params}>
            <Wrapper>
                <Answers>
                    {loadCharts()}
                </Answers>
            </Wrapper>
        </Element>
    );

}
export default ChartsPanel;