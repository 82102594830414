import React from "react";
import styled from "styled-components";

import Element from "../Element";
import QuizRank from "./QuizRank"
import AppStateContext from "appState/AppStateContext";

const Ranking = styled.div`
    display:flex;
    flex-direction:column;
    padding : 2vh;
    border-radius : 3vh;
    background-color : white;
    overflow:auto;
    
`;
const Rank = styled.div`

    flex:1;
    border-bottom: #a2a2a2 solid;

`;


const QuizRanking = (props) => {
    const appState = React.useContext(AppStateContext);
    let assistants = appState.state?.get("assistants");

    const loadRanking = () => {
        let rank = orderPlayers(assistants);
        let row = [];
        let index = 1;
        rank.forEach(element => {
            if (index <= 5) {
                row.push(
                    <Rank>
                        <QuizRank
                            position={index}
                            user={element.name}
                            userID={element.reference.split("/")[3]}
                            points={element.quizPoints !== undefined ? element.quizPoints + " pts." : "0 pts."}
                            date={element?.date}
                        />
                    </Rank>
                );
            }
            index++;

        });
        return row;

    }

    const orderPlayers = (obj) => {
        let adaptArray = [];
        for (let key in obj) {
            adaptArray.push(obj[key])
        }
        adaptArray.sort(function (a, b) {
            if (a.quizPoints === undefined) { a.quizPoints = 0; }
            if (a.quizPoints < b.quizPoints) {
                return 1;
            }
            if (a.quizPoints > b.quizPoints) {
                return -1;
            }
            return 0;
        });
        return adaptArray;
    }


    return (
        <Element params={props.params}>
            <Ranking>
                {loadRanking()}
            </Ranking>
        </Element>
    );

}
export default QuizRanking;