import React from "react"
import styled from "styled-components";

import Element from "../Element";
import AppStateContext from "appState/AppStateContext";

const Wrapper = styled.div`
${props=>`
  font-size: 3vh;
  color:white;
  font-weight:bold;
  @media (max-width: 426px) {
    font-size: 3.5vh;
    text-transform: uppercase;
    font-weight:600;
  }
  ${props.params.styles}
`}`

const TastingType = (props) => {
  const appState = React.useContext(AppStateContext);
  let tasting = appState.state.get("tasting");


  return (
    <Element params={props.params}>
      <Wrapper params={props.params}>{tasting?.name}</Wrapper>
    </Element>
  );
};

export default TastingType;
