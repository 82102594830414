import React from "react";
import styled, { keyframes } from "styled-components";
import AppStateContext from "appState/AppStateContext";
import Element from "../Element";






const AQuizEffect = (props) => {
    
    const appState = React.useContext(AppStateContext);
    let timer = appState?.state.get("quizTimer");

    const parpadea = keyframes`
    0% {
        box-shadow: inset 0 0 20vh yellow;
    }
    50% {
        box-shadow: inset 0 0 0vh yellow;
    }
    100% {
        box-shadow: inset 0 0 20vh yellow;

    }
`;
    const Wrapper = styled.div`
    display:flex;
    width:100%;
    height:100%;
    animation: 2s lineal infinite;
    animation-name: ${timer > 0 && timer <=5  ? parpadea  :"none"};

    `

    return (
        <Element params={props.params}>
            <Wrapper/>
        </Element>
    );

}
export default AQuizEffect;