import React from "react";
import Button from "components/Shared/Button"
import styled from "styled-components";
import Websockets from "Websockets/Websockets";

const Wrapper = styled.div`
    flex : 1;
`


const ButtonNext = (props) => {

    const loadQuiz = (action) =>{
        Websockets.send("NEXT_STEP", [],true);
        action();
    }

    return (
        <Wrapper>
            <Button
                active={props.active}
                value={props.text}
                onClick={(e)=>loadQuiz(props.onClick)}
                styles={"width:80%"}
            />
        </Wrapper>
    );
};

export default ButtonNext;
