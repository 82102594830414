import React, { useEffect, useState } from "react";
import Element from "../Element";
import styled from "styled-components";
import AppStateContext from "appState/AppStateContext";
import User from "assets/user/User";

const Wrapper = styled.div`
  ${(props) => `
    font-size: 4.5vh;
    color: white;
    ${props.styles}
  `}
`;

const WinnerQuiz = (props) => {

  const [winnerName, setWinnerName] = useState("");

  const appState = React.useContext(AppStateContext);
  const assistants = appState.state?.get("assistants");

  const audios = [
    {sound : "https://upwine.es/audios/sound_quiz_winner.mp3" , label : 'audioWinner'},
  ]

const playSound = (src) => {
    const sound = new Audio(src);
    sound.load();
    sound.play();
  }



  const orderPlayers = (obj) => {
    let adaptArray = [];
    for (let key in obj) {
        adaptArray.push(obj[key])
    }
    adaptArray.sort(function (a, b) {

        if (a.quizPoints < b.quizPoints) {
            return 1;
        }
        if (a.quizPoints > b.quizPoints) {
            return -1;
        }
        return 0;
    });
    return adaptArray;
  }

  useEffect(() => {
    let rank = orderPlayers(assistants);
    setWinnerName(rank[0]?.name)
    let winner = rank[0].reference;
    let prize = appState?.state?.get("quizPrize");

    if (winner) {
        //compare user reference with the winner reference to check if its really the winner.
        winner = winner.split("/")[winner.split("/").length - 1];
        if(winner === User.getId()) {
          playSound(audios[0].sound);

          if (prize && ![null, undefined, ""].includes(prize)) {
            props.params.setQuizWinnerModal(true);
          }
        }
    }
  }, []);

  return (
    <Element params={props.params}>
      <Wrapper styles={props.params.styles}>{winnerName}</Wrapper>
    </Element>
  );
};

export default WinnerQuiz;