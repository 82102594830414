function AssessmentsFinder(state, parameters) {
    let [action/*, params*/] = parameters.split('/')
    state = state.assessments;

    switch (action) {
     case 'current':return getCurrentAssessment();
      default:
        return state;
    }
    function getCurrentAssessment(){
      return state;
    }
    
  }
  
  export default AssessmentsFinder;
  