import React from "react";
import styled from "styled-components";
import Element from "../Element";
import User from "./User"
import AppStateContext from "appState/AppStateContext";
import ControlTrad from "assets/trad/ControlTrad";

const List = styled.div`
    display:flex;
    flex-direction: column;
    overflow: auto;
    height : 95%;
`;
const UserItem = styled.div`
    padding: 1vh;
`
const UserReady = styled.div`
    color:white;
`
const Wrapper = styled.div`
    height : 100%;
    overflow: hidden;

`

const UserList = (props) => {

    const appState = React.useContext(AppStateContext);
    let assistants = appState.state.get("assistants");

    let loadUsers = () => {
        let rows = [];
        Object.keys(assistants)?.forEach(element => {
            rows.push(
                <UserItem>
                    <User
                        ready={true}
                        userName={assistants[element]?.name}
                    />
                </UserItem>
            );
        });
        return rows;
    }

    return (
        <Element params={props.params}>
            <Wrapper>
                <UserReady> 
                    {Object.keys(assistants).length===1?
                    ControlTrad.getTrad("assInTheRoom_2"):
                    ControlTrad.getTrad("assInTheRoom_1")}  
                    {": " + Object.keys(assistants).length}
                </UserReady>
                <List>
                    {loadUsers()}
                </List>
            </Wrapper>
        </Element>
    );
};

export default UserList;
