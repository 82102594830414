import styled from "styled-components";

const Wrapper = styled.div`
${(props) => `
  background: rgba(255, 255, 255, 0.1);
  grid-area: ${props.params.position};
  ${props.params.styles}
`}
`;
const Rectangle = (props) => {
  return <Wrapper params={props.params}></Wrapper>;
};

export default Rectangle;
