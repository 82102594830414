import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Element from "../Element";
import Button from "components/Shared/Button"
import ModalMessage from "components/Sandbox/ModalMessage";
import User from "assets/user/User";
import Fetch from "Fetch/Fetch";
import ControlTrad from "assets/trad/ControlTrad";
import Custom from "assets/custom/Custom";
import SoundUtils from "assets/utils/SoundUtils";

const Wrapper = styled.div`
    padding: 4vh;
    @media (max-width: 426px) {
        padding : 1rem;
    }
`
const Wrapper2 = styled.div`
    padding: 6vh;
    height:100%;
    @media (max-width: 426px) {
        padding : 1rem;
    }
`

const BodyWrapper = styled.div`
    display:flex;
    flex-direction:column;
`

const LabelBox = styled.label`
    ${props => `
        text-align: left;
        padding: 0.75vh 0;
        padding: ${props.first ? "0 0 1vh 0" : "1vh 0"};

        @media (max-width: 426px) {
            padding: ${props.first ? "0 0 2vw 0" : "2vw 0"};
            font-size: 5vw;
        }
    `}  
`

const InputBox = styled.input`
    font-size: 2vh;
    border : 0;
    padding: 1vh;

    @media (max-width: 426px) {
        padding: 1.5vh;
        font-size: 5vw;
    }
`

const ButtonsWrapper = styled.div`
    padding : 2vh;
    display: flex;
    justify-content: space-around;
    @media (max-width: 426px) {
        display: none;
    }
`

const ButtonsWrapperMobile = styled.div`
    display: none;
    @media (max-width: 426px) {
        padding : 1rem;
        flex-direction: column;
        padding : 2vh;
        display: flex;
        justify-content: space-around;
    }
`

const DivCheck = styled.div`
    padding: 2vh 0 0;
    @media (max-width: 426px) {
        padding: 1vh 0 0;
    }
`

const Check = styled.input`
    color: white;
    width: 2vh;
    height: 2vh;
    @media (max-width: 426px){
        width: 1rem;
        height: 1rem;
        marginTop: 0;
    }
`
const Label = styled.label`
    font-size: 2vh;
    color: #000;

    @media (max-width: 426px){
        font-size: 3.5vw;
    }
`

const LabelLinks = styled.a`
    font-size: 2vh;
    color: #000;

    @media (max-width: 426px){
        font-size: 3.5vw;
    }
`

const ErrorSpan = styled.span`
    display:none;
    color:red;
    font-weight: bold;
    padding: 1vh 0;

    @media (max-width: 426px){
        padding: 2vw 0;
    }
`
const SendReportButton = (props) => {
    const [isEmailPopupVisible, setEmailPopupVisible] = useState(false);
    const [isSendingPopupVisible, setSendingPopupVisible] = useState(false);
    const [name, setName] = useState(User?.getNotDefaultName() || "");
    const [email, setEmail] = useState(User.getEmail() || "");
    const [check, setCheck] = useState(false);

    const openPopUp = () => {
        setEmailPopupVisible(true);
    }

    const nextScreen = (e) => {
        props.params.onClick();
    }

    function validateEmail(email) {
        email = email.replace(/^ /, '').replace(/ $/, '');
        setEmail(email);
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    }

    const sendReport = () => {
        let correct = true;

        if (name.length === 0) {
            document.getElementById("nameError").style.display = "block"
            correct = false;
        } else {
            document.getElementById("nameError").style.display = "none";
        }

        if (!validateEmail(email)) {
            document.getElementById("emailError").style.display = "block"
            correct = false;
        } else {
            document.getElementById("emailError").style.display = "none";
        }

        if (!check) {
            correct = false;
            document.getElementById("checkError").style.display = "block"
        } else {
            document.getElementById("checkError").style.display = "none";
        }

        if (correct) {
            setEmailPopupVisible(false);
            setSendingPopupVisible(true);

            User.setEmail(email);
            Fetch
                .post({ email: email, name: name }, "assistant/" + User.getId() + "/sendEmailReport")
                .then(response => {
                    console.info("Email sent: ", response);
                    nextScreen();
                }).catch(error => {
                    console.error("Error sending email: ", error);
                    nextScreen();
                });
        }
    }

    return (
        <>
            <Element params={props.params}>
                <Button value={props.params.text} onClick={(e) => openPopUp()} />
            </Element>
            <ModalMessage
                isShown={isEmailPopupVisible}
                setIsShown={setEmailPopupVisible}
                title={ControlTrad.getTrad("receiveReport")}
                styleHeader={"text-transform: uppercase; font-size:2.5vh"}
                boxSize={`
                    @media (max-width: 426px) {
                        min-width: 90vw;
                        max-width: 90vw;
                    }
                `}>

                <Wrapper>
                    <BodyWrapper>
                        <LabelBox first={true}>{ControlTrad.getTrad("nameForm")}</LabelBox>
                        <InputBox
                            value={name}
                            placeholder={ControlTrad.getTrad("insertName")}
                            onChange={(e) => { setName(e.currentTarget.value) }}
                            onKeyDown={(e) => { if (e.which == 13) { e.target.blur(); } }} />
                        <ErrorSpan id={"nameError"}>{ControlTrad.getTrad("inputLobbyError")}</ErrorSpan>

                        <LabelBox>{ControlTrad.getTrad("emailForm")}</LabelBox>
                        <InputBox
                            value={email}
                            placeholder={ControlTrad.getTrad("emailPlace")}
                            onChange={(e) => { setEmail(e.currentTarget.value) }}
                            onKeyDown={(e) => { if (e.which == 13) { e.target.blur(); } }} />
                        <ErrorSpan id={"emailError"}>{ControlTrad.getTrad("errorEmail")}</ErrorSpan>
                    </BodyWrapper>

                    <DivCheck>
                        <Check
                            type={"checkbox"}
                            id={"checkTerm"}
                            checked={check}
                            onChange={(e) => { setCheck(!check); }}
                        ></Check>
                        <Label for={"checkTerm"}>
                            {ControlTrad.getTrad("terms") + " "}
                            <b>
                                <LabelLinks href={Custom.Links.termsOfUse}
                                    target={"_blank"}
                                    rel="noopener noreferrer">
                                    {ControlTrad.getTrad("conditions1")}
                                </LabelLinks>
                            </b>
                            {" " + ControlTrad.getTrad("conditions2") + " "}
                            <b>
                                <LabelLinks href={Custom.Links.privacyPolicies}
                                    target={"_blank"}
                                    rel="noopener noreferrer">
                                    {ControlTrad.getTrad("conditions3")}
                                </LabelLinks>
                            </b>
                        </Label>
                        <ErrorSpan id={"checkError"}>{ControlTrad.getTrad("termerror")}</ErrorSpan>
                    </DivCheck>
                    <ButtonsWrapper>
                        <Button value={ControlTrad.getTrad("cancel")}
                            onClick={(e) => { setEmailPopupVisible(false) }}
                            styles={"flex:1; margin-right: 1vh; background-color:black; color: white; border: 0.3vh solid black;"} />
                        <Button
                            value={ControlTrad.getTrad("receiveReport")}
                            styles={"flex:1;"}
                            onClick={sendReport}
                            /*active={name !== "" && email !== "" && check}*/
                        >{ControlTrad.getTrad("receiveReport")}</Button>
                    </ButtonsWrapper>
                    <ButtonsWrapperMobile>
                        <Button
                            value={ControlTrad.getTrad("receiveReport")}
                            /*active={name !== "" && email !== "" && check}*/
                            onClick={sendReport}>{ControlTrad.getTrad("receiveReport")}</Button>
                    </ButtonsWrapperMobile>
                </Wrapper>
            </ModalMessage>
            <ModalMessage
                isShown={isSendingPopupVisible}
                setIsShown={setSendingPopupVisible}
                title={ControlTrad.getTrad("receiveReport")}
                disableCloseButton={true}
                boxSize={"min-height: 0;"}
            >
                <Wrapper2>
                    <b>{ControlTrad.getTrad("sendDataReport") + " " + email}</b>
                </Wrapper2>
            </ModalMessage>
        </>
    );
};

export default SendReportButton;
