import React from "react";

import styled from "styled-components";
import Button from "components/Shared/Button"
import ModalMessage from "components/Sandbox/ModalMessage";

const WrapperModal = styled.div`
  display:flex;
  flex-direction:column;
  align-items:center;
  justify-content:space-around;
  width:100%;
  font-size:3vh;
  height: 100%;
`

const SpanTxt = styled.span`
  padding-left: 2vh;
  padding-right: 2vh;
`

const QuizWinnerModal = (props) => {

    const reload = () => {
        window.location.reload();
    }

    return (
        <ModalMessage
        boxSize={" height:25vh; border-radius:1.5vh;"}
        styleHeader={"border-top-left-radius:1.5vh; border-top-right-radius:1.5vh; font-size:2.2vh;"}
        styleMain={"border-bottom-left-radius:1.5vh;border-bottom-right-radius:1.5vh;height:19vh;"}
        isShown={props.popUpError}
        setIsShown={props.setPopUpError}
        disableCloseButton={props.disableCloseButton}
        title={props.titleError}>
        <WrapperModal>
          <SpanTxt>{props.textError}</SpanTxt>
          <Button styles={"width:15vw;height:3vh;display:flex;justify-content:center;"} onClick={() => reload()}value={"Recargar"}/>
        </WrapperModal>
      </ModalMessage>
    );
};

export default QuizWinnerModal;