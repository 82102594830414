import beforeStartImage from 'assets/images/before-start-background.jpg';

class Custom{
    
    static logo;

    static Colours = {
        mainColor: '#fcaf3b', //'#2b3e50', //'#AE1C38',
        secondaryColor: '#fdd69b', //'#53728f', //'#941931',
        editButton : '#b0b0b0'
    }

    static Background = {
        mainBackground : "",
        secondaryBackground : "",
        lobbyImage  : beforeStartImage
    }

    static Lobby = {
        lobbySkipActive: true,
        lobbySkipTimeout: 10
    }

    static Text = {
        lobbyText : "Disfruta de la cata."
    }

    static Links = {
        termsOfUse : "http://terms.upwine.es/upwine/upwinesoftware-politica-privacidad.html",
        privacyPolicies : "http://terms.upwine.es/upwine/upwinesoftware-catas-condiciones-uso.html"
    }
    static getColour(colour){
        return this.Colours[colour];
    }
    static getAudios() {
        return this.audios;
    }
    static setColour(colour,value){
        this.Colours[colour] = value;
    }
    static setBackground(image,value){
        this.Background[image]  = value;
    }
    
    static setLinks (link , value){
        this.Links[link] = value;
    }

    static setLogo(value){
        this.logo = value;
    }

    static setAudios(value) {
        this.audios = value;
    }

    static toDeg(colour) {
        let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(this.getColour(colour));
      
        let r = parseInt(result[1], 16);
        var g = parseInt(result[2], 16);
        var b = parseInt(result[3], 16);
        r /= 255;
        g /= 255;
        b /= 255;
        var max = Math.max(r, g, b), min = Math.min(r, g, b);
        var h /*, s, l = (max + min) / 2*/;
      
        if (max === min) {
          h = /*s = */ 0; // achromatic
        } else {
          var d = max - min;
          //s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
          switch (max) {
            case r: h = (g - b) / d + (g < b ? 6 : 0); break;
            case g: h = (b - r) / d + 2; break;
            case b: h = (r - g) / d + 4; break;
            default: break;
          }
          h /= 6;
        }
        h = Math.round(360 * h);
        return h;
    }

}

export default Custom;