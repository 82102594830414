import React, { useState, useEffect } from "react";
import Websockets from "Websockets/Websockets";
import styled from "styled-components";

import Element from "../Element";
import AppStateContext from "appState/AppStateContext";
import Slider from "./Slider/Slider";
import ImagesSlider from "./ImagesSlider/ImagesSlider";
import SelectedValues from "./SelectedUserValues/SelectedUserValues";
import Tree from "./Tree/Tree";
import Question from "../FinalSurvey/Question";

import Button from "components/Shared/Button";
import ControlTrad from "assets/trad/ControlTrad";
import Tasting from "assets/tasting/Tasting";
import ModalMessage from "components/Sandbox/ModalMessage";
import Custom from "assets/custom/Custom";
import FetchUtils from "Fetch/FetchUtils";


const QuestionItem = styled.div`
    padding:1vh;
    display:flex;
    align-items:center;
    justify-content:space-around;
`;
const RelativeWrapper = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
  overflow-x: auto;

`;
const Wrapper = styled.div`
  ${props => `
  flex-direction: column;
  background: ${props.background ? props.background : "rgba(216, 216, 216, 0.8)"};
  display: flex;
  position: absolute;
  width:${props.disabledValues ? "100%" : "60vw"} ;
  height:${props.disabledValues ? "100%" : "90vh"};
  top:${props.disabledValues ? "0" : "4%"};
  left:${props.disabledValues ? "0" : "36%"};
  @media (max-width: 426px) {
    width:100vw;
    height: 100%;
    position: initial;
  
  }
  ${props.landscapeScreen ===  "portrait" ? `
  @media (min-width: 426px) and (pointer: coarse) {
    width:100vw;
    height: 100%;
    position: initial;  
  }
  ` : ""}
  `}
`;
const ComponentsWrapper = styled.div`
  overflow-y: auto;
  padding-top:2vh;
  flex: 1;
  -webkit-overflow-scrolling: touch;
`;
const ButtonWrapper = styled.div`
${props =>`
& > div {
  margin: 1vh;
}
@media (max-width: 426px) {
  display:none;
}
${props.landscapeScreen ===  "portrait" ? `
@media (min-width: 426px) and (pointer: coarse){
  display:none;
}
`:""}
`}

`;

const ButtonWrapperResponsive = styled.div`
${props => `  
display:flex;
  justify-content: space-between;
  background-color:#000;
  @media (min-width: 426px) {
    display:none;
  }
  ${props.landscapeScreen ===  "portrait" ? `
  @media (min-width: 426px) and (pointer: coarse){
    display:flex;
  }
  `:""}
  ${props?.onClickSave ? "display:none;" : ""}
`}`;

const ComponentTitle = styled.span`
  font-weight:600;
  font-size:2vh;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom:1.5vh;
`



const AssessmentDisplay = (props) => {
  const [state, setState] = useState({});
  const [modificated, setModificated] = useState(false);
  const [id, setId] = useState("");
  const [title, setTitle] = useState("");
  const [popUp, setPopUp] = useState(false);

  const appState = React.useContext(AppStateContext);
  let currentAssessment, components, wineRef, currentUserAssessments;

  if (Tasting.getType() === "live") {
    currentAssessment = appState.assessments.get("current");
    components = appState.state.get(`assessmentComponents/${currentAssessment}`);
    wineRef = appState.state.get(`currentWine`)?.reference;
    currentUserAssessments = appState.state.get(`currentUserAssessments`)?.[
      currentAssessment
    ];
  } else {
    currentAssessment = props.params.category;
    components = props.params.categoryComponents;
    currentUserAssessments = props.params.currentData[props.params.currentProductRef]?.[props.params.category?.id];
  }

  const loadComponent = (component, state, addAnswer) => {
    return {
      OPTIONS_SLIDER: (
        <Slider
          data={component}
          state={state[component.id]}
          addAnswer={addAnswer}
          viewVideo={viewVideo}
        />
      ),
      IMAGES_SLIDER: (
        <ImagesSlider data={component} state={state[component.id]} addAnswer={addAnswer} viewVideo={viewVideo}/>
      ),
      TREE: Tasting.getType() === 'live' ?
        <Tree background={props.params.background} data={component} state={state[component.id]} addAnswer={addAnswer} viewVideo={viewVideo}/>
        :
        <Tree
          background={props.params.background}
          currentAssessment={currentAssessment}
          currentUserAssessment={currentUserAssessments}
          data={component} state={state[component.id]} addAnswer={addAnswer} 
          viewVideo={viewVideo}
          />
          
      ,
      FACES: (
        <QuestionItem>
          <Question
            concept={component.name}
            size={10}
            separation={60}
            titleStyles={"padding-bottom: 1vh; font-weight:600;"}
            active={state?.[component.id]}
            reference={component.id}
            onClickEvent={onClickEvent}
          />
        </QuestionItem>
      )
    }[component.type];
  };

  const onClickEvent = (answer, reference) => {
    let satisfaction = document.getElementById(reference);
    satisfaction.setAttribute("satisfactionVal", answer);
    addAnswer(reference, answer);
  }

  const addAnswer = (key, val, initializing = false) => {
    setState((prevState) => {
      if (!initializing && prevState[key] !== val && key !== "videoPlayed") {
        setModificated(true);
      }
      return {
        ...prevState,
        [key]: val,
      }
    });

    /*if(props.params.onClickSave) {
      console.log("saving_answer")
      sendAnswers();
    }*/
  };

  const sendAnswers = () => {
    for (let key in state) {
      let parameter = state[key];

      if (Tasting.getType() === "live") {
        Websockets.send("SLAVE_PRODUCT_ASSESSMENT_PARAMETER", [
          wineRef,
          currentAssessment,
          key,
          parameter,
        ], false);
      } else {
        props.params.onSave((prev) => {
          let newData = {
            ...prev,
            [props.params?.currentProductRef]: {
              ...prev[props.params?.currentProductRef],
              [props.params?.category?.id]: {
                ...prev[props.params?.currentProductRef]?.[props.params?.category?.id],
                [key]: parameter
              }
            }
          }

          if (parameter === null || parameter === undefined) {
            delete newData?.[props.params.currentProductRef]?.[props.params.category.id]?.[key];
          }
          return newData;
        });
      }
    }
    if (Tasting.getType() === "podcast") {
      FetchUtils.sendProductAssessmentParameter(
        props.params?.currentProductRef,
        props.params?.category?.id,
        state
      );
    }
    props.params.onConfirmEvent();
  };

  const loadComponents = () => {
    let views = [];
    components?.forEach((component) => {
      if (component?.id)
        views.push(loadComponent(component, state, addAnswer));
    });
    return views;
  };

  const viewVideo = (id, title) => {
    setTitle(title);
    setId(id);
    setPopUp(true);
  }


  useEffect(() => {
    if (props.params.onClickSave) {
      sendAnswers();
    }
  }, [state])

  useEffect(() => {
    for (let key in currentUserAssessments) {
      let currentUserAssessment = currentUserAssessments[key];
      addAnswer(key, currentUserAssessment, true)
    }
  }, []);

  return (
    <>
      <Element params={props.params}>
        {Tasting.getType() === "live" ?
          <SelectedValues userValues={state} landscapeScreen ={props.params.landscapeScreen} />
          :
          props.params.disabledValues ?
            <></>
            :
            <SelectedValues
              userValues={state}
              categories={props.params.categories}
              category={props.params.category}
              landscapeScreen={props.params.landscapeScreen}
            />

        }
        <RelativeWrapper>
          <Wrapper background={props.params.background} disabledValues={props.params.disabledValues} landscapeScreen ={props.params.landscapeScreen}>
            <ButtonWrapperResponsive landscapeScreen ={props.params.landscapeScreen} onClickSave={props.params.onClickSave}>
              <Button
                value={ControlTrad.getTrad("cancel")}
                type="cancel"
                onClick={props.params.onCancelEvent}
                styles={`
                  flex: 1;
                  border: 0.1rem #fff solid;
                  color: #fff;
                  font-size: 2.5vh !important;
                  padding: 0.8vh;
                  margin: 1vh;
                  margin-right: 0.5vh;
                `}
              />
              <Button
                value={ControlTrad.getTrad("save")}
                highligthed={modificated}
                onClick={() => {
                  sendAnswers();
                }}
                styles={`
                  flex: 1;
                  font-size: 2.5vh !important;
                  padding: 0.8vh;
                  margin: 1vh;
                  margin-left: 0.5vh;
                `}

              />
            </ButtonWrapperResponsive>
            <ComponentsWrapper>
              <ComponentTitle>{ControlTrad.getTrad("rateTheElements")}</ComponentTitle>
              {loadComponents()}
            </ComponentsWrapper>
            {props.params.disabledValues ?
              null
              :
              <ButtonWrapper landscapeScreen ={props.params.landscapeScreen}>
                <Button
                  value={ControlTrad.getTrad("cancel")}
                  type="cancel"
                  styles={"color:black"}
                  onClick={props.params.onCancelEvent}
                />
                <Button
                  value={ControlTrad.getTrad("save")}
                  highligthed={modificated}
                  onClick={() => {
                    sendAnswers();
                  }}
                />
              </ButtonWrapper>}
          </Wrapper>
        </RelativeWrapper>
      </Element>
      <ModalMessage
        title={title}
        isShown={popUp}
        setIsShown={setPopUp}
        boxSize={`
          display: flex;
          flex-direction: column;
          width: 40vw;
          height: 60vh;
          @media (max-width: 426px) {
            width: 90vw;
            height: 42vh;
          }
        `}
        styleMain={`
          display: flex;
          flex-direction: column;
          width: 40vw;
          height: 60vh;
          @media (max-width: 426px) {
            width: 90vw;
            height: 42vh;
          }
      `}>

        <iframe
          src={popUp && id !== null && id !== "null" && id !== undefined ? `https://player.vimeo.com/video/${id}?autoplay=1&color=${Custom.getColour("mainColor")}` : null}
          frameborder="0"
          style={{
            margin: "auto",
            width: props.params.landscapeScreen === "portrait" ? "90vw" : "40vw",
            height: props.params.landscapeScreen === "portrait" ? "30vh" : "45vh",
            flexGrow: "1"
          }}
          webkitallowfullscreen
          mozallowfullscreen
          allow="autoplay"
          allowfullscreen
        />
        <Button value={ControlTrad.getTrad("close")} onClick={() => { setPopUp(false) }} styles={"width: 15vw; margin: 1vh auto;"} />
      </ModalMessage>
    </>
  );
};

export default AssessmentDisplay;
