import React from "react";

import styled from "styled-components";
import AppStateContext from "appState/AppStateContext";
import ControlTrad from "assets/trad/ControlTrad";

const Wrapper = styled.div`
${props => `
    color: white;
    font-size: 2vh;
    border-radius:1vh;
    height: 100%;
    width: 100%;
    background-color:black;
    padding : 1vh 0 0;
`}`;

const Timer = (props) => {

    const appState = React.useContext(AppStateContext);
    let timer = appState.state.get("quizTimer");
    
    return (
        <Wrapper params={props.params}>
            {ControlTrad.getTrad("time") + "00:"}
            {timer < 10 ? "0"+timer : timer}
            </Wrapper>
    );

}
export default Timer; 