import React from "react";

import styled from "styled-components";
import AppStateContext from "appState/AppStateContext";
import Colours from "assets/custom/Custom";
import User from "assets/user/User";
import ControlTrad from "assets/trad/ControlTrad";

const Wrapper = styled.div`
${props => `
    text-align : left;
    color: white;
    background-color:${Colours.getColour("mainColor")};
    width:100%;
    height:auto;
    border-radius:1vh;
    font-size: 2vh;
    padding: 1vh 0;
    text-align: center;
`}`;

const Question = (props) => {

    const appState = React.useContext(AppStateContext);
    let question = ControlTrad.getUserTrad(appState.state?.get("quizActualQuestion")?.question);

    return (
        <Wrapper params={props.params}>{question}</Wrapper>
    );

}
export default Question;