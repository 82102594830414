
import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import ModalMessage from "./ModalMessage";
import ReactHtmlParser from 'react-html-parser';
import Colours from "assets/custom/Custom";

const Text = styled.span`
  display:flex;
  align-items:center;
  justify-content:center;
  width:100%;
  height:7vh;
  font-size:2.5vh;
  white-space: break-spaces;
`

const Toast = (props) => {
    let timeout = null;

    useEffect(() => {
        return () => {
            if (timeout !== null) {
                clearTimeout(timeout);
                console.log("Toast timeout cleared");
            }
        }
    }, [props?.visible]);

    useEffect(() => {
        if (props?.visible) {
            if (timeout !== null) {
                clearTimeout(timeout);
                timeout = null;
            }

            timeout = setTimeout(() => {
                props?.close();
                timeout = null;
            }, 5000);
        }
    }, [props?.visible]);

    return (
        <ModalMessage
            boxSize={`
                height: 7vh; 
                min-height:5vh; 
                box-shadow: 0 0 6vh 2vh rgb(101 102 103);
                border-radius: 1vh; 
                padding: 0 3vh; 
                background-color: ${Colours.getColour("mainColor")};
                color: white;
            `}
            styleMain={"background-color: transparent;"}
            header={false}
            isShown={props.visible}
            background={false}
            isChat={true} >
            <Text>{ReactHtmlParser(props.text)}</Text>
        </ModalMessage>
    );
};

export default Toast;
