import React from "react";
import Element from "../Element";
import Button from "components/Shared/Button"
import Websockets from "Websockets/Websockets";

const ButtonBack = (props) => {

    const loadQuiz = (action) =>{
        Websockets.send("PREVIOUS_STEP", [],true);
        action();
    }

    return (
        <Element params={props.params}>
            <Button 
            value={props.params.text}
            onClick={(e)=>{loadQuiz(props.params.onClick)}}
            />
        </Element>
    );
};

export default ButtonBack;
