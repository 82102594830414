import Custom from "assets/custom/Custom";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Element from "../Element";
import ReactPlayer from "react-player";
import Button from "components/Shared/Button";

const PlayHint = styled.div`
    margin: auto;
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    pointer-events: none;
`;

const LoadingText = styled.div`
    margin: auto;
    color: white;
    font-size: 3vh;
`;

const VimeoComponent = (props) => {
    const VIMEO_TOKEN = "fda050b836519715dea62d128be8e435";

    let videoId = props.params.video;
    const [url, setUrl] = useState(null);
    const [isLoadingUrl, setLoadingUrl] = useState(false);
    const [ready, setReady] = useState(false);
    const [videoPlaying, setVideoPlaying] = useState(true);

    const Parent = {
        gridArea: props.params.position,
        display: props.params.disable ? "none" : "flex",
        width: "100%",
        height: "100%",
        flexDirection: "column",
        justifyContent: props.params.landscapeScreen === "landscape" ? "flex-end" : "",
        display: "flex"  //ready ? "flex" : "none",
    }

    const Wrapper = {
        width: "100%",
        aspectRatio: "16 / 9",
        position: "relative",
        textAlign: "center",
        alignItems: "center",
        pointerEvents: "all",
        animationName: props.params.highligthed ? "highligthedAnim" : "none",
        animationDuration: "2s",
        animationIterationCount: "infinite",
        animationDirection: "alternate",
        backgroundColor: "black",
    };

    const isVideoIdNotNull = () => {
        return videoId !== null && videoId !== undefined && videoId !== "null";
    }

    const loadUrl = () => {
        setUrl(null);
        if (videoId !== null && videoId !== undefined && videoId !== "null") {
            setLoadingUrl(true);
            var myHeaders = new Headers();
            myHeaders.append("Authorization", "bearer " + VIMEO_TOKEN);
            myHeaders.append("Content-Type", "application/json");
            myHeaders.append("Accept", "application/vnd.vimeo.*+json;version=3.4");

            var requestOptions = {
                method: "GET",
                headers: myHeaders,
                redirect: "follow",
            };

            fetch("https://api.vimeo.com/videos/" + videoId, requestOptions)
                .then((response) => response.text())
                .then((result) => {
                    let resObj = JSON.parse(result);
                    setUrl(resObj.link);
                    setLoadingUrl(false);
                    setVideoPlaying(true);
                })
                .catch((error) => {
                    console.error("Error loading vimeo video", error);
                    setLoadingUrl(false);
                });
        }
    }

    const playVideo = (e) => {
        if (url != null && videoPlaying) {
            setVideoPlaying(true);
        }
    }

    const onReady = () => {
        setReady(true);
    }

    const onError = (e) => {
        console.error("Player error", e);
    }

    const pauseVideo = () => {
        if (url != null && !videoPlaying) {
            setVideoPlaying(false);
        }
        onPause();
    }

    const onStart = () => {
        let onPlayEvent = props?.params?.videoEvents?.onPlay;
        if (typeof onPlayEvent === 'function') {
            onPlayEvent();
        }
    }

    const onPause = () => {
        let onPauseEvent = props?.params?.videoEvents?.onPause;
        if (typeof onPauseEvent === 'function') {
            onPauseEvent();
        }
    }

    useEffect(() => {
        loadUrl();
    }, [props.params.video]);

    useEffect(() => {
        if (videoPlaying) {
            playVideo();
        } else {
            pauseVideo();
        }
    }, [videoPlaying]);

    return (
        <>
            {isLoadingUrl ? (
                <div style={Parent}>
                    <div style={Wrapper}>
                        <PlayHint>
                            <LoadingText>Espere, cargando video...</LoadingText>
                        </PlayHint>
                    </div>
                </div>
            ) : (url !== null ? (
                <div style={Parent}>
                    <div style={Wrapper}>
                        {props.params.highligthed && ready ? (
                            <PlayHint>
                                <Button
                                    highligthed={true}
                                    value={"Mira el video"}
                                    styles={"margin:auto;"}
                                />
                            </PlayHint>
                        ) : (
                            null
                        )}

                        <ReactPlayer
                            height="100%"
                            width="100%"
                            playing={videoPlaying}
                            url={url}
                            controls={true}
                            onReady={onReady}
                            onStart={onStart}
                            onPlay={playVideo}
                            onPause={pauseVideo}
                            onError={onError}
                                playsinline={true} />
                    </div>
                </div>
            ) : (
                <></>
            ))}
        </>
    );
}
export default VimeoComponent;
