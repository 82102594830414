import React from "react"
import AppStateContext from "appState/AppStateContext";

import Element from "../Element";
import styled from "styled-components";
import ControlTrad from "assets/trad/ControlTrad";
import Tasting from "assets/tasting/Tasting";
import User from "assets/user/User";

const Wrapper = styled.div`
${props=>`
  font-size: 2.5vh;
  color: white;
  font-weight: bold;
  ${props.params.styles}
`}`;

const WineIndex = (props) => {
  const appState = React.useContext(AppStateContext);
  const visibility = Tasting.getType() === "live" ? appState.state?.get("currentWine")?.visible : props.params?.visibility?.visible;
  let wineIndex, wineCount, productType;

  if (Tasting.getType() === "live") {
    wineIndex = appState.state.get("wineIndex");
    wineCount = appState.state.get("winesCount");
    productType = ControlTrad.getUserTrad(appState.state.get("currentWine")?.product?.type.name);
  } else {
    wineIndex = props.params.current;
    wineCount = props.params.numWines;
    productType = props.params.productType;
  }
  
  return (
    <>
      {wineCount > 1 ? (
        <Element params={props.params} id={'wineIndex'}>

          {!isNaN(wineIndex) ? (
            <Wrapper params={props.params}>
              {visibility ? (
                productType + " " + (wineIndex + 1)
              ) : (
                ControlTrad.getTrad("wine") + " " + (wineIndex + 1)
              )}
              {wineCount ? (
                ControlTrad.getTrad("wineof") + " " + wineCount 
              ) : (
                ""
              )}
            </Wrapper>
          ) : (
            <></>
          )}

        </Element>
      ) : (
        <></>
      )}
    </>
  );
};

export default WineIndex;
