
import Custom from "../custom/Custom";

const soundsUrls = {
    validate: "https://upwine.es/audios/validate_sound.wav",
    startTasting: "https://upwine.es/audios/start_tasting_sound.mp3",
    finishTasting: "https://upwine.es/audios/finish_tasting_sound.mp3",
    typingSound: "https://upwine.es/audios/typing_sound.mp3",
    storeSound: "https://upwine.es/audios/store_sound.mp3",
    prerecomendation: "https://upwine.es/audios/prerecomendation_sound.mp3",
    postrecomendation: "https://upwine.es/audios/postrecomendation_sound.mp3"
}

let sounds = {}
let soundMetadata = {}
let actualLoopSound = null;

class SoundUtils {

    static getSoundUrl(soundName) {
        let customAudios = Custom.getAudios();

        // Custom sound avaliable
        if (customAudios?.[soundName]) {
            let audioData = customAudios?.[soundName];
            if (audioData?.active) {
                return audioData?.url;
            }

            // Default sound
        } else if (soundsUrls?.[soundName]) {
            return soundsUrls?.[soundName];
        }

        return null;
    }

    static preload(soundName) {
        try {
            if (sounds?.[soundName]) return;

            let url = this.getSoundUrl(soundName);
            if (url && url != null) {
                let audio = new Audio(url);
                audio.load();
                sounds[soundName] = audio;
                return audio;
            }
        } catch (e) {
            console.error("Error preloading sound:", e);
        }
        return null;
    }


    static play(soundName, loop = false) {
        try {
            let sound = sounds?.[soundName];
            if (sound === null || sound === undefined) {
                sound = this.preload(soundName);
            }
            if (sound !== null && sound !== undefined) {
                if (actualLoopSound) {
                    actualLoopSound.loop = false;
                    actualLoopSound.pause();
                }

                if (loop) {
                    sound.loop = true;
                    actualLoopSound = sound;
                }

                sound.play();

                soundMetadata[soundName] = {
                    ...soundMetadata?.[soundName],
                    played: true,
                }
            }
        } catch (e1) {
            console.error("Error playing sound:", e1);
        }
    }

    static playIfNotPlayedBefore(soundName) {
        if (!soundMetadata?.[soundName]?.played) {
            this.play(soundName);
        }
    }
}
export default SoundUtils;