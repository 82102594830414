import React from "react";

import styled from "styled-components";
import Element from "../Element";
import AppStateContext from "appState/AppStateContext";
import ControlTrad from "assets/trad/ControlTrad";
import Tasting from "assets/tasting/Tasting";


const Wrapper = styled.div`
${props => `
    text-align : left;
    color: white;
    font-size: 3vh;
    @media (max-width: 426px) {
        font-size: 2.5vh;
    }
    ${props.params.styles}
`}`;

const QuizNumQuestions = (props) => {
    const appState = React.useContext(AppStateContext);
    let numQuestions,quizIndexQuestion;
    if(Tasting.getType()==='live'){
        numQuestions = appState.state.get("quizNumQuestions");
        quizIndexQuestion = appState.state.get("quizIndexQuestion");
    }else{
        numQuestions=props.params.questions;
        quizIndexQuestion = props.params.actualQuestion;
    }


    return (
        <Element params={props.params}>
            <Wrapper params={props.params}><b> {ControlTrad.getTrad("question")} {quizIndexQuestion+1} {ControlTrad.getTrad("of")} {numQuestions}</b></Wrapper>
        </Element>
    );

}
export default QuizNumQuestions;