import React, { useEffect, useState } from "react"
import Colours from "assets/custom/Custom";
import styled from "styled-components";
import ControlTrad from "assets/trad/ControlTrad";

const Wrapper = styled.div`
    overflow: auto;
    padding: 0 0 1vh
`

const OptionsWrapper = styled.div`
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    grid-gap: 0 1vh;

    @media (min-width: 426px) and (pointer: coarse){
        flex-wrap: wrap;
    }

    @media (max-width: 426px) {
        grid-gap: none;
        margin: 0vh auto;
    }
    @media (max-width: 360px) {
        grid-gap: none;
        margin: 0vh auto;
    }
`;

const Option = styled.div`
    ${props => `
        color: #fff;
        background-color: transparent;
        padding: 1vh;
        border: 0.3vh #fff solid;
        border-radius: 2vh;
        min-width: 6.5rem;
        cursor: pointer;
        margin: 1vh 0.5vh;
        font-size: 2.5vh;
        @media (max-width: 426px) {
            font-size: 1.1rem;
            flex: 0 0 20%;
            border-radius: 0.5rem;
            padding: 0.5rem;
            margin: 0.5rem;
        }
        @media (min-width: 426px) and (pointer: coarse) and (orientation: landscape) {
            font-size: 1.1rem;
            flex : none;
            border-radius: 0.7rem;
            padding: 0.7rem;
            margin: 0.7rem;
        }
        ${props.optionStyle}
    `}
`;

const H1 = styled.div`
    font-size: 3vh;
    color: #fff;
    margin: 0 auto 1vh auto;
    @media (max-width: 426px) {
        width:100%;
        font-size: 1.2rem;
        margin: 0 auto 0.5rem auto;

      }
    @media (min-width: 426px) and (pointer: coarse) and (orientation: landscape) {
        width:100%;
        font-size: 1.4rem;
        margin: 0 auto 0.5rem auto;
    }
    
    `
const Error = styled.div`
    visibility:hidden;
`

const H2 = styled.div`
    font-size: 2vh;
    color: #fff;
    margin: 0 auto 1vh auto;
    @media (max-width: 426px) {
        font-size: 1rem;
    }
    @media (min-width: 426px) and (pointer: coarse) and (orientation: landscape) {
        font-size: 1.1rem;
    }
`


const SelectOptions = (props) => {
    const OptionActive = styled.div`
        ${props => `
            color: #fff;
            background-color: #AE1C38;
            padding: 1vh;
            border: 0.3vh #fff solid;
            border-radius: 2vh;
            cursor: pointer;
            min-width: 6.5rem;
            margin: 1vh 0.5vh;
            font-size: 2.5vh;
            @media (max-width: 426px) {
                font-size: 1.1rem;
                flex: 0 0 20%;
                border-radius: 0.5rem;
                padding: 0.5rem;
                margin: 0.5rem;
            }
            @media (min-width: 426px) and (pointer: coarse) and (orientation: landscape) {
                font-size: 1.1rem;
                flex : none;
                border-radius: 0.7rem;
                padding: 0.7rem;
                margin: 0.7rem;
            }
            ${props.optionStyle}
        `};
    `;

    const [select, setSelect] = useState(props?.value || null);

    useEffect(() => {
        if (select === null && props?.value) {
            setSelect(props?.value);
        }
    }, [select, props?.value]);

    const getOptions = () => {
        let row = [];
        props.options.forEach(option => {
            let selected = select === option;
            row.push(selected ? (
                <OptionActive key={option} optionStyle={props.optionsStyle}>
                    {option}
                </OptionActive>
            ) : (
                    <Option key={option} optionStyle={props.optionsStyle} onClick={(e) => {
                        setSelect(option);
                        if (props?.onSelect) props.onSelect();
                    }}>
                    {option}
                </Option>
            ));
        });
        return row;
    };

    return (
        <>
            <H1>{props.title}</H1>
            <Wrapper>
                <div id={props.id} formValue={select}>
                    <OptionsWrapper>
                        {getOptions()}
                    </OptionsWrapper>
                </ div>
            </Wrapper>
            <Error id={props.id+"error"}>
                <H2>{ControlTrad.getTrad("selectError")}</H2>
            </Error>
        </>
    );

}
export default SelectOptions;