import Element from "./Element";
import Button from "components/Shared/Button"


const ChangeViewButton2 = (props) => {
  return (
    <Element params={props.params}>
        <Button onClick={props.params.onClick} value={props.params.value ?  props.params.value : "Siguiente pantalla"}/>
    </Element>
  );
};

export default ChangeViewButton2;
