import User from "assets/user/User";
import React from "react";

import styled from "styled-components";


const RankWrapper = styled.div`
    display:flex;
    flex-direction:row;

`;

const RankHead = styled.div`
    text-align : center;
    color: #af1d39;
    font-size: 6vh;
    width : 5vh;
    padding 1vh;
    font-weight:bold;
`;
const RankTitle = styled.div`
text-align : left;
color: black;
font-size: 3vh;
flex: 3;
padding: 3.5vh 1vh 1vh 0;
@media (max-width: 426px) {
    flex: 2;
}
`;
const RankPoints = styled.div`
    text-align : center;
    color: #af1d39;
    font-size: 3vh;
    flex:1;
    padding: 3.5vh 1vh 1vh 0;
    font-weight:bold;
`;




const QuizRank = (props) => {
    const Rank = styled.div`
        text-align : left;
        color: white;
        width:100%;
        display:flex;
        flex-direction:column;
        height: auto;
        border-radius:0.5vh;
        margin :0 0 0.5vh;
        background-color: ${props.userID === User.getId() ? '#9c9a9a' : 'white'};
    `;

    const RankDate = styled.div`
    text-align : center;
    color: #af1d39;
    font-size: 3vh;
    flex: ${props.date !== undefined ? '0;' : '1;'}
    padding: 0;
    font-weight:bold;
    text-align: right;
    display: ${props.date !== undefined ? 'block;' : 'none;'}

`;

    return (
        <Rank params={props.params}>
            <RankWrapper>
                <RankHead>{props.position}</RankHead>
                <RankTitle params={props.params}>{props.user}</RankTitle>
                <RankPoints>{props.points}</RankPoints>
            </RankWrapper>
            <RankDate>{props.date}</RankDate>
        </Rank>
    );

}
export default QuizRank;