import React, { useState, useEffect } from "react";
import styled from "styled-components";

import AppStateContext from "appState/AppStateContext";
import Element from "../Element";
import User from "assets/user/User";
import Fetch from "Fetch/Fetch";
import Colours from "assets/custom/Custom";
import Tasting from "assets/tasting/Tasting";
import LoadingComponent from "../LoadingComponent/LoadingComponent";
import ControlTrad from "assets/trad/ControlTrad";

const General = styled.div`
  width:100%;
  height:100%;
  position: relative;
`

const Wrapper = styled.div`
  display:flex;
  flex-direction:column;
  justify-content:flex-start;
  font-size: 4.5vh;
  color: white;
  background-color: #fffffff0;
  align-items:flex-start;
  width: 95%;
  padding: 2.5%;
  height: 83%;
  border-radius:1vh;
  @media (max-width: 426px) {
    border-radius:1vh;
    margin: 0 0 4vh 0;
  }
  @media (min-width: 426px) and (pointer: coarse) {
    font-size: 3vh;
    height:60%;
  }
`;

const TextBox = styled.span`
  ${props => `
      color:black;
      font-size:1.9vh;
      text-align:left;
      ${props.styles}
  `}
`


const WineColWrapper = styled.div`
  ${props => `
    display:flex;
    flex-direction:column;
    align-items:flex-start;
    margin: 1%;
    padding: 1%;
    ${props.styles}
  `}
`

const WineRowWrapper = styled.div`
  display:flex;
  width: 100%;
  height:80%;
  flex-direction:row;
  align-items:flex-start;
  @media (max-width: 426px) {
    align-items:center;
  }
`

const WineImage = styled.img`
  max-height: 80%;
  max-width: 80%;
  margin: auto;
`

const WineRecommendation = (props) => {

  const TextTitleBox = styled.span`
    ${props => `
      color: ${Colours.getColour("mainColor")};
      font-size: 5vh;
      font-weight: 600;
      padding-bottom: 2vh;
      margin-left: 2vh;
      text-align:left;
      @media (max-width: 426px) {
        font-size: 3.5vh;
        padding-bottom: 1vh;
        ${props.styles}
      }
    `
  }`

  const WineRecoWrapper = styled.div`
    ${props => `
      display:flex;
      flex-direction:column;
      align-items:flex-start;
      border-bottom:0.2vh solid ${Colours.getColour("mainColor")};
      padding-bottom:1vh;
      width:100%;
      color:black;
      @media (max-width: 426px) {
        border-bottom: 0;
      }
      ${props.styles}
    `}
  `

  const WineInfoFlexVal = styled.div`
    flex: 1;
    margin-top: 0.5vh;
    margin-bottom: 0.5vh;
  `

  const WineInfoFlexTitle = styled.div`
    font-size: 2vh;
    font-weight: bold;
    @media (max-width: 426px) {
      font-size: 1rem;
    }
  `
  const WineInfoFlexDesc = styled.div`
    font-size: 2vh;
    @media (max-width: 426px) {
      font-size: 1rem;
    }
  `

  const Wine = styled.div`
    width: 95%;
    display: grid;
    grid-auto-columns: auto;
    grid-template-columns: auto auto;
    grid-gap: 2vh;
    @media (max-width: 426px) {
      grid-template-columns: auto;
      grid-gap: 0.5vh;
    }
  `
  const [wineRecommendation, setWineRecommendation] = useState(null);

  const appState = React.useContext(AppStateContext);
  /*const wines = appState.state.get("wines") || props.params.wines;
  const layoutType = props.params.layoutType;*/
  
  let assistants, wineRecReference;
  if (Tasting.getType() === 'live') {
    assistants = appState.state.get("assistants");
    wineRecReference = assistants?.[User.getId()]?.recomendation;
  } else {
    wineRecReference = props.params.wineRec;
  }


  let onTime;

  useEffect(() => {
    if (wineRecReference && Tasting.getType() === 'live') {
      Fetch.getRecommendation(wineRecReference.split("/")[1], wineRecReference.split("/")[wineRecReference.split("/").length - 1]).then(wineRec => {
        props.params.setWineRecommendation(wineRec);
        setWineRecommendation(wineRec)
      });
    } else {
      setWineRecommendation(wineRecReference)
    }

    onTime = setTimeout(() => {
      props.params.then();
      console.log("Recomenadation timeout timer finished");
    }, 11000);

    console.log("Recomenadation timeout timer started");

    return () => {
      clearTimeout(onTime);
      console.log("Recomenadation timeout timer cleared");
    } 
  }, [])


  const loadProperties = () => {
    let row = [];
    if (wineRecommendation !== null && wineRecommendation !== undefined) {
      Object.values(wineRecommendation?.properties).forEach(property => {
        row.push(
          <WineInfoFlexVal>
            {property?.name ? (
              <WineInfoFlexTitle>{ControlTrad.getUserTrad(property?.name)}: </WineInfoFlexTitle>
            ) : (
              <></>
            )}
            <WineInfoFlexDesc>{ControlTrad.getUserTrad(property?.value)}</WineInfoFlexDesc>
          </WineInfoFlexVal>

        );
      });
    }
    return row;
  }

  return (
    <Element params={{ ...props.params, styles: "display: flex;" }} >
      <General>
        {wineRecommendation ? (
          <Wrapper>
            <TextTitleBox styles={"text-align:center;"}>
              {wineRecommendation?.name}
            </TextTitleBox>
            <WineRowWrapper>
              <WineColWrapper styles="width: 30%; height: 90%;">
                <WineImage src={wineRecommendation?.image} alt={"WineImage"} />
              </WineColWrapper>
              <WineColWrapper styles="width: 62%; max-height: 90%; margin: auto;  overflow-x: auto;">
                <WineRecoWrapper styles="{@media (max-width: 426px) {display: none;}">
                  <TextBox styles={"font-size:3vh;color:" + Colours.getColour("mainColor") + ";font-weight:bold;"}>{wineRecommendation?.name}</TextBox>
                </WineRecoWrapper>
                <WineRecoWrapper>
                  <Wine>
                    {loadProperties()}
                  </Wine>
                </WineRecoWrapper>
              </WineColWrapper>
            </WineRowWrapper>
          </Wrapper>
        ) : (
          <LoadingComponent params={{ position: "1/1/100/100", active: true, ready: () => { } }} />
        )}
      </General>
    </Element>
  );
};

export default WineRecommendation;
