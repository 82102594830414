import React from "react";

import styled from "styled-components";
import Element from "../Element";
import ChartResponsive from "./ChartResponsive";
import AppStateContext from "appState/AppStateContext";

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    height:100%;
    width:100%;
    background-color: white;
    padding:2vh;
    border-radius:3vh;
`;

const AnswersHeader = styled.div`
    margin: auto 2vh;
    font-size: 3.5vh;
    font-weight: 500;
`;

const AnswersVertical = styled.div`
    display: flex;
    text-align : left;
    font-size: 3vh;
    height:100%;
    width: 100%;
    flex-direction: column;
    color: white;
    justify-content: space-evenly;
`;

const Answer = styled.div`
    flex : 1;
    @media (max-width: 426px) {
        flex:none;
    }
`;

const ChartsResponsive = (props) => {
    const appState = React.useContext(AppStateContext);
    let reference = appState.state?.get("quizActualQuestionReference");
    let assistants = appState.state?.get("assistants");
    let question = appState.state?.get("quizActualQuestion");
    let correct = question?.correct[0];

    const loadCharts = () => {
        let row = [];
        if (question?.options !== null && question?.options !== undefined) {
            let allUsers = Object.keys(assistants).length;
            Object.keys(question?.options).forEach(element => {
                let count = countTotalOptionAnswers(element);
                let percent = (count / allUsers) * 100;
                row.push(
                    <Answer>
                        <ChartResponsive
                            correct={correct === element}
                            answer={question?.options[element]["ES"]}
                            percent={percent + "%"}
                            answers={count}
                        />
                    </Answer>
                );
            });
        }
        return row;
    }

    const countTotalOptionAnswers = (optionId) => {
        let sum = 0;
        Object.keys(assistants).forEach(assiatantId => {
            if (assistants?.[assiatantId]?.quizAnswers?.[reference] === optionId) {
                sum++;
            }
        });
        return sum;
    }

    const countTotalQuestionAnswers = () => {
        let sum = 0;
        Object.keys(assistants).forEach(assiatantId => {
            let answer = assistants?.[assiatantId]?.quizAnswers?.[reference];
            if (answer !== null && answer !== undefined) {
                sum++;
            }
        });
        return sum;
    }

    let component = (props,) => (
        <Element params={props.params}>
            <Wrapper>
                <AnswersHeader>{countTotalQuestionAnswers()} {"resuestas de asistentes"}</AnswersHeader>
                <AnswersVertical>
                    {loadCharts()}
                </AnswersVertical>
            </Wrapper>
        </Element>
    );
    let display = component(props)
    return (display);

}
export default ChartsResponsive;