import styled from "styled-components";
import sendImg from "assets/images/recommended_anim.gif";
import React, { useEffect, useState } from "react";
import Element from "../Element";
import Tasting from "assets/tasting/Tasting";
import AppStateContext from "appState/AppStateContext";
import LoadingComponent from "../LoadingComponent/LoadingComponent";
import SoundUtils from "assets/utils/SoundUtils";


const Wrapper = styled.div`
  width:100%;
  height:100%;
  display:flex;
  align-items:center;
  justify-content:center;
  background: black;
`

const ImgWrapper = styled.img`
  max-width:100vw;
  max-height:100vh;
  width:auto;
  height:auto;
`
const RecommendationGif = (props) => {
  const [loading, setLoading] = useState(true);
  const appState = React.useContext(AppStateContext);
  const recomentation = appState.state.get("getRecommendAssistant");
  useEffect(() => {
    if (Tasting.getType() === "live") {
        if (recomentation === undefined || recomentation === null) {
          props.params.onThenNotRecomendation();
        } else {
          setLoading(false);
        }
    } else {
      setLoading(false);
    }
  }, []);

  useEffect(()=>{
    if(!loading){
      props.params.onStart();

      setTimeout(() => {
        props.params.onThen();
        SoundUtils.play("postrecomendation");
      }, 8500);

      setTimeout(() => {
        SoundUtils.play("prerecomendation");
      }, 7000);
    }
  },[loading])

  return (
    <Element params={props.params} >
      {loading ?
        <LoadingComponent params={{ position: "1/1/100/100", active: true, ready: () => { } }} />
        :
        <Wrapper>
          <ImgWrapper src={sendImg} params={props.params} />;
        </Wrapper>
      }
    </Element>


  );
};

export default RecommendationGif;
