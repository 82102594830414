import React from "react";
import AppStateContext from "appState/AppStateContext";

import styled from "styled-components";
import Tasting from "assets/tasting/Tasting";

import UnknownProduct from "assets/images/mistery-box.svg";
import User from "assets/user/User";

const Wrapper = styled.div`
  display:flex;
  align-items: center;
  text-align: center;
  grid-area: ${(props) => props.params.position};
`;

const Inner = styled.img`
  ${props =>`
    max-width:15vh;
    margin: auto;
    height: auto;
    max-height:100%;
    ${props.styles}
  `}
`;

const Loading = styled.div`
  color: white;
  margin: auto;
`

const UpwineLogo = (props) => {
  const appState = React.useContext(AppStateContext);
  const currentWine = Tasting.getType() === "live" ? appState.state?.get("currentWine") : props.params.image;
  const visibility = props.params.visibility ? props.params.visibility.visible : currentWine?.visible;

  return (
    <Wrapper params={props.params}>

      {
        currentWine?.product?.image ? (
          <Inner
            id={"wineImage"}
            styles={visibility || props.params.instructor ? "" : "max-width: 20vh; width:20vh; height:auto;"}
            src={visibility || props.params.instructor ? currentWine?.product?.image : UnknownProduct} />
        ) : (
          <Loading>Loading...</Loading>
        )
      }

    </Wrapper>
  );
};

export default UpwineLogo;
