import React from "react";
import { nanoid } from "nanoid";
import AppStateContext from "appState/AppStateContext";
import styled from "styled-components";

import PcIcon from "assets/images/icons/ic_pc.png";
import MobileIcon from "assets/images/icons/ic_mobile.jpg";
import TabletIcon from "assets/images/icons/ic_tablet.png";
import Element from "../Element";
import MicClosedIcon from "assets/images/icons/ic_mic_off_white.svg";
import MicOpenIcon from "assets/images/icons/ic_mic_on_white.svg";
import LoadingIcon from "assets/images/icons/ic_loading_white.svg";
import Websockets from "Websockets/Websockets";
import Colours from "assets/custom/Custom";
import ControlTrad from "assets/trad/ControlTrad";

const Wrapper = styled.div`
  ${(props) => `
  width:100%;
  height:100%;
  background: rgb(243, 241, 241);
  display: flex;
  overflow-x:auto;
  ${props.params.styles}
`}
`;

const Table = styled.div`
  flex: 1;
  margin: 0 2vh;
  font-size: 2.3vh;
  border-collapse: collapse;
  @media (max-width: 426px) {
    margin: 0 1vh;
  }
`;


const Icon = styled.img`
  height: 3vh;
  padding :  0 2vh;
  @media (max-width: 426px) {
    padding :  0 1vh;
  }
`;

const JitsiStateIcon = styled.img`
  ${(props) => `
  height: 2.5vh;
  width: 2.5vh;
  padding: 0.5vh;
  border-radius: 50%;
  background: ${Colours.getColour("mainColor")};
  cursor: pointer;
  opacity: ${props.active ? "1" : "0.4"};
  @media (max-width: 426px) {
    display:none;
  }
`}
`;
const AssistantRows = styled.div`
  ${(props) => `
  margin-bottom: 0.5vh;
  margin-top: 0.5vh;
  display: flex;
  padding: 1vh;
  justify-content:flex-start;
  background:${props.assistant?.jitsi?.speakRequest
      ? "rgba(174, 28, 56, 0.8)"
      : "transparent"
    }
`}
@media (max-width: 426px) {
  padding: 1vh 0;
}
`;

const Row = styled.div`
    ${props =>`
    display:flex;
    align-items:center;
    ${props?.styles}
    `}
`

const RowText = styled.span`
    ${props =>`
    width:25vh;
    max-width:25vh;
    margin-right:1vh;
    display:flex;
    align-items:center;
    justify-content:flex-start;
    flex-direction:row;
    @media (max-width: 426px) {
      max-width:20vh;
    }
    ${props.styles}
    `}
`

const AssistantListFinished = (props) => {
  const appState = React.useContext(AppStateContext);
  let assistants = appState.state.get("assistants");
  const jitsiActive = appState.state.get("jitsiActive");


  /** Returns the device icon
   *
   * @param {String} assistantDevice
   * @returns
   */
   const getDeviceType = (assistantDevice) => {
    //const isMobile = /IEMobile|Windows Phone|Lumia/i.test(ua) || /iPhone|iP[oa]d/.test(ua) && !window.MSStream || /Android/.test(ua) || /BlackBerry|PlayBook|BB10/.test(ua) || /Mobile Safari/.test(ua) || /webOS|Mobile|Tablet|Opera Mini|\bCrMo\/|Opera Mobi/i.test(ua);
    //const isTablet = /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(ua);
    if (assistantDevice === "TABLET_WEB" || assistantDevice === "TABLET_APP") return TabletIcon;
    if ( assistantDevice === "MOBILE_WEB" || assistantDevice === "MOBILE_APP") return MobileIcon;
    return PcIcon;
  };

  const audios = [
    { sound: "https://upwine.es/audios/instructor-micro-pedido.mp3", label: 'audioInstructorMic'},
  ]

  const playSound = (src) => {
    const sound = new Audio(src);
    sound.load();
    sound.play();
  }


  const loadAssistants = () => {
    let views = [];

    for (let key in assistants) {
      if(assistants[key].jitsi?.speakRequest) playSound(audios[0].sound);
      views.push(
        <AssistantRows key={nanoid()} assistant={assistants[key]}>
          {loadRow(assistants[key], key)}
        </AssistantRows>
      );
    }
    return views;
  };

  const toggleAssistantPermission = (id) => {
    Websockets.send("JITSI_GIVE_PERMISSION", [id],true);
  };
  const loadRow = (assistant, key) => {
    let views = [];
    if(assistant.name !== "Desconocido"){
      views.push(
        <Row>
          <td key={nanoid()}>
            {(jitsiActive) ? 
            !assistant.jitsi?.ready ? (
              <JitsiStateIcon src={LoadingIcon} active={false} />
            ) : (
              <JitsiStateIcon
                src={
                  assistant.jitsi?.permissionGiven ? MicOpenIcon : MicClosedIcon
                }
                active={assistant.jitsi?.permissionGiven}
                onClick={() => {
                  toggleAssistantPermission(key);
                }}
              />
            ) : <></>}
          </td>
          <div style={{width:'7vh'}} key={nanoid()}>
            <Icon src={getDeviceType(assistant.platform)}></Icon>
          </div>
          <RowText key={nanoid()}>{assistant.name}</RowText>
        </Row>
      );
      views.push(
        <Row>
          {loadState(assistant.state)}
        </Row>
      );
    }

    return views;
  };

  const loadState = (state) =>{
    return ControlTrad.getTrad(state)
  }

  return (
    <Element params={props.params}>
      <Wrapper params={props.params}>
        <Table>
          {loadAssistants()}
        </Table>
      </Wrapper>
    </Element>
  );
};

export default AssistantListFinished;
