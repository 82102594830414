import React, { useEffect } from "react";
import styled, { keyframes } from "styled-components";

import Element from "../Element";
import AppStateContext from "appState/AppStateContext";
import Tasting from "assets/tasting/Tasting";
import Custom from "assets/custom/Custom";

const Wrapper = styled.div`
    ${props =>`
        display:${props.active ? "block" : "none"};
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        color:white;
        ${props.styles}
    `}
`
const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}`;
const Animation = styled.div`
    display: inline-block;
    position: relative;
    width: 20vh;
    height: 20vh;
    margin: auto;
    div {
      box-sizing: border-box;
      display: block;
      position: absolute;
      width: 15vh;
      height: 15vh;
      margin: 2vh;
      border: 2vh solid ${Custom.getColour("mainColor")};
      border-radius: 50%;
      animation: ${spin} 1s cubic-bezier(0.5, 0, 0.5, 1) infinite;
      border-color: ${Custom.getColour("mainColor")} rgba(216,216,216,0.3) rgba(216,216,216,0.3) rgba(216,216,216,0.3);
    }
  `;
//animation: 1s ${spin} linear infinite;


const LoadingComponent = (props) => {

    const appState = React.useContext(AppStateContext);
    const state = appState?.state.get("state");

    useEffect(() => {
        if (Tasting.getType() === "live") props.params?.ready();
    }, [state])

    return (
        <Element params={props.params}>
        <Wrapper active={props.params.active} styles={props.params.styles} >
                <Animation>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </Animation>
            </Wrapper>
        </Element>

    );
};

export default LoadingComponent;
