
import React from "react";
import { Keyboard } from 'react-native';
import ControlTrad from "assets/trad/ControlTrad";
import styled from "styled-components";

const Comment = styled.input`
        border-radius: 1vh;
        background-color: rgba(216,216,216,0.5);
        padding: 2vh 0vh;
        text-align: center;
        color: #fff;
        resize: none;
        border: 0;
        height: 4vh;
        font-size: 4vh;
        width:50%;
        overflow: hidden;
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
        @media (max-width: 426px) {
            width: 80%;
            font-size: 1.5rem;
            height: 2rem;
            padding: 1rem 1.5rem;
          }

        ::-webkit-input-placeholder {
            color: rgba(255, 255, 255, 0.7);
            font-size: 4vh;
            @media (max-width: 426px) {
                font-size: 1.5rem;
            }
            @media (min-width: 426px) and (pointer: coarse) and (orientation: landscape) {
                font-size: 1.7rem;
            }
        }
        @media (min-width: 426px) and (pointer: coarse) {
            width: 80%;
        }
        @media (min-width: 426px) and (pointer: coarse) and (orientation: landscape) {
            width: 50%;
            font-size: 1.7rem;
            height: 2rem;
            padding: 1.25rem 1.5rem;
        }

        
    `
const H1 = styled.div`
    font-size: 3vh;
    color: #fff;
    margin: 0 auto 1vh auto;
    @media (max-width: 426px) {
        width:100%;
        font-size: 1.2rem;
        margin: 0 auto 0.5rem auto;

      }
    @media (min-width: 426px) and (pointer: coarse) and (orientation: landscape) {
        width:100%;
        font-size: 1.4rem;
        margin: 0 auto 0.5rem auto;
    }
    
    `
const Error = styled.div`
visibility : hidden;
`

const H2 = styled.div`
    font-size: 2vh;
    color: #fff;
    margin: 0 auto 1vh auto;
    @media (max-width: 426px) {
        font-size: 1rem;
    }
`

const Input = (props) => {
    return (
        <>
            <div>
                <H1>{props.title}</H1>
                <Comment
                    id={props.id}
                    max={15}
                    value={props?.value}
                    maxLength={15}
                    placeholder={props.placeholder}
                    onKeyDown={(e) => {
                        if (e.code === "Enter" || e.keyCode === 13 || e.which === 13) {
                            Keyboard.dismiss();
                            e.target.blur();
                        }
                    }}
                    onBlur={props.onBlur} />
            </div>
            <Error id={props.id + "error"}>
                <H2>{ControlTrad.getTrad("inputLobbyError")}</H2>
            </Error>
        </>
    );

}
export default Input;