import React, { useEffect, useState } from "react";
import Element from "../Element";
import Button from "components/Shared/Button"
import styled from "styled-components";
import ModalMessage from "components/Sandbox/ModalMessage";
import AppStateContext from "appState/AppStateContext";
import Websockets from "Websockets/Websockets";

const ShowMessagesButton = (props) => {
    const [popUp1, setPopUp1] = useState(false)
    const [newDoubts, setNewDoubts] = useState(0)
    const [newDoubtsNoReaded, setNewDoubtsNoReaded] = useState(0)

    const appState = React.useContext(AppStateContext);
    const doubts = appState.state.get("doubts");

    const WrapperModal = styled.div`
        width :100%;
        height : 34vh;
        overflow: auto;
        flex-direction: column;
        display: flex;
    `;

    const Doubts = styled.div`
        display:flex;
        flex-direction : column;
        text-align:left;
        padding : 1vh 1vh;
        margin : 1vh 2vh;
        border-radius: 1vh;
        background-color: rgb(171 171 171);
    `;

    const DoubtsReaded = styled.div`
        display:flex;
        flex-direction : column;
        text-align:left;
        padding : 1vh 1vh;
        margin : 1vh 2vh;
        border-radius: 1vh;
        background-color: white;
        opacity: 0.5;
    `;

    const audios = [
        { sound: "https://upwine.es/audios/instructor-pregunta.mp3", label: 'audioInstructorDoubt' },
    ]

    const playSound = (src) => {
        const sound = new Audio(src);
        sound.load();
        sound.play();
    }

    Object.filter = (obj, predicate) => 
    Object.keys(obj)
          .filter( key => predicate(obj[key]) )
          .reduce( (res, key) => (res[key] = obj[key], res), {} );

    const openDoubts = () => {
        setPopUp1(true)
    }

    const loadQuestions = () => {
        let row = []
        if(doubts){
            let orderDoubts = Object.keys(doubts).sort(function ( a, b ) { return b - a; })
            if (doubts !== null) {
                if (Object.keys(doubts).length !== newDoubts) {
                    playSound(audios[0].sound);
                    setNewDoubts(Object.keys(doubts).length);
                }
            }
            orderDoubts.forEach(element => {
                doubts[element].readed ?
                    row.push(
                        <DoubtsReaded>
                            <div>{doubts[element].user} - {new Date(doubts[element].date).getHours() < 10 ? "0" + new Date(doubts[element].date).getHours() : new Date(doubts[element].date).getHours()}:{new Date(doubts[element].date).getMinutes() < 10 ? "0" + new Date(doubts[element].date).getMinutes() : new Date(doubts[element].date).getMinutes()}</div>
                            <div>{doubts[element].message}</div>
                        </DoubtsReaded>
                    )
                    :
                    row.push(
                        <Doubts>
                            <div>{doubts[element].user} - {new Date(doubts[element].date).getHours() < 10 ? "0" + new Date(doubts[element].date).getHours() : new Date(doubts[element].date).getHours()}:{new Date(doubts[element].date).getMinutes() < 10 ? "0" + new Date(doubts[element].date).getMinutes() : new Date(doubts[element].date).getMinutes()}</div>
                            <div>{doubts[element].message}</div>
                        </Doubts>
                    )
            });
        }
        
        return row;
    }

    useEffect(()=>{
        if(doubts) setNewDoubtsNoReaded(Object.keys(Object.filter(doubts,doubt => doubt.readed === false)).length)
    },[doubts])

    return (
        <>
            <Element params={props.params}>
                <Button active={doubts?.length > 0} value={props.params.text} badge={newDoubtsNoReaded} onClick={() => { openDoubts() }} />
            </Element>
            <ModalMessage 
                isShown={popUp1}
                setIsShown={setPopUp1}
                title={"Preguntas: " + doubts?.length}
                boxSize={"height : 40vh "} 
                action={() => { Websockets.send("READ_DOUBTS", [], true) }}>
                <WrapperModal>
                    {loadQuestions()}
                </WrapperModal>

            </ModalMessage>
        </>

    );
};

export default ShowMessagesButton;
