import React from "react";

import styled from "styled-components";
import AppStateContext from "appState/AppStateContext";
import ControlTrad from "assets/trad/ControlTrad";

const Wrapper = styled.div`
color: white;
font-size: 2vh;
border-radius:1vh;
height: 100%;
width: 100%;
background-color:black;
padding : 1vh 0 0;
`;

const NumQuestions = (props) => {

    const appState = React.useContext(AppStateContext);
    let numQuestions = appState.state.get("quizNumQuestions");
    let quizIndexQuestion = appState.state.get("quizIndexQuestion");

    return (
        <Wrapper params={props.params}> 
            {ControlTrad.getTrad("question")} 
            {quizIndexQuestion+1} 
            {ControlTrad.getTrad("of")} 
            {numQuestions}
        </Wrapper>
    );

}
export default NumQuestions;