let sliderComponents = 
[
    {
      id:"QUALITY_PRICE_SIMPLE",
      name: {
        EN: "Rate quality/price",
        ES: "Relación calidad/precio",
        CA: "Relacio qualitat/preu",
      },
      type: "OPTIONS_SLIDER",
      options:[
        {
          id:"OPTION_1_QUALITY_PRICE_SIMPLE",
          name:{
            EN: "Very bad",
            ES: "Muy mala",
            CA: "Molt dolent"
          }
        },
        {
          id:"OPTION_2_QUALITY_PRICE_SIMPLE",
          name:{
            EN: "Bad",
            ES: "Mala",
            CA: "Dolent"
          }
        },
        {
          id:"OPTION_3_QUALITY_PRICE_SIMPLE",
          name:{
            EN: "Correct",
            ES: "Correcto",
            CA: "Correcta"
          }
        },
        {
          id:"OPTION_4_QUALITY_PRICE_SIMPLE",
          name:{
            EN: "Good",
            ES: "Buena",
            CA: "Bona"
          }
        },
        {
          id:"OPTION_5_QUALITY_PRICE_SIMPLE",
          name:{
            EN: "Very good",
            ES: "Muy buena",
            CA: "Molt bona"
          }
        }
      ]
    },
    {
      id:"PRODUCT_KNOWLEDGE",
      name: {
        EN: "Have you tried it before?",
        ES: "¿Lo habías probado anteriormente?",
        CA: "Ho havies provat anteriorment?",
      },
      type: "OPTIONS_SLIDER",
      options:[
        {
          id:"OPTION_1_PRODUCT_KNOWLEDGE_SIMPLE",
          name:{
            EN: "No",
            ES: "No",
            CA: "No"
          }
        },
        {
          id:"OPTION_2_PRODUCT_KNOWLEDGE_SIMPLE",
          name:{
            EN: "Maybe",
            ES: "Quizá",
            CA: "Potser"
          }
        },
        {
          id:"OPTION_3_PRODUCT_KNOWLEDGE_SIMPLE",
          name:{
            EN: "Yes",
            ES: "Sí",
            CA: "Si"
          }
        }
      ]
    },
    {
      id:"FUTURE_PURCHASE",
      name: {
        EN: "Will you buy it?",
        ES: "¿Lo comprarás?",
        CA: "El compraràs?",
      },
      type: "OPTIONS_SLIDER",
      options:[
        {
          id:"OPTION_1_FUTURE_PURCHASE_SIMPLE",
          name:{
            EN: "No",
            ES: "No",
            CA: "No"
          }
        },
        {
          id:"OPTION_2_FUTURE_PURCHASE_SIMPLE",
          name:{
            EN: "Maybe",
            ES: "Quizá",
            CA: "Maybe"
          }
        },
        {
          id:"OPTION_3_FUTURE_PURCHASE_SIMPLE",
          name:{
            EN: "Yes",
            ES: "Sí",
            CA: "Si"
          }
        }
      ]
    }
]

export default sliderComponents;