import React from "react";
import Element from "../Element";
import Button from "components/Shared/Button"

const SkipReportButton = (props) => {

    return (
        <Element params={props.params}>
            <Button value={props.params.text} 
                type = {props.params.type}
                styles = {props.params.styles}
                onClick={props.params.onClick}/>
        </Element>
    );
};

export default SkipReportButton;
