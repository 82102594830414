import React, { useEffect, useState } from "react";
import styled from "styled-components";

import Element from "../Element";
import QuizOption from "./QuizOption";
import Websockets from "Websockets/Websockets"
import AppStateContext from "appState/AppStateContext";
import Tasting from "assets/tasting/Tasting";

const Options = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
`;
const Option = styled.div`
  padding: 2vh 0;
  flex-grow: 1;
`;

const QuizOptions = (props) => {
  const [optionSelect, setOptionSelect] = useState(null);
  const [initControl, setInitControl] = useState(false);


  const audios = [
    { sound: "https://upwine.es/audios/sound_quiz_button_click.mp3", label: 'audioClick' },
    { sound: "https://upwine.es/audios/sound_quiz_question.mp3", label: 'audioStart' },
    { sound: "https://upwine.es/audios/sound_tic_tac.mp3", label: "audioTicTac" },
    { sound: "https://upwine.es/audios/sound_quiz_correct.mp3", label: "audioCorrect" },
    { sound: "https://upwine.es/audios/sound_quiz_wrong.mp3", label: "audioIncorrect" }
  ]


  const playSound = (src) => {
    const sound = new Audio(src);
    sound.load();
    sound.play();
  }

  const appState = React.useContext(AppStateContext);
  let state, timer, quizOptions, questionReference, correct;
  if (Tasting.getType() === "live") {
    state = appState.state.get("state");
    timer = appState.state.get("getQuizInfo")?.quizTime;
    quizOptions = appState.state.get("quizCurrentQuestion")?.options;
    questionReference = appState.state.get('quizActualQuestionReference');
    correct = appState.state.get("quizCurrentQuestion")?.correct[0];
  } else {
    timer = props.params.timer;
    quizOptions = props.params.options;
    state = props.params.state
    correct = props.params?.correct;
  }

  const selectOption = (key) => {
    if (timer > 0 && props.params.state !== "QUIZ_CORRECTION") {
      playSound(audios[0].sound);
      setOptionSelect(key);
      if (Tasting.getType() === "live") {
        Websockets.send('SLAVE_QUIZ_ANSWER', [questionReference, key], false);
      }else{
        props.params.setOption(key);
        props.params.onClick();
      }
    }
  };

  const loadQuestions = () => {
    let row = [];
    let index = 0;
    let correctControl = false
    for (let key in quizOptions) {
      let option = quizOptions[key];
      if (correct === key && timer === 0) {
        correctControl = true;
      }
      row.push(
        <Option
          onClick={(e) => {
            selectOption(key);
          }}
        >
          <QuizOption
            index={index++}
            option={option}
            isOnTime={timer > 0}
            selected={Tasting.getType() === 'live' ? optionSelect === key : props.params.option === key}
            correct={correct === key}
            state={state}
          />
        </Option>
      );
    }

    return row;
  };

  useEffect(() => {
    if (state === "QUIZ_CORRECTION") {
      if (optionSelect === correct || props.params.option === correct) {
        playSound(audios[3].sound);

      } else if (optionSelect !== correct || props.params.option !== correct) {
        playSound(audios[4].sound);

      }
      if (Tasting.getType() !== "live") {
        setTimeout(() => {
          props.params.onClick();
        }, 3000);
      }
    }
  }, [state])

  useEffect(() => {
    if (!initControl && state !== "QUIZ_CORRECTION") {
      setInitControl(true);
      playSound(audios[1].sound);
    }
    if (timer === 4) {
      playSound(audios[2].sound);
    }
  }, [timer]);

  return (
    <Element params={{ ...props.params, styles: "padding: 2vh 0;" }}>
      <Options>{loadQuestions()}</Options>
    </Element>
  );
};
export default QuizOptions;
