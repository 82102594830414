import React, { useEffect, useState } from "react";
import MutableComponent from "../Composer/MutableComponent";
import AppStateContext from "appState/AppStateContext";
import useOrientation from "orientation/Orientation"
import Websockets from "Websockets/Websockets";
import assistantBackground from "../../assets/images/background_assistant.png";
import PlayVideoIcon from "../../assets/images/play-white.svg";
import PlayQuizIcon from "../../assets/images/quiz_icon.svg";
import Colours from "assets/custom/Custom";
import ControlTrad from "assets/trad/ControlTrad";
import QuizWinnerModal from "components/Composer/Elements/QuizWinnerModal/QuizWinnerModal";
import User from "assets/user/User";
import defaultSimpleSurveySliderComponents from "assets/layouts/simple_template/default_survey";
import Layouts from "assets/layouts/layouts";
import Tasting from "assets/tasting/Tasting";
import SoundUtils from "assets/utils/SoundUtils";
import Fetch from "Fetch/Fetch";
import Toast from "components/Sandbox/Toast";
import { completeUrl } from "assets/utils/DeviceUtils";
import Custom from "assets/custom/Custom";

const Wrapper = {
    background: "linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.8)), url(\"" + assistantBackground + "\") no-repeat center",
    backgroundSize: 'cover',
    backgroundPosition: '85%',
    zIndex: '-1',
    overflow: 'hidden'
}

const AssistantComponent = (data) => {
    let tasting = data?.data;
    const appState = React.useContext(AppStateContext);
    const state = appState.state.get("state");
    const quizFinal = appState.state.get("quizIsFinal");
    const currentWineRef = appState.state.get("currentWine")?.reference;
    const landscapeScreen = useOrientation() ? "landscape" : "portrait";

    let layoutType;
    if (Tasting.getType() === "live") {
        layoutType = appState.state.get("tastType") ? appState.state.get("tastType") : "CONVENTIONAL_TAST";
        if (layoutType === "CONVENTIONAL_TEMPLATE") layoutType = "CONVENTIONAL_TAST";
    } else {
        layoutType = tasting?.tasting?.template ? tasting?.tasting?.template : "CONVENTIONAL_TAST";
        if (layoutType === "CONVENTIONAL_TEMPLATE") layoutType = "CONVENTIONAL_TAST";
    }

    let initialState;
    if (Tasting.isPodLite()) {
        initialState = "WAITING";
    } else if (Tasting.getType() == "live" && User.getName() !== null) {
        initialState = state;
    } else {
        initialState = "INITIAL";
    }

    const [currentState, setCurrentState] = useState(initialState);
    const [currentDisplay, setCurrentDisplay] = useState(0);
    const [highligthedComp, setHighligthedComp] = useState(null);
    const [popUpError, setPopUpError] = useState(false);
    const [popUpDisableError, setPopUpDisableError] = useState(false);

    const [loaded, setLoaded] = useState(true);
    const [textError, setTextError] = useState(ControlTrad.getTrad("errorPopUpText"))
    const [titleError, setTitleError] = useState(ControlTrad.getTrad("errorPopUpTitle"))
    const [quizWinnerModal, setQuizWinnerModal] = useState(false);
    const [toastData, setToastData] = useState({ visible: false });
    const [wineRecommendation, setWineRecommendation] = useState(null);

    const [currentProdIndex, setCurrentProdIndex] = useState(0);
    const [currentAssessmentCategory, setCurrentAssessmentCategory] = useState("");
    const [currentProductData, setCurrentProductData] = useState({});

    const [currentProductQuiz, setCurrentProductQuiz] = useState(0);
    const [currentProductQuizOp, setCurrentProductQuizOp] = useState("");
    const [currentProductQuizTime, setCurrentProductQuizTime] = useState(25);
    const [visibility, setVisibility] = useState(!(layoutType === "BLIND_TEMPLATE"));

    let currentProductReference = tasting?.tasting?.productReferences?.[currentProdIndex];
    let currentProductReferenceRef = currentProductReference?.reference;
    let timeOutFailedQuiz = null;

    Websockets.setError = setPopUpError;
    Websockets.setMessageError = setTextError;
    Websockets.setTitleError = setTitleError;

    SoundUtils.preload("startTasting");
    SoundUtils.preload("finishTasting");

    const enableFullScreen = () => {
        if (Tasting.getType() === "live" && appState?.state?.get("jitsiActive") && Tasting.isStreamingAllowed()) {
            requestPermissions();
        } else {
            requestFullScreen();
        }
    }

    const requestPermissions = () => {
        try {
            console.log("Requesting Permissions");
            let permisions = ({
                video: true,
                audio: true
            });

            navigator.getUserMedia(permisions,
                (localMediaStream) => {
                    console.log("Permissions Request Accepted", localMediaStream);
                    requestFullScreen();
                },
                (err) => {
                    console.error("Error requesting permissions", err);
                    requestFullScreen();
                }
            );
        } catch (e) {
            console.error("Error requesting permisions", e);
            requestFullScreen();
        }
    }

    function requestFullScreen() {
        try {
            console.log("Requesting Fullscreen");
            let elem = document.documentElement;
            if (elem.requestFullscreen) {
                elem.requestFullscreen();
            } else if (elem.mozRequestFullScreen) {
                elem.mozRequestFullScreen();
            } else if (elem.webkitRequestFullscreen) {
                elem.webkitRequestFullscreen();
            } else if (elem.msRequestFullscreen) {
                elem.msRequestFullscreen();
            }
        } catch (e) {
            console.error("Error requesting fullscreen", e);
        }
    }

    const changeState = () => {
        if (state) {
            setCurrentDisplay(0);
            setCurrentState(state);
            setPopUpError(false)
        } else {
            setPopUpError(true);
            //here goes the differents texts for the popUp error (WIP).
            setTextError(ControlTrad.getTrad("errorPopUpText"))
            setTitleError(ControlTrad.getTrad("errorPopUpTitle"))
        }
        //console.log("request_state", appState.state.get("state"))
    }

    const controlQuizNav = () => {
        setCurrentProductQuizTime(30);
        if (currentProductQuizOp === data.data.tasting?.productReferences[currentProdIndex].quizQuestions[currentProductQuiz]?.correct[0]) {
            if (data.data.tasting?.productReferences[currentProdIndex].quizQuestions.length !== currentProductQuiz + 1) {
                let question = currentProductQuiz;
                setCurrentProductQuiz(question + 1);
                setCurrentState("QUIZ_QUESTION");
            } else {
                setCurrentState("QUIZ_SCORE_YES");
                setCurrentProductQuiz(0);
                //setQuizWinnerModal(true);
            }
        } else {
            setCurrentState("QUIZ_SCORE_NO");
            setCurrentProductQuiz(0);
        }
        setCurrentProductQuizOp("");
    }

    const wineRecomended = () => {
        const options = [
            { id: "Pendiente de valorar", value: 0, text: ControlTrad.getTrad("rateForPriceNo") },
            { id: "Muy buena", value: 5, text: ControlTrad.getTrad("rateForPriceVeryGood") },
            { id: "Buena", value: 4, text: ControlTrad.getTrad("rateForPriceGood") },
            { id: "Regular", value: 3, text: ControlTrad.getTrad("rateForPriceRegular") },
            { id: "Mala", value: 2, text: ControlTrad.getTrad("rateForPriceBad") },
            { id: "Muy mala", value: 1, text: ControlTrad.getTrad("rateForPriceVeryBad") },
        ];
        let lastRate = 0, lastRatePrice = options[0].value, wineReference = "", i = 0, randomProduct;
        if (layoutType === "SIMPLE_TEMPLATE") {
            randomProduct = Math.floor((Math.random() * (tasting?.tasting?.productReferences.length)));
        }
        tasting?.tasting?.productReferences.forEach(element => {
            if (layoutType === "SIMPLE_TEMPLATE") {
                if (i === randomProduct) {
                    if (element?.recommendationPath) {
                        wineReference = element.recommendationPath;
                    }
                }
            } else {
                let changeWineRecomended = false;
                if (lastRate < currentProductData?.[element.reference]?.rateBox) {
                    changeWineRecomended = true;
                    lastRate = currentProductData?.[element.reference]?.rateBox;
                }
                let optionValue = options.find(option => currentProductData?.[element.reference]?.ratePrice === option.id)?.value;
                if (lastRatePrice < optionValue) {
                    changeWineRecomended = true;
                    lastRatePrice = optionValue;
                }
                if (changeWineRecomended) {
                    if (element?.recommendationPath) {
                        wineReference = element.recommendationPath;
                    }
                }
            }
            i++;
        });
        if (wineReference === "") {
            setCurrentDisplay(2);
            setCurrentState("FINISHED");
        } else {
            let userID = wineReference.split("/")[1]
            wineReference = wineReference.split("/")[3];
            Fetch.getRecommendation(userID, wineReference).then((wine) => {
                if (wine?.error) {
                    setCurrentDisplay(2);
                    setCurrentState("FINISHED");
                }
                setWineRecommendation(wine)
                setCurrentDisplay(0);
                setCurrentState("FINISHED");
            })
        }

    }

    const getVisibility = () => {
        if (currentProductData) {
            if (currentProductData[tasting?.tasting.productReferences[currentProdIndex].product.reference]) {
                if (currentProductData[tasting?.tasting.productReferences[currentProdIndex].product.reference].visibility)
                    return currentProductData[tasting?.tasting.productReferences[currentProdIndex].product.reference].visibility
            }
        }

        return !(layoutType === "BLIND_TEMPLATE");
    }

    const getLayoutState = () => {
        let layout = Layouts[Tasting.getType()][currentState][landscapeScreen];
        switch (currentState) {
            case "INITIAL":
                return getLayoutInitial(layout);
            case "WAITING":
                return getLayoutWaiting(layout);
            case "IN_PROGRESS":
                return getLayoutInProgress(layout);
            case "QUIZ_PRESENTATION":
                return getLayoutQuizPresentation(layout);
            case "QUIZ_QUESTION":
                return getLayoutQuizQuestion(layout);
            case "QUIZ_CORRECTION":
                return getLayoutQuizCorrect(layout);
            case "QUIZ_GRAPH":
                return getLayoutQuizGraph(layout);
            case "QUIZ_SCORE":
            case "QUIZ_SCORE_YES":
            case "QUIZ_SCORE_NO":
                return getLayoutQuizScore(layout);
            case "FINISHED":
                return getLayoutFinished(layout);

        }
        console.log(layout);
    }

    const getLayoutInitial = (layout) => {
        let screen = [
            [
                ["form", {
                    position: layout[currentDisplay]?.form?.position,
                    errorModal: true,
                    activeNext: Tasting.getType() === "podcast" ? tasting : true,
                    textBuitton: ControlTrad.getTrad("next"),
                    elements: [
                        {
                            id: "inputUserInfo",
                            element: 'input',
                            title: ControlTrad.getTrad("yourName"),
                            placeholder: ControlTrad.getTrad("insertName"),
                            value: User.getNotDefaultName(),
                            required: true
                        },
                        {
                            id: "selectOpAge",
                            element: 'select',
                            title: ControlTrad.getTrad("age"),
                            options: ["18-26", "27-36", "37-48", "49-60", "61-69", "70+"],
                            optionsStyle: "min-width: 3.5rem",
                            value: User.getAge(),
                            required: true
                        },
                        {
                            id: "selectOpGender",
                            element: 'select',
                            title: ControlTrad.getTrad("gender"),
                            options: [ControlTrad.getTrad("man"), ControlTrad.getTrad("woman"), ControlTrad.getTrad("other")],
                            value: User.getGender(),
                            required: false

                        },
                        {
                            id: "termsConditions",
                            element: 'terms',
                            required: true
                        }
                    ],
                    onClick: () => {
                        setCurrentDisplay(1);
                    },
                }],
            ],
            [
                ["initialSurvey", {
                    position: layout[currentDisplay]?.initialSurvey?.position,
                    onNextView: () => {
                        if (Tasting.getType() === "live") {
                            setCurrentDisplay(2);
                        } else {
                            setCurrentDisplay(0);
                            setCurrentState("WAITING");
                        }
                    }
                }]
            ],
            [
                ['jitsiConfiguration', {
                    position: layout[currentDisplay]?.jitsiConfiguration?.position,
                    landscapeScreen: landscapeScreen,
                    next: () => {
                        enableFullScreen();
                        changeState();
                    }
                }]
            ]
        ]


        return screen[currentDisplay];

    }
    const getLayoutWaiting = (layout) => {
        //VISTA GENERAL
        //TODOS LOS COMPONENTES INTRODUCCIDOS AQUI, SE VERAN EN LA PANTALLA, SIN CONDICIONES
        let screen = [
            [
                ["rectangle", {
                    position: layout[currentDisplay]?.rectangle?.position,
                    styles: layout[currentDisplay]?.rectangle?.styles
                },
                ],
                ["upwineLogo", { position: layout[currentDisplay]?.upwineLogo?.position }],


                ["text", {
                    position: layout[currentDisplay]?.textDescriptionTitle?.position,
                    text: ControlTrad.getTrad("description"),
                    styles: 'text-align:center; font-size:2.5vh; font-weight:bold'
                }],

                //["text", { position: "47/3/47/30", text: data.data.instructor?.name}],
                ["text", {
                    position: layout[currentDisplay]?.textTimeTitle?.position,
                    text: ControlTrad.getTrad("duration"),
                    styles: 'text-align:center; font-size:2.5vh; font-weight:bold'
                }],
                ["text", {
                    position: layout[currentDisplay]?.textWinesToTasteTitle?.position,
                    text: ControlTrad.getTrad("winesToTaste"),
                    styles: 'text-align:center; font-size:2.8vh; font-weight:bold;'
                }],
            ]
        ]

        // VISTAS DIFERENTES POR ORIENTACIÓN Y TIPO DE CATA
        // AQUI SE APLICAN CONDICIONES PARA DIFERENCIAR PLANTILLAS, ORIENTACIONES, ETC...
        if (Tasting.getType() === "podcast") {
            screen[currentDisplay].push(
                ["text", {
                    position: layout[currentDisplay]?.textTitle?.position,
                    text: tasting?.tasting?.name.toUpperCase(),
                    styles: 'align-items: start; text-align:center; font-size:3.5vh;font-weight:600;overflow-y: auto;'
                }],
                ["text", {
                    position: layout[currentDisplay]?.textTime?.position,
                    text: tasting.tasting?.duration,
                    styles: 'text-align:center; font-size:2.5vh;'
                }],
                ["text", {
                    position: layout[currentDisplay]?.textDescription?.position,
                    text: tasting.tasting?.description,
                    styles: 'text-align:center; font-size:2.5vh; overflow-y: auto;'
                }],
                ["text", {
                    position: layout[currentDisplay]?.textWinesToTaste?.position,
                    text: tasting?.tasting?.productReferences.length,
                    styles: 'text-align:center; font-size:2.5vh;'
                }],
                ["button", {
                    position: layout[currentDisplay]?.buttonStart?.position,
                    text: ControlTrad.getTrad("startTast"),
                    highligthed: true,
                    timmerEnabled: Custom.Lobby.lobbySkipActive,
                    timmerTime: Custom.Lobby.lobbySkipTimeout, 
                    onClick: () => {
                        setCurrentState("IN_PROGRESS");
                        enableFullScreen();
                    }
                }],
            );
            if (landscapeScreen === "landscape") {
                screen[currentDisplay].push(
                    ["waitingLobby", { position: layout[currentDisplay]?.waitingLobby?.position }],
                    ["text", {
                        position: layout[currentDisplay]?.observationsText4?.position,
                        text: ControlTrad.getTrad("observationsText4"),
                        styles: 'text-align:center; font-size:3vh; font-weight:bold'
                    }],
                    ["text", {
                        position: layout[currentDisplay]?.observationsText5?.position,
                        text: ControlTrad.getTrad("observationsText5"),
                        styles: 'text-align:center; font-size:2.5vh; font-weight:bold'
                    }],
                    ["text", {
                        position: layout[currentDisplay]?.observationsText6?.position,
                        text: ControlTrad.getTrad("observationsText6"),
                        styles: 'text-align:center; font-size:2.5vh; font-weight:bold'
                    }],
                );
            }
        } else {
            screen[currentDisplay].push(
                ["tastingType", {
                    position: layout[currentDisplay]?.textTitle?.position,
                    styles: 'align-items: start; text-align:center; font-size:3.5vh;font-weight:600;overflow-y: auto;'
                }],
                ["tastingDescription", {
                    position: layout[currentDisplay]?.textDescription?.position
                }],
                ["tastingDuration", {
                    position: layout[currentDisplay]?.textTime?.position
                }],
                ["tastingWines", {
                    position: layout[currentDisplay]?.textWinesToTaste?.position
                }],

            );
            if (landscapeScreen === "landscape") {
                screen[currentDisplay].push(
                    ["waitingLobby", { position: layout[currentDisplay]?.waitingLobby?.position }],
                    ["chat", { position: layout[currentDisplay]?.chat?.position, chatMaxSize: 12 }],
                    ["observations", { position: layout[currentDisplay]?.observations?.position }],
                );
            } else {
                screen[currentDisplay].push(
                    ["responsiveChat", { position: layout[currentDisplay]?.chat?.position, isLobby: true }],

                )
            }
        }
        return screen[currentDisplay];
    }

    const getLayoutInProgress = (layout) => {
        //VISTA GENERAL
        let screen = [
            [],
            [
                ["rectangle", {
                    position: landscapeScreen === "portrait" ? "1/1/100/0" : "1/1/100/35",
                    background: "rgba(255, 255, 255, 0.1)"
                }],
                ["assessmentDisplay", {
                    position: showVideo && landscapeScreen === "portrait" ? "1/1/60/100" : "1/1/100/100",
                    currentProductRef: currentProductReferenceRef,
                    category: currentAssessmentCategory,
                    landscapeScreen: landscapeScreen,
                    categoryComponents: currentAssessmentCategory?.components,
                    categories: currentProductReference?.assessmentSheet?.categories,
                    currentData: currentProductData,
                    onSave: setCurrentProductData,
                    onCancelEvent: () => {
                        setCurrentDisplay(0);
                    },
                    onConfirmEvent: () => {
                        setCurrentDisplay(0);
                    },
                }],
            ],
            [
                ["loadingComponent", {
                    position: landscapeScreen === "portrait" ? "1/1/100/50" : "1/1/100/100",
                    active: true,
                    ready: () => { }
                }],
            ]
        ]

        if (Tasting.getType() === "podcast") {
            if (currentDisplay === 0) {
                screen[0].push(
                    ["wineName", {
                        position: layout[currentDisplay]?.wineName?.position,
                        name: currentProductReference,
                        visibility: { visible: visibility },
                        productType: ControlTrad.getUserTrad(currentProductReference.product.type.name),
                    }],
                    ["wineImage", {
                        position: layout[currentDisplay]?.wineImage?.position,
                        image: currentProductReference,
                        visibility: { visible: visibility },
                    }],
                    ["winePrice", {
                        position: layout[currentDisplay]?.[layoutType]?.winePrice?.position,
                        price: currentProductReference,
                        disable: layout[currentDisplay]?.[layoutType]?.winePrice?.hide,
                        visibility: { visible: visibility },
                        currency: tasting?.currency
                    }],
                    ["moreInfoButton", {
                        position: layout[currentDisplay]?.[layoutType]?.moreInfoButton?.position,
                        wine: currentProductReference.product,
                        visibility: { visible: visibility },
                    }],
                    ["navegationWine", {
                        position: layout[currentDisplay]?.[layoutType]?.navegationWine?.position,
                        current: currentProdIndex,
                        numWines: tasting?.tasting?.productReferences.length,
                        styles: "z-index:1;",
                        highligthed: highligthedComp === "navegationWine",
                        nextWine: () => {
                            let current = currentProdIndex;
                            setCurrentProdIndex(current + 1);
                            setVisibility(!(layoutType === "BLIND_TEMPLATE"))
                        },
                        backWine: () => {
                            let current = currentProdIndex;
                            setCurrentProdIndex(current - 1);
                            setVisibility(!(layoutType === "BLIND_TEMPLATE"))
                        },
                        finally: () => {
                            setCurrentDisplay(2);
                            wineRecomended();
                        }
                    }],
                    ["showProductInfoButton", {
                        position: layout[currentDisplay]?.[layoutType]?.showProductInfo?.position,
                        disable: layout[currentDisplay]?.[layoutType]?.showProductInfo?.hide,
                        podcastData: currentProductReference,
                        currentWineRef: currentProductReferenceRef,
                        productData: currentProductData[currentProductReferenceRef],
                        setDataWine: setCurrentProductData,
                        styles: "z-index:2;",
                        visibility: { visible: visibility },
                        setVisibility: setVisibility
                    }],
                );

                if (layoutType !== "SIMPLE_TEMPLATE") {
                    screen[0].push(
                        ["categoriesWrapper", {
                            position: layout[currentDisplay]?.[layoutType]?.categoriesWrapper?.position,
                            responsive: landscapeScreen === "landscape",
                            disable: layout[currentDisplay]?.[layoutType]?.categoriesWrapper?.hide,
                            currentData: currentProductData[currentProductReferenceRef],
                            categories: currentProductReference.assessmentSheet?.categories,
                            wineVideos: currentProductReference.podcastVideos,
                            currentProductRef: currentProductReferenceRef,
                            highligthedComp: highligthedComp,
                            setVideo: setCurrentProductVideo,
                            setCategory: setCurrentAssessmentCategory,
                            setDataWine: setCurrentProductData,
                            onFinishAllAssesments: () => {
                                addProductMetadata("allCategoriesRated", true);
                            },
                            onClick: () => {
                                setCurrentDisplay(1);
                            },
                        }]
                    );

                    if (landscapeScreen === "landscape") {
                        screen[0].push(
                            ["rectangle", {
                                position: layout[currentDisplay][layoutType]?.rectangle?.position,
                                styles: layout[currentDisplay][layoutType]?.rectangle?.styles
                            }],
                            //["upwineLogo", { position: layout[currentDisplay]?.upwineLogo?.position }],
                            ["wineIndex", {
                                position: layout[currentDisplay]?.wineIndex?.position,
                                current: currentProdIndex,
                                numWines: tasting?.tasting?.productReferences.length,
                                productType: ControlTrad.getUserTrad(currentProductReference.product.type.name),
                                visibility: { visible: visibility },
                            }],
                            ["rateBox", {
                                position: layout[currentDisplay][layoutType]?.rateBox.position,
                                disable: layout[currentDisplay][layoutType]?.rateBox.hide,
                                currentProductData: currentProductData,
                                rate: currentProductData[currentProductReferenceRef]?.rateBox || 0,
                                currentProductRef: currentProductReferenceRef,
                                setDataWine: setCurrentProductData
                            }],
                            ["priceRateBox", {
                                position: layout[currentDisplay][layoutType]?.priceRateBox.position,
                                disable: layout[currentDisplay][layoutType]?.priceRateBox.hide,
                                ratePrice: currentProductData?.[currentProductReferenceRef]?.ratePrice,
                                currentProductRef: currentProductReferenceRef,
                                setDataWine: setCurrentProductData
                            }],
                            ["blindPriceRateBox", {
                                position: layout[currentDisplay][layoutType]?.blindPriceRateBox.position,
                                disable: layout[currentDisplay][layoutType]?.blindPriceRateBox.hide,
                                blindPrice: currentProductData[currentProductReferenceRef]?.blindPrice,
                                currentProductRef: currentProductReferenceRef,
                                productData: currentProductData,
                                currency: tasting?.currency,
                                setDataWine: setCurrentProductData
                            }],
                            ["quizStart", {
                                position: layout[currentDisplay][layoutType]?.startQuiz.position,
                                disable: layout[currentDisplay][layoutType]?.startQuiz.hide,
                                highligthed: highligthedComp === "quizStart",
                                active: currentProductReference?.quizQuestions?.length !== 0,
                                used: getProductMetadata("quizPlayed"),
                                text: (
                                    <div style={{ display: "flex", justifyContent: "center" }}>
                                        <img src={PlayQuizIcon} />
                                        {ControlTrad.getTrad("startQuiz")}
                                    </div>
                                ),
                                onClick: () => {
                                    addProductMetadata("quizPlayed", true);
                                    if (tasting?.tasting?.productReferences?.[currentProdIndex]?.quizQuestions?.length !== 0) {
                                        setCurrentState("QUIZ_PRESENTATION")
                                    }
                                }
                            }],
                            ["button", {
                                position: layout[currentDisplay][layoutType]?.instructorValoration.position,
                                disable: layout[currentDisplay][layoutType]?.instructorValoration.hide,
                                highligthed: highligthedComp === "instructorValoration",
                                used: getProductMetadata("finalVideoPlayed"),
                                text: (
                                    <div style={{ display: "flex", justifyContent: "center" }}>
                                        <img src={PlayVideoIcon} />
                                        {ControlTrad.getTrad("seeInstructorValoration")}
                                    </div>
                                ),
                                onClick: () => {
                                    addProductMetadata("finalVideoPlayed", true);
                                    setCurrentProductVideo(currentProductReference?.podcastVideos?.["FINAL"]?.id)
                                }
                            }],
                            ["notes", {
                                position: layout[currentDisplay][layoutType]?.notes.position,
                                disable: layout[currentDisplay][layoutType]?.notes.hide,
                                currentProductData: currentProductData,
                                currentProductRef: currentProductReferenceRef,
                                setDataWine: setCurrentProductData
                            }],
                        );
                    } else {
                        screen[0].push(
                            ["WineIndexMobile", {
                                position: layout[currentDisplay]?.wineIndex?.position,
                                current: currentProdIndex,
                                numWines: tasting?.tasting?.productReferences.length
                            }],
                            ["responsiveRate", {
                                position: layout[currentDisplay][layoutType]?.responsiveRate?.position,
                                rate: currentProductData[currentProductReferenceRef]?.rateBox || 0,
                                ratePrice: currentProductData[currentProductReferenceRef]?.ratePrice,
                                blindPrice: currentProductData[currentProductReferenceRef]?.blindPrice,
                                currentProductRef: currentProductReferenceRef,
                                setDataWine: setCurrentProductData,
                                onClick: () => {
                                    addProductMetadata("finalVideoPlayed", true);
                                    setCurrentProductVideo(currentProductReference?.podcastVideos?.["FINAL"]?.id);
                                },
                                onClickQuiz: () => {
                                    if (currentProductReference?.quizQuestions?.length !== 0) {
                                        addProductMetadata("quizPlayed", true);
                                        setCurrentDisplay(0);
                                        setCurrentState("QUIZ_PRESENTATION");
                                    }
                                },
                                highligthed: highligthedComp === "instructorValoration",
                                containsQuiz: currentProductReference?.quizQuestions?.length !== 0,
                                playQuizHighlited: highligthedComp === "quizStart",
                                quizPlayed: getProductMetadata("quizPlayed"),
                                finalVideoHighlited: highligthedComp === "instructorValoration",
                                finalVideoPlayed: getProductMetadata("finalVideoPlayed"),
                                annotation: currentProductData,
                                setDataWine: setCurrentProductData,
                                layout: layoutType,
                                visibility: visibility,
                                currency: tasting?.currency,
                            }]
                        )
                    }
                }
            }
        } else {
            if (currentDisplay === 0) {
                screen[0].push(
                    ["wineName", { position: layout[currentDisplay]?.wineName?.position, }],
                    ["wineImage", { position: layout[currentDisplay]?.wineImage?.position, }],
                    ["winePrice", { position: layout[currentDisplay]?.[layoutType]?.winePrice?.position, }],
                    ["moreInfoButton", { position: layout[currentDisplay]?.[layoutType]?.moreInfoButton?.position, }],
                );

                if (layoutType !== "SIMPLE_TEMPLATE") {
                    screen[0].push(
                        ["categoriesWrapper", {
                            position: layout[currentDisplay]?.[layoutType]?.categoriesWrapper?.position,
                            responsive: landscapeScreen !== "landscape",
                            highligthedComp: highligthedComp,
                            disable: layout[currentDisplay][layoutType]?.categoriesWrapper.hide,
                            onClick: () => {
                                setCurrentDisplay(1);
                            },
                        }]
                    );

                    if (landscapeScreen === "landscape") {
                        screen[0].push(
                            //["upwineLogo", { position: layout[currentDisplay]?.upwineLogo?.position }],
                            ["wineIndex", { position: layout[currentDisplay]?.wineIndex?.position }],
                            ["rectangle", {
                                position: layout[currentDisplay][layoutType]?.rectangle?.position,
                                styles: layout[currentDisplay][layoutType]?.rectangle?.styles
                            }],
                            ["rateBox", {
                                position: layout[currentDisplay][layoutType]?.rateBox.position,
                                disable: layout[currentDisplay][layoutType]?.rateBox.hide,
                            }],
                            ["priceRateBox", {
                                position: layout[currentDisplay][layoutType]?.priceRateBox.position,
                                disable: layout[currentDisplay][layoutType]?.priceRateBox.hide,
                            }],
                            ["blindPriceRateBox", {
                                position: layout[currentDisplay][layoutType]?.blindPriceRateBox.position,
                                disable: layout[currentDisplay][layoutType]?.blindPriceRateBox.hide,
                            }],
                            ["notes", {
                                position: layout[currentDisplay][layoutType]?.notes.position,
                                disable: layout[currentDisplay][layoutType]?.notes.hide,

                            }],
                            ["chat", {
                                position: layout[currentDisplay][layoutType]?.chat.position,
                                disable: layout[currentDisplay][layoutType]?.chat.hide,
                                chatMaxSize: 11
                            }],
                        );
                    } else {
                        screen[0].push(
                            ["WineIndexMobile", { position: layout[currentDisplay]?.wineIndex?.position }],
                            ["responsiveRate", {
                                position: layout[currentDisplay][layoutType]?.responsiveRate?.position,
                                disable: layout[currentDisplay][layoutType]?.hide?.position,
                                layout: layoutType,
                            }],
                            ["responsiveAnnotations", { position: layout[currentDisplay][layoutType]?.responsiveAnnotations?.position }],
                            ["responsiveChat", {
                                position: layout[currentDisplay][layoutType]?.responsiveChat?.position,
                                backgroundColor: Colours.getColour("mainColor"),
                                isLobby: false
                            }],
                        );
                    }
                }
            }
        }

        //PLANTILLA SIMPLE
        if (layoutType === "SIMPLE_TEMPLATE" && currentDisplay === 0) {
            let currentWine, parameters
            if (Tasting.getType() === "live") {
                currentWine = currentWine
                parameters = appState.state.get("currentAssessmentParameters");
            } else {
                currentWine = tasting?.tasting?.productReferences[currentProdIndex];
                parameters = currentWine.assessmentSheet.parameters;
            }

            if (parameters?.length <= 0 || parameters === undefined) {
                let defaultFacesSurveyComponents = [
                    {
                        id: "FACE_1_SURVEY_SIMPLE",
                        name: {
                            ES: "¿Te ha gustado?",
                            EN: "Did you like it?",
                            CA: "T'ha agradat?"
                        },
                        type: "FACES"
                    }
                ]

                parameters = [];

                defaultSimpleSurveySliderComponents.forEach(component => {
                    parameters.push(component);
                })

                defaultFacesSurveyComponents.forEach(component => {
                    parameters.push(component)
                })

            }

            screen[0].push(
                ["simpleAssessmentDisplay",
                    {
                        position: valueIfVideoIsEnabeld(
                            layout[currentDisplay][layoutType]?.assessmentDisplay.position,
                            layout[currentDisplay][layoutType]?.assessmentDisplay.positionFull
                        ),
                        disabledValues: true,
                        disable: layout[currentDisplay][layoutType]?.assessmentDisplay.hide,
                        currentProductRef: currentProductReferenceRef,
                        category: currentAssessmentCategory,
                        components: parameters,
                        categories: tasting?.tasting?.productReferences[currentProdIndex].assessmentSheet.categories,
                        currentData: currentProductData,
                        onClickSave: true,
                        setCurrentProductData: setCurrentProductData,
                        onSave: setCurrentProductData,
                        onCancelEvent: () => {
                            setCurrentDisplay(0);
                        },
                        onConfirmEvent: () => {
                            setCurrentDisplay(0);
                        },
                    },
                ])
        }

        return screen[currentDisplay]
    }
    const getLayoutQuizPresentation = (layout) => {
        let screen = [
            [
                ["text",
                    {
                        position: layout[currentDisplay]?.welcomeQuizText.position,
                        text: ControlTrad.getTrad("welcomeQuiz"),
                        styles: layout[currentDisplay]?.welcomeQuizText.styles,
                    },
                ],
                ["text",
                    {
                        position: layout[currentDisplay]?.wineQuizText.position,
                        text: ControlTrad.getTrad("wineQuiz"),
                        styles: layout[currentDisplay]?.wineQuizText.styles,
                    },
                ],
                ["separator", {
                    position: layout[currentDisplay].separator.position,
                    lineStyles: layout[currentDisplay].separator.lineStyle
                }],

                ["text",
                    {
                        position: layout[currentDisplay].quizDescText.position,
                        text: Tasting.getType() === "podcast" ? ControlTrad.getTrad("quizDescPodcast") : ControlTrad.getTrad("quizDesc"),
                        styles: layout[currentDisplay].quizDescText.styles,
                    },
                ],

            ]
        ];

        if (landscapeScreen === "landscape") {
            screen[0].push(
                ["upwineLogo", { position: layout[currentDisplay]?.upwineLogo.position }]
            );
        }

        if (Tasting.getType() === "podcast") {
            screen[0].push(
                ["button", {
                    position: layout[currentDisplay].buttonBackQuiz.position,
                    text: ControlTrad.getTrad("back"),
                    onClick: () => { setCurrentState("IN_PROGRESS") }
                }],
                ["button", {
                    position: layout[currentDisplay].buttonStart.position,
                    text: ControlTrad.getTrad("startQuiz"),
                    highligthed: true,
                    onClick: () => { setCurrentState("QUIZ_QUESTION") }
                }],
                ["quizPrize",
                    {
                        position: layout[currentDisplay].quizPrize.position,
                        styles: layout[currentDisplay].quizPrize.styles,
                        prize: tasting?.tasting?.productReferences[currentProdIndex].quizPrize
                    },
                ],
            );
        } else {
            screen[0].push(
                ["quizPrize",
                    {
                        position: layout[currentDisplay].quizPrize.position,
                        styles: layout[currentDisplay].quizPrize.styles,
                    },
                ],
            );
        }
        return screen[currentDisplay];
    }
    const getLayoutQuizQuestion = (layout) => {
        let screen = [
            [],
        ]
        if (landscapeScreen === "landscape") {
            screen[0].push(
                ["rectangle", {
                    position: layout[currentDisplay]?.rectangle.position,
                    styles: layout[currentDisplay]?.rectangle.styles
                },
                ],
                ["upwineLogo", { position: layout[currentDisplay]?.upwineLogo.position }],
            );
        }
        if (Tasting.getType() === "podcast") {
            screen[0].push(
                ["timer",
                    {
                        position: layout[currentDisplay]?.timer.position,
                        time: currentProductQuizTime,
                        state: "QUIZ_QUESTION",
                        setTime: setCurrentProductQuizTime,
                        onTimed: () => { setCurrentState("QUIZ_CORRECTION") }
                    },
                ],
                ["quizNumQuestions",
                    {
                        position: layout[currentDisplay]?.quizNumQuestions.position,
                        styles: layout[currentDisplay]?.quizNumQuestions.styles,
                        actualQuestion: currentProductQuiz,
                        questions: tasting?.tasting?.productReferences[currentProdIndex]?.quizQuestions.length
                    },
                ],
                ["quizQuestion",
                    {
                        position: layout[currentDisplay]?.quizQuestion.position,
                        styles: layout[currentDisplay]?.quizQuestion.styles,
                        question: tasting?.tasting?.productReferences[currentProdIndex]?.quizQuestions[currentProductQuiz]?.question

                    },
                ],
                ["quizOptions",
                    {
                        position: layout[currentDisplay]?.quizOptions.position,
                        timer: currentProductQuizTime,
                        setOption: setCurrentProductQuizOp,
                        options: tasting?.tasting?.productReferences[currentProdIndex]?.quizQuestions[currentProductQuiz]?.options,
                        state: "QUIZ_QUESTION",
                        onClick: () => { setCurrentState("QUIZ_CORRECTION") }
                    },
                ],
            );
        } else {
            screen[0].push(
                ["timer",
                    {
                        position: layout[currentDisplay]?.timer.position,
                    },
                ],
                ["quizNumQuestions",
                    {
                        position: layout[currentDisplay]?.quizNumQuestions.position,
                        styles: layout[currentDisplay]?.quizNumQuestions.styles,
                    },
                ],
                ["quizQuestion",
                    {
                        position: layout[currentDisplay]?.quizQuestion.position,
                        styles: layout[currentDisplay]?.quizQuestion.styles,
                    },
                ],
                ["quizOptions",
                    {
                        position: layout[currentDisplay]?.quizOptions.position,
                    },
                ]);
            if (landscapeScreen === "landscape") {
                screen[0].push(
                    ["quizPoints",
                        {
                            position: layout[currentDisplay]?.quizPoints.position,
                            styles: layout[currentDisplay]?.quizPoints.styles,
                            final: layout[currentDisplay]?.quizPoints.final,
                        },
                    ]
                );
            }
        }

        return screen[currentDisplay]
    }
    const getLayoutQuizCorrect = (layout) => {
        let screen = [
            [],
        ]
        if (landscapeScreen === "landscape") {
            screen[0].push(
                ["rectangle", {
                    position: layout[currentDisplay]?.rectangle.position,
                    styles: layout[currentDisplay]?.rectangle.styles
                },
                ],
                ["upwineLogo", { position: layout[currentDisplay]?.upwineLogo.position }],
            );
        }
        if (Tasting.getType() === "podcast") {
            screen[0].push(
                ["timer",
                    {
                        position: layout[currentDisplay]?.timer.position,
                        time: 0,
                        state: "QUIZ_CORRECTION",
                        setTime: setCurrentProductQuizTime,
                        onTimed: () => { setCurrentState("QUIZ_CORRECTION") }
                    },
                ],
                ["quizNumQuestions",
                    {
                        position: layout[currentDisplay]?.quizNumQuestions.position,
                        styles: layout[currentDisplay]?.quizNumQuestions.styles,
                        actualQuestion: currentProductQuiz,
                        questions: tasting?.tasting?.productReferences[currentProdIndex]?.quizQuestions.length
                    },
                ],
                ["quizQuestion",
                    {
                        position: layout[currentDisplay]?.quizQuestion.position,
                        styles: layout[currentDisplay]?.quizQuestion.styles,
                        question: tasting?.tasting?.productReferences[currentProdIndex]?.quizQuestions[currentProductQuiz]?.question

                    },
                ],
                ["quizOptions",
                    {
                        position: layout[currentDisplay]?.quizOptions.position,
                        timer: 0,
                        option: currentProductQuizOp,
                        setOption: setCurrentProductQuizOp,
                        correct: tasting?.tasting?.productReferences[currentProdIndex]?.quizQuestions[currentProductQuiz]?.correct[0],
                        options: tasting?.tasting?.productReferences[currentProdIndex]?.quizQuestions[currentProductQuiz]?.options,
                        state: "QUIZ_CORRECTION",
                        onClick: () => { controlQuizNav() }
                    },
                ],
            );
        } else {
            screen[0].push(
                ["timer",
                    {
                        position: layout[currentDisplay]?.timer.position,
                    },
                ],
                ["quizNumQuestions",
                    {
                        position: layout[currentDisplay]?.quizNumQuestions.position,
                        styles: layout[currentDisplay]?.quizNumQuestions.styles,
                    },
                ],
                ["quizQuestion",
                    {
                        position: layout[currentDisplay]?.quizQuestion.position,
                        styles: layout[currentDisplay]?.quizQuestion.styles,
                    },
                ],
                ["quizOptions",
                    {
                        position: layout[currentDisplay]?.quizOptions.position,
                    },
                ],
            );
            if (landscapeScreen === "landscape") {
                screen[0].push(
                    ["quizPoints",
                        {
                            position: layout[currentDisplay]?.quizPoints.position,
                            styles: layout[currentDisplay]?.quizPoints.styles,
                            final: layout[currentDisplay]?.quizPoints.final,
                        },
                    ]
                );
            }
        }

        return screen[currentDisplay]
    }
    const getLayoutQuizGraph = (layout) => {
        let screen = [
            [
                ["quizNumQuestions",
                    {
                        position: layout[currentDisplay]?.quizNumQuestions.position,
                        styles: layout[currentDisplay]?.quizNumQuestions.styles,
                    },
                ],

            ]
        ]
        if (landscapeScreen === "landscape") {
            screen[0].push(
                ["upwineLogo", { position: layout[currentDisplay]?.upwineLogo.position }],
                ["charts", { position: layout[currentDisplay]?.charts.position },],
                ["quizQuestion",
                    {
                        position: layout[currentDisplay]?.quizQuestion.position,
                        styles: layout[currentDisplay]?.quizQuestion.styles,
                    },
                ],

            );
        } else {
            screen[0].push(
                ["chartsResponsive", { position: layout[currentDisplay]?.charts.position },],
            );
        }

        return screen[currentDisplay];
    }
    const getLayoutQuizScore = (layout) => {
        //VISTA GENERAL
        let screen = [
            []
        ];
        if (landscapeScreen === "landscape") {
            screen[0].push(
                ["rectangle", {
                    position: layout[currentDisplay]?.rectangle.position,
                    styles: layout[currentDisplay]?.rectangle.styles
                },
                ],
                ["upwineLogo", { position: layout[currentDisplay]?.upwineLogo.position }],
            );
        }
        //VISTA PODCAST
        if (Tasting.getType() === "podcast") {
            const prize = tasting?.tasting?.productReferences?.[currentProdIndex]?.quizPrize;
            const containsPrize = ![null, undefined, ""].includes(prize);

            screen[0].push(
                ["button", {
                    position: layout[currentDisplay]?.buttonStart?.position,
                    text: currentState === "QUIZ_SCORE_YES" ? ControlTrad.getTrad("next") : ControlTrad.getTrad("continueTasting"),
                    styles: containsPrize ? layout[currentDisplay]?.buttonStart?.styles : "",
                    highligthed: true,
                    onClick: () => { setCurrentState("IN_PROGRESS") }
                }],
            );

            //RESPONDIDO CORRECTAMENTE
            if (currentState === "QUIZ_SCORE_YES") {
                if (containsPrize) {
                    screen[0].push(
                        ["text",
                            {
                                position: layout[currentDisplay]?.header.position,
                                text: ControlTrad.getTrad("congratulations"),
                                styles: layout[currentDisplay]?.header.styles,
                            },
                        ],
                        ["text",
                            {
                                position: layout[currentDisplay]?.text.position,
                                text: ControlTrad.getTrad("winQuizPodcast").replace("{prize}", prize),
                                styles: layout[currentDisplay]?.text.styles,
                            },
                        ],
                        ["aWinnerQuizPOD", {
                            position: layout[currentDisplay]?.winnerForm.position,
                            styles: layout[currentDisplay]?.winnerForm.styles,
                            prize: prize,
                            productRef: tasting?.tasting?.productReferences?.[currentProdIndex]?.reference,
                            toast: showToast,
                            onClick: () => { setCurrentState("IN_PROGRESS") }
                        }],
                    );
                } else {
                    screen[0].push(
                        ["text",
                            {
                                position: layout[currentDisplay]?.header?.noPrizePosition,
                                text: ControlTrad.getTrad("congratulations"),
                                styles: layout[currentDisplay]?.header?.styles,
                            },
                        ],
                        ["text",
                            {
                                position: layout[currentDisplay]?.text?.noPrizePosition,
                                text: ControlTrad.getTrad("winQuizPodcastNoPrize"),
                                styles: layout[currentDisplay]?.text?.styles,
                            },
                        ]
                    )
                }
            }
            //RESPONDIDO MAL
            if (currentState === "QUIZ_SCORE_NO") {
                screen[0].push(
                    ["text",
                        {
                            position: layout[currentDisplay]?.header.position,
                            text: containsPrize ? ControlTrad.getTrad("quizzWrongEnd") : ControlTrad.getTrad("quizzWrongEndNoPrize"),
                            styles: layout[currentDisplay]?.header.styles,
                        }
                    ]
                )
                screen[0].push(
                    ["text",
                        {
                            position: layout[currentDisplay]?.text.position,
                            text: containsPrize ? ControlTrad.getTrad("quizzWrongTryAgain").replace("{prize}", prize) : ControlTrad.getTrad("quizzWrongTryAgainNoPrize"),
                            styles: layout[currentDisplay]?.text.styles,
                        },
                    ]
                );
            }            
        } else {
            if (quizFinal) {
                screen[0].push(
                    ["quizPoints",
                        {
                            position: landscapeScreen === "landscape" ? "33/1/37/32" : "39/5/49/50",
                            styles: layout[currentDisplay]?.quizPoints.styles,
                            final: true,
                        },
                    ],
                    ["quizPodium", { position: layout[currentDisplay]?.quizPodium.position, }],
                    ["quizRanking", { position: layout[currentDisplay]?.quizRanking.position, }],
                    ["winnerQuiz", {
                        position: layout[currentDisplay]?.winnerQuiz.position,
                        styles: layout[currentDisplay]?.winnerQuiz.styles,
                        setQuizWinnerModal: setQuizWinnerModal
                    },],

                );
                if (landscapeScreen === "landscape") {
                    screen[0].push(
                        ["text",
                            {
                                position: layout[currentDisplay]?.textInfo1.position,
                                text: ControlTrad.getTrad("winner"),
                                styles: layout[currentDisplay]?.textInfo1.styles,
                            },
                        ],
                        ["text",
                            {
                                position: layout[currentDisplay]?.textInfo2.position,
                                text: ControlTrad.getTrad("result") + " " + ControlTrad.getTrad("final"),
                                styles: layout[currentDisplay]?.textInfo2.styles,
                            },
                        ],

                    );
                }
            } else {
                screen[0].push(
                    ["quizQuestion",
                        {
                            position: layout[currentDisplay]?.quizQuestion.position,
                            styles: layout[currentDisplay]?.quizQuestion.styles,
                        },
                    ],
                    ["quizPoints",
                        {
                            position: layout[currentDisplay]?.quizPoints.position,
                            styles: layout[currentDisplay]?.quizPoints.styles,
                            final: layout[currentDisplay]?.quizPoints.final,
                        },
                    ],
                    ["quizRanking", { position: layout[currentDisplay]?.quizRanking.position, }],
                );
            }

        }

        return screen[currentDisplay]
    }
    const getLayoutFinished = (layout) => {
        let screen = [
            [
                ["recommendationGif", {
                    position: layout[currentDisplay]?.recommendationGif?.position,
                    onThen: () => { setCurrentDisplay(1); },
                    onThenNotRecomendation: () => {
                        if (Tasting.getType() === "live") { Websockets.send("SLAVE_STATE", "STATE_SUMMARY", false) }
                        setCurrentDisplay(2);
                    },
                    onStart: () => {
                        if (Tasting.getType() === "live") {
                            Websockets.send("SLAVE_STATE", "STATE_RECOMMENDATION", false);
                        }
                    }
                }]
            ],
            [
                ["text", {
                    position: layout[currentDisplay]?.textInfo?.position,
                    text: (landscapeScreen === "portrait") ? ControlTrad.getTrad("wineRecommendedM") : ControlTrad.getTrad("wineRecommendedExtended"),
                    styles: layout[currentDisplay]?.textInfo?.styles
                }],
                ["button", {
                    position: layout[currentDisplay]?.nextButton?.position,
                    text: ControlTrad.getTrad("continue"),
                    onClick: () => {
                        setCurrentDisplay(2);
                        if (Tasting.getType() === "live") { Websockets.send("SLAVE_STATE", "STATE_SUMMARY", false) }
                    },
                }],
                ["wineRecommendation", {
                    position: layout[currentDisplay]?.wineRecommendation?.position,
                    wineRec: wineRecommendation,
                    setWineRecommendation: setWineRecommendation,
                    then: () => {
                        setCurrentDisplay(2);
                        if (Tasting.getType() === "live") { Websockets.send("SLAVE_STATE", "STATE_SUMMARY", false) }
                    }
                }],
            ],
            [
                ["sendReportButton",
                    {
                        position: layout[currentDisplay]?.sendReportButton?.position,
                        text: ControlTrad.getTrad("receiveReport"),
                        onClick: () => {
                            setCurrentDisplay(3);
                            if (Tasting.getType() === "live") { Websockets.send("SLAVE_STATE", "STATE_FINAL_QUIZ", false) }
                        },
                    },
                ],
                ["skipReportButton",
                    {
                        position: layout[currentDisplay]?.skipReportButton?.position,
                        text: ControlTrad.getTrad("noReceiveReport"),
                        type: "cancel",
                        styles: layout[currentDisplay]?.skipReportButton?.styles,
                        onClick: () => {
                            setCurrentDisplay(3);
                            if (Tasting.getType() === "live") { Websockets.send("SLAVE_STATE", "STATE_FINAL_QUIZ", false) }
                        },
                    },
                ],
            ],
            [
                ["sendFeedback",
                    {
                        position: layout[currentDisplay]?.sendFeedback?.position,
                        acceptText: landscapeScreen === "landscape" ? ControlTrad.getTrad("sendComment") : ControlTrad.getTrad("sendCommentM"),
                        cancelText: landscapeScreen === "landscape" ? ControlTrad.getTrad("noSendComment") : ControlTrad.getTrad("noSendCommentM"),
                        onClick: () => {
                            setCurrentDisplay(4);
                            if (Tasting.getType() === "live") { Websockets.send("SLAVE_STATE", "STATE_SHOP", false); }
                        }
                    },
                ],
            ],
            [
                ["button",
                    {
                        position: layout[currentDisplay]?.externalBuyFinally?.position,
                        text: ControlTrad.getTrad("externalBuyFinally"),
                        onClick: () => {
                            setCurrentDisplay(5);
                            if (Tasting.getType() === "live") Websockets.send("SLAVE_STATE", "STATE_GRATITUDE", false)
                        },
                    },
                ],
            ],
            [
                ["text", {
                    position: layout[currentDisplay]?.textinfo1?.position,
                    text: ControlTrad.getTrad("tittle").toUpperCase(),
                    styles: 'text-align:center; font-size: 4.5vh;'
                }],
                ["text", {
                    position: layout[currentDisplay]?.textInfo3?.position,
                    text: ControlTrad.getTrad("userReturn"),
                    styles: 'text-align:center; font-size:2.5vh;'
                }],
                ["text", {
                    position: layout[currentDisplay]?.textinfo2?.position,
                    text: ControlTrad.getTrad("share"),
                    styles: 'text-align:center; fotn-size:2.5vh;'
                }],
                ["socialNetworks", {
                    position: layout[currentDisplay]?.socialNetworks?.position,
                    socialMedia: data?.data?.socialMedia
                }],
                //["socialNetworks", { position: "40/2/50/48" }],
                ["button", {
                    position: layout[currentDisplay]?.closeButton?.position,
                    text: ControlTrad.getTrad("close"),
                    onClick: () => {
                        SoundUtils.play("finishTasting");
                        if (data?.data?.returnUrl) {
                            window.location.replace(completeUrl(data?.data?.returnUrl));
                        } else {
                            window.location.replace('../');
                        }
                    },
                }],
            ]
        ]
        if (Tasting.getType() === "podcast") {
            if (currentDisplay === 2) {
                screen[2].push(
                    ["tastingSummary", {
                        position: layout[currentDisplay]?.tastingSummary?.position,
                        productReferences: tasting?.tasting.productReferences,
                        currentData: currentProductData,
                        wineRec: wineRecommendation,
                        layoutType: layoutType,
                        currency: tasting?.currency
                    }],
                );
            }
            if (currentDisplay === 3) {
                screen[3].push(
                    ["finalSurvey", {
                        position: layout[currentDisplay]?.finalSurvey?.position,
                        onNoQuestions: () => { setCurrentDisplay(4); },
                        questions: data?.data?.tasting?.satisfactionConcepts

                    }],
                );
            }
            if (currentDisplay === 4) {
                screen[4].push(
                    ["productsStore", {
                        position: layout[currentDisplay]?.productsStore?.position,
                        onNoStore: () => { setCurrentDisplay(5); },
                        wines: data?.data?.tasting.productReferences,
                        currentData: currentProductData,
                        productType: ControlTrad.getUserTrad(tasting?.tasting?.productReferences[currentProdIndex].product.type.name),
                        wineRec: wineRecommendation,
                        layout: layoutType,
                        currency: tasting?.currency
                    }],
                );

            }
        } else {
            if (currentDisplay === 2) {
                screen[2].push(
                    ["tastingSummary", {
                        position: layout[currentDisplay]?.tastingSummary?.position,
                        wineRec: wineRecommendation,
                        layoutType: layoutType,
                        currency: tasting?.currency
                    }],
                );
            }
            if (currentDisplay === 3) {
                screen[3].push(
                    ["finalSurvey", {
                        position: layout[currentDisplay]?.finalSurvey?.position,
                        onNoQuestions: () => {
                            setCurrentDisplay(4);
                            Websockets.send("SLAVE_STATE", "STATE_SHOP", false)
                        },
                    }],
                );
            }
            if (currentDisplay === 4) {
                screen[4].push(
                    ["productsStore", {
                        position: layout[currentDisplay]?.productsStore?.position,
                        onNoStore: () => {
                            setCurrentDisplay(5);
                            Websockets.send("SLAVE_STATE", "STATE_GRATITUDE", false)
                        },
                        wineRec: wineRecommendation,
                        layout: layoutType,
                        currency: tasting?.currency
                    }],
                );
            }
            if (currentDisplay === 5) {
                screen[5].push(
                    ["socialNetworks", {
                        position: layout[currentDisplay]?.socialNetworks?.position,
                        socialMedia: data?.data?.socialMedia
                    }],
                )
            }
        }
        if (landscapeScreen === "landscape") {
            if (currentDisplay !== 0) {
                screen[currentDisplay].push(
                    ["upwineLogo", { position: layout[currentDisplay]?.upwineLogo?.position }],
                    ["rectangle", { position: layout[currentDisplay]?.rectangle?.position }],
                )
            }
            if (currentDisplay => 2 && currentDisplay <= 4) {
                screen[currentDisplay].push(
                    [
                        "text",
                        {
                            position: layout[currentDisplay]?.textinfo1?.position,
                            text: currentDisplay === 2 ? ControlTrad.getTrad("winesSummary") :
                                currentDisplay === 3 ? ControlTrad.getTrad("rateYourExperience") :
                                    currentDisplay === 4 ? ControlTrad.getTrad("wantToBuy") : "",
                            styles: layout[currentDisplay]?.textinfo1?.styles,
                        },
                    ],
                    [
                        "text",
                        {
                            position: layout[currentDisplay]?.textinfo2?.position,
                            text: currentDisplay === 2 ? ControlTrad.getTrad("instructionsSummary") :
                                currentDisplay === 3 ? ControlTrad.getTrad("instructionsExperience") :
                                    currentDisplay === 4 ? ControlTrad.getTrad("offers") : "",
                            styles: layout[currentDisplay]?.textinfo2?.styles,
                        },
                    ],
                )
            }
        } else {
            if (currentDisplay === 5) {
                screen[currentDisplay].push(
                    ["upwineLogo", { position: layout[currentDisplay]?.upwineLogo?.position }],

                )
            }
        }
        return screen[currentDisplay];
    }

    //USEEFECT PODCAST
    useEffect(() => {
        window.scrollTo(0, 0);
        if (Tasting.getType() === "podcast") {
            if (currentState === "IN_PROGRESS") {
                if (currentProductData[tasting?.tasting.productReferences[currentProdIndex].reference] === undefined) {
                    setCurrentProductData((prev) => ({
                        ...prev,
                        [tasting?.tasting.productReferences[currentProdIndex].reference]: {}
                    }))
                }
                let podcastMainVideo = tasting?.tasting?.productReferences?.[currentProdIndex].podcastVideos?.["MAIN"];
                if (podcastMainVideo !== undefined) {
                    setCurrentProductVideo(podcastMainVideo.id)
                } else {
                    setCurrentProductVideo(null)
                }

                setVisibility(getVisibility())

            }
            if (currentState === "QUIZ_SCORE_NO" && Tasting.getType() === "podcast") {
                timeOutFailedQuiz = setTimeout(() => {
                   // setCurrentState("IN_PROGRESS");
                }, 10000);

                return (() => { clearTimeout(timeOutFailedQuiz) })
            }
        }
    }, [currentState, currentProdIndex])

    const setMainVideoPlayed = () => {
        addProductMetadata("mainVideoPlayed", true);
        setVideoEvents(null);
    }

    const getProductMetadata = (key) => {
        return currentProductData?.[currentProductReferenceRef]?._metadata?.[key];
    }

    const addProductMetadata = (key, value) => {
        setCurrentProductData((prev) => ({
            ...prev,
            [currentProductReferenceRef]: {
                ...prev[currentProductReferenceRef],
                _metadata: {
                    ...prev[currentProductReferenceRef]?._metadata,
                    [key]: value,
                }
            }
        }));
    }

    const calculateHighlitedComp = () => {
        let newHighligthedComp = null;

        if (Tasting.getType() === "podcast") {
            let currentProd = tasting?.tasting?.productReferences[currentProdIndex];

            if (currentState === "IN_PROGRESS") {
                let mainVideo = currentProd?.podcastVideos?.["MAIN"]?.id;
                let finalVideo = currentProd?.podcastVideos?.["FINAL"]?.id;

                if (!getProductMetadata("mainVideoPlayed") && mainVideo !== null && mainVideo !== undefined) {
                    newHighligthedComp = "VimeoComponent";
                    setVideoEvents({ onPlay: setMainVideoPlayed });
                } else if (!getProductMetadata("allCategoriesRated")) {
                    newHighligthedComp = "CategoriesWrapper";
                } else if (!getProductMetadata("finalVideoPlayed") && finalVideo !== null && finalVideo !== undefined) {
                    newHighligthedComp = "instructorValoration";
                } else if (!getProductMetadata("quizPlayed") && currentProd?.quizQuestions?.length !== 0) {
                    newHighligthedComp = "quizStart";
                } else {
                    newHighligthedComp = "navegationWine";
                }
            }
        } else if (Tasting.getType() === "live") {
            if (currentState === "IN_PROGRESS") {
                newHighligthedComp = "CategoriesWrapper";
            }
        }
        if (highligthedComp !== newHighligthedComp) {
            setHighligthedComp(newHighligthedComp);
        }
    }

    const playAudioIfAppropiate = () => {
        switch (currentState) {
            case "IN_PROGRESS":
                SoundUtils.playIfNotPlayedBefore("startTasting");
                break;
        }
    }

    const valueIfVideoIsEnabeld = (valueWithVideo, valueWithoutVideo) => {
        if (showVideo) {
            return valueWithVideo || valueWithoutVideo;
        } else {
            return valueWithoutVideo || valueWithVideo;
        }
    }

    const loadVideoIfAppropiate = () => {
        let isAppropiate = haveToLoadVideo();
        if (isAppropiate) {
            let newVideoPosition = getVideoCoords();
            if (newVideoPosition !== videoPosition) {
                setVideoPosition(newVideoPosition);
            }
            let newVideoId = getVideoId();
            if (newVideoId !== null && currentProductVideo !== newVideoId) {
                setCurrentProductVideo(newVideoId);
            }
        }
        if (isAppropiate !== showVideo) {
            setShowVideo(isAppropiate);
        }
    }

    const haveToLoadVideo = () => {
        let value = false;
        if (Tasting.getType() === "podcast") {
            switch (currentState) {
                case "WAITING":
                    if (tasting?.tasting?.podcastPresentationVideo?.id) {
                        value = true;
                    }
                    break;
                case "IN_PROGRESS":
                    if (currentDisplay !== 2) {
                        let videos = tasting?.tasting?.productReferences?.[currentProdIndex]?.podcastVideos;
                        value = (videos !== null) && (videos !== undefined) &&
                            (Object.values(videos) || []).some(video => (video?.id !== null && video?.id !== undefined && video?.id !== "null"));
                    }
                    break;
                case "FINISHED":
                    if (currentDisplay !== 0 && tasting?.tasting?.podcastGoodbyeVideo?.id) {
                        value = true;
                    }
                    break;
            }
        } else {
            if (currentState !== "INITIAL") {
                value = appState?.state?.get("jitsiActive");
            }
        }

        return value;
    }

    const getVideoId = () => {
        let videoId = null;
        if (Tasting.getType() === "podcast") {
            switch (currentState) {
                case "WAITING":
                    videoId = tasting?.tasting?.podcastPresentationVideo?.id;
                    break;
                case "FINISHED":
                    videoId = tasting?.tasting?.podcastGoodbyeVideo?.id;
                    break;
            }
        }
        return videoId;
    }

    const getVideoCoords = () => {
        let coords = "0/0/1/1";

        switch (currentState) {
            case "WAITING":
                coords = landscapeScreen === "landscape" ? "59/1/100/35" : "66/1/99/50";
                break;
            case "IN_PROGRESS":
                coords = landscapeScreen === "landscape" ? "55/1/97/35" : "61/1/99/50";
                break;
            case "QUIZ_PRESENTATION":
                coords = landscapeScreen === "landscape" ? "59/1/100/32" : "66/1/99/50";
                break;
            case "QUIZ_QUESTION":
            case "QUIZ_CORRECTION":
            case "QUIZ_GRAPH":
            case "QUIZ_SCORE":
                coords = landscapeScreen === "landscape" ? "59/1/100/32" : "99/1/120/50";
                break;
            case "FINISHED":
                coords = landscapeScreen === "landscape" ? "59/1/100/35" : "66/3/99/50";
                if (!landscapeScreen === "landscape" && currentDisplay === 1) coords = "99/-2/120/50";
                break;
        }
        return coords;
    }

    const showToast = (text) => {
        setToastData({
            visible: true,
            text: text
        });
    }

    const [currentProductVideo, setCurrentProductVideo] = useState(getVideoId());
    const [videoPosition, setVideoPosition] = useState(getVideoCoords());
    const [videoEvents, setVideoEvents] = useState(null);
    const [showVideo, setShowVideo] = useState(haveToLoadVideo());

    //USEEFFECT DIRECTO
    useEffect(() => {
        if (state !== null && state !== undefined) {
            if (Tasting.getType() === "live") {
                if (currentState === "INITIAL") {
                    if (User.getName() !== null) {
                        changeState();
                    }
                } else {
                    changeState();
                }
            }
        }
    }, [state]);

    useEffect(() => {
        setCurrentProductData((prev) => {
            return Object.assign(prev, data?.assessments);
        });
    }, [data?.assessments])

    useEffect(() => {
        if (Tasting.getType() === "live") { data.setTheme(appState.state.get("theme")) };
    }, [appState.state.get("theme")]);

    useEffect(() => {
        if (currentState === "IN_PROGRESS") {
            setCurrentDisplay(0);
        }
    }, [currentWineRef]);

    useEffect(() => {
        calculateHighlitedComp();
    }, [currentProductData, currentState]);

    useEffect(() => {
        playAudioIfAppropiate();
        loadVideoIfAppropiate();
    }, [currentState, currentDisplay, landscapeScreen])

    return (
        <div style={Wrapper} >
            {loaded && currentState ? (
                <>
                    <MutableComponent
                        toast={showToast}
                        popUpError={popUpError}
                        setPopUpError={setPopUpError}
                        textError={textError}
                        titleError={titleError}
                        showVideo={showVideo}
                        videoPosition={videoPosition}
                        videoEvents={videoEvents}
                        highligthedComp={highligthedComp}
                        currentProductVideo={currentProductVideo}
                        disableCloseButton={popUpDisableError}
                        display={getLayoutState()}
                        landscapeScreen={landscapeScreen}
                        data={data}
                    />

                    <QuizWinnerModal isShown={quizWinnerModal} setIsShown={setQuizWinnerModal} />
                    <Toast visible={toastData.visible} text={toastData.text} close={() => { setToastData({ visible: false }) }} />
                </>
            ) : (
                <></>
            )}
        </div>
    );


}
export default AssistantComponent;
