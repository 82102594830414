import ModalMessage from "components/Sandbox/ModalMessage";
import React, { useEffect, useState } from "react";

import styled from "styled-components";
import Element from "../Element";
import PriceRateBoxResponsive from "./PriceRateBoxResponsive";
import RateBoxResponsive from "./RateBoxResponsive"
import Colours from "assets/custom/Custom";
import EditIcon from "assets/images/valorar.svg";
import ControlTrad from "assets/trad/ControlTrad";
import AppStateContext from "appState/AppStateContext";
import Tasting from "assets/tasting/Tasting";
import Play from "assets/images/icons/ic-play-circle-filled.svg";
import ArrowIcon from 'assets/images/icons/Mflecha.svg';
import BlindPriceRateBoxResp from "./BlindPriceRateBoxResp";
import Websockets from "Websockets/Websockets";
import FetchUtils from "Fetch/FetchUtils";

const Wrapper = styled.div`
    display: flex;
`

const ComponentDiv = styled.div`
    padding: 0 0 1vh;
`

const DivButtons = styled.div`
    display:flex;
`

const Title = styled.div`
    padding-left:1rem
`

const WrapperNotes = styled.div`
    font-size: 2.5vh;
    height:30vh;
    width:100%;
    display:flex;
    flex-direction: column;
    border-radius: 1vh;
    text-align:left;
`;

const MainButton = styled.div`
    ${(props) => `
        background-color: ${props.used ? "#b0b0b0" : Colours.getColour("mainColor")};
        border-radius:  ${props.divided ? "0 0 0 0.5rem" : "0 0.5rem 0.5rem 0"};
        color: white;
        display: flex;
        justify-content: center;
        padding: 1.5vh 1rem ;
        flex:1;
        cursor:pointer;
        @media (max-width: 426px) {
            padding: 0.5rem 1rem;
            font-size: 1rem;
        }
        ${props.highligthed ? `
            animation-name: highligthedAnim;
            animation-duration: 2s;
            animation-iteration-count: infinite;
            animation-direction: alternate;
            border: 0; 
        `: null}
    `}
`;

const SecondaryButton = styled.div`
    ${(props) => `
        background-color: ${props.used ? "#b0b0b0" : Colours.getColour("mainColor")};
        border-left: 1px solid white;
        color: white;
        display: flex;
        justify-content: center;
        font-size: 2vh ;
        padding: 1.5vh 1rem;
        flex:1;
        border-radius 0 0 0.5rem 0;

        ${props.visible ? "" : "display : none;"}
        @media (max-width: 426px) {
            padding: 0.5rem 1rem !important;
            font-size: 1rem;
        }

        ${props.highligthed ? `
            animation-name: highligthedAnim;
            animation-duration: 2s;
            animation-iteration-count: infinite;
            animation-direction: alternate;
            border: 0; 
            `: null}
    `}
`;

const ButtonIcon = styled.img`
    ${(props) => `
        width: 1.3rem;
        ${props.styles}
    `}
`;

const AuxiliarButton = styled.div`
    ${(props) => `
        background-color: ${props.used ? "#b0b0b0" : Colours.getColour("mainColor")};
        color: white;
        display: flex;
        justify-content: center;
        padding: 1.5vh 1rem ;
        flex:1;
        margin: 0.5rem 0;
        border-radius:1vh;
        @media (max-width: 426px) {
            padding: 0.5rem 1rem;
            font-size: 1rem;
        }
        ${props.highligthed ? `
            animation-name: highligthedAnim;
            animation-duration: 2s;
            animation-iteration-count: infinite;
            animation-direction: alternate;
            border: 0; 
        `: null}
    `}
`

const TitleRate = styled.div`
    ${(props) => `
        background-color: white;
        font-weight: bold;
        font-size: 2.7vh;
        padding: 1vh 0px;
        border-top-left-radius: 0.5rem;

        ${Tasting.getType() === "live" ?
        "border-bottom-left-radius:  0.5rem ;"
        : "border-radius:  0.5rem 0.5rem 0 0;"
        }

        ${props?.rated ? `
            border-right: 1px solid white;
            background: #b0b0b0;
            color: white;
        ` : ``}

        cursor: pointer;
        width: -webkit-fill-available;
        @media (max-width: 426px) {
            font-size: 1.2rem;
            padding: 0.5rem 0 0.5rem 0.5rem;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    `}
`

const CloseModal = styled.div`
    ${(props) => `
        text-align: center;
        border-radius: 0px 0px 1vh 1vh;
        color: white;
        font-size: 4.5vw;
        padding: 1.75vh 1vh;
        border: 1px solid transparent;
        background: ${Colours.getColour("mainColor")};
        cursor: pointer;

        @keyframes highligthedAnim {
            0%   {
                box-shadow: 0px 0px 30px 15px white;
                background: ${Colours.getColour("mainColor")};
            }
            100% {
                box-shadow: 0px 0px 20px 5px gray;
                background: ${Colours.getColour("secondaryColor")};
            }
        }

        ${props.highligthed ? `
            animation-name: highligthedAnim;
            animation-duration: 2s;
            animation-iteration-count: infinite;
            animation-direction: alternate;
            border: 0; 
        `: null}
    `}
`



const ResponsiveRate = (props) => {
    const [ratePopupVisible, setRatePopupVisible] = useState(false);
    const [notesPopupVisible, setNotesPopupVisible] = useState(false);
    const [modificated, setModificated] = useState(false);

    const appState = React.useContext(AppStateContext);
    const currency = (props.params.currency ? props.params.currency : appState.state.get("currency")) || "€";
    let rateBoxValue, priceBoxValue, wineAnnotation, currentProductRef, blindPrice;

    if (Tasting.getType() === 'live') {
        rateBoxValue = appState?.state.get('currentUserAssessmentsParameters')?.rateBox || 0;
        priceBoxValue = appState?.state.get('currentUserAssessmentsParameters')?.ratePrice || null;
        blindPrice = appState?.state.get('currentUserAssessmentsParameters')?.blindPrice;
    } else {
        rateBoxValue = props.params.rate;
        priceBoxValue = props.params.ratePrice;
        currentProductRef = props.params.currentProductRef;
        wineAnnotation = props.params.annotation[currentProductRef]?.annotation;
        blindPrice = props.params.blindPrice;
    }

    const [assistantAnn, setAssistantAnn] = useState(wineAnnotation || "");
    const [oldWine, setOldWine] = useState(currentProductRef);

    const AssistantNotesRes = {
        borderRadius: '1vh',
        padding: '1.5vh 2vh',
        border: '0px',
        flex: '1 1 0%',
        fontSize: '2vh',
        outline: 'none',
        resize: 'none',
        color: props.editable ? "white" : "black",
        backgroundColor: props.editable ? "#4d4d4d" : "white",
        fontFamily: `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif'`,
    }

    const sendAnswer = (value) => {
        if (Tasting.getType() === "live") {
            Websockets.send("SLAVE_PRODUCT_PARAMETER", [
                currentProductRef,
                "annotation",
                value,
            ], false);
        } else {
            props.params.setDataWine((prev) => ({
                ...prev,
                [props.params.currentProductRef]: {
                    ...prev[props.params.currentProductRef],
                    annotation: value
                }
            }));
            FetchUtils.sendProductParameter(props.params.currentProductRef, "annotation", value);
        }
    };

    const isRateBoxRated = () => {
        return rateBoxValue !== undefined && rateBoxValue !== null && rateBoxValue > 0;
    }

    const isPriceBoxRated = () => {
        if (props.params.layout === "BLIND_TEMPLATE") {
            return blindPrice !== undefined && blindPrice !== null && blindPrice.length > 0;
        } else {
            return priceBoxValue !== undefined && priceBoxValue != undefined && priceBoxValue !== "Pendiente de valorar";
        }
    }

    const isRated = () => {
        return isRateBoxRated() && isPriceBoxRated();
    }

    const getFormatedPrice = (price) => {
        if (price !== null && price !== undefined && price.length > 0) {
            let numString = price.replace(",", ".");
            let num = Number(numString);

            if (!Number.isNaN(num)) {
                let formated = num.toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
                return formated;
            }
        }
        return price;
    }

    useEffect(() => {
        if (Tasting.getType() === "live") {
            setOldWine(currentProductRef);
            sendAnswer(assistantAnn, oldWine);
            setAssistantAnn(wineAnnotation || "");
        } else {
            //sendAnswer(assistantAnn, currentProductRef);
            setAssistantAnn(props.params.annotation[currentProductRef]?.annotation)
        }
    }, [currentProductRef]);

    const getTitleText = () => {
        if (isRated()) {
            let text = rateBoxValue + ControlTrad.getTrad("stars");

            if (props.params.layout === "BLIND_TEMPLATE") {
                text += " / " + getFormatedPrice(blindPrice?.toString()) + currency;
            } else {
                text += " / " + priceBoxValue;
            }

            return text;
        } else {
            if (props.params.layout === "BLIND_TEMPLATE") {
                return ControlTrad.getTrad("blindTitleRateWine");
            } else {
                return ControlTrad.getTrad("rateWineM");
            }
        }
    }

    return (
        <>
            <Element params={props.params}>
                <ComponentDiv>
                    <Wrapper onClick={() => { setRatePopupVisible(true) }}>
                        <TitleRate rated={isRated()}>
                            {getTitleText()}
                        </TitleRate>
                        {Tasting.getType() === 'live' ? (
                            <MainButton
                                onClick={() => {
                                    setRatePopupVisible(true);
                                    setModificated(false);
                                }}
                                used={isRated()}
                                divided={false}>
                                <ButtonIcon src={EditIcon} />
                            </MainButton>
                        ) : (
                            <></>
                        )}
                    </Wrapper>
                    <DivButtons>
                        {Tasting.getType() !== 'live' ? (
                            <>
                                <MainButton
                                    onClick={() => {
                                        setRatePopupVisible(true);
                                        setModificated(false);
                                    }}
                                    highligthed={props.params.highligthed && !isRated()}
                                    used={isRated()}
                                    divided={isRated()}>
                                    <ButtonIcon src={EditIcon} />
                                </MainButton>
                                <SecondaryButton
                                    used={props.params.finalVideoPlayed}
                                    highligthed={props.params.finalVideoHighlited}
                                    visible={isRated()}
                                    onClick={() => { props.params.onClick() }}>
                                    <ButtonIcon src={Play} styles={"filter: invert(1)"} />
                                </SecondaryButton>
                            </>
                        ) : (
                            <></>
                        )}
                    </DivButtons>
                </ComponentDiv>
                <div style={{ display: "flex" }}>
                    {Tasting.getType() !== 'live' ? (
                        <>
                            {props?.params?.containsQuiz ? (
                                <Wrapper style={{ flex: '1', margin: '0 0.5rem 0 0' }} onClick={props.params.onClickQuiz}>
                                    <AuxiliarButton
                                        highligthed={props?.params?.playQuizHighlited}
                                        used={props?.params?.quizPlayed}>
                                        {ControlTrad.getTrad("playQuizM")}
                                        <ButtonIcon src={ArrowIcon} styles={"width: 0.8rem; padding: 0 0.5rem;"} />
                                    </AuxiliarButton>
                                </Wrapper>

                            ) : (
                                <></>
                            )}
                            <Wrapper style={{ flex: '1' }} onClick={() => { setNotesPopupVisible(true) }}>
                                <AuxiliarButton used={assistantAnn && assistantAnn.length > 0}>
                                    {ControlTrad.getTrad("myAnotationsM")}
                                    <ButtonIcon src={EditIcon} styles={"width: 0.8rem; padding: 0 0.5rem;"} />
                                </AuxiliarButton>
                            </Wrapper>
                        </>
                    ) : (
                        <></>
                    )}
                </div>
            </Element>

            <ModalMessage
                header={true}
                isShown={ratePopupVisible}
                title={ControlTrad.getTrad("rateWineM")}
                setIsShown={setRatePopupVisible}
                styleMain={"background-color:white"}
                styleHeader={"background-color:white"}
                titleStyle={"color: black;justify-content:center;font-weight: bold;font-size:4.5vw;"}
                styleClose={"filter:invert(0%); margin: auto;"}
                boxSize={"min-width: 95%; !important"}>

                <RateBoxResponsive
                    rate={rateBoxValue}
                    currentProductRef={props.params.currentProductRef}
                    setDataWine={(input) => {
                        setModificated(true);
                        props.params.setDataWine(input);
                    }} />

                {props.params.layout !== "BLIND_TEMPLATE" ? (
                    <PriceRateBoxResponsive
                        priceBoxValue={priceBoxValue}
                        currentProductRef={props.params.currentProductRef}
                        setDataWine={props.params.setDataWine}
                    />
                ) : (
                    <BlindPriceRateBoxResp
                            isShown={ratePopupVisible}
                            blindPrice={blindPrice}
                            currentProductRef={props.params.currentProductRef}
                        setDataWine={props.params.setDataWine}
                        currency={currency} />
                )}

                <CloseModal
                    highligthed={modificated}
                    onClick={(e) => {
                        setRatePopupVisible(false);
                        setModificated(false);
                    }}>
                    {ControlTrad.getTrad("save")}
                </CloseModal>
            </ModalMessage>


            <ModalMessage
                isShown={notesPopupVisible}
                setIsShown={setNotesPopupVisible}
                title={ControlTrad.getTrad("myAnotations")}
                header={true}
                styleMain={"background-color:white;"}
                boxSize={"width:80%;"}
                styleHeader={"background-color:white"}
                titleStyle={"color: black;justify-content:center;font-weight: bold;font-size:4.5vw;"}
                styleClose={"filter:invert(0%); margin: auto;"}>
                <WrapperNotes>
                    <textarea style={AssistantNotesRes}
                        onChange={(e) => { setAssistantAnn(e.currentTarget.value) }}
                        value={assistantAnn}
                        placeholder={ControlTrad.getTrad("insertNotes")}
                    >
                    </textarea>
                    <CloseModal
                        highligthed={false}
                        onClick={(e) => {
                        sendAnswer(assistantAnn);
                        setNotesPopupVisible(false);
                    }}>{ControlTrad.getTrad("save")}</CloseModal>
                </WrapperNotes>
            </ModalMessage>
        </>
    );

}
export default ResponsiveRate;