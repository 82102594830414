import Element from "../Element";
import Button from "components/Shared/Button"


const ButtonP = (props) => {

  return (
    <Element params={props.params}>
      <Button
        styles={props.params.styles}
        onClick={props.params.onClick}
        value={props.params.text}
        highligthed={props.params.highligthed}
        used={props.params.used}
        timmerEnabled={props.params.timmerEnabled}
        timmerTime={props.params.timmerTime}/>
    </Element>
  );
};

export default ButtonP;
