import React, { useState } from "react"
import styled from "styled-components";

import AppStateContext from "appState/AppStateContext";
import RateStars from 'components/Shared/RateStars';
import Websockets from "Websockets/Websockets";
import ControlTrad from "assets/trad/ControlTrad";
import Custom from "assets/custom/Custom";
import Tasting from "assets/tasting/Tasting";
import FetchUtils from "Fetch/FetchUtils";

const Wrapper = styled.div`
  font-size: 4vw;
  height:100%;
  width:auto;
  display:flex;
  text-align:center;
  align-items:center;
  border-radius: 1vh;
  flex-direction: column;
  justify-content:center;
  padding : 1vh 2vh;
`;

const Title = styled.div`
    font-weight: bold;
`;

const RateBoxResponsive = (props) => {
  const appState = React.useContext(AppStateContext);
  let currentProductRef, rateBoxValue;

  if(Tasting.getType() === "live"){
    currentProductRef = appState?.state.get(`currentWine`)?.reference;
    rateBoxValue = appState?.state.get('currentUserAssessmentsParameters')?.rateBox || 0;
  } else {
    rateBoxValue = props.rate;
    currentProductRef = props.currentProductRef;
  }

  const sendAnswers = (value) => {
    if(Tasting.getType() === "live"){
      Websockets.send("SLAVE_PRODUCT_PARAMETER", [
        currentProductRef,
        "rateBox",
        value,
      ],false)
    } else {
      props.setDataWine((prev) => ({
        ...prev,
        [props.currentProductRef]: {
          ...prev[props.currentProductRef],
            rateBox: value
          }
        })
      );
      FetchUtils.sendProductParameter(props.currentProductRef, "rateBox", value);
    }
  };

  const onRateChange = (val) => {
    sendAnswers(val);
  }
 
  return (  
    <Wrapper>
      <Title>{ControlTrad.getTrad("rateWine")}</Title>
      <RateStars
        value={rateBoxValue}
        onChange={onRateChange}
        clear={false}
        size={8}
        color1={"#4d4d4d"}
        color2={Custom.getColour("mainColor")}
      />
    </Wrapper>
  );
};

export default RateBoxResponsive;