class Tasting{
    static setPublicCode(val){
        Tasting.publicCode = val;
    }
    static getPublicCode(){
        return Tasting.publicCode;
    }
    static setType(type){
        Tasting.type = type;
    }
    static setStreamingAllowed(allowed) {
        Tasting.streamingAllowed = allowed;
    }
    static isStreamingAllowed() {
        return Tasting.streamingAllowed === null || Tasting.streamingAllowed === undefined ? true : Tasting.streamingAllowed;
    }
    static getType(){
        return Tasting.type;
    }
    static getLite(){
        return Tasting.lite;
    }
    static setLite(lite){
        Tasting.lite = lite;
    }
    static isPodcast() {
        return Tasting.type === "podcast"
    }
    static isPodLite() {
        return Tasting.lite && Tasting.type === "podcast"
    }
}
export default Tasting;
