import React, { useState, useEffect } from "react"
import AppStateContext from "appState/AppStateContext";
import Element from "../Element";
import styled from "styled-components";
import MoreInfoButton from "components/Composer/Elements/MoreInfoButton/MoreInfoButton";
import Tasting from "assets/tasting/Tasting";
import ControlTrad from "assets/trad/ControlTrad";
import Custom from "assets/custom/Custom";

const Wrapper = styled.div`
  ${props => `
    font-size: 2.2vh;
    color: black;
    display:flex;
    align-items:center;
    justify-content:center;
    flex-direction: ${props.params.iconType ? "row" : "column"};
    @media (max-width: 426px) {
      justify-content:left;
    }
    @media (min-width: 426px) and (orientation: portrait) and (pointer: coarse) {
      font-size: 2rem;
    }
    ${props.params.wrapperStyles || ""}
  `}
`;

const WineSpan = styled.span`
  ${props => `
    font-size:3.5vh;
    color: white;

    @media (max-width: 426px) {
      font-size: 3vh;
      ${props.visible ? "" : "display: none;"}
    }

    @keyframes nameAnim {
      0% { opacity: 1;}
      50% { opacity: 0;}
      100% { opacity: 1;}
    }
    ${props.anim ? "animation: nameAnim 3s linear 0s 2 alternate;" : ""}
    ${props.styles}
  `}
`;

const WineName = (props) => {
  const hidenName = ControlTrad.getTrad("productHide");
  const appState = React.useContext(AppStateContext);
  const currentWine = Tasting.getType() === "live" ? appState.state?.get("currentWine") : props.params.name;
  const sound = new Audio("https://upwine.es/audios/product_shown_notification.mp3");
  sound.load();

  const [visible, setVisible] = useState(null);
  const [activeAnim, setActiveAnim] = useState(false);

  const isVisibile = () => {
    const isVisible = props.params.visibility ? props.params.visibility.visible : currentWine?.visible;
    return isVisible || props.params.instructor;
  }

  const getText = () => {
    if (isVisibile()) {
      return currentWine?.product?.name;
    } else {
      return hidenName;
    }
  }

  useEffect(() => {
    let newVisibility = isVisibile();

    if (visible === null || visible !== newVisibility) {
      setVisible(newVisibility);
    }

    if (visible !== null && visible !== newVisibility && !props.params.instructor && newVisibility && !activeAnim) {
      sound.play();
      setActiveAnim(true);
    }
  }, [props?.params?.visibility?.visible, currentWine?.visible]);

  return (
    <Element params={props.params}>
      <Wrapper params={props.params}>
        <WineSpan anim={activeAnim} styles={props.params.styles} visible={visible}>{getText()}</WineSpan>
        {props.params.infoButton && isVisibile() ? (
          <>
            <MoreInfoButton params={{
              position: "",
              iconType: props.params.iconType,
              instructor: props.params.instructor,
              styles: props.params.iconType ? null : `
                color: ${Custom.getColour("mainColor")};
                border-color: ${Custom.getColour("mainColor")};
                padding: 0.5vh 4vw;
                margin: 0vh auto;
                ${props?.params?.moreInfoStyles || ""}
              `,
            }} />
          </>
        ) : (
          <></>
        )}
      </Wrapper>
    </Element>
  );
};

export default WineName;
