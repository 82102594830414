import React, { useState } from "react";
import styled from "styled-components";

import Element from "../Element";
import Button from "components/Shared/Button"
import ModalMessage from "components/Sandbox/ModalMessage";
import User from "assets/user/User";
import Fetch from "Fetch/Fetch";
import ControlTrad from "assets/trad/ControlTrad";


const Wrapper = styled.div`
    padding : 2vh;
`
const Wrapper2 = styled.div`
    padding: 6vh;
    height:100%;
    @media (max-width: 426px) {
        padding : 1rem;
    }
`

const BodyWrapper = styled.div`
    display:flex;
    flex-direction:column;
    padding: 2vh;
    padding-top: 1vh;
`

const LabelBox = styled.label`
    text-align: left;
    padding: 0 0 0.5vh;
    @media (max-width: 426px) {
        font-size: 1rem;
    }
`

const AreaBox = styled.textarea`
    display:flex;
    justify-content:flex-start;
    resize:none;
    font-size: 2vh;
    border : 0;
    border-radius:0.5vh;
    height:20vh;
    outline:none;
    padding:2vh;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    @media (max-width: 426px) {
        height:6rem;
        font-size: 1rem;

    }
`

const ButtonsWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
`

const SendFeedback = (props) => {
    const [popUp1, setPopUp1] = useState(false)
    const [popUp2, setPopUp2] = useState(false)
    const [feedback, setFeedback] = useState("");

    const openPopUp = () => {
        setPopUp1(true)
    }

    const nextScreen = () => {
        props.params.onClick();
    }

    const sendFeedback = () => {
        setPopUp1(false);
        if (feedback !== "") {
            Fetch.post({ finalComment: feedback }, "assistant/" + User.getId()).then((res) => {
                console.debug("Feedback send succefully", res);
                sendSatisfacction();
            }).catch((error) => {
                console.error("Error sending feedback", error);
                sendSatisfacction();
            });
        } else {
            sendSatisfacction();
        }
    }

    const sendSatisfacction = () => {
        let satisfactionComponent = document.getElementsByClassName("sat-value");

        for (let i = 0; i < satisfactionComponent.length; i++) {
            let satisfactionResponse = satisfactionComponent[i].getAttribute("satisfactionVal");

            if (satisfactionResponse !== null && satisfactionResponse !== undefined) {
                let satisfactionData = {
                    satisfactionConceptRef: satisfactionComponent[i].id,
                    value: satisfactionResponse
                }

                Fetch.post(satisfactionData, "assistant/" + User.getId() + "/addSatisfactionResponse").then((requestResponse) => {
                    console.debug("Satisfaction send succefully", satisfactionData, requestResponse);
                }).catch((error) => {
                    console.error("Error sending satisfaction", satisfactionData, error);
                });
            }
        }
        nextScreen();
    }

    const openCommentPopup = () => {
        sendFeedback();
        setPopUp2(true);
    }

    return (
        <>
            <Element params={props.params}>
                <ButtonsWrapper>
                    <Button value={props.params.cancelText} onClick={sendSatisfacction} type={"cancel"} styles={"color:white;width:40%;margin-right: 2%"} />
                    <Button value={props.params.acceptText} onClick={openPopUp} styles={"width:40%;margin-left: 2%"} />
                </ButtonsWrapper>
            </Element>
            <ModalMessage
                isShown={popUp1}
                setIsShown={setPopUp1}
                title={ControlTrad.getTrad("info_7_title")}
                disableCloseButton={false}
            >
                <Wrapper>
                    <BodyWrapper>
                        <LabelBox>{ControlTrad.getTrad("info_7")}</LabelBox>
                        <AreaBox value={feedback} placeholder={""} onChange={(e) => { setFeedback(e.currentTarget.value) }} />
                    </BodyWrapper>
                    <ButtonsWrapper>
                        <Button value={ControlTrad.getTrad("sendComment")} onClick={openCommentPopup}></Button>
                    </ButtonsWrapper>
                </Wrapper>
            </ModalMessage>

            <ModalMessage
                isShown={popUp2}
                setIsShown={setPopUp2}
                title={ControlTrad.getTrad("info_7_title")}
                disableCloseButton={true}
            >
                <Wrapper2>
                    {ControlTrad.getTrad("sendData")}
                </Wrapper2>
            </ModalMessage>
        </>
    );
};

export default SendFeedback;
