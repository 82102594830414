import React, { useState } from "react";
import styled from "styled-components";

import Websockets from "Websockets/Websockets";
import AppStateContext from "appState/AppStateContext";
import sendImg from "assets/images/chat_send_arrow.svg";
import Colours from "assets/custom/Custom";

const Wrapper = styled.div`
  font-size: 2.5vh;
  background: white;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction:column;
  border-radius: 1vh;
  overflow: hidden;
  background-color: rgb(230, 230, 230);
  @media (max-width: 426px) {
    font-size: 1rem;
  }
  
`;


const AllMessagesDiv = styled.div`
  ${props => `
    flex-grow: 1;
    overflow: hidden scroll;
    border-radius: 0.7vh;
    height: 40vh;
    ${props.stylesBody}
  `}
}
`

const MessageDiv = styled.div`
display: flex;
border-radius: 0px 5px 5px;
margin: 0px;
flex-flow:row;
padding:0;
`
const Message = styled.p`
color: black;
font-size: 2vh;
margin: 0;
text-align: left;
padding: 0.5vh 0px 0.5vh 2vh;
@media (max-width: 426px) {
  font-size: 1rem;
}
@media (min-width: 426px) and (pointer: coarse) {
  font-size:1.3rem;
}

`
const MessageUser = styled.span`color: ${Colours.getColour("mainColor")};`

const InputDiv = styled.div`
  display:flex;
  justify-content:center;
  align-items:center;
  padding: 0.5vh 2vh;
  margin-bottom: 1vh;
  @media (max-width: 426px) {
    margin-bottom:2vw;
  }
`
const SendInput = styled.input`
flex:2;
height: 4vh;
font-size:2.8vh;
@media (max-width: 426px) {
  max-width:75vw;
  border:0.1vh solid rgba(190,194,203,0.8);
  outline:none;
  border-radius:0.5vh;
  height:7vw;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
}
`
const SendImg = styled.img`
flex:1
color: white;
border-radius: 50%;
font-size: 2rem;
margin-left: 0.5vw;
opacity: 0.6;
cursor:pointer;
`

const Chat = (props) => {

  const appState = React.useContext(AppStateContext);
  let allChat = appState.state.get("chat");

  const [input, setInput] = useState("")


  const sendChat = () => {
    if (input.length > 0 && input.trim().length > 0) {
      Websockets.send("CHAT_MESSAGE", [
        input.trim()
      ],false);
      setInput("")
    }
  }

  const viewAllMessages = () => {
    let rows = []
    if (allChat) {
      Object.keys(allChat).forEach(element => {
        rows.push(
          <MessageDiv>
            <Message>
              <MessageUser>
                [{new Date(allChat[element].date).getHours() < 10 ? "0" + new Date(allChat[element].date).getHours() : new Date(allChat[element].date).getHours()}:{new Date(allChat[element].date).getMinutes() < 10 ? "0" + new Date(allChat[element].date).getMinutes() : new Date(allChat[element].date).getMinutes()}] 
               {" "}{allChat[element].instructor ? <b>Instructor</b> : allChat[element].user}
              </MessageUser>: {allChat[element].message}
            </Message>
          </MessageDiv>
        )
      });
    }
    return rows
  }


  setTimeout(()=>{
    viewAllMessages()
    var messageBody = document.getElementById("msg-wrapper");
    if(document.getElementById("msg-wrapper") !== null){
      messageBody.scrollTop = messageBody.scrollHeight - messageBody.clientHeight;
    }
  }, 500)


  return (
      <Wrapper>
        <AllMessagesDiv stylesBody={props.stylesBody ? props.stylesBody : ""} id={"msg-wrapper"}>{viewAllMessages()}</AllMessagesDiv>
        <InputDiv>
          <SendInput value={input}
            autocomplete="off"
            autocorrect="off"
            autocapitalize="off"
            spellcheck="false"
            onKeyDown={(event) => {
              var keycode = event.keyCode ? event.keyCode : event.which;
              if (keycode === 13) {
                sendChat();
                event.target.blur();
              }
            }}
            onChange={(e) => setInput(e.currentTarget.value)} />
          <SendImg onClick={(e) => {sendChat()}} src={sendImg} />
        </InputDiv>
      </Wrapper>
  );
};

export default Chat;
