import ModalMessage from "components/Sandbox/ModalMessage";
import React, { useState, useEffect } from "react";
import AppStateContext from "appState/AppStateContext";
import styled from "styled-components";
import Element from "../Element";
import Chat from "./Chat";
import IconEditar from "assets/images/icons/ic_edit.svg";
import Valorate from "assets/images/valorar.svg";
import Custom from "assets/custom/Custom";
import ControlTrad from "assets/trad/ControlTrad";

const Wrapper = styled.div`
    ${props => `
        display: flex;
        width: 100%;
        background-color: #b7344e;
        border-radius:1vh;
        position: relative;
        flex-direction: ${props.isLobby ? "row" : "column"} ;
        @media (max-width: 426px) {
            border-radius:0.5rem;
        }
        cursor:pointer;
        background-color: ${props.backgroundColor ? props.backgroundColor : "#b7344e"} ;
    `}
`;

const TitleButton = styled.div`
    ${props => `
        background: white;
        border-radius: 1vh 1vh 0 0;
        font-weight: bold;
        padding: 1vh 0;
        font-size: 3vh;
        display: ${props.isLobby ? "none" : "initial"} ;

        @media (max-width: 426px) {
        font-size: 1.1rem;
        padding: 0.5rem 0;
        border-radius: 0.5rem 0.5rem 0 0;
        }
        @media (min-width: 426px) and (pointer: coarse) {
            ${ props.isLobby ? "font-size: 1.1rem" : "font-size:1.5rem" };
            padding: 0.5rem 0;
            border-radius: 0.5rem 0.5rem 0 0;
        }
    `}
`;

const ButtonRate = styled.div`
    ${props => `
        font-weight:${props.backgroundColor ? "500" : "bold"};
        color: ${props.backgroundColor ? "white" : "black"};
        display: flex;
        justify-content: center;
        padding: 0vh 2vh;
        border-top-left-radius:1vh;
        border-bottom-left-radius:1vh;
        font-size: 2vh ;
        width: 75%;
        @media (max-width: 426px) {
            padding: 0vh 1rem;
            border-top-left-radius:0.5rem;
            border-bottom-left-radius:0.5rem;
            font-size: 1rem;
            padding: 0.6rem 1rem ;
        }
        @media (min-width: 426px) and (pointer: coarse) {
            padding: 0vh 1rem;
            border-top-left-radius:0.5rem;
            border-bottom-left-radius:0.5rem;
            font-size: 1.3rem;
            padding: 0.6rem 1rem ;
            width: auto;
        }
        background-color:${props.backgroundColor ? props.backgroundColor : "white"};
    `}
}`;

const ImgEditar = styled.img`
    width:3vh;
    height:auto;
    margin-left: 1.5vh;
    @media (min-width: 426px) and (pointer: coarse) {
        width:4vh;
        height:auto;
        margin-left: 3vh;
    }
`;

const Badge = styled.div`
    position: absolute;
    height: 4vh;
    width: 4vh;
    top: -1.5vh;
    right: -1.5vh;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2.5vh;
    border-radius: 50%;
    background: red;
    color: white;
`;

const ResponsiveChat = (props) => {
    const [isChatOpened, setChatOpened] = useState(false);
    const [newMessages, setNewMessages] = useState(false);
    const [readedMsg, setReadedMsg] = useState([]);
    const [pendingMessage, setPendingMessage] = useState(0);
    const appState = React.useContext(AppStateContext);
    let allChat = appState.state.get("chat");

    useEffect(() => {
        if (allChat && allChat.length > 0) {
            if (isChatOpened) {
                readAll();
            } else {
                checkPendingMsgs();
            }
        }
    }, [allChat]);

    const readAll = () => {
        let msgIds = [];
        (allChat || []).forEach((msg) => {
            msgIds.push(msg.id);
        });
        setReadedMsg((prev) => ([
            ...prev,
            ...msgIds
        ]));
        setPendingMessage(0);
        setNewMessages(false);
    }

    const checkPendingMsgs = () => {
        let pendingMsgs = 0;
        (allChat || []).forEach((msg) => {
            if (!readedMsg.includes(msg.id)) {
                pendingMsgs++;
            }
        });
        setPendingMessage(pendingMsgs);
        setNewMessages(pendingMessage > 0);
    }

    return (
        <>
            <Element params={props.params}>
                <Wrapper backgroundColor={(newMessages) ? props.params.backgroundColor : "#b0b0b0"} isLobby={props.params.isLobby} onClick={() => { setChatOpened(true); readAll(); }}>
                    {pendingMessage > 0 ? <Badge>{pendingMessage}</Badge> : <></>}

                    <TitleButton isLobby={props.params.isLobby}> {(newMessages) ? ControlTrad.getTrad("chatNewMessage") : ControlTrad.getTrad("showChat")} </TitleButton>

                    <ButtonRate backgroundColor={(newMessages) ? props.params.backgroundColor : "#b0b0b0"} params={props.params}>
                        {props.params.isLobby ? (newMessages) ? ControlTrad.getTrad("chatNewMessages") : ControlTrad.getTrad("chat") : <img src={Valorate} alt={"Valorate"} style = {{width:'1.3rem',filter : `hue-rotate(${Custom.toDeg("mainColor")}deg)`}}/>}
                        
                    </ButtonRate>
                    { props.params.backgroundColor ? 
                        null
                        :
                        <ImgEditar src={IconEditar} />
                    }
                    
                </Wrapper>
            </Element>
            <ModalMessage
                boxSize={"min-width: 20vw; min-height:max-content; !important; border-radius:1vh;"}
                styleMain={"min-height:max-content; max-height:unset; border-bottom-left-radius:1vh; border-bottom-right-radius:1vh;"}
                styleHeader={"border-top-left-radius:1vh; border-top-right-radius:1vh; height:6vw; display:flex; align-items:center;"}
                isShown={isChatOpened}
                media={"width:92vw;height:max-content;"}
                setIsShown={setChatOpened}
                title={ControlTrad.getTrad("chat")}
                header={true}
            >
                <Chat stylesBody={"height:25vw;"}/>
            </ModalMessage>
        </>
    );

}
export default ResponsiveChat;