import React, {useState} from 'react';
import styled from 'styled-components';

const ModeButton = () => {
    const [left, setLeft] = useState(true)
    const Wrapper = styled.div`
    position: absolute;
    top: 2vh;
    left: ${left?'2vh':'auto'};
    right: ${left?'auto':'2vh'};
    padding: 1vh 2vh;
    display:${process.env.REACT_APP_ENVIRONMENT=='prod'?'none':'block'};
    border-radius: .7vh;
    color: white;
    cursor: default;
    background-color: green;
    `

    const modeState = (state) => {
        return state === "dev" ? "DEV" : state === "local" ? "LOCAL"  : ""
    }
        
    return ( <Wrapper onClick={()=>{setLeft(!left)}}>{modeState(process.env.REACT_APP_ENVIRONMENT)} MODE</Wrapper> );
}
 
export default ModeButton;