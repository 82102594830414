
import Rectangle from "./Elements/Rectangle/Rectangle";
import UpwineLogo from "./Elements/UpwineLogo/UpwineLogo";
import WineName from "./Elements/WineName/WineName";
import WinePrice from "./Elements/WinePrice/WinePrice";
import MoreInfoButton from "./Elements/MoreInfoButton/MoreInfoButton";
import WineImage from "./Elements/WineImage/WineImage";
import WineIndex from "./Elements/WineIndex/WineIndex";
import WineIndexMobile from "./Elements/WineIndexMobile/WineIndexMobile";
import CategoriesWrapper from "./Elements/CategoriesWrapper/CategoriesWrapper"
import RateBox from "./Elements/RateBox/RateBox"
import PriceRateBox from "./Elements/PriceRateBox/PriceRateBox"
import BlindPriceRateBox from "./Elements/BlindPriceRateBox/BlindPriceRateBox"
import Notes from "./Elements/Notes/Notes"
import Chat from "./Elements/Chat/Chat"
import AssessmentDisplay from "./Elements/AssessmentDisplay/AssessmentDisplay";
import SimpleAssessmentDisplay from "./Elements/SimpleAssessmentDisplay/SimpleAssessmentDisplay";
import InputUserInfo from "./Elements/InputUserInfo/Input";
import TermsAndConditions from "components/Shared/TermsAndConditions/TermsAndConditions";
import FormButton from "./Elements/FormButton/FormButton";
import LoadingComponent from "./Elements/LoadingComponent/LoadingComponent";

import Button from "./Elements/Button/Button";
import StartTastingButton from "./Elements/StartTastingButton/StartTastingButton";
import CheckTermsUserInfo from "./Elements/CheckTermsUserInfo/CheckTermsUserInfo";
import NextStepButton from "./Elements/NextStepButton/NextStepButton";
import IFinishButton from "./Elements/IFinishButton/IFinishButton";
import PreviousStepButton from "./Elements/PreviousStepButton/PreviousStepButton"
import AssistantsList from "./Elements/AssistantsList/AssistantsList";
import AssistantListLobby from "./Elements/AssistantListLobby/AssistantListLobby"
import AssistantListFinished from "./Elements/AssistantListFinished/AssistantListFinished"

import AssessmentsList from "./Elements/AssessmentsList/AssessmentsList";
import DisplayChatButton from "./Elements/DisplayChatButton/DisplayChatButton";
import ShowProductInfoButton from "./Elements/ShowProductInfoButton/ShowProductInfoButton";
import ShowMessagesButton from "./Elements/ShowMessagesButton/ShowMessagesButton";
import StartQuizButton from "./Elements/StartQuizButton/StartQuizButton";
import TastingPublicCode from "./Elements/TastingPublicCode/TastingPublicCode";
import AssistantsCount from "./Elements/AssistantsCount/AssistantsCount";
import AverageRate from "./Elements/AverageRate/AverageRate";
import AverageProductPrice from "./Elements/AverageProductPrice/AverageProductPrice";
import SendReportButton from "./Elements/SendReportButton/SendReportButton";
import SkipReportButton from "./Elements/SkipReportButton/SkipReportButton";
import TastingSummary from "./Elements/TastingSummary/TastingSummary";
import WineRecommendation from "./Elements/WineRecommendation/WineRecommendation";
import WaitingLobby from "./Elements/WaitingLobby/WaitingLobby";
import Observations from "./Elements/Observations/Observations";
import TastingDescription from "./Elements/TastingDescription/TastingDescription";
import TastingDuration from "./Elements/TastingDuration/TastingDuration";
import TastingType from "./Elements/TastingType/TastingType";
import TastingWines from "./Elements/TastingWines/TastingWines";
import SocialNetworks from "./Elements/SocialNetworks/SocialNetworks";

import JitsiConfiguration from "./Elements/JitsiConfiguration/JitsiConfiguration";
import JitsiIframe from "./Elements/JitsiIframe/JitsiIframe";
import ChangeViewButton1 from "./Elements/ChangeViewButton1";
import ChangeViewButton2 from "./Elements/ChangeViewButton2";

import Timer from "./Elements/AQuizTimer/Timer";
import Text from "./Elements/Text/Text";
import Separator from "./Elements/Separator/Separator";
import QuizPoints from "./Elements/AQuizPoints/QuizPoints";
import QuizNumQuestions from "./Elements/AQuizNumQuestions/QuizNumQuestions";
import QuizPrize from "./Elements/AQuizPrize/QuizPrize";
import QuizQuestion from "./Elements/AQuizQuestion/QuizQuestion";
import QuizOptions from "./Elements/AQuizOptions/QuizOptions";
import Image from "./Elements/Image/Image";
import QuizRanking from "./Elements/AQuizRanking/QuizRanking";
import QuizPodium from "./Elements/AQuizPodium/QuizPodium";
import Charts from "./Elements/ACharts/Charts";
import ChartsResponsive from "./Elements/AChartsResponsive/ChartsResponsive";
import WinnerQuiz from "./Elements/WinnerQuiz/WinnerQuiz";
import AQuizEffect from "./Elements/AQuizEffect/AQuizEffect"
import ProductsStore from "./Elements/ProductsStore/ProductsStore";

import UserListInstructor from "./Elements/IQuizUserListReady/UserList";
import ButtonStart from "./Elements/IQuizButtonStart/ButtonStart";
import ButtonBack from "./Elements/IQuizButtonBack/ButtonBack";
import ControlPanel from "./Elements/IQuizControlPanel/Panel";
import QuestionsPanel from "./Elements/IQuizQuestionsPanel/QuestionsPanel";
import InsQuizRanking from "./Elements/IQuizRanking/Ranking";
import ChartsPanel from "./Elements/IQuizChartsPanel/ChartsPanel";
import ResponsiveRate from "./Elements/ResponsiveRate/ResponsiveRate";
import ResponsiveAnnotations from "./Elements/ResponsiveAnnotations/ResponsiveAnnotations";
import ResponsiveChat from "./Elements/ResponsiveChat/ResponsiveChat";
import ResponsiveQuizStart from "./Elements/ResponsiveQuizStart/ResponsiveQuizStart";
import TastingSelection from "./Elements/TastingSelection/TastingSelection";
import SignOutButton from "./Elements/SignOutButton/SignOutButton";
import InstructorPlan from "./Elements/InstructorPlan/InstructorPlan";

import InitialSurvey from "./Elements/IntialSurvey/InitialSurvey";
import FinalSurvey from "./Elements/FinalSurvey/FinalSurvey";
import SimpleSurvey from "./Elements/SimpleSurvey/SimpleSurvey";
import SendFeedback from "./Elements/SendFeedback/SendFeedback";
import SelectOptions from "components/Shared/SelectOptions/SelectOptions";
import IButtonStartJitsi from "./Elements/IButtonStartJitsi/IButtonStartJitsi";
import RecommendationGif from "./Elements/RecommendationGif/RecommendationGif";
import Forms from "./Elements/Forms/Forms";

import NavegationWine from "./Elements/NavegationWine/NavegationWine";
import VimeoComponent from "./Elements/VimeoComponent/VimeoComponent"
import QuizStart from "./Elements/QuizStart/QuizStart"

import AWinnerQuizPOD from "./Elements/AWinnerQuizPOD/AWinnerQuizPOD";

class Composer{
  static add = (elem, params) => {
    
    return {
      formButton : <FormButton params={params} />,
      inputUserInfo : <InputUserInfo params={params}/>,
      selectOptions : <SelectOptions params={params} />,
      checkTermsUserInfo : <CheckTermsUserInfo params = {params} />,
      terms : <TermsAndConditions params = {params} />,

      button : <Button params={params}/>,
      rectangle: <Rectangle params={params}></Rectangle>,
      upwineLogo: <UpwineLogo params={params} />,
      wineName: <WineName params={params} />,
      winePrice: <WinePrice params={params} />,
      moreInfoButton: <MoreInfoButton params={params} />,
      wineImage: <WineImage params={params} />,
      wineIndex: <WineIndex params={params} />,
      WineIndexMobile: <WineIndexMobile params={params} />,
      categoriesWrapper: <CategoriesWrapper params={params} />,
      rateBox: <RateBox params={params} />,
      priceRateBox: <PriceRateBox params={params} />,
      blindPriceRateBox: <BlindPriceRateBox params={params} />,
      notes:<Notes params={params} />,
      chat:<Chat params={params} />,
      assessmentDisplay: <AssessmentDisplay params={params} />,
      simpleAssessmentDisplay:<SimpleAssessmentDisplay params={params} />,
      nextStepButton:<NextStepButton params={params} />,
      startTastingButton:<StartTastingButton params = {params} />,
      iFinishButton:<IFinishButton params={params} />,
      loadingComponent: <LoadingComponent params={params} />,

      previousStepButton:<PreviousStepButton params={params} />,
      assistantsList: <AssistantsList params={params} />,
      assistantsListLobby: <AssistantListLobby params={params} />,
      assistantListFinished: <AssistantListFinished params={params} />,

      assessmentsList: <AssessmentsList params={params} />,
      displayChatButton: <DisplayChatButton params={params} />,
      showProductInfoButton: <ShowProductInfoButton params={params} />,
      showMessagesButton: <ShowMessagesButton params={params} />,
      startQuizButton: <StartQuizButton params={params} />,
      tastingPublicCode: <TastingPublicCode params={params} />,
      tastingSelection: <TastingSelection params={params} />,
      assistantsCount: <AssistantsCount params={params} />,
      averageRate: <AverageRate params={params} />,
      averageProductPrice: <AverageProductPrice params={params} />,
      sendReportButton: <SendReportButton params={params}/>,
      skipReportButton: <SkipReportButton params={params}/>,
      tastingSummary: <TastingSummary params={params} />,
      wineRecommendation: <WineRecommendation params={params} />,
      responsiveRate: <ResponsiveRate params={params} />,
      responsiveAnnotations: <ResponsiveAnnotations params={params} />,
      responsiveQuizStart : <ResponsiveQuizStart params={params}/>,
      responsiveChat: <ResponsiveChat params={params} />,
      socialNetworks: <SocialNetworks params={params} />,
      signOutButton: <SignOutButton params={params} />,
      instructorPlan: <InstructorPlan params={params} />,

      waitingLobby: <WaitingLobby params={params}/>,
      observations: <Observations params={params}/>,
      tastingDescription: <TastingDescription params={params}/>,
      tastingDuration: <TastingDuration params={params}/>,
      tastingType: <TastingType params={params}/>,
      tastingWines: <TastingWines params={params}/>,
      jitsiConfiguration: <JitsiConfiguration params={params} />,
      jitsiIframe: <JitsiIframe params={params} />,
      changeViewButton1: <ChangeViewButton1 params={params} />,
      changeViewButton2: <ChangeViewButton2 params={params} />,

      timer : <Timer params={params} />,
      text : <Text params={params} />,
      separator : <Separator params={params} />,
      quizPrize:<QuizPrize params={params}/>,
      quizNumQuestions:<QuizNumQuestions params={params}/>,
      quizPoints:<QuizPoints params={params}/>,
      quizQuestion:<QuizQuestion params={params}/>,
      quizOptions: <QuizOptions params={params}/>,
      image : <Image params={params}/>,
      quizRanking : <QuizRanking params={params}/>,
      quizPodium : <QuizPodium params={params}/>,
      charts : <Charts params={params}/>,
      chartsResponsive : <ChartsResponsive params={params}/>,
      winnerQuiz : <WinnerQuiz params={params}/>,
      quizEffect : <AQuizEffect params={params}/>,
      productsStore: <ProductsStore params={params} />,

      userListInstructor : <UserListInstructor params={params}/>,
      buttonStart : <ButtonStart params={params}/>,
      buttonBack : <ButtonBack params={params}/>,
      controlPanel: <ControlPanel params={params}/>,
      questionsPanel: <QuestionsPanel params={params}/>,
      insQuizRanking: <InsQuizRanking params={params}/>,
      chartsPanel: <ChartsPanel params={params}/>,
      
      initialSurvey : <InitialSurvey params = {params} />,
      finalSurvey : <FinalSurvey params = {params} />,
      simpleSurvey : <SimpleSurvey params={params} />,
      sendFeedback : <SendFeedback params = {params} />,
      iButtonStartJitsi : <IButtonStartJitsi params={params} />,
      
      recommendationGif : <RecommendationGif params={params} />,
      form : <Forms params={params} />,
      navegationWine : <NavegationWine params={params} />,
      vimeoComponent : <VimeoComponent params ={params}/>,
      quizStart : <QuizStart params ={params}/>,

      aWinnerQuizPOD : <AWinnerQuizPOD params ={params}/>,


    }[elem];
  };
}

export default Composer;
