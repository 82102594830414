import React, { useEffect } from "react";

import styled from "styled-components";
import Element from "../Element";
import AppStateContext from "appState/AppStateContext";
import ControlTrad from "assets/trad/ControlTrad";
import Tasting from "assets/tasting/Tasting";
//import { Howl } from "howler";


const Wrapper = styled.div`
${props => `
    text-align : left;
    color: white;
    font-size: 3vh;
    ${props.params.styles}
    @media (min-width: 426px) and (pointer: coarse) {
        font-size: 2.7vh;
      }
`}`;


const QuizPrize = (props) => {
    const appState = React.useContext(AppStateContext);
    let prize;
    if(Tasting.getType()==="live"){
        prize = appState.state.get("quizPrize");
    }else{
        prize = props.params.prize;
    }

    const audios = [
        {sound : "https://upwine.es/audios/sound_quiz_presentation.mp3" , label : 'audioStartQuiz'},
      ]

    const playSound = (src) => {
        const sound = new Audio(src);
        sound.load();
        sound.play();
      }

    useEffect(()=>{
        playSound(audios[0].sound);
    },[prize])
    return (
        <Element params={props.params}>
            <Wrapper params={props.params}>{prize !== null && prize !== undefined && prize !== "" ? <b>{ControlTrad.getTrad("toWin") + ":"} {prize}</b> :<></>}</Wrapper>
        </Element>
    );

}
export default QuizPrize;