import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Select from "./Select";
import Element from "../Element";
import JitsiConfig from "./JitsiConfig";

import Button from "components/Shared/Button";

import Tasting from "assets/tasting/Tasting";
import User from "assets/user/User";
import AppStateContext from "appState/AppStateContext";
import IconQuestion from "assets/images/icons/ic_question_white.svg";
import JitsiBackground from "assets/images/user_placeholder.png";
import ControlTrad from "assets/trad/ControlTrad";


const DOMAIN = "meets.linked.wine"; //"meet.jit.si"; //true ? "meet.jit.si" : "jitsi.uptasting.online";

const Wrapper = styled.div`
  height: calc(100% - 3vh);
  width: calc(100% - 2vh);
  padding: 2vh 5vh;
  display: flex;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 2vh;
  color: white;
`;
const Title = styled.div`
  font-size: 5vh;
  padding: 2vh 0;
  font-weight: bold;
  text-transform: uppercase;
  text-align: left;
`;
const ContentWrapper = styled.div`
  flex: 1;
  display: flex;
`;
const StreamingWrapper = styled.div`
  height: 100%;
  width: 100%;

`;
const StreamingPlaceholder = styled.div`
  background-color: #f6f6f6;
  background-image: url(${JitsiBackground});
  background-position:center;
  background-repeat:no-repeat;
  background-size: auto 100%;
  height: 100%;
  width: 100%;
  display: grid;
  justify-content: center;
  align-content: center;
`;
const ConfigWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 50%;
  padding: 0 4vh;
  text-align: left;
`;
const Description = styled.div`
  padding: 2vh 0;
  font-size: 2.3vh;
`;
const Row = styled.div``;

const Header = styled.div`
  font-size: 3vh;
  margin-bottom:1vh;
`;
const SelectWrapper = styled.div`
  display: flex;
  width: 45vh;
  border-radius:0.5vh !important;
`;
const ImgConfig = styled.img`
  width: 4vh;
  height: auto;
`
const options = [{ label: ControlTrad.getTrad("jitsiWaiting") }];

const loadJitsiScript = () => {
  let resolveLoadJitsiScriptPromise = null;

  const loadJitsiScriptPromise = new Promise((resolve) => {
    resolveLoadJitsiScriptPromise = resolve;
  });

  const script = document.createElement("script");
  script.src = `https://${DOMAIN}/external_api.js`;
  script.async = true;
  script.onload = () => resolveLoadJitsiScriptPromise(true);
  document.body.appendChild(script);

  return loadJitsiScriptPromise;
};

const JitsiConfiguration = (props) => {
  const appState = React.useContext(AppStateContext);
  let jitsiActive = appState.state.get("jitsiActive") && Tasting.isStreamingAllowed();
  const jitsiContainerId = "jitsi-container-id";
  const state = appState.state.get("state")

  const [jitsi, setJitsi] = useState(null);
  const [devicesList, setDevicesList] = useState(null);

  if (props.params.landscapeScreen === "portrait") { props.params.next(); }

  const initialiseJitsi = async () => {
    if (!window.JitsiMeetExternalAPI) {
      await loadJitsiScript();
    }
    let config = {
      parentNode: document.getElementById(jitsiContainerId),
      roomName: `UPTASTING${Tasting.getPublicCode()}${User.getId()}`,
      userInfo: {
        displayName: User.getName(),
      },
    };
    config = { ...config, ...JitsiConfig.getConfig(props.params) };
    const _jitsi = new window.JitsiMeetExternalAPI(DOMAIN, config);
    setJitsi(_jitsi);
  };

  const loadDevices = () => {
    jitsi.getAvailableDevices().then((devices) => {
      setDevicesList(devices)
    });
  };
  const startStreaming = () => {
    initialiseJitsi();
  };
  const setAudioInput = (label) => {
    jitsi.setAudioInputDevice(label);
  }
  const setAudioOutput = (label) => {
    jitsi.setAudioOutputDevice(label);
  }
  const setVideoInput = (label) => {
    jitsi.setVideoInputDevice(label);
  }

  useEffect(() => {
    if (jitsi !== null) {
      loadDevices();
    } else if (!jitsiActive) {
      props.params.next();
    }
  }, [jitsi]);

  useEffect(() => {
    if (!jitsiActive) props.params.next();
  }, [state]);


  return (
    <Element params={props.params}>
      <Wrapper>
        <Title>{ControlTrad.getTrad("jitsiTitle") + " "}<ImgConfig src={IconQuestion} /></Title>
        <ContentWrapper>
          <StreamingWrapper id={jitsiContainerId}>
            {!jitsi ? (
              <StreamingPlaceholder>
                <Button
                  value={ControlTrad.getTrad("jitsiActiveCamara")}
                  styles={"font-size:3vh;"}
                  onClick={() => {
                    startStreaming();
                  }}
                />
              </StreamingPlaceholder>
            ) : null}
          </StreamingWrapper>

          <ConfigWrapper>
            <Row>
              <Header>{ControlTrad.getTrad("jitsiMicro")}</Header>
              <SelectWrapper>
                <Select options={devicesList?.audioInput || options} onChange={jitsi ? setAudioInput : null} />
              </SelectWrapper>
            </Row>
            <Row>
              <Header>{ControlTrad.getTrad("jitsiAudio")}</Header>
              <SelectWrapper>
                <Select options={devicesList?.audioOutput || options} onChange={jitsi ? setAudioOutput : null} />
              </SelectWrapper>
            </Row>
            <Row>
              <Header>{ControlTrad.getTrad("jitsiVideo")}</Header>
              <SelectWrapper>
                <Select options={devicesList?.videoInput || options} onChange={jitsi ? setVideoInput : null} />
              </SelectWrapper>
            </Row>
            <Button value={ControlTrad.getTrad("jitsiContinue")} styles={"text-align: center; height:4vh; font-size:3vh;"} onClick={(E) => { props.params.next() }} />
          </ConfigWrapper>
        </ContentWrapper>

        <Description>
          {ControlTrad.getTrad("jitsiInstructions")}
        </Description>
      </Wrapper>
    </Element>
  );
};

export default JitsiConfiguration;
