import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Firebase from "../../Firebase"

import Fetch from "Fetch/Fetch";

import TastingEvent from "../Composer/Elements/TastingSelection/TastingEvent/TastingEvent";
import TastingId from "../Composer/Elements/TastingSelection/TastingId/TastingId";
import Button from "../Shared/Button";

import LogoWhite from "assets/images/logo_white.svg";
import LogoBlack from "assets/images/logo.png";

import Background from "assets/images/background_assistant.png";
import ControlTrad from "assets/trad/ControlTrad";

const Wrapper = styled.div`
    display: flex;
    flex-direction:row;
    width:100vw;
    height:100vh;
    @media (max-width: 426px) {
        flex-direction:column;
    }
`;

const PlanWrapper = styled.div`
    display:flex;
    flex-direction:column;
    align-items:center;
    width:15vw;
    height:100vh;
    background-color:black;
    @media (max-width: 426px) {
        width:100vw;
        height:4rem;

    }
`

const SelectionWrapper = styled.div`
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;
    width:85vw;
    height:100vh;
    background-color:#efe9e7;
    @media (max-width: 426px) {
        width:100vw;
        height:100vh;
    }
`

const BlockWrapper = styled.div`
    display:flex;
    flex-direction:column;
    align-items:center;
    width:85vw;
    height:100vh;
    background-color:#efe9e7;
`

const TextBox = styled.span`
    ${props => `
        font-size:2.2vh;
        color:white;
        font-weight:normal;
        ${props?.styles}
`}`


const UpwineLogo = styled.img`
    width:15vh;
    @media (max-width: 426px) {
        
    }
    ${props => `
        ${props?.styles}
    `
    }`
const GroupWrapper = styled.div`
    ${props => `
        display:flex;
        flex-direction:column;
        align-items:center;
        justity-content:center;
        ${props?.styles}
`}`
const GroupsWrapper = styled.div`
    ${props => `
        display:flex;
        flex-direction:row;
        align-items:center;
        justify-content:space-between;
        width: 100vw;

        ${props?.styles}
`}`


const LoadingEffect = styled.div`
    ${props => `
        background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.8)), url("${Background}") no-repeat center;
        background-size:cover;
        position:absolute;
        display:${props.active ? "flex" : "none"};
        align-items:center;
        justify-content:center;
        width:100vw;
        height:100vh;
    `}
`

const PanelNoResponsive = styled.div`
height: 100vh;
display:flex;
flex-direction:column;
align-items:center;
justify-content: space-between;
@media (max-width: 426px) {
    display:none;
    
}
`

const PanelResponsive = styled.div`
@media (min-width: 426px) {
    display:none;
}
`

const InstructorTastingSelection = (user) => {
    const [loading, setLoading] = useState(true);
    const [block, setBlock] = useState(false);
    const [planName, setPlanName] = useState("");
    const [userData, setUserData] = useState("");
    const [tastingId, setTastingId] = useState(null);
    const [logo1, setLogo1] = useState(LogoBlack)
    const [logo2, setLogo2] = useState(LogoWhite)

    const f = new Firebase().firebase.default;

    const signOut = () => {
        f.auth().signOut();
        window.location.replace("/");
    }

    const switchPlan = () => {
        window.open("https://editor.upwine.online/dashboard/cellar/plan", "_blank")
    }

    const loadCustomBackground = (urlLogo) => {
        let firebase = new Firebase().firebase;
        console.log(urlLogo);
        if (urlLogo !== undefined) {
            firebase.storage().refFromURL(urlLogo).getDownloadURL().then((url) => {
                setLogo1(url);
                setLogo2(url);
            })
        }
    }

    useEffect(() => {
        Fetch.getPlan(f.auth().currentUser?.uid).then(plan => {
            setPlanName(plan.name);
        })
        Fetch.getUser(f.auth().currentUser?.uid).then(user => {
            setUserData(user?.user);
            loadCustomBackground(user?.user?.logo);
        })
    }, []);

    useEffect(() => {
        if (planName === "Gratuito" && (planName !== undefined && planName !== "")) {
            if (userData !== undefined && userData !== "") {
                if (new Date(userData.freePlanLimit).getTime() <= Date.now()) {
                    setBlock(true);
                    setLoading(false);

                } else {
                    setLoading(false);
                }
            }

        } else {
            if (userData !== undefined && userData !== "") {
                setLoading(false)

            }
        }
    }, [planName, userData]);

    return (
        <>
            <LoadingEffect active={loading} />
            <Wrapper>
                <PlanWrapper>
                    <PanelNoResponsive>
                        <GroupWrapper styles={"margin-top:2vh;"}>
                            <TextBox styles={"font-size:3.2vh;font-weight:bold; margin-bottom: 1vh;"}>{ControlTrad.getTrad("tast")}</TextBox>
                            <UpwineLogo src={logo1} alt={"Upwine Logo"} />
                        </GroupWrapper>

                        <GroupWrapper>
                            <TextBox styles={"margin-bottom:0.5vh;"}>{userData?.name}</TextBox>
                            <TextBox styles={"margin-bottom:1vh;"}>{ControlTrad.getTrad("license")} <TextBox styles={"font-weight:bold;"}>{planName}</TextBox></TextBox>
                            <Button value={ControlTrad.getTrad("changeMode")}
                                onClick={switchPlan} />
                        </GroupWrapper>

                        <GroupWrapper styles={"margin-bottom: 2vh;"}>
                            <Button value={ControlTrad.getTrad("logOut")}
                                onClick={signOut} />
                        </GroupWrapper>
                    </PanelNoResponsive>
                    <PanelResponsive>
                        <GroupsWrapper>
                            <GroupWrapper>
                                {tastingId !== null ?
                                <Button value={ControlTrad.getTrad("back")}
                                onClick={() => setTastingId(null)} /> :<></>}
                            </GroupWrapper>
                            <GroupWrapper>
                                <div>
                                    <Button value={ControlTrad.getTrad("logOut")}
                                    onClick={signOut} />
                                </div>
                            </GroupWrapper>
                        </GroupsWrapper>


                    </PanelResponsive>
                </PlanWrapper>
                {block ?
                    <BlockWrapper>
                        <UpwineLogo src={logo1} alt={"Upwine Logo"} styles={"margin-top:5vh;"} />
                        <h1>{ControlTrad.getTrad("demoText1")}</h1>
                        <p>{ControlTrad.getTrad("demoText2")}</p>
                        <a href="https://editor.upwine.online/dashboard/cellar/plan" target="_blank">
                            <Button value={ControlTrad.getTrad("goToEditor")}
                                onClick={() => { }} />
                        </a>
                    </BlockWrapper>

                    :
                    <SelectionWrapper>
                        <UpwineLogo src={logo2} alt={"Upwine Logo"} styles={"margin-top:5vh;"} />

                        {tastingId ?
                            <TastingEvent setLoading={setLoading} tastingId={tastingId} setTastingId={setTastingId} user={user} />
                            :
                            <TastingId setTastingId={setTastingId} user={user} />}



                    </SelectionWrapper>
                }
            </Wrapper>
        </>
    );
};

export default InstructorTastingSelection;
