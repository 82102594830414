import React from "react";
import styled from "styled-components";
import { nanoid } from "nanoid"
import Colours from "assets/custom/Custom";
import User from "assets/user/User";
import ControlTrad from "assets/trad/ControlTrad";
import video from "assets/images/play-white.svg";
import Tasting from "assets/tasting/Tasting";

const Wrapper = styled.div`
  background: white;
  margin: 2vh;
  margin-top: 0.5vh;
  margin-bottom:1vh;
  border-radius: 2vh;
  box-shadow: rgb(0 0 0 / 50%) 0px 1vh 1vh 0px;
  display: flex;
  flex-direction: column;
  text-align: center;
`;

const SliderContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin: 2vh;
  flex-direction: row;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
`;

const TitleContainer = styled.div`
  display: flex;
  font-weight: bold;
  font-size: 2.5vh;
  padding : 2vh 2vh 0vh;
`;

const Title = styled.div`
  text-align: left;
  font-size: 2.2vh;
`;

const Delete = styled.div`
  ${(props) => `
    font-size: 2.2vh;
    display: ${props.active ? "block" : "none"};
    color: ${Colours.getColour("mainColor")};
    cursor: pointer;
    border-left: 0.5vh solid lightgrey;
    margin: 0 0 0 2vh;
    padding: 0 0 0 1vh;
  `}
`;

const ValueContainer = styled.div`
  ${(props) => `
      background: ${props.active ? Colours.getColour("mainColor") : "#d3d3d3"};
      color: white;
      display: grid;
      flex-direction: column;
      align-items: center;
      width: 16vh;
      margin: 1vh 1vh 1vh 2vh;
      font-size: 3vh;
      padding: 0 1vh;
      border-radius: 1vh;
      position: relative;

      &::before {
        content: "";
        height: 2vh;
        width: 2vh;
        background: ${props.active ? Colours.getColour("mainColor") : "#d3d3d3"};
        position: absolute;
        left: -1vh;
        transform: rotate(45deg);
        top: calc(50% - 1vh);
      }

      @media (max-width: 426px) {
        display: none;
      }
  `}
`;

const OptionsSlider = styled.div`
  flex: 1;
  display: flex;
  overflow: auto;
  display: flex;
  flex-direction: row;
  padding-bottom: 0.5vh;
`;

const OptionWrapper = styled.div`
  ${(props) => `
    display: flex;
    flex: 1 0 20%;
    align-items: center;
    cursor: pointer;
    flex-direction: column;
    border-radius: 2vh;
    background: ${props.selected ? Colours.getColour("mainColor") : 'white'};
    color: ${props.selected ? 'white' : 'black'};

    &:hover {
      ${props.selected ? "" : "background-color: #d3d3d370;"}
    }

    @media (max-width: 426px) {
      flex: 1 0 25%;
    }
  `}
`;

const TextWrapper = styled.div`
  display: flex;
  height: 2.2vh;
  width: 80%;
  margin: 1vh;
  overflow: hidden;
  white-space: normal;
  text-overflow: ellipsis;

  @media (max-width: 426px) {
    height: 4.4vh;
    margin: 0 0.5vh 0.5vh;
  }
`;

const OptionText = styled.div`
  ${(props) => `
    text-align: center;
    line-height: 2.2vh;
    white-space: normal;
    width: 100%;
    max-height: 4.4vh;
    margin: auto;

    overflow: hidden;
    white-space: normal;
    text-overflow: ellipsis;
    max-width: 100%;
    font-weight: ${props?.selected ? "bold" : "normal"};
  `}
`;

const OptionImage = styled.div`
  ${(props) => `
    width: 80%;
    max-width: 20vh;
    margin: 1vh;
    height: 13vh;

    background-image: url("${props.src}");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;

    @media (max-width: 426px) {
      max-width: 15vh;
      height: 10vh;
    }
  `}
`;

const FlexControl = styled.div`
  display:flex;
  flex-direction: row;
  overflow: auto;
`;

const VideoText = styled.div`
  font-weight: 500;
  @media (max-width: 426px) {
    display: none !important;
  }
`
const VideoExplication = styled.div`
  ${(props) => `
    margin-right: 21vh;
    height: 3.5vh;
    background-color: ${Colours.getColour("mainColor")};
    border-radius:0.5rem;
    cursor:pointer;
    font-weight: 500;
    padding: 0.25vh 1vh;
    color: white;
    display:flex;
    font-size: 2.2vh;
    @media (max-width: 426px) {
      margin-right: 0vh;
    }
  `}
`

const VideoImg = styled.img`
  margin: auto 1vh;
  height: 80%;
`

const ImagesSlider = (props) => {


  const addAnswer = (id)=>{
    props.addAnswer(props.data.id, id);
  }
  const getTextFromOptionId = ()=>{
    return ControlTrad.getUserTrad(props.data.options?.find(option => option.id === props.state)?.name);
  }

  const getOptions = () => {
    let rows = [];
    props.data.options.forEach(({ id, name, icon }) => {
      
      rows.push(
        <OptionWrapper
          onClick={(e) => addAnswer(id)}
          selected={props.state === id}
          key={nanoid()}
        >
          <OptionImage src={icon} />
          <TextWrapper>
            <OptionText selected={props.state === id}>{ControlTrad.getUserTrad(name)}</OptionText>
          </TextWrapper>
        </OptionWrapper>
      );
    });

    return rows;
  };

  return (

    <Wrapper>
        <TitleContainer>
          <div style={{ display: "flex", flex: "1" }}>
          <Title styles={"margin-right:1vh;"}>{ControlTrad.getUserTrad(props.data.name)}</Title>
            <Delete
              onClick={() => {
                props.addAnswer(props.data.id, null)
              }
              }
              active={props.state}
            >
              {ControlTrad.getTrad("delete")}
            </Delete>
        </div>

        {Tasting.getType() === "podcast" && props.data.videoExplanationUrl !== null && props.data.videoExplanationUrl !== undefined ? (
            <div>
            <VideoExplication onClick={() => { props.viewVideo(props.data.videoExplanationId, ControlTrad.getUserTrad(props.data.name)) }}>
                <VideoImg src={video} />
                <VideoText>{ControlTrad.getTrad("viewExplication")}</VideoText>
              </VideoExplication>

            </div>
        ) : (
          <></>
        )}

        </TitleContainer>
      <FlexControl>
        <SliderContainer>
          <OptionsSlider>{getOptions()}</OptionsSlider>
        </SliderContainer>
        <ValueContainer active={props.state}>
          {props.state
            ? getTextFromOptionId()
            : ControlTrad.getTrad("withoutValuing")}
        </ValueContainer>
      </FlexControl>
    </Wrapper>
  );

};

export default ImagesSlider;
