import React from "react";

import styled from "styled-components";
import Element from "../Element";
import AppStateContext from "appState/AppStateContext";
import Colours from "assets/custom/Custom";
import Valorate from "assets/images/valorar.svg";
import Notes from "assets/images/notes.svg";
import ControlTrad from "assets/trad/ControlTrad";
import Tasting from "assets/tasting/Tasting";
import flecha from 'assets/images/icons/Mflecha.svg';

const Wrapper = styled.div`
display: flex;
flex-direction: column;
border-radius: 1vh;
width: 100%;
height: 100%;
text-align: center;
cursor: pointer;
`

const Title = styled.div`
padding-left:1rem
`


const WrapperNotes = styled.div`
  font-size: 2.5vh;
  height:40vh;
  width:100%;
  display:flex;
  flex-direction: column;
  border-radius: 1vh;
  text-align:left;
`;

const AssistantNotes = styled.textarea`
  ${(props) =>
        `
  border-radius: 1vh;
  padding: 1vh 2vh;
  border: 0px;
  flex: 1 1 0%;
  font-size: 2vh;
  outline: none;
  resize: none;
  color: ${props.editable ? "white" : "black"};
  background-color: ${props.editable ? "#4d4d4d" : "white"};
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
-webkit-font-smoothing: antialiased;
-moz-osx-font-smoothing: grayscale;
  @media (max-width: 426px) {
    padding: 0vh 1rem;
    font-size: 1rem;
    padding: 0.5rem 1rem ;
}
  `}
  `;




const ResponsiveAnnotations = (props) => {
    let currentWineRef, wineAnnotation;

    const appState = React.useContext(AppStateContext);

    
    const ButtonRate = styled.div`
    background-color: ${wineAnnotation === "" || wineAnnotation === undefined ? Colours.getColour("mainColor") : "#b0b0b0"};
    color: white;
    display: flex;
    justify-content: center;
    padding: 0.6rem 1rem;
    font-size: 2vh ;
    padding: 1vh 1rem ;
    width: -webkit-fill-available;
    @media (max-width: 426px) {
        font-size: 1rem;
        padding: 0.6rem 1rem ;
        height: 100%;
        
    }
    ${Tasting.getType() === "live" ? 
    "border-radius: 0 0 1vh 1vh;" :
    "border-radius: 1vh;margin: 0.2rem;"}

}
`


const TitleButton = styled.div`
background: white;
border-radius: 1vh 1vh 0 0;
font-weight: bold;
padding: 1vh 0;
font-size: 3vh;
flex-direction:column;
display:flex;
align-items: center;
@media (max-width: 426px) {
  font-size: 1.1rem;
  padding: 0.5rem 0;
  border-radius: 0.5rem 0.5rem 0 0;
}
${Tasting.getType() === "live" ? "" :"display:none;"}
`;





    return (
        <>
            <Element params={props.params}>
                <Wrapper onClick={props.params.onClick}>
                    <ButtonRate>
                        {Tasting.getType() !== "live" ? ControlTrad.getTrad("playQuizM") : ""}
                        <img src={flecha} alt={"Notes"} style={{ width: '0.8rem', padding:"0 0.5rem", filter: `hue-rotate(${Colours.toDeg("mainColor")}deg)` }} />
                    </ButtonRate>
                </Wrapper>
            </Element>
        </>
    );

}
export default ResponsiveAnnotations;