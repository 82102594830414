import React from "react";
import styled from "styled-components";

import Element from "../Element";
import Tasting from "assets/tasting/Tasting";
import IconInstagram from "assets/images/icons/ic_instagram.png";
import IconFacebook from "assets/images/icons/ic_facebook.png";
import IconLinkedIn from "assets/images/icons/ic_linkedin.png";
import IconTwitter from "assets/images/icons/ic_twitter.png";
import IconWhatsApp from "assets/images/icons/ic_whatsapp.png";
import AppStateContext from "appState/AppStateContext";
import ControlTrad from "assets/trad/ControlTrad";
import { completeUrl } from "assets/utils/DeviceUtils";

const NetworksWrapper = styled.div`
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content:center;
`

const SocialNetworkIcon = styled.img`
    width:6vh;
    height:6vh;
    margin-left:2vh;
`

const Link = styled.a`
    text-decoration:none;
`

const SocialNetworks = (props) => {
    const appState = React.useContext(AppStateContext);
    let socialMedia;

    if (Tasting.getType() === "live") {
        socialMedia = appState.state.get("socialMedia");
    } else {
        socialMedia = props?.params?.socialMedia;
    }

    return (
        <Element params={props.params}>
            <NetworksWrapper>
                <Link href={socialMedia?.instagram ? completeUrl(socialMedia?.instagram) : "https://www.instagram.com/upwine_app/"} target="_blank" alt="instagram">
                    <SocialNetworkIcon src={IconInstagram}/>
                </Link>
                <Link href={socialMedia?.twitter ? completeUrl(socialMedia.twitter) : "https://twitter.com/intent/tweet?url=www.upwinelovers.com&text=" + ControlTrad.getTrad("share") + "&hashtags=Upwine,WineLovers"} target="_blank" alt="twitter">
                    <SocialNetworkIcon src={IconTwitter}/>
                </Link>
                <Link href={socialMedia?.linkedin ? completeUrl(socialMedia.linkedin) : "https://www.linkedin.com/shareArticle?mini=true&url=www.upwinelovers.com&title=Quiz upwine&summary=" + ControlTrad.getTrad("share")} target="_blank" alt="linkedin">
                    <SocialNetworkIcon src={IconLinkedIn}/>   
                </Link>
                <Link href={socialMedia?.facebook ? completeUrl(socialMedia.facebook) : `https://www.facebook.com/sharer/sharer.php?u=www.upwinelovers.com&quote=${ControlTrad.getTrad("share")}.`} target="_blank" alt="facebook">
                    <SocialNetworkIcon src={IconFacebook}/>   
                </Link>
                <Link href={`https://api.whatsapp.com/send?text=${ControlTrad.getTrad("share")}%20www.upwinelovers.com`} target="_blank" alt="whatsapp">
                    <SocialNetworkIcon src={IconWhatsApp}/>   
                </Link>
            </NetworksWrapper>
        </Element>
    );
};

export default SocialNetworks;
