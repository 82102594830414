import React from "react"
import styled from "styled-components";

import Element from "../Element";
import AppStateContext from "appState/AppStateContext";

const Wrapper = styled.div`
${props=>`
  font-size: 2vh;
  color: white;
  @media (max-width: 426px) {
    font-size: 2.7vh;
    color:#aaa8a9;
  }
  ${props.params.styles}
`}`

const TastingWines = (props) => {
  const appState = React.useContext(AppStateContext);
  let winesCount = appState.state.get("winesCount");

  return (
    <Element params={props.params}>
      <Wrapper params={props.params}>{winesCount}</Wrapper>
    </Element>
  );
};

export default TastingWines;
