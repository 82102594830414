import React from "react";
import styled from "styled-components";

const Wrapper = styled.select`
  padding: 1.5vh 1vh;
  border-radius: 0.5vh;
  flex:1;
  font-size: 2.5vh;
  width:100%;
`;

const Option = styled.option`
  padding: 1vh;
`;

const loadOptions = (options) => {
  let views = [];
  options.forEach((option) => {
    views.push(<Option value={option.label}>{option.label}</Option>);
  });
  return views;
};

const Select = (props) => {
  return (
    <Wrapper onChange={(e) => props?.onChange(e?.target?.value)}>
      {loadOptions(props.options)}
    </Wrapper>
  );
};

Option.defaultProps = {
  onClick: ()=>{}
}

export default Select;
