import React from "react";

import styled from "styled-components";
import Element from "../Element";
import User from "assets/user/User"
import Colours from "assets/custom/Custom";

import AppStateContext from "appState/AppStateContext";
import Tasting from "assets/tasting/Tasting";
import ControlTrad from "assets/trad/ControlTrad";

const Wrapper = styled.div`
    ${props => `
        color: white;
        background-color:${Colours.getColour("mainColor")};
        border-radius: 2vh;
        border: 0.5vh solid white;
        text-align: center;

        padding: 2vh;
        font-size: 4vh;

        width: 94%;
        max-width: 94%;

        height: auto;
        max-height: 16vh;

        overflow: hidden;
        text-overflow: ellipsis;
        white-space: wrap;

        @supports (-webkit-line-clamp: 3) {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: initial;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
        }

        ${props.params.styles}
    `}
`;



const QuizQuestion = (props) => {
    const appState = React.useContext(AppStateContext);
    let quizQuestion;
    if(Tasting.getType() === "live"){
        quizQuestion = appState.state.get('quizCurrentQuestion')?.question;
    }else{
        quizQuestion = props.params.question;
    }

    return (
        <Element params={props.params}>
            <Wrapper params={props.params}>{ControlTrad.getUserTrad(quizQuestion)}</Wrapper>
        </Element>
    );

}
export default QuizQuestion;