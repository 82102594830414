import React from "react";
import styled from "styled-components";

const StartQuizButton = (props) => {
    const Wrapper = styled.div`
    display:flex;
    flex-direction:row;
    justify-content: space-between;
    background-color:white;
    padding : 2vh;
    border-radius:2vh;
    margin: 1vh 0;
`;
const UserName = styled.div`

`;
const UserReady = styled.div`
    
`;

    return (
        <Wrapper>
           <UserName>{props.userName}</UserName>
           <UserReady>{props.ready ? 'Listo': 'No conectado' }</UserReady>
        </Wrapper>
    );
};

export default StartQuizButton;
