import React from "react"
import styled from "styled-components";

import Element from "../Element";
import AppStateContext from "appState/AppStateContext";

const Wrapper = styled.div`
${props=>`
  font-size: 2vh;
  text-align: center;
  color:white;
  @media (max-width: 426px) {
    font-size:2.3vh;
    overflow-y: auto;
    max-height: 22vh;
    font-weight:normal;
    color:#aaa8a9;
  }
  ${props.params.styles}
`}`

const TastingDescription = (props) => {
  const appState = React.useContext(AppStateContext);
  let tasting = appState.state.get("tasting");

  return (
    <Element params={props.params}>
      <Wrapper params={props.params}>{tasting?.description}</Wrapper>
    </Element>
  );
};

export default TastingDescription;
