import React, { useEffect, useState } from "react";
import styled from "styled-components";

import Element from "../Element";
import Question from "./Question";
import Fetch from "Fetch/Fetch";
import LoadingComponent from "../LoadingComponent/LoadingComponent";

const QuestionItem = styled.div`
    height:100vh;
`;

const InitialSurvey = (props) => {
    const [indexQuestion, setIndexQuestion] = useState(0);
    const [question, setQuestion] = useState({});

    const nextQuestion = () => {
        if (question.length !== indexQuestion + 1) {
            let index = indexQuestion + 1;
            setIndexQuestion(index);
        }else{
            props.params.onNextView();
        }
    }

    const loadQuesions = () => {
        let row = [];
        if (Object.keys(question).length !== 0 ) {
            if(question[indexQuestion] && question[indexQuestion] !== undefined){
                row.push(
                    <QuestionItem>
                        <Question
                            index={indexQuestion + 1}
                            type={question[indexQuestion]?.type}
                            hint={question[indexQuestion]?.hint}
                            question={question[indexQuestion]?.question}
                            options={question[indexQuestion]?.options}
                            reference={question[indexQuestion]?.reference}
                            nextQuestion={nextQuestion}
                        />
                    </QuestionItem>
                )
            }
        }

        return row;
    }

    useEffect(() => {
        if (Object.keys(question).length === 0) {
            Fetch.get("surveyQuestions").then(
                (result) => {
                    if (Object.keys(result).length === 0) {
                        props.params.onNextView();
                    } else {
                        setQuestion(result);
                    }
                }
            );
            loadQuesions();
        }
    }, [question]);

    return (
        <Element params={props.params}>
            {Object.keys(question).length === 0 ? (
                <LoadingComponent params={{ position: "1/1/100/100", active: true, ready: () => { } }} />
            ) : (
                loadQuesions()
            )}
        </Element>
    );

}
export default InitialSurvey;