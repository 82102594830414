class Config {
  static getConfig(props) {
    return {
      configOverwrite: {
        startWithAudioMuted: false,
        startWithVideoMuted: false,
        disableDeepLinking: true,
        defaultLanguage: "es",
        disableKick: true,
        prejoinPageEnabled: false,
        toolbarButtons: [],
        maxFullResolutionParticipants: 1,
        hideConferenceSubject: true,
        hideConferenceTimer: true,
        hideParticipantsStats: true,
        disableResponsiveTiles: true,

        makeJsonParserHappy: "even if last key had a trailing comma",
      },
      interfaceConfigOverwrite: {
        VIDEO_QUALITY_LABEL_DISABLED: true,
        CONNECTION_INDICATOR_DISABLED: true,
        CONNECTION_INDICATOR_AUTO_HIDE_ENABLED: true,
        SHOW_CHROME_EXTENSION_BANNER: false,
        FILM_STRIP_MAX_HEIGHT: 0.1,
        HIDE_INVITE_MORE_HEADER: true,
        ENFORCE_NOTIFICATION_AUTO_DISMISS_TIMEOUT: 1,
        SETTINGS_SECTIONS: [],
        makeJsonParserHappy: "even if last key had a trailing comma",
      },
    };
  }
}

export default Config;
