import React from "react";
import styled from "styled-components";

import QuizOption from "./QuizOption"
import AppStateContext from "appState/AppStateContext";
import ControlTrad from "assets/trad/ControlTrad";


const Options = styled.div`
    display:flex;
    flex-direction:column;
    height:100%
    
`;
const Option = styled.div`
    flex:1;
    padding: 0.5vh 0;
`;



const QuizOptions = (props) => {

    const appState = React.useContext(AppStateContext);
    let question = appState.state?.get("quizActualQuestion");
    let reference = appState.state?.get("quizActualQuestionReference");

    let assistants = appState.state?.get("assistants");
    let correct = question?.correct[0];
    const abc='ABCDEFGHIJKLMNROPQSTUVWXYZ'

    const loadOptions = () => {
        let rows = [];
        let index = 0;
        if (question?.options !== null && question?.options !== undefined) {
            Object.keys(question?.options).forEach(element => {
                rows.push(
                    <Option>
                        <QuizOption
                            optionHead={abc[index]}
                            option={question?.options[element]["ES"]}
                            answers={countAnswers(element)}
                            correct={correct === element}

                        />
                    </Option>
                );
                index++;
            });
        }
        return rows;
    }

    const countAnswers = (opt)=> {
        let sum = 0;
        Object.keys(assistants).forEach(element => {
            if(assistants?.[element]?.quizAnswers?.[reference] === opt){
                sum++;
            }
        });
        return sum + " " + ControlTrad.getTrad("resp");
    }

    return (
        <Options>
            {loadOptions()}
        </Options>
    );

}
export default QuizOptions;