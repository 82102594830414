import React, { useState } from "react";

import styled from "styled-components";
import Websockets from "Websockets/Websockets";
import AppStateContext from "appState/AppStateContext";
import sendImg from "assets/images/chat_send_arrow.svg";
import Colours from "assets/custom/Custom";
import ControlTrad from "assets/trad/ControlTrad";

const Wrapper = styled.div`
  font-size: 2.5vh;
  background: white;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction:column;
  border-radius: 1vh;
  overflow: hidden;
  background-color: rgb(230, 230, 230);
`;


const AllMessagesDiv = styled.div`
flex-grow: 1;
overflow: hidden scroll;
border-radius: 0.7vh;
height: 25vh;
}
`

const MessageDiv = styled.div`
display: flex;
border-radius: 0px 5px 5px;
margin: 0px;
flex-flow:row;
padding:0;
`
const Message = styled.p`
color: black;
font-size: 1.8vh;
margin: 0;
text-align: left;
padding: 0.3vh 0px 0.3vh 2vh;

`
const MessageUser = styled.span`color: ${Colours.getColour("mainColor")};`

const InputDiv = styled.div`
display:flex;
padding: 0.5vh 2vh;

`
const SendInput = styled.input`
flex:2
`
const SendImg = styled.img`
flex:1
color: white;
border-radius: 50%;
margin: auto 0px;
font-size: 2rem;
padding: 0px 0.5vh;
opacity: 0.6;
`

const Chat = () => {

  const appState = React.useContext(AppStateContext);
  let allChat = appState.state.get("chat");

  const [input, setInput] = useState("")


  const sendChat = () => {
    if (input.length > 0 && input.trim().length > 0) {
      Websockets.send("CHAT_MESSAGE", [
        input.trim()
      ],true);
      setInput("")
    }
  }


  const viewAllMessages = () => {
    let rows = []
    if (allChat) {
      Object.keys(allChat).forEach(element => {
        rows.push(
          <MessageDiv>
            <Message>
              <MessageUser>
                [{new Date(allChat[element].date).getHours() < 10 ? "0" + new Date(allChat[element].date).getHours() : new Date(allChat[element].date).getHours()}:{new Date(allChat[element].date).getMinutes() < 10 ? "0" + new Date(allChat[element].date).getMinutes() : new Date(allChat[element].date).getMinutes()}] 
               {" "}{allChat[element].instructor ? <b>{ControlTrad.getTrad("instructor")}</b> : allChat[element].user}
              </MessageUser>: {allChat[element].message}
            </Message>
          </MessageDiv>
        )
      });
    }
    return rows
  }


  setTimeout(()=>{
    viewAllMessages()
    var messageBody = document.getElementById("msg-wrapper");
    if(document.getElementById("msg-wrapper") !== null){
      messageBody.scrollTop = messageBody.scrollHeight - messageBody.clientHeight;
    }


  }, 500)


  return (
      <Wrapper>
        <AllMessagesDiv id={"msg-wrapper"}>{viewAllMessages()}</AllMessagesDiv>
        <InputDiv>
          <SendInput value={input}
            autocomplete="off"
            autocorrect="off"
            autocapitalize="off"
            spellcheck="false"
            onKeyDown={(event) => {
              var keycode = event.keyCode ? event.keyCode : event.which;
              if (keycode === 13) {
                sendChat();
                event.target.blur();
              }
            }}
            onChange={(e) => setInput(e.currentTarget.value)} />
          <SendImg onClick={(e) => {sendChat()}} src={sendImg} />
        </InputDiv>
      </Wrapper>
  );
};

export default Chat;
