import React from "react"
import styled from "styled-components";

import RateStars from 'components/Shared/RateStarsOld';
import Colours from "assets/custom/Custom";
import ControlTrad from "assets/trad/ControlTrad";
import Custom from "assets/custom/Custom";

const WineWrapper = styled.div`
  ${props => `
    display:flex;
    align-items:center;
    justify-content: space-around;
    flex-direction:row;
    margin-top: ${props.recommendation ? "0" : "1.9vh"};   
    overflow:auto;
    background-color: ${props.recommendation ? "#a9a9a9e0" : "transparent"};
`}`;

const WineInfoWrapper = styled.div`
  display:flex;
  flex-direction:column;
  text-align:left;
  width: 40%;
  @media (max-width: 426px) {
    flex:2
  }
`;

const WineImageWrapper = styled.div`
  width: 25%;
  height:25vh;
  display:flex;
  justify-content:center;
  align-items:center;
  @media (max-width: 426px) {
    flex:1
  }
`
const LastColumWrapper = styled.div`
  width: 35%;
  @media (max-width: 426px) {
    display: none !important;
  }
`;

const WineImage = styled.img`
  width: auto;
  height: 90%;
  margin: auto;
  @media (max-width: 426px) {
    width: 10vw;
    height: auto;
  }
`;

const WineName = styled.span`
  font-size:3vh;
  font-weight:bold;
  @media (max-width: 426px) {
    font-size:2vh;
  }
`

const WineType = styled.span`
  margin-top: 0.5vh;
  font-size:2vh;
  font-weight:500;
`

const WineRatePrice = styled.span`
  ${props => `
    font-size: 2vh;
    ${props?.styles}
`}`

const WineUserRateDiv = styled.div`
  ${props => `
    display:flex;
    flex-direction:row;
    ${props?.styles}
  `}
`

const WineUserTitleRate = styled.div`
  font-size:2.2vh;
  margin-left:2vh;
  text-align:center;
`

const WineUserRate = styled.span`
  ${props => `
    font-size:2vh;
    font-weight:600;
    margin-left:2vh;
    margin-top:0.5vh;
    text-align:center;
    ${props.styles}
  `}
`

const NotesWrapper = styled.div`
  background-color: #e7e7e7;
  padding: 2vh;
  display: flex;
  flex-direction: column;
  border-radius: 15px;
  min-height:15vh;
  width: 14vw;
  @media (max-width: 426px) {
    display: none !important;
  }
`;

const NotesTitle = styled.span`
  font-size: 2vh;
  font-weight: bold;
  margin-bottom: 1vh;
  text-align:left;
`;

const NotesAssistant = styled.span`
  color: #7c8288;
  font-size: 2vh;
  text-align:left;
  overflow-y:auto;
`;

const RecTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height:15vh;
  font-size: 2vh;
  text-align:left;
  width: 14vw;
  @media (max-width: 426px) {
    display: none !important;
  }
`

const RecText = styled.span`
  ${props => `
    margin-bottom:1vh;
    ${props?.styles}
`}`


const RecTextResponsive = styled.span`

  ${props => `
  display:none;
  @media (max-width: 426px) {
    margin-bottom:1vh;
    display:block;
    ${props?.styles}
  }

`}`

const TastingSummaryRow = (props) => {
  return (
    <>
      <WineWrapper recommendation={props.recommendation}>

        <WineImageWrapper>
          <WineImage src={props.wineImage} alt="wineImage" />
        </WineImageWrapper>
        <WineInfoWrapper>
          <WineName>{props.wineName}</WineName>
          <WineType>{props.wineType} {props.subtype} {props.wineYear}</WineType>
          {
            !props.recommendation ?
              <RateStars
                value={props.rateBox}
                edit={false}
                color2={Custom.getColour("mainColor")}
                size={6}
              />
              :
              null
          }
          {!props.recommendation ?
            <>
              {props.layoutType === "BLIND_TEMPLATE" ?
                <WineUserRateDiv>
                  <WineUserRateDiv styles={"flex-direction:column;"}>
                    <WineUserTitleRate>Precio estimado</WineUserTitleRate>

                    <WineUserRate>{props.userPrice} {props.currency}</WineUserRate>
                  </WineUserRateDiv>
                  {props.winePrice !== undefined && props.winePrice !== null && typeof props.winePrice !== "object" ? (
                    <WineUserRateDiv styles={"flex-direction:column;"}>
                      <WineUserTitleRate>Precio real</WineUserTitleRate>

                      <WineUserRate styles={"color:" + Colours.getColour("mainColor")}>{props.winePrice} {props.currency}</WineUserRate>
                    </WineUserRateDiv>
                  ) : (
                    <></>
                  )}
                </WineUserRateDiv>
                :
                <>
                  <WineRatePrice>{ControlTrad.getTrad("textValorate")}</WineRatePrice>
                  <WineRatePrice styles={"color:" + Colours.getColour("mainColor") + ";font-weight:bold;font-size:3vh"}>{props.ratePrice}</WineRatePrice>
                </>
              }

            </>
            :
            <RecTextResponsive styles={"color:#b21c38; font-weight:bolder;"}>
              {ControlTrad.getTrad("winerecommended")}
            </RecTextResponsive>
          }

        </WineInfoWrapper>
        <LastColumWrapper>
          {
            !props.recommendation ?
              <NotesWrapper>
                <NotesTitle>{ControlTrad.getTrad("textNotes")}</NotesTitle>
                <NotesAssistant>{props.assistantAnn}</NotesAssistant>
              </NotesWrapper>
              :
              <RecTextWrapper>
                <RecText styles={"color:#b21c38; font-weight:bolder;"}>
                  {ControlTrad.getTrad("winerecommended")}
                </RecText>
                <RecText>
                  {ControlTrad.getTrad("textrecommended")}
                </RecText>
              </RecTextWrapper>
          }

        </LastColumWrapper>

      </WineWrapper>

    </>
  );
};

export default TastingSummaryRow;
