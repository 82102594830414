import React from "react";

import styled from "styled-components";
import Element from "../Element";
import AppStateContext from "appState/AppStateContext";
import ControlTrad from "assets/trad/ControlTrad";


const Wrapper = styled.div`
${props => `
    text-align : center;
    color: white;
    font-size: 3vh;
    ${props.params.styles}
`}`;

const QuizPoitns = (props) => {
    const appState = React.useContext(AppStateContext);
    let quizPointsUser = appState.state.get("quizPointsUser");
    return (
        <Element params={props.params}>
            {props.params.final 
            ? <Wrapper params={props.params}><b> {ControlTrad.getTrad("finalPoints")} {quizPointsUser}</b></Wrapper>
            : <Wrapper params={props.params}><b> {ControlTrad.getTrad("questionPoints")} {quizPointsUser}</b></Wrapper>}
        </Element>
    );

}
export default QuizPoitns;