import React from "react";
import styled from "styled-components";
import Colours from "assets/custom/Custom";
import AppStateContext from "appState/AppStateContext";
import User from "assets/user/User";
import ControlTrad from "assets/trad/ControlTrad";
import Tasting from "assets/tasting/Tasting";
import ReactHtmlParser from 'react-html-parser'; 

const CategoryTitle = styled.h1`
    color:white;
    font-size:6vh;
    margin-top:0.5vh;
    margin-bottom:0.5vh;
`

const CategorySubTitle = styled.h2`
    width:90%;
    border-bottom: 0.3vh solid white;
    color:white;
    margin-top:0;
    font-weight:normal;
    padding-bottom:0.5vh;
`

const CategoryKeyText = styled.span`
    font-weight:bold;
`

const NoSelectedValues = styled.div`
    border-radius:1vh;
    background-color: rgba(75, 75, 75, 0.95);
    color:white;
    padding: 1.5vh;
    box-shadow: 0px 0px 30px 3px white;
`

const NoSelectedValuesHint = styled.div`
    padding: 1vh 4vh;
    text-align: left;
`

const SelectedValues = (props) => {
    const Wrapper = styled.div`
        ${props => `
            flex-direction: column;
            position:absolute;
            display: flex;
            justify-content:flex-start;
            align-items:center;
            width: 29%;
            height: 100%;
            top: 0;
            left: 2.5%;
            @media (max-width: 426px) {
                display:none;
            }
            ${props.landscapeScreen === "portrait" ? `
                @media (min-width: 426px) and (pointer: coarse){
                    display:none;
                }
            ` : ""}
        `}
    `;

    const CategoryList = styled.span`
        border-radius:1vh;
        background-color: ${Colours.getColour("mainColor")};
        color:white;
        width:22vw;
        height:5vh;
        margin-bottom:2vh;
        display:flex;
        align-items:center;
        justify-content:center;
    `

    const appState = React.useContext(AppStateContext);
    let categories, currentAssessment, category;
    if (Tasting.getType() === "live") {
        categories = appState.state.get("currentAssessmentCategories");
        currentAssessment = appState.assessments.get("current");
        category = categories.find(category => category.id === currentAssessment);
    } else {

        categories = props.categories;
        currentAssessment = props.category;
        category = props.category;
    }



    const options =
    {
        "OPTION_LOW":
        {
            name: { CA: "Baixa", ES: "Baja", EN: "Low" }
        },
        "OPTION_MEDIUM":
        {
            name: { CA: "Mitja", ES: "Media", EN: "Medium" },
        },
        "OPTION_HIGH":
        {
            name: { CA: "Alta", ES: "Alta", EN: "High" }
        },
    };


    const getViews = (category, oldViews, component, tree) => {
        let views = oldViews;

        Object.keys(category).forEach(categoryKey => {

            if (category[categoryKey] instanceof Object) {
                views = getViews(category[categoryKey], views, component, tree);
            } else if (tree) {
                if("options" in component){ 
                    component.options.forEach(option => {
                        if (option.id === categoryKey && options[category[categoryKey]]) {
                            views[ControlTrad.getUserTrad(option?.name)] =
                                <CategoryList>
                                    <CategoryKeyText>{ControlTrad.getUserTrad(option?.name)}</CategoryKeyText>: {ControlTrad.getUserTrad(options[category[categoryKey]]?.name)}
                                </CategoryList>
    
                        } else if ("options" in option) {
                            views = getViews(category, views, option, tree);
                        }
    
                    })
                }
            } else {
                if (categoryKey === component?.id && category[categoryKey]) {
                    if (component?.options?.find(x => x.id === category[categoryKey]) !== undefined) {
                        let userOptionValue = ControlTrad.getUserTrad(component?.options.find(x => x.id === category[categoryKey])?.name);

                        views[ControlTrad.getUserTrad(component?.name)] =
                            <CategoryList>
                                <CategoryKeyText>{ControlTrad.getUserTrad(component?.name)}</CategoryKeyText> : {userOptionValue}
                            </CategoryList>
                    } else {
                        views[ControlTrad.getUserTrad(component?.name)] =
                            <CategoryList>
                                <CategoryKeyText>{ControlTrad.getUserTrad(component?.name)}</CategoryKeyText> : {category[categoryKey]}
                            </CategoryList>
                    }
                }
            }

        })

        return views;
    }

    const loadSelectedValues = () => {
        let viewsObj = {};

        category?.components?.forEach(component => {
            viewsObj = getViews(props.userValues, viewsObj, component, component?.type === "TREE")
        })

        let views = [];
        Object.keys(viewsObj).forEach(key => {
            views.push(viewsObj[key])
        })

        if (views.length === 0) {
            views.push(
                <NoSelectedValues>
                    {ControlTrad.getTrad("noSelected")}
                    <NoSelectedValuesHint>
                        {ReactHtmlParser(Tasting.getType() === "podcast" ? ControlTrad.getTrad("noSelectedHintPod") : ControlTrad.getTrad("noSelectedHint"))}
                    </NoSelectedValuesHint>
                </NoSelectedValues>
            );
        }
        return views;
    }

    return (
        <Wrapper landscapeScreen ={props.landscapeScreen}>
            <CategoryTitle>{ControlTrad.getUserTrad(category?.name)}</CategoryTitle>
            <CategorySubTitle>{ControlTrad.getTrad("yourSelections")}</CategorySubTitle>
            {loadSelectedValues()}
        </Wrapper>
    );
};

export default SelectedValues;
