import React, { useState, useEffect } from "react";

import Element from "../Element";

import styled from "styled-components";
import Tasting from "assets/tasting/Tasting";
import Colours from "assets/custom/Custom";
import CopyIcon from "assets/images/copy.svg";
import ShareIcon from "assets/images/share.svg";
import ModalMessage from "components/Sandbox/ModalMessage";
import ControlTrad from "assets/trad/ControlTrad";

const Wrapper = styled.div`
${props => `
  display:flex;
  align-items:center;
  justify-content:center;
  width:100%;
  height:100%;
  font-size : ${props.params.shared ? "2.3vh" : "3.5vh"};
  font-weight:600;
  cursor : ${props.params.shared ? "pointer" : ""};

  background-color : ${props.params.shared ? Colours.Colours.mainColor : ""};
  color : ${props.params.shared ? "white" : "black"};
  border-radius : ${props.params.shared ? "1vh" : "0vh"};
  
  ${props.params.styles}
`}`

const IconWrapper = styled.div`
  ${props => `
    display:flex;
    align-items:center;
    justify-content:center;
    width:100%;
    margin: 1vh 1vw 1vh 1vw;
    ${props?.styles}
`}`

const Icon = styled.img`
  ${props => `
    width: 2vh;
    padding-left: 1vh;

    @media (max-width: 426px) {
      height: 4vh;
      width: auto;
      margin: 0.25vh;
    }
    ${props?.styles}
  `}
`

const CodeShareText = styled.span`
  display:flex;
  align-items:center;
  justify-content:center;
  width:100%;
  height:8vh;
  font-size:2.7vh;
`


const TastingPublicCode = (props) => {
  let id = Tasting.getPublicCode()
  const [popUp, setPopUp] = useState(false);
  const [active, setActive] = useState(false);

  const copyCode = () => {
    var url = document.location.origin + "/" + id;
    navigator.clipboard.writeText(url).then(function () {
      setPopUp(true);
    }, function (err) {
      console.error('Async: Could not copy text: ', err);
    });
  }

  const shareCode = () => {
    if (navigator.share) {
      navigator.share({
        title: "UpTasting",
        text: `Join the tasting with code ${id} on UpTasting!`,
        url: document.location.origin + "/" + id,
      });
    }
  }

  useEffect(() => {
    if(popUp && !active){
      setActive(true);
      setTimeout(() => {
        setPopUp(false);
        setActive(false);
      }, 3000);
    }
  }, [popUp]);

  return (
    <Element params={props.params}>
      {props.params.shared ? (
        <Wrapper params={props.params}
          onClick={(e) => {
            copyCode();
          }}>
          {id}
          <Icon src={CopyIcon} />
        </Wrapper>
      ) : props.params.responsiveShared ? (
          <Wrapper params={{ ...props.params, styles: "flex-direction: column; justify-content: flex-start;" + props?.params?.styles }}>
            {id}
            <IconWrapper>
              <Icon src={CopyIcon} styles={"filter: invert(100%);"} onClick={copyCode} />
              <Icon src={ShareIcon} styles="" onClick={shareCode} />
            </IconWrapper>
          </Wrapper>
      ) : (
        <Wrapper params={props.params}>{ControlTrad.getTrad("codeEvent")} {id}</Wrapper>
      )}
      <ModalMessage 
        boxSize={"top: 100%; right: 100%; min-width:5vh; width:55vh; height: 8vh; min-height:5vh; box-shadow: 0 0 3vh 0.1vh rgba(101,102,103,0.9); border-radius: 1vh;"}
        styleMain={"background-color:white; color: black;"}
        header={false} 
        isShown={popUp} 
        setIsShown={setPopUp} 
        background={false} 
        isChat={true} >
          <CodeShareText>{ControlTrad.getTrad("copyCode")}</CodeShareText>
      </ModalMessage>
    </Element>
  );
};

export default TastingPublicCode;
