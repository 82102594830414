
import React, { useState } from "react"
import styled from "styled-components"

import Element from "../Element";
import Button from "components/Shared/Button";
import Fetch from "Fetch/Fetch";
import User from "assets/user/User";
import LocalStorage from "LocalStorage/LocalStorage";
import Tasting from "assets/tasting/Tasting";
import ControlTrad from "assets/trad/ControlTrad";
import ModalMessage from "components/Sandbox/ModalMessage";

const WrapperModal = styled.div`
  display:flex;
  flex-direction:column;
  align-items:center;
  justify-content:space-around;
  width:100%;
  font-size:3vh;
  height: 100%;
  padding: 1vh 0;
`

const SpanTxt = styled.span`
  padding-left: 2vh;
  padding-right: 2vh;
`

const ErrorText = styled.div`
    margin: 1vh 0;
    display:flex;
    algin-items:center;
    justify-content:center;
    font-size:2vh;
`

const FormButton = (props) => {
    const [isShown, setIsShown] = useState(false)

    const loadComponents = (elements) => {
        let validate = true;
        let obj ={}
        elements.forEach(element => {
            //console.log(elements);
            let value = "";
            if(document.getElementById(element.id).type === "checkbox"){
                value = document.getElementById(element.id).checked;

            } else {
                value = document.getElementById(element.id)?.getAttribute("formValue")
                if (value === null | value === undefined) {
                    value = document.getElementById(element.id).value;
                }
            }

            if (element.required && (value === '' || value === false)) {
                validate = false;
                document.getElementById(element.id + "error").style.visibility = "visible";
            } else {
                let idT = element.id;
                obj[idT] = value
                document.getElementById(element.id + "error").style.visibility = "hidden";
            }

        });
        if (validate) {
            sendInfo(obj)
            props.params.onClick();
        } else if (props.params.errorModal) {
            setIsShown(true)
        }
    }

    const loadErrors = (elements) => {
        let views = [];

        elements.forEach(element => {
            let value = "";
            let textElement = "";
            if(element){
                if(document.getElementById(element.id)?.type === "checkbox"){
                    value = document.getElementById(element.id).checked;
    
                } else {
                    value = document.getElementById(element.id)?.getAttribute("formValue")
                    if (value === null | value === undefined) {
                        value = document.getElementById(element.id)?.value;
                    }
                }
    
                if (element.required && (value === '' || value === false)) {
                    //console.log(element.id, element.id.includes("Age"), "request_age")
                    textElement = element.id.includes("Age") ? ControlTrad.getTrad("ageError") : document.getElementById(element.id + "error")?.textContent
                    views.push(
                        <ErrorText>
                            {textElement}
                        </ErrorText>
                    )
                }
            }

        })

        return views;
    }

    const sendInfo = (obj)=> {
        User.setName(obj.inputUserInfo)
        User.setGender(obj.selectOpGender)
        User.setAge(obj.selectOpAge)

        Fetch.post(User.getUser(), "assistant/" + User.getId()).then((res) => {
            let INSTANCE_ID = Tasting.getPublicCode();
            LocalStorage.set(INSTANCE_ID, User.getUser());
            console.log("SEND", res);
        });
    }

    return (
        <Element params={props.params}>
            <Button 
                styles={"background:#AE1C38;border: 0.3vh solid #AE1C38;"}
                value={props.params.activeNext === null ? ControlTrad.getTrad("loading") : ControlTrad.getTrad("nextLobby")} 
                active={props.params.activeNext !== null}
                onClick={() => loadComponents(props.params.elements)}
            />

            <ModalMessage
                boxSize={"min-width: 80vh !important; border-radius:1.5vh;"}
                styleHeader={"border-top-left-radius:1.5vh; border-top-right-radius:1.5vh; font-size:2.2vh;"}
                styleMain={"border-bottom-left-radius:1.5vh;border-bottom-right-radius:1.5vh;"}
                media={"min-width: 50vw !important;"}
                isShown={isShown}
                setIsShown={setIsShown}
                title={ControlTrad.getTrad("errorInitalForm")} >

                <WrapperModal>
                    <SpanTxt>{loadErrors(props.params.elements)}</SpanTxt>
                    <Button styles={"width:15vw;height:3vh;display:flex;justify-content:center;"} onClick={() => setIsShown(false)}value={ControlTrad.getTrad("accept")}/>
                </WrapperModal>
            </ModalMessage>
        </Element>

    );

}
export default FormButton;