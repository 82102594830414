import React from "react";
import User from "assets/user/User"

import styled from "styled-components";
import ControlTrad from "assets/trad/ControlTrad";
import Colours from "assets/custom/Custom";


const QuizRank = (props) => {

    const Rank = styled.div`
        ${(props) => `
            text-align : left;
            color: white;
            width:100%;
            display:flex;
            flex-direction:row;
            height: auto;
            border-radius:0.5vh;
            background-color: ${props.userID === User.getId() ? '#d3d3d3' : 'white'};
        `}
    `;


    const RankTitle = styled.div`
        text-align : left;
        color: black;
        font-size: 2vh;
        flex: 2;
        padding: 2vh 1vh;

        @media (max-width: 426px) {
            padding: 1vh;
        }
    `;
    const RankPoints = styled.div`
        text-align : center;
        color: white;
        font-size: 2vh;
        flex: 1;
        padding: 2vh 1vh;
        font-weight:bold;
        background-color: ${Colours.getColour("mainColor")};
        border-top-right-radius: 0.5vh;
        border-bottom-right-radius: 0.5vh;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        @media (max-width: 426px) {
            padding: 1vh;
            font-weight: normal;
        }
    `;

    const RankAnswer = styled.div`
        text-align : center;
        color: ${Colours.getColour("mainColor")};
        font-size: 2vh;
        flex: 2;
        padding: 2vh 1vh;
        font-weight:bold;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        @media (max-width: 426px) {
            padding: 1vh;
        }
    `;

    return (
        <Rank params={props.params}>
            <RankTitle>{props.user}</RankTitle>
            <RankAnswer >{props.answer !== undefined ? props.answer : ControlTrad.getTrad("noAnswer")}</RankAnswer>
            <RankPoints>{props.points + " " + "PTS"} </RankPoints>
        </Rank>
    );

}
export default QuizRank;