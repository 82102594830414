import React, { useEffect, useState } from "react"
import styled from "styled-components";

import AppStateContext from "appState/AppStateContext";
import Element from "../Element";
import RateStars from 'components/Shared/RateStars';
import Websockets from "Websockets/Websockets";
import ControlTrad from "assets/trad/ControlTrad";
import Custom from "assets/custom/Custom";
import Tasting from "assets/tasting/Tasting";
import FetchUtils from "Fetch/FetchUtils";

const Wrapper = styled.div`
  font-size: 2.5vh;
  background: white;
  height:100%;
  width:100%;
  display:flex;
  text-align:center;
  align-items:center;
  border-radius: 1vh;
  flex-direction: column;
  justify-content: flex-start;
`;

const Title = styled.div`
  font-weight: bold;
  padding-top: 1vh;
`

const RateBox = (props) => {
  const appState = React.useContext(AppStateContext);
  let currentProductRef, rate;

  if(Tasting.getType() === "live"){
    rate = appState?.state.get('currentUserAssessmentsParameters')?.rateBox;
    currentProductRef = appState?.state.get(`currentWine`)?.reference;
  } else {
    rate = props.params.rate;
    currentProductRef = props.params.currentProductRef;
  }

  const [rateBoxValue, setRateBoxValue] = useState(rate);

  useEffect(() => {
    setRateBoxValue(rate);
  }, [currentProductRef])

  const sendAnswers = (value) => {
    if(Tasting.getType() === "live"){
      Websockets.send("SLAVE_PRODUCT_PARAMETER", [
        currentProductRef,
        "rateBox",
        value,
      ], false);
    } else {
      props.params.setDataWine((prev) => ({
        ...prev,
        [currentProductRef]: {
          ...prev[currentProductRef],
            rateBox: value
          }
      }));
      FetchUtils.sendProductParameter(currentProductRef, "rateBox", value);
    }
  };

  const onRateChange = (val) => {
    setRateBoxValue(val);
    sendAnswers(val);
  };

  return (  
    <Element params={props.params} >
      <Wrapper>
        <Title>{ControlTrad.getTrad("rateWine")}</Title>
        <RateStars
          value={rateBoxValue}
          onChange={onRateChange}
          clear={false}
          color1={"#4d4d4d"}
          color2={Custom.getColour("mainColor")} />
      </Wrapper>
    </Element>
  );
};

export default RateBox;