import Tasting from "assets/tasting/Tasting";

let INSTANCE_ID;

const apiServersUrls = {
  prod: "https://uptasting-api-server.herokuapp.com",
  dev: "https://uptasting-api-server-dev.herokuapp.com",
  local: "http://localhost:8080"
}

const DOMAIN = apiServersUrls[process.env.REACT_APP_ENVIRONMENT];
console.debug("Selected api url: ", DOMAIN, process.env.REACT_APP_ENVIRONMENT);

class Fetch {

  static async apiGet(pathFetch) {
    let response = await fetch(pathFetch);
    let resJson = await response?.json();

    if (resJson.error) {
      throw resJson;
    } else {
      return resJson;
    }
  }

  static async apiPost(path, body) {
    var headers = new Headers();
    headers.append("Content-Type", "application/json");

    var requestOptions = {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(body || {}),
      redirect: 'follow'
    };

    let response = await fetch(path, requestOptions);
    let resJson = await response?.json();

    if (resJson.error) {
      throw resJson;
    } else {
      return resJson;
    }
  }

  static async get(param) {
    INSTANCE_ID = Tasting.getPublicCode();
    return this.apiGet(DOMAIN + "/api/v1/session/" + INSTANCE_ID + "/" + param);
  }

  static async addAssitant(data) {
    INSTANCE_ID = Tasting.getPublicCode();
    let response = this.apiPost(DOMAIN + "/api/v1/session/" + INSTANCE_ID + "/assistant", data);
    return response;
  }

  static async getAssitant(assistantId) {
    INSTANCE_ID = Tasting.getPublicCode();
    let response = this.apiGet(DOMAIN + "/api/v1/session/" + INSTANCE_ID + "/assistant/" + assistantId);
    return response;
  }

  static async updateAssitant(assistantId, data) {
    INSTANCE_ID = Tasting.getPublicCode();
    let response = this.apiPost(DOMAIN + "/api/v1/session/" + INSTANCE_ID + "/assistant/" + assistantId, data);
    return response;
  }

  static async addAssistantAction(assistantId, assistantAction) {
    INSTANCE_ID = Tasting.getPublicCode();
    let response = this.apiPost(DOMAIN + "/api/v1/session/" + INSTANCE_ID + "/assistant/" + assistantId + "/addAction", assistantAction);
    return response;
  }

  static async addAssitantAssessment(assistantId, data) {
    INSTANCE_ID = Tasting.getPublicCode();
    let response = this.apiPost(DOMAIN + "/api/v1/session/" + INSTANCE_ID + "/assistant/" + assistantId + "/addAssessment", data);
    return response;
  }

  static async addQuizWinner(assistantId, data) {
    INSTANCE_ID = Tasting.getPublicCode();
    let response = this.apiPost(DOMAIN + "/api/v1/session/" + INSTANCE_ID + "/assistant/" + assistantId + "/addQuizWinner", data);
    return response;
  }

  static async checkCode(code) {
    let response = this.apiGet(DOMAIN + "/api/v1/session/checkAll/" + code);
    return response;
  }

  static async getCodeInfo(code) {
    return this.apiGet(DOMAIN + "/api/v1/session/" + code);
  }

  static async getLiveSession(code) {
    let response = await fetch(DOMAIN + "/api/v1/session/" + code + "/live");
    return response?.json();
  }
  
  static async getCodeInfoPod(code) {
    return this.apiGet(DOMAIN + "/api/v1/session/" + code + "/podcast");
  }

  static async getUser(userUid) {
    return this.apiGet(DOMAIN + "/api/v1/user/" + userUid);
  }


  static async getPlan(tastId) {
    let response = await fetch(DOMAIN + "/api/v1/user/" + tastId + "/plan");
    return response?.json();
  }

  static async getTasting(uid, tastingId) {
    let response = await fetch(DOMAIN + "/api/v1/tasting/" + uid + "/" + tastingId);
    return response?.json();
  }

  static async getTastings(uid) {
    let response = await fetch(DOMAIN + "/api/v1/tasting/" + uid);
    return response?.json();
  }

  static async getTastings(uid, type) {
    let response = await fetch(DOMAIN + "/api/v1/tasting/" + uid + "?type=" + type);
    return response?.json();
  }

  static async getSessions(uid, tastingId) {
    let response = await fetch(DOMAIN + "/api/v1/tasting/" + uid + "/" + tastingId + "/sessions");
    return response?.json();
  }

  static async generateDemoSession(uid, tastingId, publicAccess = false) {
    return this.apiGet(DOMAIN + "/api/v1/tasting/" + uid + "/" + tastingId + "/demoSession?publicAccess=" + publicAccess);
  }

  static async getSurveyQuestions() {
    let response = await fetch(DOMAIN + "/api/v1/session/" + Tasting.getPublicCode() + "/surveyQuestions");
    return response?.json();
  }

  static async getRecommendation(tastUserId, wineId) {
    let response = await fetch(DOMAIN + "/api/v1/product/" + tastUserId + "/" + wineId)
    return response?.json();
  }
  
  static async post(request, param) {
    INSTANCE_ID = Tasting.getPublicCode();
    let response = this.apiPost(DOMAIN + "/api/v1/session/" + INSTANCE_ID + "/" + param, request);
    return response;
  }

  static async postLogin(idToken) {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      idToken: idToken,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(DOMAIN + "/api/v1/user/createSessionCookie", requestOptions)
      .then((response) => response.text())
      .then((result) => {
        var resultObj = JSON.parse(result);
        if (resultObj.error) {
          //onError(resultObj);
        } else {
          var sessionCookie = resultObj.sessionCookie;
          console.debug("[Upwine Auth] Session cookie: ", sessionCookie);
          document.cookie = "session=" + sessionCookie + "; max-age=3600; path=/";
          this.apiLogin();
        }
      });
    //.catch(onError);
  }
  static async apiLogin() {
    var requestOptions = {
      method: "POST",
      redirect: "follow",
    };

    fetch(DOMAIN + "/api/v1/user/login", requestOptions)
      .then((response) => response.text())
      .then((result) => {
        console.log(result);
        var resultObj = JSON.parse(result);
        if (resultObj.error) {
          //onError(resultObj);
        } else {
          console.log("ESTA PERFECT");
          //onSuccess(resultObj);
        }
      });
    //.catch(onError);
  }
}
export default Fetch;
