import React from "react"
import AppStateContext from "appState/AppStateContext";

import Element from "../Element";
import styled from "styled-components";
import Tasting from "assets/tasting/Tasting";

const Wrapper = styled.div`
${props=>`
  font-size: 2vh;
  color: white;
  font-weight: bold;
  @media (min-width: 426px) and (pointer: coarse) {
    font-size: 3vh;
  }
  ${props.params.styles}
`}`;

const WineIndexMobile = (props) => {
  const appState = React.useContext(AppStateContext);
  let wineIndex,wineCount;
  if(Tasting.getType() === "live"){
    wineIndex = appState.state.get("wineIndex");
    wineCount = appState.state.get("winesCount");
  }else{
    wineIndex = props.params.current;
    wineCount = props.params.numWines;
  }
  
  return (
    <>
      {wineCount > 1 ? (
        <Element params={props.params} id={'wineIndex'}>

          {!isNaN(wineIndex) ? (
            <Wrapper params={props.params}>
              {(wineIndex + 1)} / {wineCount}
            </Wrapper>
          ) : (
            <></>
          )}

        </Element>
      ) : (
        <></>
      )}
    </>
  );
};

export default WineIndexMobile;
