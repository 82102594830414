import Element from "../Element";
import Button from "components/Shared/Button"


const QuizStart = (props) => {


  return (
    <Element params={props.params}>
      <Button
        styles={props.params.btnStyles}
        active={props.params.active}
        onClick={props.params.onClick}
        highligthed={props.params.highligthed}
        value={props.params.text}
        used={props.params.used} />
    </Element>
  );
};

export default QuizStart;
