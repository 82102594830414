import React, { useEffect } from "react";
import AssistantComposer from "./Composer";

import useOrientation from "orientation/Orientation";
import JitsiFrame from "components/Composer/Elements/JitsiIframe/JitsiIframe";

import ErrorModal from "components/Composer/Elements/ErrorModal/ErrorModal";
import Tasting from "assets/tasting/Tasting";
import Custom from "assets/custom/Custom";
import User from "assets/user/User";
import VimeoComponent from "./Elements/VimeoComponent/VimeoComponent";

const MutableComponent = (props) => {

  let width = useOrientation() ? 100 : 50;
  let height = 100;
  const columns = (size) => {
    let res = "";
    for (let i = 1; i < size; i++) {
      res += "1fr ";
    }
    return res;
  };

  let Wrapper = {
    height: User.getDevice() === "IPHONE" ? "93vh" :'100vh' ,
    width: '100vw',
    display: 'grid',
    position: 'absolute',
    gridTemplateColumns: columns(width),
    gridTemplateRows: columns(height),
    zIndex: '-1',
    overflow: 'hidden',
  }

  const loadElements = (props) => {
    let views = [];
    props.forEach(([type, params]) => {
      if(!params?.disable){
        views.push(AssistantComposer.add(type, params));
      }
    });
    return views;
  };

  return (
    <div style={Wrapper} id="mutable">
      <ErrorModal 
        popUpError={props.popUpError}
        setPopUpError={props.setPopUpError}
        titleError={props.titleError}
        textError={props.textError}
        disableCloseButton={props.disableCloseButton}
      />

      {loadElements(props.display)}


      {(Tasting.getType() === "live" || props?.isInstructor ) && props.showVideo ?
        <JitsiFrame params={{
          position: props.videoPosition,
          isInstructor: props.isInstructor,
          styles: "scroll: auto;",
          showToast: props.toast
        }} />
        :
        <></>
      }

      {Tasting.getType() === "podcast" && props.showVideo ?  
        <VimeoComponent params={{
          position: props.videoPosition,
          video: props.currentProductVideo,
          videoEvents: props.videoEvents,
          landscapeScreen: props.landscapeScreen,
          highligthed: props.highligthedComp === "VimeoComponent"
        }} />
      : <></>}
    </div>
  );
};

export default MutableComponent;
