import React from "react";
import AppStateContext from "appState/AppStateContext";
import {nanoid} from "nanoid"
import Colours from "assets/custom/Custom";
import User from "assets/user/User";
import Element from "../Element";

import styled from "styled-components";
import ControlTrad from "assets/trad/ControlTrad";

const Wrapper = styled.div`
  ${(props) => `
  width:100%;
  height:100%;
  background: white;
  text-align: left;
  overflow:auto;
  ${props.params.styles}
`}
`;
const Header = styled.div`
  background: black;
  color: white;
  padding: 0 2vh;
  font-size: 3vh;
  height: 8vh;
  display: flex;
  & > div {
    margin: auto 0;
    flex: 1;
  }
  & > div:last-child {
    text-align: right;
    font-size: 2.5vh;
  }
`;
const Rows = styled.div`
  & > div:last-child {
    border-bottom: 0.2vh solid transparent;
  }
`;
const Row = styled.div`
  margin: 0.5vh 2vh;
  border-bottom: 0.2vh solid #d3d3d3;
`;
const Title = styled.div`
  font-weight: bold;
  font-size: 2.5vh;
`;
const Answers = styled.div`
  display: flex;
  flex-flow: row wrap;
  padding: 0.5vh 0;
  font-size: 2.2vh;
`;
const Answer = styled.div`
  margin: 0 1vh 0 0;
`;

const AssessmentsList = (props) => {
  const Number = styled.span`
  color: ${Colours.getColour("mainColor")};
`;
  
  const appState = React.useContext(AppStateContext);
  const aggregatedAssessmentsAnswers = appState.state.get(
    "aggregatedAssessmentsAnswers"
  );
  const currentAssessmentCategories = appState.state.get(
    "currentAssessmentCategories"
  );
  const currentWineRef = appState.state.get("currentWine")?.reference;
  const assistants = appState.state.get("assistants");

  const loadAssessments = () => {
    let views = [];

    currentAssessmentCategories?.forEach((category) => {
      views.push(
        <div key={nanoid()}>
          <Header>
            <div>{ControlTrad.getUserTrad(category.name)}</div>
            <div>
              {ControlTrad.getTrad("answersStats")} {aggregatedAssessmentsAnswers?.[category.id]?.total || 0}/
              {Object.keys(assistants).length}
            </div>
          </Header>
          <Rows>{loadComponents(category.components, [category.id])}</Rows>
        </div>
      );
    });
    return views;
  };
  
  const loadComponents = (category, ids) => {
    let views = [];
    category?.forEach((component) => {
      views.push(
        <Row key={nanoid()}>
          <Title>{ControlTrad.getUserTrad(component?.name)}</Title>
          <Answers>
            {loadAnswers(component?.options, ids.concat(component?.id), component?.type)}
          </Answers>
        </Row>
      );
    });
    return views;
  };

  const getLeafInfo = (leafs, leafId) => {
    for (let i = 0; i < leafs.length; i++) {
      let leaf = leafs[i];
      if (leaf.id === leafId) {
        return { name: leaf.name, icon: leaf.icon, id: leaf.id };
      } else {
        if ("options" in leaf) {
          let res = getLeafInfo(leaf.options, leafId);
          if (res !== false) return res;
        }
      }
    }
    return false;
  };

  const loadAnswers = (answers, ids, type) => {
    let views = [];

    let assessmentsList = {}
    answers?.forEach(answer => {
      if(type !== "TREE")
        assessmentsList[answer.id] = 0;
    })
    Object.keys(assistants).forEach(assistantKey => {

      let assistant = assistants[assistantKey];
      let assistantCat = assistant?.assessments?.[currentWineRef]?.categories

      if(assistantCat?.[ids[0]]){
        if(assistantCat?.[ids[0]][ids[1]]){

          if(type === "TREE") {

            Object.keys(assistantCat[ids[0]][ids[1]]).forEach(aggregatedTreeKey => {

              if(assessmentsList[aggregatedTreeKey]) 
                assessmentsList[aggregatedTreeKey] = assessmentsList[aggregatedTreeKey] + 1;
              else 
                assessmentsList[aggregatedTreeKey] = 1;

            })

          } else {

            if(assessmentsList[assistantCat?.[ids[0]][ids[1]]]) 
              assessmentsList[assistantCat?.[ids[0]][ids[1]]] = assessmentsList[assistantCat?.[ids[0]][ids[1]]] + 1;
            else 
              assessmentsList[assistantCat?.[ids[0]][ids[1]]] = 1;
          }
        }
      }
    })

    Object.keys(assessmentsList).forEach(assessmentKey => {
      let name = ControlTrad.getUserTrad(answers?.find(x => x.id === assessmentKey)?.name);
      
      if((name === undefined || !name) && answers !== undefined) {
        let leaf = getLeafInfo(answers, assessmentKey);
        if(leaf){
          name = ControlTrad.getUserTrad(leaf.name);
        }
      }

      if(answers === undefined || !answers)
        name = assessmentKey;

      views.push(
        <Answer  key={nanoid()}>
          {name}:{" "}
          <Number>
            {assessmentsList[assessmentKey]}
          </Number>
        </Answer>
      )
    })

    //Older version
    /*
    if(type === "TREE"){
      if(aggregatedAssessmentsAnswers && aggregatedAssessmentsAnswers?.[ids[0]]?.[ids[1]]) {

        Object.keys(aggregatedAssessmentsAnswers?.[ids[0]]?.[ids[1]]).forEach(aggregatedTreeKey => {
          let leafInfo = getLeafInfo(answers, aggregatedTreeKey)

          views.push(
            <Answer  key={nanoid()}>
              {leafInfo.name[User.getLang()]}:{" "}
              <Number>
                {aggregatedAssessmentsAnswers?.[ids[0]]?.[ids[1]][aggregatedTreeKey] || 0}
              </Number>
            </Answer>
          );

        })  

      }
    } else {
      answers?.forEach((answer) => { 
        views.push(
          <Answer  key={nanoid()}>
            {answer.name[User.getLang()]}:{" "}
            <Number>
              {aggregatedAssessmentsAnswers?.[ids[0]]?.[ids[1]]?.[answer.id] || 0}
            </Number>
          </Answer>
        );
      });
    }*/


    return views;
  };

  return (
    <Element params={props.params}>
      <Wrapper params={props.params} key={`foo-component-${aggregatedAssessmentsAnswers}`}>
        {loadAssessments()}
      </Wrapper>  
    </Element>
  );
};

export default AssessmentsList;
