import React, { useState, useEffect, isValidElement } from "react";
import Button from "components/Shared/Button";
import styled from "styled-components";
import Colours from "assets/custom/Custom";
import User from "assets/user/User";
import { nanoid } from "nanoid";
import ControlTrad from "assets/trad/ControlTrad";
import flecha from 'assets/images/icons/Mflecha.svg';

const RelativeWrapper = styled.div`
  ${props =>`
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    z-index: 1;
    top:0;
    background: ${props.background ? props.background : "rgba(216, 216, 216)"};
  `}
`;

const Wrapper = styled.div`
  border-radius: 2vh;
  overflow-y: scroll;
  flex: 1;
  background:rgba(216,216,216);
  z-index:1;
  display: flex;
  flex-direction: row;
  & > div {
    flex: 1;
  }
`;

const Option = styled.div`
  ${(props) => `
    display:flex;
    color: ${props.selected ? "white" : "black"};
    background: ${props.selected ? Colours.getColour("mainColor") : "white"};
    margin: 1vh;
    box-shadow: "rgb(0 0 0 / 50%) 0px 0.5vh 1vh 0px";
    cursor: pointer;
    font-size: 2.3vh;
    border-radius: 2vh;
  `}
`;

const OptionHoverWrapper = styled.div`
  &:hover ${Option} {
    box-shadow: rgb(0 0 0 / 50%) 0px 0.5vh 1vh 0px;
  }
`

const Main = styled.div`
  display:flex;
  align-items:flex-start;
  flex: 1;
  margin: auto;
`;

const Icon = styled.div`
  height: 7vh;
  width: 7vh;
  border-radius: 50%;
  background: white;
  padding: 0.2vh;
  display: flex;
  overflow: hidden;
  margin: 1vh;
  > img {
    width: 5vh;
    margin: auto;
    overflow: hidden;
    transform: scale(2);

  }
`;

const SelectButton = styled.div`
  ${(props) => `
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 1vh;
    padding: 1vh;
    font-size: 2.5vh;
    margin: 1vh;
    border: ${props.isMobile && !props.selected ? "" : "0.2vh solid white"};
    color: ${props.selected ? "white" : "black"};
    background: ${props.selected || props.isMobile ? Colours.getColour("mainColor") : "white"};
  `}
`;

const ButtonWrapper = styled.div`
  & > div {
    margin: 1vh;
  }
  @media (max-width: 426px) {
    display:none;
  }
  @media (min-width: 426px) and (pointer: coarse) {
    display:none;
  }
`;

const ButtonWrapperResponsive = styled.div`
  display:flex;
  justify-content: space-between;
  background-color: #000;
  @media (min-width: 426px) {
    display:none;
  }
  @media (min-width: 426px) and (pointer: coarse) {
    display:flex;
    padding: 0.5rem 0rem;
  }
  @media (max-width: 426px) and (pointer: coarse) {
    padding: 0.5rem;
  }
`;

const Flecha = styled.img`
  ${(props) => `
  `}
`

const TreeSelector = (props) => {
  const [state, setState] = useState(props.state);
  const [selected, setSelected] = useState([]);

  //returns array with parents of given options
  const getOptionParents = (options, val, keys = [], index = 0) => {
    for (let i = 0; i < options?.length; i++) {
      let option = options[i];
      if (option.id === val) {
        return keys;
      }
      keys[index] = option.id;
      let found = getOptionParents(option.options, val, keys, index + 1);
      if (found) {
        return keys;
      }
      keys.length = index;
    }
  };

  //sets leaf to current state
  const setLeafOption = (keys, val, currentState, index = 0) => {
    if (index === keys.length) {
      if (val in currentState) {
        delete currentState[val]
      } else {
        currentState[val] = {};
      }

      return currentState;
    } else {
      let res = setLeafOption(keys, val, currentState[keys[index]], index + 1);
      currentState[keys[index]] = res;
      return currentState;
    }
  };

  const selectOption = (val) => {
    let keys = getOptionParents(props.data.options, val);
    let currentState = { ...state };
    currentState = setLeafOption(keys, val, currentState);
    setState(currentState);
  }

  const setSelectedValue = (e, index, val) => {
    e.stopPropagation();
    let sel = [...selected];
    sel.length = index;
    if (selected[index] === val) {
      sel.splice(index);
    } else {
      sel[index] = val;
    }
    setSelected(sel);
    setTimeout(()=>{
      document.getElementById("tree_items").scrollLeft =document.getElementById("tree_items").scrollWidth;
    }, 500)
  };

  const createWrapper = (index, options, currentState = null) => {
    let views = [];
    currentState = currentState !== null ? currentState : state;

    views.push(<div id={"tree_items"+index}>{loadOptionsList(index, options, currentState)}</div>);
    if (selected.length > 0 && index < selected.length) {
      views.push(
        createWrapper(
          index + 1,
          options?.find((option) => option.id === selected[index])?.options,
          selected.length > 0 ? currentState[selected[index]] : state
        )
      );
    }
    return views;
  };

  const loadOptionsList = (index, options, currentState) => {
    let views = [];
    let isMobile = User.getDevice() === "IPAD" || User.getDevice() === "IPHONE" || User.getDevice() === "MOBILE_WEB" || User.getDevice() === "TABLET_WEB";

    options?.forEach((option) => {
      views.push(
        <OptionHoverWrapper>
          <Option key={nanoid()}
            selected={option.id in currentState}
            onClick={() => {
              selectOption(option.id);
            }}>

            {option.icon ? (
              <Icon>
                <img src={option.icon} alt={"icon"}/>
              </Icon>
            ) : (
              <Icon></Icon>
            )}

            <Main>{ControlTrad.getUserTrad(option.name)}</Main>

            {option.options && option.id in currentState && option.options?.length > 0 ? (
              <SelectButton
                selected={option.id === selected[index]}
                isMobile={isMobile}
                onClick={(e) => {
                  setSelectedValue(e, index, option.id);
                }}>

                {isMobile ? (
                  <Flecha active={option.id === selected[index]} src={flecha} />
                ) : (
                  ControlTrad.getTrad("nextLevel")
                )}
              </SelectButton>
            ) : (
              null
            )}
          </Option>
        </OptionHoverWrapper>

      );
    });

    return views;
  };

  return (
    <RelativeWrapper background={props.background}>
      <ButtonWrapperResponsive>
        <Button
          value={ControlTrad.getTrad("cancel")}
          type="cancel"
          onClick={props.onCancel}
          styles={"border:0.1rem #fff solid; color: #fff;flex:1;font-size: 1rem !important; margin-right: 0.25rem"} />
        <Button
          value={ControlTrad.getTrad("add")}
          styles={"flex:1; font-size: 1rem !important;  margin-left: 0.25rem"}
          onClick={() => {
            props.onSave(state);
          }} />
      </ButtonWrapperResponsive>

      <Wrapper id={"tree_items"}>{createWrapper(0, props.data.options)}</Wrapper>

      <ButtonWrapper>
        <Button value={ControlTrad.getTrad("cancel")} 
          styles={"color:black"}
          type="cancel" onClick={() => { props.onCancel() }} />
        <Button
          value={ControlTrad.getTrad("add")}
          onClick={() => {
            props.onSave(state);
          }} />
      </ButtonWrapper>

    </RelativeWrapper>
  );
};

export default TreeSelector;
