import React from "react";
import styled from "styled-components";
import Colours from "assets/custom/Custom";

const Wrapper = styled.div`
  ${(props) => `
    background: ${Colours.getColour("mainColor")};
    width: 3vh;
    height: 3vh;
    padding: 1vh;
    border-radius: 50%;
    cursor: pointer;
    transition: 0.1s;
    opacity: ${props.active ? "0.9" : "0.5"};
    &:hover{
        opacity: ${props.active ? "1" : "0.5"};
    }
`}
`;
const Image = styled.img`
    height: 100%;
    width: 100%;
`;
const Icon = (props) => {
  return (
    <Wrapper
      active={props.active}
      title={props.hint}
      onClick={() => {
        props.onClick();
      }}
    >
      <Image src={props.src} />
    </Wrapper>
  );
};

Icon.defaultProps = {
  active: true,
  onClick: () => {},
};
export default Icon;
