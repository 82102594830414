import React from "react";
import styled from "styled-components";

import Element from "../Element";
import QuizRank from "./Rank";
import AppStateContext from "appState/AppStateContext";


const Ranking = styled.div`
    display:flex;
    flex-direction:column;
    height : 100%;
    padding : 2vh;
    border-radius : 1vh;
    background-color : rgba(255, 255, 255, 0.8);
    overflow-x:auto;
`;
const Rank = styled.div`
    padding: 1vh;
    @media (max-width: 426px) {
        padding: 0;
    }
`;

const QuizRanking = (props) => {

    const appState = React.useContext(AppStateContext);
    let assistants = appState.state.get("assistants");
    let reference = appState.state.get("quizActualQuestionReference")
    let question = appState.state?.get("quizActualQuestion")?.options;

    let loadUsers = () => {
        let rows = [];
        let rank = orderPlayers(assistants)
        rank?.forEach(element => {
            rows.push(
                <Rank>
                    <QuizRank
                        user={element.name}
                        userID={element.reference.split("/")[3]}
                        points={element.quizPoints}
                        answer={question?.[assistants[element.reference.split("/")[3]]?.quizAnswers?.[reference]]?.["ES"]}

                    />
                </Rank>
            );
        });
        return rows;
    }


    const orderPlayers = (obj) => {
        let adaptArray = [];
        for (let key in obj) {
            adaptArray.push(obj[key])
        }
        adaptArray.sort(function (a, b) {

            if (a.quizPoints < b.quizPoints) {
                return 1;
            }
            if (a.quizPoints > b.quizPoints) {
                return -1;
            }
            return 0;
        });
        return adaptArray;
    }

    return (
        <Element params={{ ...props.params, styles: "background-color : transparent" }} >
            <Ranking>
                {loadUsers()}
            </Ranking>
        </Element>
    );
};

export default QuizRanking;