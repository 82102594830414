import React, { useState } from "react";
import Element from "../Element";
import Button from "components/Shared/Button"

import ModalMessage from "components/Sandbox/ModalMessage";
import styled from "styled-components";
import ControlTrad from "assets/trad/ControlTrad";

const Wrapper = styled.div`
  font-size:1.5rem;
  padding-left:5.5vh;
  padding-right:5.5vh;
  display:flex;
  justify-content:center;
  margin-top:2vh;
`

const ButtonWrapper = styled.div`
  width:100%;
  display:flex;
  align-items:center;
  justify-content:center;
  margin-top:3vh;
  margin-bottom:0.5vh;
`

const IFinishButton = (props) => {
  const [popUp1, setPopUp1] = useState(false);

    const finallyStep = ()=>{
      if(props.params.noJitsi){
        props.params.onClick();
      }else{
        setPopUp1(true);
      }
    }
    const continueTast = () =>{
      props.params.onClick();
    }
  return (
    <>
      <Element params={props.params}>
        <Button onClick={finallyStep} value={props.params.text} />
      </Element>
      <ModalMessage 
       isShown={popUp1} 
       setIsShown={setPopUp1} 
       title={ControlTrad.getTrad("title_info_6")}
       titleStyle={"font-size:2.1vh; margin-left:3vh;"} 
       boxSize={"width:40rem; border-radius:1vh;"} 
       styleMain={"border-bottom-left-radius:1vh;border-bottom-right-radius:1vh;"}
       styleHeader={"border-top-left-radius:1vh;border-top-right-radius:1vh;"} >
          <Wrapper>{ControlTrad.getTrad("info_6")}</Wrapper>
          <ButtonWrapper>
            <Button styles={"background-color:black; border:none; color:white; width:23vh;"} onClick={()=>{setPopUp1(false)}} type={"cancel"} value={ControlTrad.getTrad("no")}/>
            <Button styles={"width:23vh; height: 2.9vh; margin-left:2vh;"} onClick={()=>{continueTast()}} value={ControlTrad.getTrad("yes")} />
          </ButtonWrapper>
          <br/>
      </ModalMessage>
    </>
  );
};

export default IFinishButton;
