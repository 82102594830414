import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Websockets from "Websockets/Websockets";
import AppStateContext from "appState/AppStateContext";
import ControlTrad from "assets/trad/ControlTrad";
import Tasting from "assets/tasting/Tasting";
import FetchUtils from "Fetch/FetchUtils";
import Colours from "assets/custom/Custom";

const Wrapper = styled.div`
  font-size: 4vw;
  height: 100%;
  width: auto;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  border-radius: 1vh;
  justify-content: center;
  padding : 3vw;
`;

const Title = styled.div`
  font-weight: bold;
  padding: 0 0 1vh;
`;

const OptionsWrapper = styled.div`
  display: flex;
  flex-direction: column; 
  width: 95%;
  margin: auto;
  justify-content: center;
`;

const Option = styled.div`
  ${(props) => `
    width: 50%;
    font-size: 1.8vh;
    margin: 0.5vh auto;
    padding: 1vh;
    background: ${props?.params?.selected || !props?.enabled ? Colours.getColour("mainColor") : "transparent"};
    color: ${props?.params?.selected || !props?.enabled ? "white" : "black"};
    border-radius: 0.7vh;
    white-space: nowrap;
    overflow: hidden;
    display: block;
    text-overflow: ellipsis;
    cursor: pointer;
    animation-fill-mode: forwards;
    animation-duration: 2s;

    @keyframes selectAnim {
      to {
        background: ${Colours.getColour("mainColor")};
        color: white;
        width: 60%;
      }
    }

    @keyframes unselectAnim {
      to {
        opacity: 0.75;
        width: 50%;
      }
    }

    ${props?.selected ? `
      animation-name: selectAnim;
    ` : props?.enabled ? `
      animation-name: unselectAnim;
    ` : `
      animation-name: none;
    `}
  `}
`;

const PriceRateBox = (props) => {
  const appState = React.useContext(AppStateContext);
  const [currentProductRef, setCurrentProductRef] = useState(null);
  const [priceBoxValue, setPriceBoxValue] = useState(null);

  const options = [
    //{ id: "Muy buena", text: ControlTrad.getTrad("rateForPriceVeryGood") },
    { id: "Buena", text: ControlTrad.getTrad("rateForPriceGood")},
    { id: "Regular", text: ControlTrad.getTrad("rateForPriceRegular") },
    { id: "Mala", text: ControlTrad.getTrad("rateForPriceBad") },
    //{ id: "Muy mala", text: ControlTrad.getTrad("rateForPriceVeryBad") },
  ];

  useEffect(() => {
    let newCurrentProductRef, newPriceBoxValue;    

    if (Tasting.getType() === 'live') {
      newCurrentProductRef = appState?.state.get(`currentWine`)?.reference;
      newPriceBoxValue = appState?.state.get('currentUserAssessmentsParameters')?.ratePrice;
    } else {
      newCurrentProductRef = props.currentProductRef;
      newPriceBoxValue = props.priceBoxValue;
    }

    if (newCurrentProductRef !== currentProductRef) setCurrentProductRef(newCurrentProductRef);
    if (newPriceBoxValue !== priceBoxValue) setPriceBoxValue(newPriceBoxValue);
  }, [appState, props]);

  const sendAnswer = (value) => {
    if(Tasting.getType() === 'live'){
      Websockets.send("SLAVE_PRODUCT_PARAMETER", [
        currentProductRef,
        "ratePrice",
        value,
      ],false);
    } else {
      props.setDataWine((prev) => ({
        ...prev,
        [props.currentProductRef]: {
          ...prev[props.currentProductRef],
          ratePrice: value
          }
      }));
      FetchUtils.sendProductParameter(props.currentProductRef, "ratePrice", value);
    }
  };

  const onChange = (val) => {
    if (val === priceBoxValue) {
      val = null;
    }
    setPriceBoxValue(val);
    sendAnswer(val);
  };

  const loadOptions = () => {
    let views = [];
    options.forEach((option) => {
      views.push(
        <Option
          onClick={() => { onChange(option.id) }}
          selected={option.id === priceBoxValue}
          enabled={priceBoxValue !== null && priceBoxValue !== undefined}>
          {option.text}
        </Option>
      );
    });
    return views
  }

  return (
      <Wrapper>
      <Title>{ControlTrad.getTrad("tittlerateWine")}</Title>

      <OptionsWrapper>
        {loadOptions()}
      </OptionsWrapper>

      </Wrapper>
  );
};

export default PriceRateBox;
