import React, { useState } from "react";
import styled from "styled-components";

import User from "assets/user/User";
import Fetch from "Fetch/Fetch";
import Colours from "assets/custom/Custom";
import ControlTrad from "assets/trad/ControlTrad";

const QuestionItem = styled.div`
    display:flex;
    flex-direction:column;
`;

const Title = styled.div`
    text-align : center;
    font-size: 4vh;
    color: white;
    padding: 0vh 0 2vh;
    margin: auto;
    max-width: 65%;
    @media (max-width: 426px) {
        max-width: 95%;
    }
`;

const Hint = styled.div`
    text-align : center;
    font-size: 2vh;
    color: white;
`;

const Options = styled.div`
    text-align : center;
    color: white;
    flex:1;
    padding: 1vh 1vh;
    display:flex;
    flex-direction:row;
    flex-wrap: wrap;
`;

const OptionBody = styled.div`
    display: flex;
    padding: 0.5vh 0px;
    text-align: center;
    color: rgb(255, 255, 255);
    transition: 0.3s;
    width: 49%;
`;

const OptionBodyActive = styled.div`
    display: flex;
    padding: 0.5vh 0px;
    text-align: center;
    width: 49%;
    transition: 0.3s;
    color: black;
`
const Option = styled.div`
    background-color: rgba(167, 167, 167, 0.3);
    border: 0.2vh solid white;
    padding: 1.4vh 0px;
    border-radius: 1vh;
    flex-grow: 1;
    font-size: 3vh;
    box-shadow: rgb(0 0 0 / 60%) 0px 12px 12px 0px;
    cursor: pointer;
    transition: all 0.3s ease 0s;
    margin: 1vh;
    &:hover {
        opacity:0.7;
    }
    @media (max-width: 426px) {
        font-size: 2.5vh;
    }
`;

const OptionActive = styled.div`
    background-color: rgb(250, 250, 240);
    border: 0.2vh solid white;
    padding: 1.4vh 0px;
    border-radius: 1vh;
    flex-grow: 1;
    font-size: 3vh;
    box-shadow: rgb(0 0 0 / 60%) 0px 12px 12px 0px;
    cursor: pointer;
    transition: all 0.3s ease 0s;
    margin: 1vh;
    &:hover {
        opacity:0.7;
    }
    @media (max-width: 426px) {
        font-size: 2.5vh;
    }
`;

const NumQuestion = styled.div`
    color: rgb(255, 255, 255);
    font-size: 2vh;
    text-align: center;
    padding: 5vh 0 2vh 0;
`;

const BNext = styled.div`
    ${props => `
        background: ${Colours.getColour("mainColor")};
        display: inline-block;
        border-radius: 0.7vh;
        font-size: 2.5vh;
        color: white;
        -webkit-transition: 0.1s;
        transition: 0.1s;
        cursor: pointer;
        border: 0.3vh solid ${Colours.getColour("mainColor")};
        opacity: 1;
        position: fixed;
        left: 85%;
        top: 5%;
        border-radius: 1vh;
        padding: 2vh;
        &:hover{
            background: ${Colours.getColour("secondaryColor")};
            border: 0.3vh solid ${Colours.getColour("secondaryColor")};
        }
        @media (max-width: 426px) {
            position: initial;
            left: 0%;
            top: 0%;
            margin:2vh 0 0;
        }
        @media (min-width: 426px) and (max-width:900px) and (pointer: coarse) {
            position: initial;
            left: 0%;
            top: 0%;
            margin:2vh 0 0;

        }
    `}
`;

const Question = (props) => {
    const [multiOptions, setMultiOptions] = useState(new Map());

    const loadOptions = () => {
        let row = [];
        let options = props?.options;
        Object.keys(options).forEach(key => {
            row.push(
                multiOptions.get(key) !== undefined ?
                    <OptionBodyActive
                        onClick={() => { clickOption(key, options[key]["ES"]) }}>
                        <OptionActive>
                            {options[key]["ES"]}
                        </OptionActive>
                    </OptionBodyActive>
                    :
                    <OptionBody
                        onClick={() => { clickOption(key, options[key]["ES"]) }}>
                        <Option>
                            {options[key]["ES"]}
                        </Option>
                    </OptionBody>
            )
        });

        return row;
    }

    const clickOption = (key, option) => {
        if (props.type === "SIMPLE_QUESTION") {
            sendAnswers([key])
        } else {
            setOptions(key, option);
        }
    }

    const setOptions = (i, answer) => {
        let valueHelp = new Map([...multiOptions]);
        if (valueHelp.length === 0) {
            valueHelp.set(i, answer);
        } else {
            if (valueHelp.get(i) != null) {
                valueHelp.delete(i);
            } else {
                valueHelp.set(i, answer);
            }
        }
        setMultiOptions(valueHelp);
    };


    const actionNextQuestion = () => {
        if (multiOptions.size !== 0) {
            if (props.type !== "SIMPLE_QUESTION") {
                let IDs = [];
                let idMap = multiOptions.keys();
                multiOptions.forEach(() => {
                    IDs.push(idMap.next().value)
                });
                sendAnswers(IDs)
            }
        }
        setMultiOptions(new Map())
        props.nextQuestion();
    }

    const sendAnswers = (key) => {
        Fetch.post({
            questionRef: props.reference,
            optionsIds: key,
        }, 'assistant/' + User.getId() + '/addSurveyAnswer').then((res) => {
        });
        props.nextQuestion();

    }

    return (
        <>
            <BNext
                onClick={(e) => { actionNextQuestion() }}
            >{ControlTrad.getTrad("next") + " >"}</BNext>

            <QuestionItem>
                <NumQuestion>{ControlTrad.getTrad("question")} {props.index}</NumQuestion>
                <Title>{ControlTrad.getUserTrad(props.question)}</Title>
                <Hint>{ControlTrad.getUserTrad(props?.hint)}</Hint>
                <Options>{loadOptions()}</Options>
            </QuestionItem>
        </>
    );
}
export default Question;