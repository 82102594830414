import React, { useEffect, useState } from "react";

import styled from "styled-components";
import AppStateContext from "appState/AppStateContext";
import Button from "components/Shared/Button"
import ModalMessage from "components/Sandbox/ModalMessage";
import User from "assets/user/User";
import Fetch from "Fetch/Fetch";
import ControlTrad from "assets/trad/ControlTrad";
import Tasting from "assets/tasting/Tasting";
import Custom from "assets/custom/Custom";

const Wrapper = styled.div`
    padding : 2vh;
    @media (max-width: 426px) {
        padding : 1rem;
    }
`

const BodyWrapper = styled.div`
    display:flex;
    flex-direction:column;
    
`

const LabelBox = styled.label`
    text-align: left;
    padding: 0 0 0.5vh;
    margin-top:1vh;
    @media (max-width: 426px) {
        padding: 0 0 0.25rem
    }
    @media (min-width: 426px) and (pointer: coarse) {
        font-size: 1.5vw;
    }
`

const InputBox = styled.input`
    font-size: 2vh;
    border : 0;
    margin-top:0.2vh;
    border-radius:1vh;
    padding:0.5vh;
    height:3.5vh;
    @media (max-width: 426px) {
        font-size: 2.5vh;
        height: 4vh;
    }
    @media (min-width: 426px) and (pointer: coarse) {
        font-size: 1.5vw;
        padding: 0.25vw;
        height: 2vw;
    }
`


const ButtonsWrapper = styled.div`
    padding : 1vh 0;
    display: flex;
    justify-content: space-between;
    @media (max-width: 426px) {
        padding : 1rem;
        flex-direction: column;
    }
`

const Check = styled.input`
    color: white;
    width: 2vh;
    height: 2vh;
    @media (max-width: 426px){
        width: 1rem;
        height: 1rem;
        marginTop: 0;
    }

    @media (min-width: 426px) and (pointer: coarse) {
        width: 1.5vw;
        height: 1.5vw;
    }
`

const DivCheck = styled.div`
    padding:2vh 0 ;
    text-align: initial;
    @media (max-width: 426px) {
        padding:1rem 0 0;
    }
`

const Label = styled.label`
    text-align:left;
    font-size: 2vh;
    color: #000;
    @media (max-width: 426px){
        font-size: 1rem;
    }
    @media (min-width: 426px) and (pointer: coarse) {
        font-size: 1.5vw;
    }
`
const LabelLinks = styled.a`
    font-size: 2vh;
    color: #000;
    @media (max-width: 426px){
        font-size: 1rem;
    }
    @media (min-width: 426px) and (pointer: coarse) {
        font-size: 1.5vw;
    }
`

const ErrorSpan = styled.span`
    display:none;
    color: red;
`
const ErrorP = styled.p`
    display:none;
`

const QuizWinnerModal = (props) => {
    const [name, setName] = useState(User.getNotDefaultName());
    const [email, setEmail] = useState("");
    const [check, setCheck] = useState(false);

    const appState = React.useContext(AppStateContext);
    let assistants, prize;
    if (Tasting.getType() === 'live') {
        assistants = appState.state.get("assistants");
        prize = appState.state?.get("quizPrize");
    }


    const orderPlayers = (obj) => {
        let adaptArray = [];
        for (let key in obj) {
            adaptArray.push(obj[key])
        }
        adaptArray.sort(function (a, b) {

            if (a.quizPoints < b.quizPoints) {
                return 1;
            }
            if (a.quizPoints > b.quizPoints) {
                return -1;
            }
            return 0;
        });
        return adaptArray;
    }

    function validateEmail(email) {
        email = email.replace(/^ /, '').replace(/ $/, '');
        setEmail(email);
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    const checkWinner = () => {
        let winner = orderPlayers(assistants)[0].reference;
        if (winner) {
            let correct = true;
            if (name.length === 0) {
                document.getElementById("quizWinnerNameError").style.display = "block"
                correct = false;
            } else document.getElementById("quizWinnerNameError").style.display = "none";

            if (!validateEmail(email)) {
                document.getElementById("quizWinnerEmailError").style.display = "block"
                correct = false;
            } else document.getElementById("quizWinnerEmailError").style.display = "none";

            if (!check) {
                correct = false;
                document.getElementById("quizWinnerCheckError").style.display = "block"
            } else document.getElementById("quizWinnerCheckError").style.display = "none";

            if (correct) {
                //compare user reference with the winner reference to check if its really the winner.
                winner = winner.split("/")[winner.split("/").length - 1];
                if (winner === User.getId()) {
                    Fetch.post({
                        name: name,
                        email: email
                    }, "assistant/" + User.getId())
                        .then(data => {
                            props.setIsShown(false);
                        })
                }
            }

        }

    }

    useEffect((() => { }), [Custom.Links]);
    useEffect(() => { setName(User.getNotDefaultName()) }, [User.getName()])


    return (
        <ModalMessage
            isShown={props.isShown}
            setIsShown={props.setIsShown}
            title={ControlTrad.getTrad("quizModalWinnerTitle") + (prize !== undefined ? " - " + ControlTrad.getTrad("winPrizeQuiz") + prize : "")}
            disableCloseButton={true}
            styleMain={"border-bottom-left-radius:1vh;border-bottom-right-radius:1vh;"}
            styleHeader={"border-top-left-radius:1vh;border-top-right-radius:1vh; font-size:3.3vh;"}
            boxSize={"border-radius:1vh;min-width:64vh; max-width:64vh;"}
        >
            <Wrapper>
                <BodyWrapper>
                    <LabelBox>{ControlTrad.getTrad("nameForm")}</LabelBox>
                    <InputBox
                        value={name}
                        placeholder={ControlTrad.getTrad("insertName")}
                        onChange={(e) => { setName(e.currentTarget.value) }}
                        onKeyDown={(e) => { if (e.which == 13) { e.target.blur(); } }} />
                    <ErrorSpan id={"quizWinnerNameError"}>{ControlTrad.getTrad("inputLobbyError")}</ErrorSpan>
                    <LabelBox>{ControlTrad.getTrad("emailForm")}</LabelBox>
                    <InputBox
                        value={email}
                        placeholder={ControlTrad.getTrad("emailPlace")}
                        onChange={(e) => { setEmail(e.currentTarget.value) }}
                        onKeyDown={(e) => { if (e.which == 13) { e.target.blur(); } }} />
                    <ErrorSpan id={"quizWinnerEmailError"}>{ControlTrad.getTrad("errorEmail")}</ErrorSpan>
                </BodyWrapper>
                <DivCheck>
                    <Check
                        type={"checkbox"}
                        id={"checkTerm"}
                        checked={check}
                        onChange={(e) => { setCheck(!check); }} />

                    <Label for={"checkTerm"}>
                        {ControlTrad.getTrad("terms") + " "}
                        <b>
                            <LabelLinks href={Custom.Links.termsOfUse}
                                target={"_blank"}
                                rel="noopener noreferrer">
                                {ControlTrad.getTrad("conditions1")}
                            </LabelLinks>
                        </b>
                        {" " + ControlTrad.getTrad("conditions2") + " "}
                        <b>
                            <LabelLinks href={Custom.Links.privacyPolicies}
                                target={"_blank"}
                                rel="noopener noreferrer">
                                {ControlTrad.getTrad("conditions3")}
                            </LabelLinks>
                        </b>
                    </Label>
                </DivCheck>
                <ErrorSpan id={"quizWinnerCheckError"}>{ControlTrad.getTrad("termerror")}</ErrorSpan>

                <ButtonsWrapper>
                    <Button
                        styles={`
                            background-color: black;
                            border-color: black;
                            width:25vh;
                            @media (min-width: 426px) and (pointer: coarse) {
                                font-size: 1.5vw;
                                width: 40%;
                            }
                        `}
                        value={ControlTrad.getTrad("noWin")}
                        onClick={() => props.setIsShown(false)}
                    />
                    <Button
                        styles={`
                            width:25vh;
                            @media (min-width: 426px) and (pointer: coarse) {
                                font-size: 1.5vw;
                                width: 40%;
                            }
                        `}
                        value={ControlTrad.getTrad("win")}
                        /*active={name !== "" && email !== "" && check}*/
                        onClick={checkWinner}
                    />
                </ButtonsWrapper>
            </Wrapper>
        </ModalMessage>
    );
};

export default QuizWinnerModal;