import React, { useState } from "react"
import styled from "styled-components";

import AppStateContext from "appState/AppStateContext";
import Element from "../Element";
import User from "assets/user/User";
import TastingSummaryRow from "./TastingSummaryRow";
import ControlTrad from "assets/trad/ControlTrad";
import Tasting from "assets/tasting/Tasting";

const Wrapper = styled.div`
  font-size: 2.5vh;
  background-color: #fffffff0;
  height:100%;
  border-radius:1vh;
  overflow-y:auto;
  @media (max-width: 426px) {
    border-radius:0vh;
  }
`;

const TastingSummary = (props) => {

  const appState = React.useContext(AppStateContext);
  let productReferences, assistants, currentData, currency;

  if (Tasting.getType() === 'live') {
    productReferences = appState.state.get(`wines`);
    assistants = appState.state.get("assistants");
    currency = appState.state.get("currency")
  } else {
    productReferences = props.params.productReferences;
    currentData = props.params.currentData;
    currency = props.params.currency
  }

  const loadProductsList = () => {
    let views = [];

    productReferences?.forEach(productReference => {
      let rateBox, ratePrice, assistantAnn, userPrice, price;

      if (Tasting.getType() === "live") {
        ratePrice = assistants?.[User.getId()]?.assessments?.[productReference.reference]?.parameters?.ratePrice || ControlTrad.getTrad("notRated");
        rateBox = assistants?.[User.getId()]?.assessments?.[productReference.reference]?.parameters?.rateBox || 0;
        assistantAnn = assistants?.[User.getId()]?.assessments?.[productReference.reference]?.parameters?.annotation || ControlTrad.getTrad("textNoNotes");
        userPrice = assistants?.[User.getId()]?.assessments?.[productReference.reference]?.parameters?.blindPrice || 0;
      } else {
        rateBox = currentData[productReference?.reference]?.rateBox || 0;
        ratePrice = currentData[productReference?.reference]?.ratePrice || ControlTrad.getTrad("notRated");
        assistantAnn = currentData[productReference?.reference]?.annotation || ControlTrad.getTrad("textNoNotes");
        userPrice = currentData[productReference?.reference]?.blindPrice || 0;
      }

      views.push(
        <TastingSummaryRow
          wineImage={productReference?.product?.image}
          wineName={productReference?.product?.name}
          wineType={ControlTrad.getUserTrad(productReference?.product?.type?.name)}
          wineYear={productReference?.product?.year}
          subtype={ControlTrad.getUserTrad(productReference?.product?.subtype.name)}
          rateBox={rateBox}
          ratePrice={ratePrice}
          userPrice={userPrice}
          currency={currency}
          winePrice={productReference?.product?.price}
          layoutType={props?.params?.layoutType}

          assistantAnn={assistantAnn}
          recommendation={false}
        />
      )
    });

    return views;
  }

  return (
    <Element params={props.params} >
      <Wrapper>
        {props.params.wineRec ?
          <TastingSummaryRow
            wineImage={props?.params?.wineRec?.image}
            wineName={props?.params?.wineRec?.name}
            wineType={ControlTrad.getUserTrad(props?.params?.wineRec?.type?.name)}
            wineYear={props?.params?.wineRec?.year}
            prodType={ControlTrad.getUserTrad(props?.params?.wineRec?.type?.name)}
            subtype={ControlTrad.getUserTrad(props?.params?.wineRec?.subtype?.name)}
            recommendation={true}
          />
          : <></>}

        {loadProductsList()}
      </Wrapper>
    </Element>
  );
};

export default TastingSummary;