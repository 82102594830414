import React, { useEffect, useState } from "react";
import styled from "styled-components";

import Element from "../Element";
import Question from "../FinalSurvey/Question";
import Slider from "../AssessmentDisplay/Slider/Slider";
import ImagesSlider from "../AssessmentDisplay/ImagesSlider/ImagesSlider";
import Tree from "../AssessmentDisplay/Tree/Tree";
import Button from "components/Shared/Button";
import Tasting from "assets/tasting/Tasting";
import Websockets from "Websockets/Websockets";
import ControlTrad from "assets/trad/ControlTrad";
import AppStateContext from "appState/AppStateContext";
import FetchUtils from "Fetch/FetchUtils";

const QuestionItem = styled.div`
    padding:1vh;
    display:flex;
    align-items:center;
    justify-content:space-around;
    background: white;
    border-radius: 2vh;
    box-shadow: rgb(0 0 0 / 50%) 0px 1vh 1vh 0px;
    align-items: center;
    margin: 2vh;
    margin-top: 0.5vh;
    margin-bottom: 1vh;
    text-align: center;
`;

const RelativeWrapper = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
  overflow-x: auto;
`;

const Wrapper = styled.div`
  ${props => `
  flex-direction: column;
  background: ${props.background ? props.background : "rgba(216, 216, 216, 0.8)"};
  display: flex;
  position: absolute;
  width:100%;
  height:100%;
  top:0;
  left:0;
  @media (max-width: 426px) {
    width:100vw;
    height: 100%;
    position: initial;
  
  }
  @media (min-width: 426px) and (pointer: coarse) {
    width:100vw;
    height: 100%;
    position: initial;  
  }
  `}
`;

const ComponentsWrapper = styled.div`
  overflow-y: auto;
  padding-top:2vh;
  flex: 1;
`;

const ButtonWrapperResponsive = styled.div`
${props => `  
display:flex;
  justify-content: space-between;
  background-color:#000;
  @media (min-width: 426px) {
    display:none;
  }
  
  @media (min-width: 426px) and (pointer: coarse) {
    display:flex;
    justify-content: space-between;
  }
  ${props?.onClickSave ? "display:none;" : ""}
`}`;

const ComponentTitle = styled.span`
  font-weight:600;
  font-size:2vh;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom:1.5vh;
`

const SimpleAssessmentDisplay = (props) => {
  const appState = React.useContext(AppStateContext);

  const components = props.params.components;
  let currentAssessment = props.params.category;

  let productRef;
  let userAssessments;

  if (Tasting.getType() === "live") {
    productRef = appState?.state.get("currentWine")?.reference;
    userAssessments = appState?.state.get("currentUserAssessmentsParameters");
  } else {
    productRef = props.params.currentProductRef;
    userAssessments = props.params.currentData?.[productRef];
  }

  const sendAnswer = (key, value) => {
    if (Tasting.getType() === "live") {
      Websockets.send("SLAVE_PRODUCT_PARAMETER", [
        productRef,
        key,
        value,
      ], false);
    } else {
      props.params.onSave((prev) => ({
        ...prev,
        [productRef]: {
          ...prev?.[productRef],
          [key]: value
        }
      }));
      FetchUtils.sendProductParameter(productRef, key, value);
    }
  };

  const loadSimpleAssessments = () => {
    let row = [];

    components.forEach(component => {
      switch (component.type) {
        case "OPTIONS_SLIDER":
          row.push(
            <Slider data={component} state={userAssessments?.[component.id]} addAnswer={sendAnswer} />
          )
          break;
        case "IMAGES_SLIDER":
          row.push(
            <ImagesSlider data={component} state={userAssessments?.[component.id]} addAnswer={sendAnswer} />
          )
          break;
        case "TREE":
          row.push(
            Tasting.getType() === 'live' ?
              <Tree background={props.params.background} data={component} state={userAssessments?.[component.id]} addAnswer={sendAnswer} />
              :
              <Tree
                background={props.params.background}
                currentAssessment={currentAssessment}
                currentUserAssessment={userAssessments}
                data={component}
                state={userAssessments?.[component.id]}
                addAnswer={sendAnswer} />
          )
          break;
        case "FACES":
          row.push(
            <QuestionItem>
              <Question
                concept={component.name}
                size={10}
                separation={60}
                titleStyles={"padding-bottom: 1vh; font-weight:600;"}
                active={userAssessments?.[component.id]}
                reference={component.id}
                onClickEvent={(answer, reference) => {
                  sendAnswer(reference, answer)
                }}
              />
            </QuestionItem>
          )
          break;
      }
    })
    return row;
  }

  return (
    <Element params={props.params}>
      <RelativeWrapper>
        <Wrapper background={props.params.background} >
          <ComponentsWrapper>
            <ComponentTitle>{ControlTrad.getTrad("rateTheElements")}</ComponentTitle>
            {loadSimpleAssessments()}
          </ComponentsWrapper>
        </Wrapper>
      </RelativeWrapper>
    </Element>
  );
}
export default SimpleAssessmentDisplay;