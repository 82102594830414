import ControlTrad from "assets/trad/ControlTrad";
import React from "react";

import styled from "styled-components";

const Wrapper = styled.div`
    display:flex;
    flex-direction:column;
    color: black;
    font-size: 2.5vh;
    text-align : center;
    height:100%;
    justify-content: flex-end;
    padding: 1vh;
`;

const NumAnswers = styled.div`
    color: black;
    flex:1
`;

const Num = styled.div`
    color: black;
    font-size: 3vh;

`;
const Answers = styled.div`
    color: black;
    border-bottom : black solid;
`;


const Chart = (props) => {
const Bar = styled.div`
width : 100%;
height : ${props.percent};
color:white;
background-color : ${props.correct ? "green" : "grey"};

`;
    return (
        <Wrapper>
            <NumAnswers>
                <Num>{props.answers}</Num> 
                <Answers>
                    {props.answers === 1 ? ControlTrad.getTrad("answer") :ControlTrad.getTrad("answers")}
                </Answers>
            </NumAnswers>
            <Bar params={props.params}></Bar>
            <div>{props.answer}</div>
        </Wrapper>
    );

}
export default Chart;