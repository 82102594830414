import LocalStorage from "LocalStorage/LocalStorage";
import { getDevicePlatform } from "assets/utils/DeviceUtils";


let id = null;
let name = null;
let lang = LocalStorage.get("LANG") || "ES";
let platform = getDevicePlatform();
let gender = null;
let age = null;
let email = null;
let linkClicked = {};

class User {
  static setUser(par) {
    if (par?.id) User.setId(par.id);
    if (par?.name) User.setName(par.name);
    if (par?.lang) User.setLang(par.lang);
    if (par?.language) User.setLang(par.language);
    if (par?.gender) User.setGender(par.gender);
    if (par?.age) User.setAge(par.age);
    if (par?.email) User.setEmail(par.email);
    if (par?.platform) User.setPlatform(par.platform);
  }
  static getUser() {
    return {
      id: User.getId(),
      name: User.getName(),
      lang: User.getLang(),
      gender: User.getGender(),
      age: User.getAge(),
      email: User.getEmail(),
      platform: User.getPlatform(),
    };
  }
  static setId(val) {
    id = val;
  }
  static getId() {
    return id;
  }
  static setName(val) {
    name = val;
  }
  static setGender(val) {
    gender = val;
  }
  static setAge(val) {
    age = val;
  }
  static setEmail(val) {
    email = val;
  }
  static setLang(val) {
    lang = val;
  }
  static setPlatform(val) {
    platform = val;
  }
  static getName() {
    return name;
  }
  static getLang() {
    return lang;
  }
  static getGender() {
    return gender;
  }
  static getAge() {
    return age;
  }
  static getEmail() {
    return email;
  }
  static getPlatform() {
    return platform;
  }

  static isLinkClicked(linkId) {
    return linkClicked?.[linkId] || false;
  }

  static setLinkClicked(linkId) {
    return linkClicked[linkId] = true;
  }

  static getNotDefaultName() {
    if (!["defaul", "Lite assistant"].includes(name)) {
      return name;
    } else {
      return null;
    }
  }
  static getDevice() {

    const ua = navigator.userAgent;
    if (
      (/iPhone/.test(ua) && !window.MSStream)
    ) {
      return "IPHONE";
    }
    if (
      (/iP[oa]d/.test(ua) && !window.MSStream)
    ) {
      return "IPAD";
    }
    if (/(Tablet|(android(?!.*Mobile))|(windows(?!.*phone)(.*touch))|Nexus|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(ua)) {
      return "TABLET_WEB";
    }
    if (
      /IEMobile|Windows Phone|Lumia/i.test(ua) ||  /Android/.test(ua) || /BlackBerry|PlayBook|BB10/.test(ua) || /Mobile Safari/.test(ua) || /webOS|Mobile|Tablet|Opera Mini|\bCrMo\/|Opera Mobi/i.test(ua)
    ) {
      return "MOBILE_WEB";
    }
    return "PC_WEB";
  };

  static getNavegation(){
    if(typeof InstallTrigger !== "undefined"){return "firebox"}
    var isSafari =
      /constructor/i.test(window.HTMLElement) ||
      (function (p) {
        return p.toString() === "[object SafariRemoteNotification]";
      })(
        !window["safari"] ||
        (typeof safari !== "undefined" && window["safari"].pushNotification)
      );

    if(isSafari){return "safari"}
    var isIE = false || !!document.documentMode;
    if(isIE){return "IE"}
    if(!isIE && /Edg/.test(navigator.userAgent)){return "edge"}
    if(navigator.userAgent.toLowerCase().indexOf('chrome') > -1){return "chrome"}
    if(navigator.userAgent.match(/Opera|OPR\//)){return "opera"}
    }
}
export default User;
