import React from "react";

import Element from "../Element";
import Button from "components/Shared/Button";
import AppStateContext from "appState/AppStateContext";



const StartQuizButton = (props) => {
    const appState = React.useContext(AppStateContext);

    const loadQuiz = (action) =>{
        //Websockets.send("LOAD_QUIZ", [],true);
        action();
    } 

    const containsQuiz = () => {
        let numQuestions = appState.state?.get("quizNumQuestions");
        return (numQuestions > 0);
    }

    return (
        <Element params={props.params}>
            <Button
                styles={(props.params.styles || "") + (props.params.btnStyles || "")}    
                value={props.params.text}
                onClick={()=> loadQuiz(props.params.onClick)}
                active={containsQuiz()}
            />
        </Element>
    );
};

export default StartQuizButton;
