import React, { useEffect, useState } from "react";

import Element from "../Element";
import Button from "components/Shared/Button"
import AppStateContext from "appState/AppStateContext";
import ControlTrad from "assets/trad/ControlTrad";
import Websockets from "Websockets/Websockets";

const ShowProductInfoButton = (props) => {
    const [showHiddenPrice, setHiddenPrice] = useState(props.params.visibilty ? props.params.visibilty.visible : false);

    const appState = React.useContext(AppStateContext);
    const currentWine = props.params.podcastData ? props.params.podcastData : appState.state.get("currentWine");

    const displayHiddenPrice = () => {
        //send websockets
        setHiddenPrice(!showHiddenPrice);
        if (props.params.setVisibility) props.params.setVisibility(!showHiddenPrice);
        if (props.params.setDataWine) {
            props.params.setDataWine((prev) => ({
                ...prev,
                [props.params.currentWineRef]: {
                    ...prev[props.params.currentWineRef],
                    ["visibility"]: !showHiddenPrice
                }
            }))
        }

        Websockets.send("TOGGLE_PRODUCT_VISION", "TOGGLE_PRODUCT_VISION", true);
    }


    useEffect(() => {
        setHiddenPrice(currentWine?.visible);

        if (props.params.productData) {
            if (props.params.productData.visibility) {
                setHiddenPrice(props.params.productData.visibility);
            }
        }
    }, [currentWine])


    
    return (
        <>
            {props.params.podcastData ? !showHiddenPrice ?
                <Element params={props.params}>
                    <Button
                        styles={(props.params.styles || "") + (props.params.btnStyles || "")}
                        value={showHiddenPrice ? ControlTrad.getTrad("hideDisplayedProduct") : ControlTrad.getTrad("showHiddenProduct")}
                        onClick={() => { displayHiddenPrice() }} />
                </Element>
                : <></> : 
                <Element params={props.params}>
                    <Button
                        styles={(props.params.styles || "") + (props.params.btnStyles || "")}
                        value={showHiddenPrice ? ControlTrad.getTrad("hideDisplayedProduct") : ControlTrad.getTrad("showHiddenProduct")}
                        onClick={() => { displayHiddenPrice() }} />
                </Element>
            }
        </>

    );
};

export default ShowProductInfoButton;
