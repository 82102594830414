import React from "react";
import User from "assets/user/User"
import styled from "styled-components";
import correct from "assets/images/icons/check.svg";
import incorrect from "assets/images/icons/cross.svg";
import AppStateContext from "appState/AppStateContext";
import Tasting from "assets/tasting/Tasting";
import ControlTrad from "assets/trad/ControlTrad";

const Option = styled.div`
  ${(props) => `
        background-color : ${props.params.isOnTime? (props.params.selected ? "#636363" : "white") : props.params.selected ? (props.params.correct ? (props.params.state === "QUIZ_CORRECTION" ? "green" : "#636363") : (props.params.state === "QUIZ_CORRECTION" ? "#AE1C38" : "#636363")) : (props.params.correct ? (props.params.state === "QUIZ_CORRECTION" ? "green" : "#9c9a9a") : "#9c9a9a")};
        text-align : left;
        color: white;
        width:100%;
        height: 100%;
        display:flex;
        flex-direction:row;
        border-radius:0.5vh;
        border-bottom-left-radius: 1vh;
        border-top-left-radius: 1vh;
        transition : 0.2s;
        cursor: pointer;
        &:hover {
            ${props.isOnTime?'opacity:0.7':''};
        }`}
`;

const OptionHead = styled.div`
    text-align : center;
    display: flex;
    color: white;
    background-color: black;
    font-size: 6vh;
    width : 7vh;
    padding 1vh;
    border-bottom-left-radius: 0.5vh;
    border-top-left-radius: 0.5vh;
    @media (max-width: 426px) {
      font-size: 3vh;
    }
    @media (min-width: 426px) and (pointer: coarse) {
      font-size: 3vh;
  }
`;

const Head = styled.div`
  margin: auto;
`;

const OptionTitle = styled.div`
  text-align: center;
  color: black;
  font-size: 5vh;
  flex: 1;
  padding: 0.75vh;
  margin: auto;
  @media (max-width: 426px) {
    font-size: 3vh;
  }
  @media (min-width: 426px) and (pointer: coarse) {
    font-size: 3vh;
  }
`;

const abc='ABCDEFGHIJKLMNROPQSTUVWXYZ'

const QuizOption = (props) => {
  const appState = React.useContext(AppStateContext);
  let state;
  if(Tasting.getType()==='live'){
     state = appState.state.get("state")?.state;
  }else{
    
  }
  return (
    <Option params={props}>
      <OptionHead>
        <Head>
          
          {props?.isOnTime ? 
          abc[props.index] : 
          props.selected && state === "QUIZ_CORRECTION" ? (props.correct ? <img src={correct} alt={"quiz_response"}/> : <img src={incorrect} alt={"quiz_response"}/>) : abc[props.index]}

        </Head>
      </OptionHead>
      <OptionTitle>{ControlTrad.getUserTrad(props.option)}</OptionTitle>
    </Option>
  );
};
export default QuizOption;
