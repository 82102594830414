import React from "react"
import AppStateContext from "appState/AppStateContext";

import Element from "../Element";

import styled from "styled-components";
import ControlTrad from "assets/trad/ControlTrad";

const Wrapper = styled.div`
${props=>`
  font-size: 3vh;
  color: white;
  text-align: left;
  ${props.params.styles}
`}`
  


const AssistantsCount = (props) => {
  const appState = React.useContext(AppStateContext);
  let assistants = appState.state.get("assistants");
  let count = assistants?Object.entries(assistants).length:0;
  return (
    <Element params={props.params}>
      <Wrapper params={props.params}>
        {count}{" "}
        {count===1?ControlTrad.getTrad("assInTheRoom_2"):ControlTrad.getTrad("assInTheRoom_1")} 
        </Wrapper>
    </Element>
  );
};

export default AssistantsCount;
