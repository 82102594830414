import Trad from "./trad.json"
import User from "../user/User"


const DEFAULT_LANG = "ES"
class ControlTrad {


    static getLang() {
        if (User.getLang() !== undefined && User.getLang() !== null) {
            return User.getLang();
        } else {
            let urlParams = new URLSearchParams(window.location.search);
            let urlLang = urlParams?.get("lang")?.toUpperCase();
            if (urlParams !== undefined && urlLang !== null) {
                return urlLang;
            }
        }
        return DEFAULT_LANG;
    }

    static getTrad(key, prefLang) {
        let lang = prefLang || this.getLang();

        if (Trad[lang][key]) {
            return Trad[lang][key];
        }
        if (Trad[DEFAULT_LANG][key]) {
            return Trad[DEFAULT_LANG][key];
        }
    }

    /**
     * Return translation of a text based on an array and a key.
     * 
     * @param {Array} trad 
     * @param {String} lang 
     * @returns {String}
    */
    static getTradFromObject(trad, lang){
        return (trad?.[lang] || trad?.["ES"] || "");
    }

    static getUserTrad(trad) {
        return this.getTradFromObject(trad, this.getLang());
    }

}
export default ControlTrad;