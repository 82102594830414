//import PropTypes from "prop-types";
import styled from "styled-components";
import Colours from "assets/custom/Custom";
import { nanoid } from "nanoid";
import { useEffect, useState } from "react";

const Button = (props) => {

  const styles = {
    confirm: {
      background: `${Colours.getColour("mainColor")}`,
      backgroundHover: `${Colours.getColour("secondaryColor")}`,
      border:`${Colours.getColour("mainColor")}`,
      borderHover:`${Colours.getColour("secondaryColor")}`,
      color: 'white',
    },
    cancel: {
      background: `transparent`,
      border:`${Colours.getColour("mainColor")}`,
      borderHover:`${Colours.getColour("mainColor")}`,
      color: `${Colours.getColour("mainColor")}`,
    },
    cancel2: {
      
    }
  };

  const Wrapper = styled.div`
    ${(props) => `
      position: relative;
      background: ${props.params.used ? "#b0b0b0" : styles[props.params.type]?.background};
      display: inline-block;
      padding: 1vh 2vh;
      border-radius: 0.7vh;
      font-size: 2.5vh;
      color: ${styles[props.params.type]?.color};
      transition: 0.1s;
      cursor: pointer;
      opacity: ${props.params.active ? "1" : "0.6"};
      text-overflow: ellipsis;
      border: 0.3vh solid ${props.params.used ? "#b0b0b0" : styles[props.params.type]?.border};
      img {
        height: 3vh;
        width: 3vh;
        margin: auto 1vh;
      }
      @media (max-width: 426px) {
        font-size: 1rem;
        border-radius: 0.4rem;
        padding: 0.5rem 1rem;
      }

      @media (min-width: 426px) and (pointer: coarse) {
        font-size: 2.5vh;
        border-radius: 0.4rem;
        padding: 0.5rem 1rem;

      }

      ${props.params.styles};

      ${props.params.active ? `
        &:hover {
          background: ${styles[props.params.type]?.backgroundHover};
          border-color: ${styles[props.params.type]?.borderHover};
        }` : null }
      
      ${props?.params?.highligthed && props?.params?.active ? `
        animation-name: highligthedAnim;
        animation-duration: 2s;
        animation-iteration-count: infinite;
        animation-direction: alternate;
        border: 0; 
      `: null}
    `}
  `;

  const Badge = styled.div`
    position: absolute;
    height: 4vh;
    width: 4vh;
    top: -1.5vh;
    right: -1.5vh;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2.5vh;
    border-radius: 50%;
    background: red;
    color: white;
  `;

  let timmer = null;
  let [count, setCount] = useState(props?.timmerTime || null);

  useEffect(() => {
    if (props?.timmerEnabled && timmer == null) {
      timmer = setInterval(() => {
        if (count <= 0) {
          clearInterval(timmer);
          props.onClick();
        } else {
          setCount(count--);
        }
      }, 1000);
    }

    return () => {
      if (timmer) {
        clearInterval(timmer);
      }
    }
  }, []);

  if (props.value === "Mostrar Chat") console.log("Hola2", props);

  return (
    <Wrapper
      params={props}
      onClick={() => {
        if(props.active) props.onClick();
      }}
    >
      {props.value}
      {/*count ? " (" + count + ")" : ""*/}

      {props.badge && (props?.badge > 0 || props?.badge.length > 0) ? (
        <Badge>{props.badge}</Badge>
      ) : (
        <></>
      )}

    </Wrapper>
  );
};

Button.defaultProps = {
  active: true,
  onClick: () => {},
  type: "confirm",
  key:nanoid(),
  value:'Botón'
};
export default Button;
