import React from "react"

import Element from "../Element";
import styled from "styled-components";

const Wrapper = styled.div`
    ${(props) => `
        width:10vh;
        height:0.5vh;
        background-color: #af1d39;
        ${props.params.lineStyles};
    `}
`;

const Separator = (props) => {
    return (
        <Element params={props.params}>
            <Wrapper params={props.params} ></Wrapper>
        </Element>
    );
};

export default Separator;
