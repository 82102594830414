import React, { useState } from "react";
import Element from "../Element";
import Button from "components/Shared/Button"

import AppStateContext from "appState/AppStateContext";
import Websockets from "Websockets/Websockets";
import ModalMessage from "components/Sandbox/ModalMessage";
import styled from "styled-components";
import ControlTrad from "assets/trad/ControlTrad";

const Wrapper = styled.div`
  display:flex;
  flex-direction:column;
  align-items:center;
  justify-content:space-around;
  width:100%;
  font-size:3vh;
  height: 25.1vh;
`
const ButtonsWrapper = styled.div`
  width:100%;
  display:flex;
  flex-direction:row;
  justify-content:center;
  align-items:center;
`

const SpanTxt = styled.span`
  padding-left: 2vh;
  padding-right: 2vh;
`

const NextStepButton = (props) => {
  const [popUp1, setPopUp1] = useState(false);

    const appState = React.useContext(AppStateContext);
    let wineIndex = appState.state.get("wineIndex");
    let winesCount = appState.state.get("winesCount");
    const nextStep = ()=>{
        Websockets.send("NEXT_STEP",[],true);
    }
    const finallyStep = ()=>{
      setPopUp1(true)
    }
    const continueTast = () =>{
      props.params.onClick();
    }
  return (
    <>
      <Element params={props.params}>
          {wineIndex + 1 !== winesCount ?
            <Button onClick={nextStep} value={props.params.text}/>
          : 
            <Button onClick={finallyStep} value={props.params.textFinally} />
          }
      </Element>
      <ModalMessage 
        boxSize={"min-width: 80vh !important; height:31vh; border-radius:1.5vh;"} 
        styleHeader={"border-top-left-radius:1.5vh; border-top-right-radius:1.5vh; font-size:2.2vh;"} 
        styleMain={"border-bottom-left-radius:1.5vh;border-bottom-right-radius:1.5vh"}
        media={"min-width: 80vw !important;"}
        isShown={popUp1} 
        setIsShown={setPopUp1} 
        title={ControlTrad.getTrad("title_info_4")}>
          <Wrapper>
            <SpanTxt>{ControlTrad.getTrad("info_4")}</SpanTxt>
            <ButtonsWrapper>
            <Button styles={"background-color:black; border: none; color:white;width:15vw;height:3.2vh;display:flex;justify-content:center;"} type={"cancel2"} onClick={()=>{setPopUp1(false)}} value={ControlTrad.getTrad("cancel")}/>
            <Button styles={"width:15vw; margin-left:1vh;height:3vh;display:flex;justify-content:center;"} onClick={()=>{continueTast()}} value={ControlTrad.getTrad("accept")}/>
          </ButtonsWrapper>
          </Wrapper>
      </ModalMessage>
    </>
  );
};

export default NextStepButton;
