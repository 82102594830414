import SockJS from "sockjs-client";
import Stomp from "stompjs";
import Fetch from "Fetch/Fetch";
import Tasting from "assets/tasting/Tasting";
import User from "assets/user/User";
import LocalStorage from "LocalStorage/LocalStorage";
import Firebase from "../Firebase";

import ControlTrad from "assets/trad/ControlTrad";

let INSTANCE_ID;

const websocketServersUrls = {
  prod: "https://uptasting-websocket-server.herokuapp.com",
  dev: "https://uptasting-websocket-server-dev.herokuapp.com",
  local: "http://localhost:8081"
}

const server = websocketServersUrls[process.env.REACT_APP_ENVIRONMENT];
console.debug("Selected websocket url: ", server, process.env.REACT_APP_ENVIRONMENT);

let stomp = null;
class Websockets {
  static setError = null;
  static setMessageError = null;
  static setTitleError = null;
  static connect(isInstructor) {
    INSTANCE_ID = Tasting.getPublicCode();

    if (isInstructor) {
      Fetch.getCodeInfo(Tasting.getPublicCode()).then((result) => {
        new Firebase().firebase
          .firestore()
          .doc(result.reference)
          .get()
          .then((result) => {
            LocalStorage.set(INSTANCE_ID + "_instructor", result.data().instructorRef.id);
            this.connectWebsockets(isInstructor);
          });
      });
    } else {
      let oldUser = LocalStorage.get(INSTANCE_ID);
      console.log(oldUser);
      if (oldUser !== null) {
        User.setUser(oldUser);
        this.connectWebsockets(isInstructor);
      } else {
        Fetch.post(
          {
            name: "Desconocido",
            gender: "",
            age: "",
            lang: "ES",
            platform: getDeviceType(),
          },
          "assistant"
        ).then((res) => {
          User.setId(res.assistantId);
          LocalStorage.set(INSTANCE_ID, User.getUser());
          this.connectWebsockets(isInstructor);
        });
      }
    }
  }

  static send(command, msg, isInstructor) {
    if (!Array.isArray(msg)) {
      msg = [msg];
    }
    stomp?.send(
      "/app/to_server." + INSTANCE_ID,
      getHeader(isInstructor),
      JSON.stringify({
        id: getId(isInstructor),
        command: command,
        parameters: msg,
      })
    );
  }
  static setReceived(funct) {
    Websockets.receive = funct;
  }
  static setOnConnect(funct) {
    Websockets.onConnect = funct;
  }

  static setError(setError) {
    this.setError = setError;
  }

  static connectWebsockets = (isInstructor) => {
    var socket = new SockJS(server + "/iwine-websocket", null, {
      transports: ["websocket"],
    });
  
    var stompClient = Stomp.over(socket);
    stompClient.reconnect_delay = 1000;
  
    stompClient.connect(
      getHeader(isInstructor),
      () => {
        stompClient.subscribe(
          "/topic/to_master." + INSTANCE_ID,
          (received) => {
            received = JSON.parse(received.body);
            let msg = received.parameters[0];
            let command = received.command;
            
            if(command === "SERVER_CLOSED_INACTIVITY" && this.setError) {
              this.setError(true);
              if (this.setMessageError && this.setTitleError) {
                this.setMessageError(ControlTrad.getTrad("inactivityError"));
                this.setTitleError("");
              }
            }
              

            Websockets.receive(command, msg);
          },
          getHeader(isInstructor)
        );
        if(this.setError) this.setError(false)

        stomp = stompClient;
        stomp.reconnect_delay = 1000;
        if (Websockets.onConnect != null) {
          Websockets.onConnect(INSTANCE_ID);
        }
      },
      (e) => {
        errorCallback(e);
        if(this.setError) {
          this.setError(true);
          if(this.setMessageError && this.setTitleError) {
            this.setMessageError(ControlTrad.getTrad("errorPopUpText"));
            this.setTitleError(ControlTrad.getTrad("errorPopUpTitle"));
          }
        }
      }
    );
  };
}

const getDeviceType = () => {
  const ua = navigator.userAgent;
  console.log("User agent", ua);
  if (/(iPad|Tablet|(android(?!.*Mobile))|(windows(?!.*phone)(.*touch))|Nexus|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(ua)) {
    return "TABLET_WEB";
  }
  if (
    /IEMobile|Windows Phone|Lumia/i.test(ua) || (/iPhone|iP[oa]d/.test(ua) && !window.MSStream) || /Android/.test(ua) || /BlackBerry|PlayBook|BB10/.test(ua) || /Mobile Safari/.test(ua) || /webOS|Mobile|Tablet|Opera Mini|\bCrMo\/|Opera Mobi/i.test(ua)
  ) {
    return "MOBILE_WEB";
  }

  return "PC_WEB";
};

const getId = (isInstructor) => {
  if (isInstructor) {
    return LocalStorage.get(INSTANCE_ID + "_instructor");
  } else {
    return User.getId();
  }
};

const getHeader = (isInstructor) => {

  if (isInstructor) {
    let uid = LocalStorage.get(INSTANCE_ID + "_instructor");
    return {
      assistantId: uid
    };

  } else {
    return {
      assistantId: User.getId(),
    };
  }

};

const errorCallback = (e) => {
  console.log(e);
};

export default Websockets;
