import React, { useState, useEffect } from "react";
import styled from "styled-components";

import Button from "components/Shared/Button";
import TreeSelector from "./TreeSelector";
import Slider from "../Slider/Slider";
import User from "assets/user/User";
import AppStateContext from "appState/AppStateContext";
import ControlTrad from "assets/trad/ControlTrad";
import Tasting from "assets/tasting/Tasting";

const Wrapper = styled.div``;
const ButtonWrapper = styled.div`
margin: 2vh;
  margin-top: 0.5vh;
  margin-bottom:1vh;
  padding: 1vw 5vh;
  border-radius: 0.7vh;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  background-color: white;
`;

const Tree = (props) => {
  const [leafs, setLeafs] = useState([]);
  const [treeState, setTreeState] = useState({});
  const [isTreeSelectorActive, setIsTreeSelectorActive] = useState(false);
  const toggleTreeSelector = () => {
    setIsTreeSelectorActive(!isTreeSelectorActive);
  };
  const appState = React.useContext(AppStateContext);
  let currentAssessment, currentUserAssessments;
  if(Tasting.getType() === "live"){
    currentAssessment = appState.assessments.get("current");
    currentUserAssessments = appState.state.get(`currentUserAssessments`)?.[
      currentAssessment
    ];
  } else {
    currentAssessment = props.currentAssessment;
    currentUserAssessments = props.currentUserAssessment;
  }


  const foundNewLeaf = (currentUserAssessments, leafId, found) => {
    if(currentUserAssessments) {
      Object.keys(currentUserAssessments).forEach(assessmentKey => {
        if(currentUserAssessments[assessmentKey] instanceof Object) {
          found = foundNewLeaf(currentUserAssessments[assessmentKey], leafId, found);
        } else if(leafId === assessmentKey) {
          if(currentUserAssessments[assessmentKey]) {
            found = true;
          }
        }
      })
    }

    return found;
  }

  //save selected values to
  const saveFields = (selected) => {
    //adds static answer to any new leaf that does not have a value selected.
    let newLeafs = getLeafOptions(selected);
    let unsettedLeafes = [];
    let bool = false;
    newLeafs.forEach(newLeaf => {
      bool = foundNewLeaf(currentUserAssessments, newLeaf, false)
      if(!bool) {
        unsettedLeafes.push(newLeaf);
      }
    })

    addMultipleTreeAnswers(unsettedLeafes);

    /*
    let oldLeafs = [...leafs];
    newLeafs.forEach((leaf) => {
      oldLeafs.push(leaf);
    });
    let uniqueLeafs = [...new Set(oldLeafs)];
    */
    setLeafs(newLeafs);
    setTreeState(selected);
    setIsTreeSelectorActive(false);
  };

  //returns array of leafs from given state
  const getLeafOptions = (state, arr = []) => {
    for (let key in state) {
      let current = state[key];
      if (Object.entries(current).length === 0) {
        //has no leafs
        arr.push(key);
      } else {
        //hasLeafs
        getLeafOptions(current, arr);
      }
    }
    return arr;
  };

  //gets all info of leaf given
  const getLeafInfo = (leafs, leafId) => {
    for (let i = 0; i < leafs.length; i++) {
      let leaf = leafs[i];
      if (leaf.id === leafId) {
        return { name: leaf.name, icon: leaf.icon, id: leaf.id };
      } else {
        if ("options" in leaf) {
          let res = getLeafInfo(leaf.options, leafId);
          if (res !== false) return res;
        }
      }
    }
    return false;
  };

  //converts state to leafs array
  const stateToLeafs = (state) => {
    let arr = [];
    for (let key in state) {
      arr.push(key);
    }
    return arr;
  };

  const loadLeafs = () => {
    let views = [];
    leafs.forEach((leaf) => {
      let options = [
        { id: "OPTION_LOW", name: { CA: "Baixa", ES: "Baja", EN: "Low" } },
        {
          id: "OPTION_MEDIUM",
          name: { CA: "Mitja", ES: "Media", EN: "Medium" },
        },
        { id: "OPTION_HIGH", name: { CA: "Alta", ES: "Alta", EN: "High" } },
      ];
      let leafInfo = getLeafInfo(props.data.options, leaf);

      let data = {
        id: leafInfo.id,
        name: leafInfo.name,
        options: options,
        icon: leafInfo.icon,
      };
      views.push(
        <Slider
          data={data}
          state={props.state?.[leafInfo.id]}
          addAnswer={addTreeAnswers}
          viewVideo={() => { props.viewVideo(props.data.videoExplanationId, ControlTrad.getUserTrad(props.data.name)) }}
        />
      );
    });
    return views;
  };

  //deletes current state values that are not leafs
  const filterStateToCurrentLeafs = () => {
    let oldState = { ...props.state };
    let obj = {};
    leafs.forEach((leaf) => {
      obj[leaf] = oldState[leaf];
    });
    props.addAnswer(props.data.id, obj);
  };

  const addMultipleTreeAnswers = (leafs) => {
    let oldState = { ...props.state };
    leafs.forEach(leaf => {
      if (!(leaf in oldState)) {
        oldState[leaf] = "OPTION_MEDIUM";
      }
    });
    props.addAnswer(props.data.id, oldState)
  }

  const addTreeAnswers = (key, val) => {
    let oldState = { ...props.state };
    if(val === null) 
      delete oldState[key];
    else 
      oldState[key] = val;
    
    if(Object.entries(oldState).length === 0) oldState = null;

    props.addAnswer(props.data.id, oldState);
  };

  const stateToTreeState = (obj = {}, options) => {
    for (let i = 0; i < options.length; i++) {
      let option = options[i];
      if (option.id in props.state) {
        obj[option.id] = {};
      } else {
        if ("options" in option) {
          let res = stateToTreeState(obj[option.id], option.options);
          if (Object.entries(res).length > 0) {
            obj[option.id] = res;
          }
        }
      }
    }
    return obj;
  };

  const getAddButtonValue = () =>{
    let text = "";
    if (ControlTrad.getUserTrad(props.data.buttonAddText) !== "") {
      return ControlTrad.getUserTrad(props.data.buttonAddText);
    } else {
      text = ControlTrad.getTrad("add") + " ";
      text += (props.state) ? ControlTrad.getUserTrad(props.data.name).toLowerCase() : ControlTrad.getTrad("more");
      return text;
    }
  }

  useEffect(() => {
    if (leafs.length > 0) filterStateToCurrentLeafs();
  }, [leafs]);

  useEffect(() => {
    if (leafs.length === 0) {
      setLeafs(stateToLeafs(props.state));
      if (props.state !== undefined) {
        setTreeState(stateToTreeState({}, props.data.options));
      }
    }
  }, [props.state]);

  return (
    <>
      {!isTreeSelectorActive ? (
          <Wrapper>
            {loadLeafs()}
            <ButtonWrapper>
              <Button
                value={getAddButtonValue()}
                onClick={() => {
                  toggleTreeSelector();
                }}
              />
            </ButtonWrapper>
          </Wrapper>

      ) : (
        <TreeSelector
          data={props.data}
          state={treeState}
          background={props.background}
          addAnswer={props.addAnswer}
          onSave={saveFields}
          onCancel={() => {
            setIsTreeSelectorActive(false);
          }}
        />
      )}
    </>
  );
};

export default Tree;
