import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import Element from "../Element";
import Websockets from "Websockets/Websockets";
import AppStateContext from "appState/AppStateContext";
import ControlTrad from "assets/trad/ControlTrad";
import FetchUtils from "Fetch/FetchUtils";
import Tasting from "assets/tasting/Tasting";
import User from "assets/user/User";

const Wrapper = styled.div`
  font-size: 2.5vh;
  background: white;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  border-radius: 1vh;
  justify-content: flex-start;
`;

const Title = styled.div`
  font-weight: bold;
  padding-top: 1vh;
`;

const InputWrapper = styled.div`
    display:Flex;
    align-items:center;
    justify-content:center;
    flex-direction:row;
    margin: auto;
`

const Input = styled.input`
  ${props => `
  width: 20vh;
  height: 3.5vh;
  border:none;
  background-color: rgb(230,230,230);
  border-radius:1vh;
  outline: none;
  text-align:center;
  font-size:2vh;
  transition: all 0.3s ease-in-out;
  ${props.styles}
  `}

`
const TextBox = styled.span`
    font-size:2.5vh;
    margin-left:0.5vh;
    font-weight:500;
`

const BlindPriceRateBox = (props) => {
  const appState = React.useContext(AppStateContext);

  let currentProductRef, userGuess;

  if (Tasting.getType() === 'live') {
    currentProductRef = appState?.state.get(`currentWine`)?.reference;
    userGuess = appState?.state.get('currentUserAssessmentsParameters')?.blindPrice;
  } else {
    currentProductRef = props?.params?.currentProductRef;
    userGuess = props?.params?.blindPrice;
  }

  const [winePriceTag, setWinePriceTag] = useState(userGuess);
  const [saveAnswer, setSaveAnswer] = useState(true);
  const [oldWine, setOldWine] = useState(currentProductRef);
  const currency = props.params.currency ? props.params.currency : appState.state.get("currency");

  useEffect(() => {
    setOldWine(currentProductRef);
    sendAnswer(winePriceTag, oldWine);
    setWinePriceTag(userGuess || "");
  }, [currentProductRef]);

  const sendAnswer = (value, productRef = currentProductRef) => {
    if(!props.params.setDataWine){
      Websockets.send("SLAVE_PRODUCT_PARAMETER", [
        productRef,
        "blindPrice",
        value,
      ],false)
    } else {
      props.params.setDataWine((prev) => ({
        ...prev,
        [currentProductRef]: {
          ...prev[productRef],
          ["blindPrice"]: value
        }
      }));
      FetchUtils.sendProductParameter(props.params.currentProductRef, "blindPrice", value);
    }
  };

  const onChange = (val) => {
    if (checkVal(val)) {
      setWinePriceTag(val);
    }
  };

  const checkVal = (val) => {
      let numbers = /^\d*,?\d{0,2}$/;

      if(val.match(numbers) || val === "")
          return true;
      else
          return false;
  } 

  const getPriceTag = (data) => {
    if(data){
      if (data[currentProductRef]) {
        if (data[currentProductRef]["blindPrice"]) {
          return data[currentProductRef]["blindPrice"] || "";
        }
      }
    }

    return userGuess || "";
  }

  const getFormatedValue = () => {
    if (saveAnswer && winePriceTag !== null && winePriceTag !== undefined && winePriceTag.length > 0) {
      let numString = winePriceTag.replace(",", ".");
      let num = Number(numString);

      if (!Number.isNaN(num)) {
        let formated = num.toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
        return formated;
      }
    }
    return winePriceTag;
  }

  useEffect(() => {
    let priceTag = getPriceTag(props.params.productData);
    setWinePriceTag(priceTag)
  }, [currentProductRef])

  return (
    <Element params={props.params}>
      <Wrapper>
        <Title>{ControlTrad.getTrad("blindTitleRateWine")}</Title>
        <InputWrapper>
          <Input
            inputMode={User.getDevice() === "IPHONE" ? "text": "decimal"}
            value={getFormatedValue()}
            readOnly={saveAnswer}
            placeholder={""}
            styles={saveAnswer ? "background-color: #4d4d4d; color:white;" : ""}
            onBlur={() => {
              setSaveAnswer(!saveAnswer);
              sendAnswer(winePriceTag);
            }}
            onClick={() => {
              if (saveAnswer) setSaveAnswer(!saveAnswer)
            }}
            onChange={(e) =>
              onChange(e.currentTarget.value)
            } 
            onKeyDown={(e) => {
              if (e.which == 13) {
                e.target.blur();
              }
            }}
          />

          <TextBox>{currency}</TextBox>

        </InputWrapper>
      </Wrapper>
    </Element>
  );
};

export default BlindPriceRateBox;
