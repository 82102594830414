import AppStateContext from "./AppStateContext";
import useAppState from "./AppState";
import Websockets from "Websockets/Websockets.js";
import Fetch from "Fetch/Fetch";

const AppStateProvider = (props) => {
  const appState = useAppState();

  const OverwritableParams = [
    "assistants",
    "assessments",
    "chatMessages",
    "doubts"
  ]

  Websockets.setOnConnect((sessionCode) => {
    Fetch.getLiveSession(sessionCode).then((staticSessionData) => {
      mergeState(staticSessionData);
    });
  });

  Websockets.setReceived((command, msg) => {
    if (command === "SESSION_STATUS") {
      mergeState(msg);
    }
  });

  const mergeState = (stateFragment) => {
    let state = appState.state.get("");
    if (!state) state = {};

    joinObject(state, stateFragment);
    appState.state.set(state);
    console.log("New state:", state);
  };

  const joinObject = (destination, source) => {
    if (source != null && typeof source  === "object" && destination != null && typeof destination  === "object") {
      Object.entries(source).forEach(([key, value]) => {

        // Exceptional parts to overwrite integrally
        if (OverwritableParams.includes(key)) {
          destination[key] = value;

          // Join Object
        } else if (typeof value === "object" && !isArray(value)) {
          if (!destination[key]) {
            destination[key] = {}
          }
          joinObject(destination[key], source[key]);

          // Join Array
        } else if (isArray(value)) {
          if (!destination[key]) {
            destination[key] = [];
          }

          value.forEach(sourceElement => {
            let destinationElement = destination[key]?.find(e => {
              return (e?.reference && sourceElement?.reference && e?.reference === sourceElement?.reference) ||
                (e?.id && sourceElement?.id && e?.id === sourceElement?.id)
            });
            if (!destinationElement) {
              destination[key].push(sourceElement);
              destinationElement = destination[key]?.find(e => e?.reference === sourceElement?.reference);
            }

            if (typeof sourceElement === "object") {
              joinObject(destinationElement, sourceElement);
            }
          });

        // Simple variable
        } else {
          destination[key] = value;
        }
      });
    } 
  }

  const isArray = (object) => {
    if (typeof Array.isArray === 'undefined') {
      return Object.prototype.toString.call(object) === '[object Array]';
    } else {
      return Array.isArray(object);
    }
  }

  return <AppStateContext.Provider value={appState}>{props.children}</AppStateContext.Provider>;
};
export default AppStateProvider;
