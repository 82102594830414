import React, { useLayoutEffect, useState } from "react";
import styled from "styled-components";

import Colours from "assets/custom/Custom";
import User from "assets/user/User";
import ControlTrad from "assets/trad/ControlTrad";
import video from "assets/images/play-white.svg";
import Tasting from "assets/tasting/Tasting";

const Wrapper = styled.div`
  background: white;
  border-radius: 2vh;
  box-shadow: rgb(0 0 0 / 50%) 0px 1vh 1vh 0px;
  display: flex;
  align-items:center;
  margin: 2vh;
  margin-top: 0.5vh;
  margin-bottom:1vh;
  text-align: center;
`;

const SliderContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: calc(100% - 36vh);
  margin: 1vh 2vh;

  @media (max-width: 426px) {
    width: calc(100% - 4vh);
  }
`;

const RowWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const ColumnWrapper = styled.div`
  ${(props) => `
    display: flex;
    flex-direction: column;

    ${props.imageShown ? `
      width: calc(100% - 14.5vh);
      margin: auto 0 auto 1.5vh;
    `: `
      width: 100%;
      margin: auto;
    `}

    @media (max-width: 426px) {
      ${props.imageShown ? `
        width: calc(100% - 9.5vh);
      `: `
        width: 100%;
      `}
    }
  `}
`;

const TitleContainer = styled.div`
  display: flex;
  align-items:center;
  font-weight: bold;
  font-size: 2.2vh;
  justify-content: space-between;
`;

const Title = styled.div`
  ${props => `
    text-align: left;
    ${props.styles}
  `}
`;

const Delete = styled.div`
  ${(props) => `
    display: ${props.active ? "block" : "none"};
    color: ${Colours.getColour("mainColor")};

    cursor: pointer;
    border-left: 0.5vh solid lightgrey;
    margin: 0 0 0 2vh;
    padding: 0 0 0 1vh;
  `}
`;

const Input = styled.input`
  ${(props) => `
    appearance: none;
    background: #d3d3d3;
    width: 100%;
    outline: none;
    cursor: pointer;
    border-radius: 2vh;
    margin: 1vh 0;
    height:2.5vh;

    &::-webkit-slider-thumb {
      appearance: none;
      width: ${100 / (props.max + 1)}%;
      height:2.5vh;
      border-radius: 2vh;
      background: ${props.active ? Colours.getColour("mainColor") : "#b5b5b5"};
    }
    &::-moz-range-thumb {
      width: ${100 / (props.max + 1)}%;
      height: 100%;
      background: ${props.active ? Colours.getColour("mainColor") : "#b5b5b5"};
      border-radius: 51px;
      cursor: pointer;
      border: 0;
    }
  `}
`;

const WrapperIphone = styled["input"]`
  ${(props) => `
    padding: 1vh;
    color: ${props.active ? Colours.getColour("mainColor") : "#b5b5b5"};
    -slider-filled-track-color: red ;
    -slider-track-color: -slider-filled-track-color ;
  `
}`

const ValuesList = styled.div`
  display: flex;
  color:#6d7278;
  justify-content: space-around;
  margin: 0 0 auto 0;
  & > div {
    flex: 1;
  }
`;

const ValueContainer = styled.div`
  ${(props) => `
    background: ${props.active ? Colours.getColour("mainColor") : "#d3d3d3"};
    color: white;
    display: grid;
    flex-direction: column;
    align-items: center;
    width: 16vh;
    margin: 1vh 1vh 1vh 2vh;
    font-size: 3vh;
    padding: 0 1vh;
    border-radius: 1vh;
    position: relative;
    height:10vh;
    &::before {
      content: "";
      height: 2vh;
      width: 2vh;
      background: ${props.active ? Colours.getColour("mainColor") : "#d3d3d3"};
      position: absolute;
      left: -1vh;
      transform: rotate(45deg);
      top: calc(50% - 1vh);
    }
    @media (max-width: 426px) {
        display: none;
    }
  `}
`;

const IconSlider = styled.div`
  ${(props) => `
    width : 9vh;
    height: 9vh;
    border-radius: 50%;
    margin: 0.5vh 2vh 0.5vh 0.5vh;
    background-image: url("${props.img}");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;

    &:hover {
      background-color: #d3d3d370;
    }

    @media (max-width: 426px) {
      width : 7vh;
      height: 7vh;
      margin: 0.25vh;
    }
  `} 
`;

const IconSlidersWrapper = styled.div`
  display : initial;
`;

const VideoExplication = styled.div`
  ${(props) => `
    height: 3.5vh;
    background-color: ${Colours.getColour("mainColor")};
    border-radius:0.5rem;
    cursor:pointer;
    font-weight: 500;
    padding: 0.25vh 1vh;
    color: white;
    display:flex;
    font-size: 2.2vh;
  `}
`

const VideoImg = styled.img`
  margin: auto 1vh;
  height: 80%;
`

const VideoText = styled.div`
  @media (max-width: 426px) {
    display: none !important;
  }
`;

const OptionTag = styled.div`
  ${(props) => `
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-weight: ${props?.selected ? "bold" : "normal"};

    @media (max-width: 426px) {
      font-size: 1.5vh;
    }
  `}
`

const Slider = (props) => {

  const loadValuesList = (data) => {
    let views = [];
    data.forEach((component) => {
      let selected = component?.id === props?.state;
      views.push(
        <OptionTag key={component.id} selected={selected}>{ControlTrad.getUserTrad(component.name)}</OptionTag>
      );
    });
    return views;
  };

  const indexToOptionId = (index) => {
    return props.data.options[index].id
  }

  const optionIdToIndex = (id) => {
    let options = props.data.options;

    if (id === null || id === undefined) {
      return Math.floor(options?.length / 2);
    }

    for (let i = 0; i < options.length; i++) {
      if (options[i].id === id) return i;
    }
    return null;
  }

  const optionIdToText = (id) => {
    let options = props.data.options;
    for (let i = 0; i < options.length; i++) {
      if (options[i].id === id) return ControlTrad.getUserTrad(options[i].name);
    }
    return null;
  }

  const addAnswer = (e) => {
    props.addAnswer(props.data.id, indexToOptionId(e.target.value))
  }

  return (
    <Wrapper>
      <SliderContainer>

        <TitleContainer>
          <div style={{ display: "flex", flex: "1" }}>
            <Title styles={"margin-right:1vh;"}>{ControlTrad.getUserTrad(props.data.name)}</Title>
            <Delete
              onClick={() => {
                props.addAnswer(props.data.id, null)
              }}
              active={props.state}
            >
              {ControlTrad.getTrad("delete")}
            </Delete>
          </div>

          {Tasting.getType() === "podcast" && props.data.videoExplanationUrl !== null && props.data.videoExplanationUrl !== undefined ? (
            <div>
              <VideoExplication onClick={() => { props.viewVideo(props.data.videoExplanationId, ControlTrad.getUserTrad(props.data.name)) }}>
                <VideoImg src={video} />
                <VideoText>{ControlTrad.getTrad("viewExplication")}</VideoText>
              </VideoExplication>

            </div>
          ) : (
            <></>
          )}

        </TitleContainer>

        <RowWrapper>
          {props.data.icon ?
            <IconSlidersWrapper>
              <IconSlider img={props.data.icon} data={props.data} />
            </IconSlidersWrapper>
            : <></>}

          <ColumnWrapper imageShown={props.data.icon}>
            {/*User.getDevice() === "IPAD" || User.getDevice() === "IPHONE" ?
              <WrapperIphone
                type="range"
                max={props.data.options.length - 1}
                min="0"
                step="1"
                active={props.state}
                value={optionIdToIndex(props.state)}
                onClick={(e) => { addAnswer(e); }}
                onTouchEnd={(e) => { addAnswer(e); }}
                onInput={(e) => { addAnswer(e); }}
              />
              :*/
              <Input
                type="range"
                max={props.data.options.length - 1}
                min="0"
                step="1"
                active={props.state}
                value={optionIdToIndex(props.state)}
                onClick={(e) => { addAnswer(e); }}
                onTouchEnd={(e) => { addAnswer(e); }}
                onInput={(e) => { addAnswer(e); }}
              />
            }
            <ValuesList>{loadValuesList(props.data.options)}</ValuesList>
          </ColumnWrapper>
        </RowWrapper>


      </SliderContainer>
      <ValueContainer active={props.state}>
        {optionIdToText(props.state) || ControlTrad.getTrad("withoutValuing")}
      </ValueContainer>
    </Wrapper>

  );
};

export default Slider;
