import React, { useContext, useEffect, useState } from "react";
import { Keyboard } from 'react-native';
import styled from "styled-components";

import Websockets from "Websockets/Websockets";
import AppStateContext from "appState/AppStateContext";
import Tasting from "assets/tasting/Tasting";
import ControlTrad from "assets/trad/ControlTrad";
import FetchUtils from "Fetch/FetchUtils";
import User from "assets/user/User";

const Wrapper = styled.div`
  font-size: 4vw;
  height: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
  padding : 1vh 2vh;
`;

const Title = styled.div`
  font-weight: bold;
  padding: 0 0 1vh;
`;

const InputWrapper = styled.div`
    display:Flex;
    align-items:center;
    justify-content:center;
    flex-direction:row;
    margin: auto;
    padding: 1vh;
`;

const Input = styled.input`
  ${props => `
    width: 40%;
    border:none;
    background-color: rgb(230,230,230);
    padding: 1vh;
    border-radius: 1vh;
    outline: none;
    text-align:center;
    font-size: 6vw;
    transition: all 0.3s ease-in-out;
    ${props.styles}
  `}
`;

const TextBox = styled.span`
    font-size: 6vw;
    margin: auto 1vh;
    font-weight: 500;
`

const BlindPriceRateBoxResp = (props) => {
  const appState = React.useContext(AppStateContext);

  let currentProductRef, userGuess;

  if (Tasting.getType() === 'live') {
    currentProductRef = appState?.state.get(`currentWine`)?.reference;
    userGuess = appState?.state.get('currentUserAssessmentsParameters')?.blindPrice;
  } else {
    currentProductRef = props?.currentProductRef;
    userGuess = props?.blindPrice;
  }

  const [winePriceTag, setWinePriceTag] = useState(userGuess);
  const [saveAnswer, setSaveAnswer] = useState(true);

  const sendAnswer = (value) => {
    if(!props.setDataWine){
      Websockets.send("SLAVE_PRODUCT_PARAMETER", [
        currentProductRef,
        "blindPrice",
        value,
      ], false);
    } else {
      console.log("test");
      props.setDataWine((prev) => ({
        ...prev,
        [currentProductRef]: {
          ...prev[currentProductRef],
          ["blindPrice"]: value
        }
      }));
      FetchUtils.sendProductParameter(props.currentProductRef, "blindPrice", value);
    }
  };

  const onChange = (val) => {
    if(checkVal(val)){
        setWinePriceTag(val);
    }
  };

  const checkVal = (val) => {
      let numbers = /^\d*,?\d{0,2}$/;

      if(val.match(numbers) || val === "")
          return true;
      else
          return false;
  } 

  const getPriceTag = (data) => {
    if(data){
      if (data[currentProductRef]) {
        if (data[currentProductRef]["blindPrice"]) {
          return data[currentProductRef]["blindPrice"] || "";
        }
      }
    }

    return userGuess || "";
  }

  useEffect(() => {
    let priceTag = getPriceTag(props.params?.productData);
    setWinePriceTag(priceTag)
  }, [currentProductRef]);

  useEffect(() => {
    if (!props?.isShown && winePriceTag) {
      setSaveAnswer(true);
      sendAnswer(winePriceTag);
    }
  }, [props?.isShown]);


  const getFormatedValue = () => {
    if (saveAnswer && winePriceTag !== null && winePriceTag !== undefined && winePriceTag.length > 0) {
      let numString = winePriceTag.replace(",", ".");
      let num = Number(numString);

      if (!Number.isNaN(num)) {
        let formated = num.toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
        return formated;
      }
    }
    return winePriceTag;
  }

  return (
      <Wrapper>
        <Title>{ControlTrad.getTrad("blindTitleRateWine")}</Title>
        <InputWrapper>
        <Input
          inputMode={User.getDevice() === "IPHONE" ? "text": "decimal"}
          value={getFormatedValue()}
          readOnly={saveAnswer}
          placeholder={""}
          styles={saveAnswer ? (
            "background-color: #4d4d4d; color:white;"
          ) : (
            "background-color: rgb(211, 211, 211)"
          )}
          onKeyDown={(e) => {
            if (e.which == 13) {
              e.target.blur();
            }
          }}
          onBlur={() => {
            setSaveAnswer(!saveAnswer);
            sendAnswer(winePriceTag);
          }}
          onClick={() => {
            if (saveAnswer) setSaveAnswer(!saveAnswer)
          }}
          onChange={(e) =>
            onChange(e.currentTarget.value)
          } />

        <TextBox>{props.currency}</TextBox>
        </InputWrapper>
      </Wrapper>
  );
};

export default BlindPriceRateBoxResp;
