import React, { useState } from "react";
import { nanoid } from "nanoid";
import AppStateContext from "appState/AppStateContext";
import styled from "styled-components";

import ClockIcon from "assets/images/icons/ic_clock_black.svg";
import CheckIcon from "assets/images/icons/success-green.svg";
import PcIcon from "assets/images/icons/ic_pc.png";
import MobileIcon from "assets/images/icons/ic_mobile.jpg";
import TabletIcon from "assets/images/icons/ic_tablet.png";
import User from "assets/user/User";
import Element from "../Element";
import RateStars from "components/Shared/RateStarsOld";
import MicClosedIcon from "assets/images/icons/ic_mic_off_white.svg";
import MicOpenIcon from "assets/images/icons/ic_mic_on_white.svg";
import LoadingIcon from "assets/images/icons/ic_loading_white.svg";
import Websockets from "Websockets/Websockets";
import ModalMessage from "components/Sandbox/ModalMessage";
import Colours from "assets/custom/Custom";
import ControlTrad from "assets/trad/ControlTrad";
import angry from "assets/images/faces/angry.svg";
import halfAngry from "assets/images/faces/half_angry.svg";
import halfHappy from "assets/images/faces/half_happy.png";
import happy from "assets/images/faces/happy.png";

const Wrapper = styled.div`
  ${(props) => `
    width:100%;
    height:100%;
    background: rgb(243, 241, 241);
    display: flex;
    overflow-x:auto;
    ${props.params.styles}
  `}
`;

const Table = styled.table`
  flex: 1;
  margin: 0 2vh;
  font-size: 2.3vh;
  border-collapse: collapse;
  height: max-content;
  tr {
    border-bottom: 0.2vh solid #d3d3d3;
  }
  tr:last-child {
    border-bottom: 0.2vh solid transparent;
  }
  td {
    padding: 1vh 0;
  }
`;

const Header = styled.tr`
  font-size: 2vh;
`;

const Icon = styled.img`
  height: 3vh;
`;

const AssistantRows = styled.tr`
  ${(props) => `
    margin-bottom: 0.5vh;
    margin-top: 0.5vh;
    font-size:1.9vh;
    background:${ props.assistant.jitsi?.speakRequest ? "rgba(174, 28, 56, 0.8)" : "transparent" }
  `}
`;

const CategorySection = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  height: 100%;

  @media (max-width: 426px) {
    overflow: auto;
    grid-template-columns: repeat(1, 1fr);
  }
`;

const CategoryPop = styled.div`
  padding: 1vh 2vh 0 2vh;

  @media (max-width: 426px) {
    padding: 1vh 2vh;
  }
`;

const CategoryValorates = styled.div`
  display: flex;
  @media (max-width: 426px) {
    margin: 0.5vh 0;
  }
`;

const CategoryClick = styled.td`
  cursor: pointer;
`;

const AssistantHeaderTd = styled.td`
  font-weight: bold;
`;

const ParameterHeaderTd = styled.td`
  font-weight: bold;
  width: 20%;
  max-width: 20%;
  text-overflow: ellipsis;
`;

const ParameterValueTd = styled.td`
  width: 20%;
  max-width: 20%;
  text-overflow: ellipsis;
`;


const AssistantsList = (props) => {
  const JitsiStateIcon = styled.img`
    ${(props) => `
      height: 2.5vh;
      width: 2.5vh;
      padding: 0.5vh;
      border-radius: 50%;
      background: ${Colours.getColour("mainColor")};
      cursor: pointer;
      opacity: ${props.active ? "1" : "0.4"};
    `}
  `;

  const CategoryTitle = styled.div`
    font-size: 3vh;
    text-align: center;
    border-bottom: 0.1vh solid ${Colours.getColour("mainColor")};
    margin-bottom: 1vh;
    padding-bottom: 1vh;
  `;

  const appState = React.useContext(AppStateContext);
  let currentAssessmentCategories = appState.state.get("currentAssessmentCategories");
  let currentAssessmentParameters = appState.state.get("currentAssessmentParameters");
  const layoutType = appState.state.get("tastType") ? appState.state.get("tastType") : "CONVENTIONAL_TAST";
  const JITSI_ACTIVE = appState.state.get("jitsiActive");
  const currency = appState.state.get("currency") || "€";

  let wineReference = appState.state.get("currentWine")?.reference;
  let assistants = appState.state.get("assistants");

  const [popUp1, setPopUp1] = useState(false);
  const [idAssistant, setIdAssistant] = useState("");

  const facesOptons = {
    4: angry,
    3: halfAngry,
    2: halfHappy,
    1: happy
  }

  const treeOptions = {
    OPTION_LOW: {
      name: {
        CA: "Baixa",
        ES: "Baja",
        EN: "Low",
      },
    },
    OPTION_MEDIUM: {
      name: {
        CA: "Mitja",
        ES: "Media",
        EN: "Medium",
      },
    },
    OPTION_HIGH: {
      name: {
        CA: "Alta",
        ES: "Alta",
        EN: "High",
      },
    },
  };

  /** Returns the device icon
   *
   * @param {String} assistantDevice
   * @returns
   */
  const getDeviceType = (assistantDevice) => {
    //const isMobile = /IEMobile|Windows Phone|Lumia/i.test(ua) || /iPhone|iP[oa]d/.test(ua) && !window.MSStream || /Android/.test(ua) || /BlackBerry|PlayBook|BB10/.test(ua) || /Mobile Safari/.test(ua) || /webOS|Mobile|Tablet|Opera Mini|\bCrMo\/|Opera Mobi/i.test(ua);
    //const isTablet = /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(ua);
    console.log(assistantDevice);
    if (assistantDevice === "TABLET_WEB" || assistantDevice === "TABLET_APP")
      return TabletIcon;
    if (assistantDevice === "MOBILE_WEB" || assistantDevice === "MOBILE_APP")
      return MobileIcon;
    return PcIcon;
  };

  const audios = [
    {
      sound: "https://upwine.es/audios/instructor-micro-pedido.mp3",
      label: "audioInstructorMic",
    },
  ];

  const playSound = (src) => {
    const sound = new Audio(src);
    sound.load();
    sound.play();
  };

  const loadHeader = () => {
    let views = [];
    views.push(<AssistantHeaderTd key={nanoid()}></AssistantHeaderTd>); //loadingIcon
    views.push(<AssistantHeaderTd key={nanoid()}></AssistantHeaderTd>); //DeviceIcon
    views.push( // UserName
      <AssistantHeaderTd key={nanoid()}>
        {ControlTrad.getTrad("user")}
      </AssistantHeaderTd>
    );

    if (layoutType == "SIMPLE_TEMPLATE") {
      (currentAssessmentParameters || []).forEach((parameter) => {
        views.push(
          <ParameterHeaderTd key={nanoid()}>
            {ControlTrad.getUserTrad(parameter.name)}
          </ParameterHeaderTd>
        )
      });
    } else {
      currentAssessmentCategories?.forEach((category) => {
        views.push(
          <AssistantHeaderTd key={nanoid()}>
            {ControlTrad.getUserTrad(category.name)}
          </AssistantHeaderTd>
        )
      });
      views.push(
        <AssistantHeaderTd key={nanoid()}>
          {ControlTrad.getTrad("rateUser")}
        </AssistantHeaderTd>
      );
      views.push(
        <AssistantHeaderTd key={nanoid()}>
          {layoutType === "BLIND_TEMPLATE"
            ? ControlTrad.getTrad("price")
            : ControlTrad.getTrad("rateQualityPrice")}
        </AssistantHeaderTd>
      );
    }
    return views;
  };

  const loadAssistants = () => {
    let views = [];
    console.log("myAssistants", assistants);

    for (let key in assistants) {
      if (assistants[key].name !== "Desconocido") {
        if (assistants[key].jitsi?.speakRequest) playSound(audios[0].sound);
        views.push(
          <AssistantRows key={nanoid()} assistant={assistants[key]}>
            {loadRow(assistants[key], key)}
          </AssistantRows>
        );
      }
    }
    return views;
  };

  const loadCategoryResponse = (assistantResponse) => {
    if (!assistantResponse) return ClockIcon;
    else if (Object.entries(assistantResponse).length === 0) return ClockIcon;
    return CheckIcon;
  };

  const toggleAssistantPermission = (id) => {
    Websockets.send("JITSI_GIVE_PERMISSION", [id], true);
  };

  const loadRow = (assistant, key, isLobby) => {
    let views = [];
    let assistantRatePrice = assistant.assessments?.[wineReference]?.parameters.ratePrice;

    views.push(
      <td key={nanoid()}>
        {JITSI_ACTIVE ? (
          !assistant.jitsi?.ready ? (
            <JitsiStateIcon src={LoadingIcon} active={false} />
          ) : (
            <JitsiStateIcon
              src={
                assistant.jitsi.permissionGiven ? MicOpenIcon : MicClosedIcon
              }
              active={assistant.jitsi.permissionGiven}
              onClick={() => {
                toggleAssistantPermission(key);
              }}
            />
          )
        ) : (
          <></>
        )}
      </td>
    );
    views.push(
      <td key={nanoid()}>
        <Icon src={getDeviceType(assistant.platform)}></Icon>
      </td>
    );
    views.push(<td key={nanoid()}>{assistant.name}</td>);

    if (layoutType === "SIMPLE_TEMPLATE") {
      currentAssessmentParameters?.forEach((parameter) => {
        let optionId = assistant.assessments?.[wineReference]?.parameters?.[parameter.id];
        let optionText;

        if (parameter.type == "FACES") {
          optionText = <Icon src={facesOptons[optionId]} />
        } else {
          optionText = ControlTrad.getUserTrad(parameter?.options?.find(op => op.id === optionId)?.name);
        }

        views.push(
          <ParameterValueTd key={nanoid()}>
            {optionText ? (
              optionText
            ) : (
              <Icon src={ClockIcon} />
            )}
          </ ParameterValueTd>
        );
      });
    } else {
      currentAssessmentCategories?.forEach((category) => {
        views.push(
          <CategoryClick
            key={nanoid()}
            onClick={() => {
              viewAssetments(key);
            }}>
            {
              <Icon
                src={loadCategoryResponse(
                  assistant.assessments?.[wineReference]?.categories[category.id]
                )}
              />
            }
          </CategoryClick>
        );
      });
      views.push(
        <td key={nanoid()}>
          {
            <RateStars
              value={assistant.assessments?.[wineReference]?.parameters.rateBox}
              edit={false}
              size={2.5}
              color2={Colours.getColour("mainColor")}
            />
          }
        </td>
      );
      views.push(
        <td key={nanoid()}>
          {layoutType === "BLIND_TEMPLATE"
            ? assistant.assessments?.[wineReference]?.parameters.blindPrice
              ? assistant.assessments?.[wineReference]?.parameters.blindPrice +
              " " +
              currency
              : ""
            : assistantRatePrice
              ? assistantRatePrice
              : ControlTrad.getTrad("notRateQualityPrice")}
        </td>
      );
    }

    return views;
  };

  const viewAssetments = (key) => {
    setIdAssistant(key);
    setPopUp1(true);
  };

  const getLeafInfo = (leafs, leafId) => {
    for (let i = 0; i < leafs.length; i++) {
      let leaf = leafs[i];
      if (leaf.id === leafId) {
        return { name: leaf.name, icon: leaf.icon, id: leaf.id };
      } else {
        if ("options" in leaf) {
          let res = getLeafInfo(leaf.options, leafId);
          if (res !== false) return res;
        }
      }
    }
    return false;
  };

  const viewCategories = (categories) => {
    let views = [];

    for (const key in currentAssessmentCategories) {
      let title = (
        <CategoryTitle>
          {ControlTrad.getUserTrad(currentAssessmentCategories[key].name)}
        </CategoryTitle>
      );
      let valorate = [];
      for (const parm in currentAssessmentCategories[key]?.components) {
        if (
          categories?.[currentAssessmentCategories?.[key]?.id]?.[
          currentAssessmentCategories[key]?.components?.[parm]?.id
          ]
        ) {
          let referenceAssessment =
            categories?.[currentAssessmentCategories[key]?.id]?.[
            currentAssessmentCategories[key]?.components[parm].id
            ];
          if (
            currentAssessmentCategories[key]?.components[parm].type === "TREE"
          ) {
            if (referenceAssessment) {
              Object.keys(referenceAssessment).forEach((optionKeyTree) => {
                let leafInfo = getLeafInfo(
                  currentAssessmentCategories[key]?.components[parm].options,
                  optionKeyTree
                );
                let optionKeyValue =
                  ControlTrad.getUserTrad(treeOptions?.[referenceAssessment?.[optionKeyTree]]?.name);
                if (optionKeyValue) {
                  valorate.push(
                    <CategoryValorates>
                      <b>{ControlTrad.getUserTrad(leafInfo.name)}</b>
                      {optionKeyValue ? ": " + optionKeyValue : ""}
                    </CategoryValorates>
                  );
                }
              });
            }
            } else {
            for (let parm2 in currentAssessmentCategories[key]?.components[parm].options) {
              let assistantAssessment =
                currentAssessmentCategories[key]?.components[parm].options[
                parm2
                ];
              if (assistantAssessment.id === referenceAssessment) {
                let nameComponent = ControlTrad.getUserTrad(currentAssessmentCategories[key]?.components[parm].name);
                let valueComponent = ControlTrad.getUserTrad(assistantAssessment.name);
                  valorate.push(
                    <CategoryValorates>
                      <b>{nameComponent}</b>
                      {": " + valueComponent}
                    </CategoryValorates>
                  );
                }
              }
          }
        }
      }
      views.push(
        <CategoryPop>
          {title}
          {valorate}
        </CategoryPop>
      );
    }

    return <CategorySection>{views}</CategorySection>;
  };


  const viewParameters = (parameters) => {
    let views = [];

    return <CategorySection>{views}</CategorySection>
  }

  return (
    <>
      <Element params={props.params}>
        <Wrapper params={props.params}>
          <Table>
            <thead>
              <Header>{loadHeader()}</Header>
            </thead>
            <tbody>{loadAssistants()}</tbody>
          </Table>
        </Wrapper>
      </Element>
      <ModalMessage
        title={ControlTrad.getTrad("assistant") + ": " + assistants[idAssistant]?.name}
        isShown={popUp1}
        setIsShown={setPopUp1}
        action={() => {
          setIdAssistant(null);
        }}
        boxSize={"width:60% !important; max-width:80%; height: 35%;"}
        media={"width:90vw !important;"}
        styleMain={"height:84%; "}>
        <div>
          {layoutType === "SIMPLE_TEMPLATE" ? (
            viewParameters(assistants[idAssistant]?.assessments[wineReference]?.parameters)
          ) : (
            viewCategories(assistants[idAssistant]?.assessments[wineReference]?.categories)
          )}
        </div>
      </ModalMessage>
    </>
  );
};

export default AssistantsList;
