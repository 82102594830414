import React from "react";

import styled from "styled-components";
import TrophyImg from "assets/images/icons/trophy.svg"
//import winner from "assets/audios/sound_quiz_winner.mp3";
import AppStateContext from "appState/AppStateContext";
import ControlTrad from "assets/trad/ControlTrad";

const Rank = styled.div`
    text-align : center;
    color: white;
    height:100%;
    display:flex;
    flex-direction:column;
    background-color: transparent;
    padding : 0 2vh;
    justify-content: flex-end;
    @media (max-width: 426px) {
        padding : 0 0.5vh;
    }
`;

const RankHead = styled.div`
    text-align : center;
    font-size: 2.5vh;
    padding 1vh;
    font-weight:bold;
    flex: 1;

    @media (max-width: 426px) {
        font-size: 2vh;
    }
`;
const One = styled.div`
width: 100%;
height:100%;
background-color: white;
display: flex;
@media (max-width: 426px) {
    flex : 1;
}
`
const Two = styled.div`
width: 100%;
height: 50%;
background-color: white;
@media (max-width: 426px) {
    height: 25%;
}
`;
const Three = styled.div`
width: 100%;
height: 25%;
background-color: white;
@media (max-width: 426px) {
    height: 10%;
}
`;
const RankTitle = styled.div`
    text-align : center;
    font-size: 2.5vh;
`;

const Trophy = styled.img`
margin: auto;
width:6vh;
`;
//filter: hue-rotate(${hxToHls(colours.accent1)}deg);


const QuizRank = (props) => {
    const appState = React.useContext(AppStateContext);
    let numQuestions = appState.state.get("quizNumQuestions");
    let quizIndexQuestion = appState.state.get("quizIndexQuestion");
    const state = appState.state.get("state");


    return (
        <Rank params={props.params}>
            <RankHead>
                {props.position}<br/>
                {ControlTrad.getTrad("position")}
            </RankHead>
            {props.position === 1 ? 
            <One>
                <Trophy src={TrophyImg}/>
            </One> : <></>}
            {props.position === 2 ? <Two/> : <></>}
            {props.position === 3 ? <Three/> : <></>}
            <RankTitle>{props?.user !== undefined  ? props?.user.split(" ").length !== 0 ? props?.user.split(" ")[0] : props?.user : props?.user}</RankTitle>
        </Rank>
    );

}
export default QuizRank;