import React, { useState } from "react"
import styled from "styled-components";
import ControlTrad from "assets/trad/ControlTrad";

const DivCheck = styled.div`
padding:2vh 0 0 ;

`

const Check = styled.input`
color: white;
width: 2vh;
height: 2vh;
@media (pointer: coarse) and (min-width:426px) and (orientation: landscape) : {
    width: 1rem;
    height: 1rem;
    marginTop: 0;
}
@media (max-width:426px): {
    width: 1rem;
    height: 1rem;
    marginTop: 0;
}
`
const Label = styled.label`
font-size: 2vh;
color: #000;
@media (max-width:426px) {
    font-size: 1rem;
}
`
const LabelLinks = styled.a`
font-size: 2vh;
color: #000;
@media (max-width:426px) {
    font-size: 1rem;
}
`

const CheckTermsUserInfo = () => {

    return (
        <>
            <DivCheck>
                <Check type={"checkbox"} id={"checkTerm"}></Check>
                <Label for={"checkTerm"}>
                    {ControlTrad.getTrad("terms")+" "}
                    <b>
                        <LabelLinks href={"http://terms.upwine.es/upwine/upwinesoftware-catas-condiciones-uso.html"}
                            target={"_blank"}
                            rel="noopener noreferrer">
                            {ControlTrad.getTrad("conditions1")}
                        </LabelLinks>
                    </b>
                    {" "+ ControlTrad.getTrad("conditions2") +" "}
                    <b>
                        <LabelLinks href={"http://terms.upwine.es/upwine/upwinesoftware-politica-privacidad.html"}
                            target={"_blank"}
                            rel="noopener noreferrer">
                            {ControlTrad.getTrad("conditions3")}
                        </LabelLinks>
                    </b>
                </Label>
            </DivCheck>
        </>
    );

}
export default CheckTermsUserInfo;