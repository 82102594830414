import React from "react"
import styled from "styled-components";

import Element from "../Element";
import RateStars from 'components/Shared/RateStarsOld';

import AppStateContext from "appState/AppStateContext";
import Custom from "assets/custom/Custom";

const Wrapper = styled.div`
  font-size: 2.5vh;
  height:100%;
  width:100%;
  display:flex;
  flex-direction: row;
  text-align:center;
  align-items:center;
  justify-content:center;
`;
const Number = styled.div`
  margin-top: 0.6vh;
  font-size: 3vh;
  font-weight: bold;
`

const AverageRate = (props) => {
  
  const appState = React.useContext(AppStateContext);

  const rateBoxValue = appState.state.get('aggregatedParameters')?.rateBox?.avg || 0;

  return (
    <Element params={props.params} >
      <Wrapper>
        <RateStars
          value={rateBoxValue}
          edit={false} 
          color2={Custom.getColour("mainColor")}
          />
          <Number>({rateBoxValue})</Number>
      </Wrapper>
    </Element>
  );
};

export default AverageRate;