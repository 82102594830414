import ControlTrad from "assets/trad/ControlTrad";
import React from "react"
import styled from "styled-components";

import Element from "../Element";

const Wrapper = styled.div`
    ${props=>`
        height: 100%;
        width: 100%;
        display:flex;
        flex-direction:column;
        ${props?.styles}
`}`

const WrapperBox = styled.div`
  ${props=>`
    background: black;
    border:0.1vh solid white;
    height: 100%;
    width: 100%;
    display:flex;
    text-align: left;
    align-items: start;
    color:white;
    border-radius: 1vh;
    ${props?.styles}
`}`



const InnerText = styled.span`
  font-size:2vh;
  margin:1vh;
`

const TitleText = styled.span`
  font-size:2.5vh;
  text-align:left;
  color:white;
  margin-bottom:0.5vh;
`

const Observations = (props) => {

  return (
    <Element params={props.params}>
        <Wrapper>
          <TitleText>{ControlTrad.getTrad("observations")}</TitleText>
          <WrapperBox>
            <InnerText>{ControlTrad.getTrad("observationsText1")}<br/> {ControlTrad.getTrad("observationsText2")}<br/><br/> {ControlTrad.getTrad("observationsText3")}</InnerText>
          </WrapperBox>
        </Wrapper>
    </Element>
  );
};

export default Observations;