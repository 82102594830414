import Element from "../Element";

import styled from "styled-components";
import Custom from "assets/custom/Custom";

const Inner = styled.div`
  ${props => `
    height: 100%;
    width: 100%;
    background-image: url("${props.src}");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;

    ${props.params.styles}
  `}
`;
const UpwineLogo = (props) => {

  return (
    <Element params={props.params}>
      {Custom.logo ?
        <Inner params={props.params} src={Custom.logo} />
        : 
        <></>
      } 
    </Element>
  );
};

export default UpwineLogo;
