import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import AppStateProvider from "appState/AppStateProvider";
import Websockets from "Websockets/Websockets";
import Tasting from "assets/tasting/Tasting"
import InstructorComponent from "./InstructorComponent";
import Fetch from "Fetch/Fetch";
import Firebase from "../../Firebase";

function InstructorEngine() {
  const [loading, setLoading] = useState(true);
  let history = useHistory();

  let isPublic = window.location.href.split("/")[3] === "publicInstructor";
  let id = window.location.href.split("/")[4];
  let f = new Firebase().firebase.default;
  Tasting.setPublicCode(id);
  Tasting.setType("live");

  useEffect(() => {
    let loggedUser = f.auth().currentUser;

    if (loggedUser !== null && loggedUser !== undefined && !isPublic) {
      Fetch.getCodeInfo(id).then((res) => {
        let sessionOwnerId = res.reference.split("/")[1];
        let loggedUserId = loggedUser.uid;

        if (sessionOwnerId === loggedUserId) {
          setLoading(true);
        } else {
          console.error("The current session does not belong to the logged user.\n\tLogged user ID: " + loggedUserId + "\n\tSession owner ID: " + sessionOwnerId);
          history.replace('/instructorHome');
        }
      })
    }
    Websockets.connect(true);
  }, [f.auth().currentUser]);
  
 
  return (
      <AppStateProvider>
        {loading ? <InstructorComponent /> : <></>}
      </AppStateProvider>
  );
  
 
}

export default InstructorEngine;
