import React from "react";
import AppStateContext from "appState/AppStateContext";

import Element from "../Element";

import styled from "styled-components";
import Tasting from "assets/tasting/Tasting";

const Wrapper = styled.div`
  font-size: 4.5vh;
  color: white;
  @media (max-width: 426px) {
    font-size: 3vh;
  }
`;

const WineName = (props) => {
  const appState = React.useContext(AppStateContext);
  const currency = props.params?.currency ? props.params?.currency : appState.state?.get("currency");
  const currentWine = Tasting.getType() === "live" ? appState.state?.get("currentWine") : props.params.price;
  const visibility = props.params.visibility ? props.params.visibility.visible : currentWine?.visible;
  const price = currentWine?.product?.price;

  const getCurrency = () => {
    if(currency !== null && currency !== undefined){
      return currency
    } else {
      return "€"
    }
  }

  return (
    <Element params={props.params}>
      <Wrapper params={props.params}>
        {visibility && price !== undefined && price !== null && typeof price !== "object" ? (
          price.toString().replace('.', ',') + getCurrency()
        ) : (
          <></>
        )}
      </Wrapper>
    </Element>
  );
};

export default WineName;
