import ControlTrad from "assets/trad/ControlTrad";
import LoadingComponent from "components/Composer/Elements/LoadingComponent/LoadingComponent";
import Fetch from "Fetch/Fetch";
import Colours from "assets/custom/Custom";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import styled from "styled-components";

const PublicTastingEngine = () => {
    let { uid, tid } = useParams();
    let history = useHistory();
    const [errorMsg, setErrorMsg] = useState(false);

    const Wrapper = styled.div`
        text-align:center;
        display: flex;
        align-items:center;
        justify-content:center;
        height:100vh;
        flex-direction:column;
        color: white;
        &>*{
            margin: 1vh 2vh;
        }
        @media (max-width: 426px) {
            flex-direction : column;
            overflow: auto;
            justify-content: normal;
        }
    `;

    const ErrorHeader = styled.div`
        font-size: 5vh;
        font-weight: bold;
        margin: auto;
        padding: 2vh;
    `;

    const Error = styled.div`
        font-size: 3vh;
        margin: auto;
        padding: 2vh;
    `;

    const ReturnBtn = styled.div`
        width: fit-content;
        cursor: pointer;
        background:  ${Colours.getColour("mainColor")};
        padding: 1.5vh 4vh;
        margin: 2vh auto;
        border-radius: 0.7vh;
        font-size: 2.5vh;
        color: white;
    `;

    const generatePublicSession = () => {
        Fetch.generateDemoSession(uid, tid, true).then((res) => {
            console.log("Public session created", res);
            onLoad(res.publicCode);
        }).catch((error) => {
            console.error("Error creating public session", error);
            onFail(error);
        });
    }

    const onLoad = (code) => {
        history.replace(`/publicInstructor/${code}`);
    }

    const onFail = (error) => {
        if (error.exception === "PUBLIC_ACCESS_DISABLED") {
            setErrorMsg(ControlTrad.getTrad("errorPublicAccess"));
        } else {
            setErrorMsg(ControlTrad.getTrad("errorOtherPublicAccess"));
        }
    }

    const goBack = () => {
        history.replace(`/`);
    }

    useEffect(() => {
        if (uid !== null && uid !== undefined && tid !== null && tid !== undefined) {
            generatePublicSession();
        }
    }, [uid, tid]);

    return (
        <Wrapper>
            {errorMsg ? (
                <div>
                    <ErrorHeader>{ControlTrad.getTrad("error")}</ErrorHeader>
                    <Error>{errorMsg}</Error>
                    <ReturnBtn onClick={goBack}>{ControlTrad.getTrad("back")}</ReturnBtn>
                </div>
            ) : (
                <LoadingComponent params={{
                    position: "1/1/100/100",
                    active: true,
                    ready: () => { }
                }} />
            )}
        </Wrapper>
    );
};

export default PublicTastingEngine;