import User from "assets/user/User";
function StateFinder(state, parameters) {
  let [action, params] = parameters.split("/");

  state = state.state;
  switch (action) {
    case "isReady":
      return isReady();
    case "tastingId":
      return getTastingId();
    case "wines":
      return getWines();
    case "currentWine":
      return getCurrentWine();
    case "quizInfo":
      return getQuizInfo();
    case "quizCurrentIndex":
      return getQuizCurrentIndex();
    case "quizCurrentQuestion":
      return getQuizCurrentQuestion();
    case "assistantQuizInfo":
      return getAssistantQuizInfo();
    case "wineIndex":
      return getWineIndex();
    case "currency":
      return getCurrency();
    case "winesCount":
      return getWinesCount();
    case "assistants":
      return getAssistants();
    case "sizeAssistants":
      return getSizeAssistants();
    case "tasting":
      return getTasting();
    case "recommendations":
      return getRecommendations();
    case "tastType":
      return getTastType();
    case "currentWineAssessments":
      return currentWineAssessments();
    case "currentUserAssessments":
      return currentUserAssessments();
    case "currentUserAllAssessments":
      return currentUserAllAssessments();
    case "currentUserAssessmentsParameters":
      return currentUserAssessmentsParameters();
    case "currentAssessmentParameters":
      return currentAssessmentParameters();
    case "currentAssessmentCategories":
      return currentAssessmentCategories();
    case "assessmentComponents":
      return assessmentComponents();
    case "aggregatedAssessmentsAnswers":
      return aggregatedAssessmentsAnswers();
    case "aggregatedParameters":
      return aggregatedParameters();
    case "quizPrize":
      return quizPrize();
    case "quizTimer":
      return quizTimer();
    case "quizNumQuestions":
      return quizNumQuestions();
    case "quizIndexQuestion":
      return quizIndexQuestion();
    case "quizIsFinal":
      return quizIsFinal()
    case "quizActualQuestion":
      return quizActualQuestion();
    case "quizActualQuestionReference":
      return quizActualQuestionReference();
    case "quizAnswerUser":
      return quizAnswerUser();
    case "quizPointsUser":
      return quizPointsUser();
    case "jitsiConfig":
      return getJitsiConfig();
    case "assistantJitsiConfig":
      return getAssistantJitsiConfig();
    case "chat":
      return messagesChat();
    case "socialMedia":
      return socialMedia();
    case "state":
      return state.state;
    case "jitsiActive":
      return jitsiActive();
    case "doubts":
      return doubts();
    case "surveyFinalActive":
      return surveyFinalActive()
    case "storeActive":
      return storeActive();
    case "theme" : 
      return themeParams();
    case "isConnectedInstructor":
      return isConnectedInstructor();
    case "getRecommendAssistant":
      return getRecommendationAssistant();
    default:
      return state;
  }

  function isReady() {
    return Object.keys(state || {}).length > 1;
  }
  function getTastingId(){
    return state.userId;
  }
  function getWines() {
    return state.tasting.productReferences;
  }
  function getWineIndex() {
    return state.wineIndex;
  }
  function getQuizInfo() {
    let obj = {
      wineIndex: state.wineIndex,
      quizQuestionIndex: state.quizQuestionIndex,
      quizTime: state.quizTime,
      quizLength: getQuizQuestions()?.length,
    };
    return obj;
  }
  function getQuizCurrentIndex() {
    return state.quizQuestionIndex;
  }
  function getAssistantQuizInfo() {
    let ass = state.assistants[User.getId()];
    return {
      quizPoints: ass.quizPoints,
      quizAnswers: ass.quizAnswers,
    };
  }
  function getQuizQuestions() {
    return getCurrentWine()?.quizQuestions;
  }
  function getQuizCurrentQuestion() {
    return getQuizQuestions()?.[getQuizCurrentIndex()];
  }
  function getCurrentWine() {
    return getWines()[getWineIndex()];
  }
  function getWinesCount() {
    return getWines().length;
  }
  function getAssistants() {
    return state.assistants;
  }
  function getSizeAssistants() {
    return Object.keys(state.assistants).length;
  }
  function getTasting() {
    return state.tasting;
  }
  function getCurrency() {
    return state.currency;
  } 
  function getRecommendations() {
    return state.recommendations;
  }
  function getTastType() {
    return getTasting().template;
  }
  function currentWineAssessments() {
    let obj = [];
    for (let key in state.assistants) {
      let assistant = state.assistants[key];
      obj.push([
        assistant.name,
        assistant.assessments[getCurrentWine().reference],
      ]);
    }
    return obj;
  }

  function currentUserAllAssessments() {
    return state.assistants[User.getId()].assessments[
      getCurrentWine().reference
    ];
  }
  function currentUserAssessmentsParameters() {
    return currentUserAllAssessments().parameters;
  }
  function currentUserAssessments() {
    return currentUserAllAssessments().categories;
  }
  function currentAssessmentCategories() {
    let categories = getWines()[getWineIndex()].assessmentSheet.categories;
    if(categories != null && typeof categories === "object") {
      categories = Object.values(categories);
    }
    return categories;
  }
  function currentAssessmentParameters() {
    let parameters = getWines()[getWineIndex()].assessmentSheet.parameters;
    if (parameters != null && typeof parameters === "object") {
      parameters = Object.values(parameters);
    }
    return parameters;
  }
  function aggregatedAssessmentsAnswers() {
    return state.aggregatedData[getCurrentWine().reference].categories;
  }
  function assessmentComponents() {
    return currentAssessmentCategories().find((item) => item.id === params)
      .components;
  }
  function aggregatedParameters() {
    return state.aggregatedData[getCurrentWine().reference].parameters;
  }
  function quizPrize() {
    return getCurrentWine()?.quizPrize;
  }
  function quizTimer() {
    return state.quizTime;
  }
  function quizNumQuestions() {
    return getQuizQuestions()?.length;
  }
  function quizIndexQuestion() {
    return state.quizQuestionIndex;
  }
  function quizIsFinal() {
    let isFinal = quizNumQuestions() === state.quizQuestionIndex + 1 && state.state === "QUIZ_SCORE";
    return isFinal;
  }
  function quizActualQuestion() {
    return getQuizQuestions()?.[quizIndexQuestion()];
  }
  function quizActualQuestionReference() {
    return quizActualQuestion()?.reference;
  }
  function quizPointsUser() {
    return state.assistants[User.getId()].quizPoints;
  }
  function quizAnswerUser() {
    return state.assistants[User.getId()].quizAnswer[quizIndexQuestion()];
  }
  function getJitsiConfig() {
    return state.jitsi;
  }
  function getAssistantJitsiConfig() {
    return state.assistants[User.getId()].jitsi
  }
  function messagesChat() {
    return state.chatMessages;
  }
  function socialMedia() {
    return state.socialMedia;
  }
  function jitsiActive() {
    return state.jitsi.active;
  }
  function doubts() {
    return state.doubts;
  }
  function surveyFinalActive() {
    return state.satisfactionActive;
  }
  function storeActive() {
    return state.store.storeActive;
  }
  function themeParams() {
    return state.theme;
  }
  function isConnectedInstructor(){
    return state.instructor?.websockets?.connected;
  }
  function getRecommendationAssistant() {
    return state.assistants[User.getId()]?.recomendation;
  }
}

export default StateFinder;
