import React from "react";

import Element from "../Element";
import Button from "components/Shared/Button"
import Websockets from "Websockets/Websockets";
import AppStateContext from "appState/AppStateContext";

const IButtonStartJitsi = (props) => {
    const appState = React.useContext(AppStateContext);
    const jitsiActive = appState.state.get("jitsiActive");

    const controlJitsi = () => {
        Websockets.send('JITSI_START', [],true);
    }

    return (
        <Element params={props.params}>
            <Button value={jitsiActive ? props.params.active : props.params.text } onClick={() => controlJitsi()} />
        </Element>
    )
}
export default IButtonStartJitsi;